import { isNumber, isString } from './types'

/**
 * Get initials of name or string provided
 * @param {string} name The name to get initials from
 * @param {number} maxLength maximum length of the initials
 * @returns {string} the initials of the name
 *
 * @example
 * initials('John Doe') // 'JD'
 * initials('John Doe', 1) // 'J'
 * initials('John Doe', 3) // 'JDO'
 */
export function initials(name, maxLength = 2) {
  if (!isString(name)) {
    return ''
  }

  return name
    .split(' ')
    .map(namePart => namePart.charAt(0).toUpperCase())
    .slice(0, maxLength)
    .join('')
    .toUpperCase()
}

/**
 * Pseudo-random id generator
 * @param {number} length id's length (in chars)
 * @param {Array} except list of ids that should be avoided
 * @returns {string} the generated
 *
 * @example
 * // Generate an 8 characters long id
 * const a = uid() // '8j3n4f9d'
 *
 * // Generate a 10 characters long id
 * const b = uid(10) // '8j3n4f9d1a'
 *
 * // Avoid generating the same id
 * uid(10, [a, b]) // '8j3n4f9d2a'
 */
export function uid(length = 8, except = []) {
  if (!isNumber(length) || length <= 0) {
    throw new Error('uid: The length must be a positive number')
  }

  // Generate the id using:
  const id = Math.random() // a random number
    .toString(36) // convert it to base 36 (0-9a-z)
    .slice(-length) // get the last n characters to get the desired length

  // if the id is included in the list Try again if the id is in the except list
  if (except.includes(id)) {
    return uid(length, except)
  }

  return id
}

/**
 * Validates an id as a mongodb id and returns a boolean
 * @param {string} id - the id to validate as a mongodb id
 * @returns {boolean} true if the id is a valid mongodb id
 *
 * @example
 * validateMongoId('5f8f9a4d1c9d440000f7b4c3') // true
 * validateMongoId('5f8f9a4d1c9d440000f7b4c') // false (missing one character at the end)
 */
export function validateMongoId(id) {
  if (!id) return false

  const regex = /^[0-9a-fA-F]{24}$/

  return regex.test(id)
}

/**
 * Capitalizes first letter of the string
 * @param {string} string The string to capitalize
 * @returns {string} the string with the first letter capitalized
 *
 * @example
 * capitalize('hello') // 'Hello'
 * capitalize('world') // 'World'
 * capitalize('hello world') // 'Hello world'
 */
export function capitalize(string) {
  if (!isString(string)) {
    return ''
  }

  return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

/**
 * Converts a string into a slug[ish] String
 *
 * @param {string} text The string to convert in a slug
 * @param {object} options Options object
 * @param {number} options.maxLength - Maximum slug string lenght
 * @param {boolean} options.addDashBetweenCharAndNumber - Add a dash between a number and a character that is not a number (except -)
 * @returns {string} the slugified string
 *
 * @example
 * slugify('Hello World') // 'hello-world'
 * slugify('Hello World', { maxLength: 5 }) // 'hello'
 * slugify('Hello123 World') // 'hello123-world'
 * slugify('Hello123 World', { addDashBetweenCharAndNumber: true }) // 'hello-123-world'
 */
export function slugify(
  text,
  { maxLength, addDashBetweenCharAndNumber = false } = {}
) {
  let slug = text
    .normalize('NFKC') // Replaces special characters with their normal version
    .replace(/[°<>#%{}()|\\^~[\]`'?=:;]/g, '') // Removes invalid characters to make it a valid URL

    // Replace spaces with dashes
    .replace(/ /g, '-') // Replaces spaces with dashes

  if (addDashBetweenCharAndNumber) {
    // Adds a dash between a number and a character that is not a number (except -)
    slug.replace(/([^\d-])(?=\d)|(\d)(?![\d-]|$)(?=[^\d-])/g, '$1$2-')
  }

  // Cleans up the slug
  slug = slug
    .toLowerCase() // Converts to lowercase to avoid case sensitive issues
    .replace(/\/+/g, '') // Removes slashes
    .replace(/\s+/g, '-') // Replaces spaces with dashes
    .replace(/[,!"§$&_+*]/g, '-') // Replaces some special characters with dashes
    .replace(/--+/g, '-') // Replaces two or more dashes with a single one
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
    .replace(/\u{2D}\u{301}/gu, '') //Replaces acute accent when it's combined with a dash, beacuse this character appears when you type an acute accent without any other character

  // Truncates the slug if maxLength is provided
  if (isNumber(maxLength) && slug.length > maxLength) {
    slug = slug.slice(0, maxLength).replace(/-+$/, '') // Trim - from end of text
  }

  return slug
}

/**
 * Encodes a given string in HEX
 * @param {string} value string to encode
 * @returns {string} encoded string
 */
export function stringToHex(value) {
  return `${value || ''}`
    .split('') // Split the string into an array of characters
    .map(char => char.charCodeAt(0).toString(16).padStart(2, '0')) // Convert each character to its ASCII code and then to HEX
    .join('') // Join the HEX values to form the final HEX string
}

/**
 * Decodes an HEX string
 * @param {string} hex hex string to decode
 * @returns {string} decoded hex
 */
export function hexToString(hex = '') {
  return hex
    .split(/(\w\w)/g) // Split the HEX string into an array of 2 characters
    .filter(p => !!p) // Remove empty strings
    .map(c => String.fromCharCode(parseInt(c, 16))) // Convert each HEX value to its ASCII character
    .join('') // Join the characters to form the final string
}

/**
 * Escapes all special characters in the provided string
 * @param {string} string string to sanitize.
 * @returns {string} escaped string
 */
export function escapeSpecialChars(string = '') {
  if (!isString(string)) {
    return ''
  }
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}
