import PropTypes from 'prop-types'
import React from 'react'

import { parseISO } from 'date-fns'
import { Controller, useFormContext } from 'react-hook-form'

import Field from '../Field'
import { useRules } from '../validationHooks'
import { DateRange } from './components/DateRange'
import { DateTime } from './components/DateTime'

export function DateTimeField({
  className,
  disabled,
  help,
  label,
  labelPosition,
  maxValue,
  minValue,
  name,
  required,
  type,
  value,
  onChange,
  fullWidth,
}) {
  const minDate = minValue
    ? typeof minValue === 'string'
      ? parseISO(minValue)
      : minValue
    : undefined

  const maxDate = maxValue
    ? typeof maxValue === 'string'
      ? parseISO(maxValue)
      : maxValue
    : undefined

  return (
    <Field
      className={className}
      name={name}
      label={label}
      help={help}
      required={required}
      disabled={disabled}
      labelPosition={labelPosition}
    >
      {type === 'daterange' ? (
        <DateRange
          id={name}
          disabled={disabled}
          max={maxDate}
          min={minDate}
          name={name}
          onChange={onChange}
          value={value}
        />
      ) : (
        <DateTime
          id={name}
          disabled={disabled}
          max={maxDate}
          min={minDate}
          name={name}
          onChange={onChange}
          type={type}
          value={value}
          fullWidth={fullWidth}
        />
      )}
    </Field>
  )
}
DateTimeField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  help: PropTypes.string,
  maxValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  minValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['top', 'left', 'right']),
  onChange: PropTypes.func,
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({ value: PropTypes.bool, message: PropTypes.string }),
  ]),
  type: PropTypes.oneOf(['date', 'datetime', 'daterange', 'time']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fullWidth: PropTypes.bool,
}

export default function DateTimeController({
  className,
  disabled,
  help,
  label,
  labelPosition,
  max,
  maxField,
  min,
  minField,
  name,
  required,
  shouldUnregister,
  type = 'datetime',
  validate,
  value,
}) {
  const { control, watch } = useFormContext()

  const maxValue = maxField ? watch(maxField) : max
  const minValue = minField ? watch(minField) : min

  const rules = useRules({ required, validate })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <DateTimeField
          className={className}
          name={name}
          label={label}
          labelPosition={labelPosition}
          error={fieldState.error}
          help={help}
          required={rules?.required?.value}
          disabled={disabled}
          maxValue={maxValue}
          minValue={minValue}
          type={type}
          value={field.value}
          onChange={field.onChange}
        />
      )}
    />
  )
}

DateTimeController.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  help: PropTypes.string,
  max: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  maxField: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  minField: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['top', 'left', 'right']),
  onChange: PropTypes.func,
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({ value: PropTypes.bool, message: PropTypes.string }),
  ]),
  shouldUnregister: PropTypes.bool,
  type: PropTypes.oneOf(['date', 'datetime', 'daterange', 'time']),
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}
DateTimeController.displayName = 'DateTime'
