import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { stringToHEX } from '@utils/colors'
import { getImageUrl } from '@utils/images'
import { initials } from '@utils/strings'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const avatar = {
  'xs': 'w-5 h-5 ring-1',
  'sm': 'w-8 h-8 ring-1',
  'md': 'w-12 h-12 ring-2',
  'lg': 'w-16 h-16 ring-2',
  'xl': 'w-24 h-24 ring-4',
  '2xl': 'w-32 h-32 ring-4',
}

const imageSizes = {
  'xs': 'w:40',
  'sm': 'w:64',
  'md': 'w:96',
  'lg': 'w:128',
  'xl': 'w:184',
  '2xl': 'w:198',
}

const initialsClass = {
  'xs': 'text-xs transform scale-75',
  'sm': 'text-xs',
  'md': 'text-2xl',
  'lg': 'text-3xl',
  'xl': 'text-5xl',
  '2xl': 'text-6xl',
}

const iconClass = {
  'xs': 'text-xs mb-px transform scale-75',
  'sm': 'text-base mb-1',
  'md': 'text-3xl mb-1',
  'lg': 'text-5xl mb-1',
  'xl': 'text-7xl mb-2',
  '2xl': 'text-8xl mb-2',
}

export default function Avatar({
  className = '',
  size = 'md',
  name = '',
  icon = 'user',
  image,
  imageUrl,
}) {
  const imgSrc = useMemo(() => {
    if (!(image || imageUrl)) return ''
    return imageUrl || getImageUrl(image, imageSizes[size] || imageSizes.md)
  }, [image, imageUrl, size])

  return (
    <div
      className={`rounded-full ring-1 ring-gray-300 ${avatar[size]} ${className}`}
    >
      {imgSrc ? (
        <img
          alt={name}
          title={name}
          className="h-full w-full rounded-full object-cover object-center"
          src={imgSrc}
        />
      ) : (
        <div
          className="flex h-full w-full items-center justify-center rounded-full bg-gray-200 text-white"
          style={{ backgroundColor: name ? stringToHEX(name) : '' }}
          title={name}
        >
          {name ? (
            <span className={`font-semibold ${initialsClass[size]}`}>
              {initials(name, size === 'xs' || size === 'sm' ? 1 : 2)}
            </span>
          ) : (
            <span className={iconClass[size]}>
              <Icon name={icon} />
            </span>
          )}
        </div>
      )}
    </div>
  )
}

Avatar.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  image: PropTypes.shape({
    container: PropTypes.string,
    name: PropTypes.string,
    mime: PropTypes.string,
    extension: PropTypes.string,
  }),
  imageUrl: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  name: PropTypes.string,
}
