import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { useDesign } from '@app/designs/hooks/useDesign'
import Button from '@ui/buttons/Button'
import Section from '@ui/data-display/Section'
import ColorPalette from '@ui/data-entry/ColorPalette'
import Form from '@ui/data-entry/Form'
import Submit from '@ui/data-entry/Submit'
import Heading from '@ui/typography/Heading'

import { CustomColorsField } from './components/CustomColorsField'

function isPaletteSet(colors = {}) {
  return Object.keys(colors || {}).length > 0
}

export function ColorsForm({ site, onSubmit }) {
  const { t } = useTranslation(['designs/adventist', 'designs/base'])
  const design = useDesign({ design: 'adventist', site })

  const { colors } = site?.design || {}

  const handleSubmit = React.useCallback(
    formData => {
      const customColors = formData.customColors.reduce(
        (acc, { code, ...color }) => {
          return {
            ...acc,
            [code]: color,
          }
        },
        {}
      )

      onSubmit?.({
        ...site,
        design: {
          ...site.design,
          colors: {
            ...formData.colors,
            ...customColors,
          },
        },
      })
    },
    [onSubmit, site]
  )

  const formData = React.useMemo(() => {
    const customColors = Object.entries(site.design?.colors || {})
      .filter(([key]) => key.startsWith('color_'))
      .reduce((acc, [key, value]) => {
        if (Object.keys(value || {}).length === 0) {
          return acc
        }
        return [...acc, { ...value, code: key }]
      }, [])

    return {
      colors: design.colors,
      customColors,
    }
  }, [design.colors, site])

  const onReset = React.useCallback(() => {
    const initialData = { ...formData }
    delete initialData.design.colors

    onSubmit(initialData)
  }, [formData, onSubmit])

  return (
    <Section title={t('designs/base:colors')}>
      <Form
        data={formData}
        onSubmit={handleSubmit}
        id={`${site.id}-${site.updatedAt}`}
      >
        <Section.Body>
          <div className="flex flex-col gap-16">
            <CustomColorsField />

            <div className="flex flex-col gap-8">
              <Heading as="h4">{t('systemColors')}</Heading>
              <ColorPalette
                name="colors.neutral"
                label={t('colorsNeutral')}
                help={
                  isPaletteSet(colors?.neutral)
                    ? t('colorsNeutralHelp')
                    : t('designs/base:colorsNotSetHelp')
                }
              />
              <ColorPalette
                name="colors.danger"
                label={t('colorsDanger')}
                help={
                  isPaletteSet(colors?.danger)
                    ? t('colorsDangerHelp')
                    : t('designs/base:colorsNotSetHelp')
                }
              />
              <ColorPalette
                name="colors.warn"
                label={t('colorsWarning')}
                help={
                  isPaletteSet(colors?.warn)
                    ? t('colorsWarningHelp')
                    : t('designs/base:colorsNotSetHelp')
                }
              />
              <ColorPalette
                name="colors.success"
                label={t('colorsSuccess')}
                help={
                  isPaletteSet(colors?.success)
                    ? t('colorsSuccessHelp')
                    : t('designs/base:colorsNotSetHelp')
                }
              />
            </div>
          </div>
        </Section.Body>
        <Section.Footer className="justify-between">
          <Button label={t('reset')} onClick={onReset} />
          <Submit label={t('update')} reverse />
        </Section.Footer>
      </Form>
    </Section>
  )
}
ColorsForm.propTypes = {
  site: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
}
