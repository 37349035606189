import React from 'react'

import config from './config'

const StandaloneDonationSettings = React.lazy(() => import('./Settings'))
const StandaloneDonationBlock = React.lazy(() => import('./Block'))

export default function StandaloneDonation(props) {
  return <StandaloneDonationBlock {...props} />
}

StandaloneDonation.toolbarItem = {
  ...config,
  component: <StandaloneDonation />,
}

StandaloneDonation.craft = {
  displayName: 'StandaloneDonation',
  props: {
    project: '',
    paymentMethod: '',
    successMessage: '',
  },
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: StandaloneDonationSettings,
  },
}
