import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { SearchDropdown } from '@ui/data-entry/SearchDropdown'
import { isEmpty } from '@utils/arrays'
import useDebounce from '@utils/useDebounce'

import useCourse from '../services/hooks/useCourse'
import useCourses from '../services/hooks/useCourses'

export function CoursePicker({
  className = '',
  help,
  ignoreIds,
  label,
  name,
  onChange,
  providerId,
  required,
  value,
}) {
  const { t } = useTranslation('courses/public')
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const filters = { 'ignore-ids': !isEmpty(ignoreIds) ? ignoreIds : null }

  const { resources } = usePageResources()

  const _providerId = providerId || resources?.CourseProvider?.id

  const { courses } = useCourses({
    providerId: _providerId,
    search: debouncedSearchTerm,
    fetchOnSearch: true,
    limit: 9999,
    filters,
  })
  const id = typeof value === 'object' ? value?.id : value
  const { course } = useCourse(id)

  const onSearch = useCallback(text => {
    setSearchTerm(text)
  }, [])

  return (
    <div className={`space-y-4 ${className}`}>
      <SearchDropdown
        label={label || t('course')}
        placeholder={t('typeToSearch')}
        name={name}
        value={id}
        selectedLabel={course?.title}
        onChange={onChange}
        onSearch={onSearch}
        help={help}
        required={required}
      >
        {courses?.map(course => (
          <SearchDropdown.Option
            key={course.id}
            label={course.title}
            name={name}
            value={course.id}
          />
        ))}
      </SearchDropdown>
    </div>
  )
}
CoursePicker.propTypes = {
  className: PropTypes.string,
  help: PropTypes.string,
  hideLabel: PropTypes.bool,
  ignoreIds: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  providerId: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default function CoursePickerController({
  className = '',
  help,
  hideLabel,
  ignoreIds,
  label,
  name,
  onChange,
  providerId,
  required,
  shouldUnregister,
}) {
  const { control } = useFormContext()

  const onFieldChange = useCallback(
    field => value => {
      field.onChange(value)

      if (typeof onChange === 'function') {
        onChange(value)
      }
    },
    [onChange]
  )

  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <CoursePicker
          className={className}
          help={help}
          hideLabel={hideLabel}
          ignoreIds={ignoreIds}
          label={label}
          name={name}
          providerId={providerId}
          value={field.value}
          onChange={onFieldChange(field)}
          required={required}
        />
      )}
    />
  )
}
CoursePickerController.propTypes = {
  className: PropTypes.string,
  help: PropTypes.string,
  hideLabel: PropTypes.bool,
  ignoreIds: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  providerId: PropTypes.string,
  required: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
}
