import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'
import { escapeSpecialChars } from '@utils/strings'

import { useSiteId } from './useSite'

export default function useDataSources({
  allowedTypes,
  enabled = true,
  fetchOnSearch = false,
  limit = 10,
  order = 'asc',
  page = 1,
  pagination = false,
  resourceModels,
  search = '',
  skip = 0,
  sort = '',
  statuses = ['active'],
}) {
  const siteId = useSiteId()

  const { data, ...rest } = useQuery(
    [
      'site',
      siteId,
      'data-sources',
      allowedTypes,
      limit,
      order,
      page,
      pagination,
      resourceModels,
      search,
      siteId,
      skip,
      sort,
      statuses,
    ],
    () =>
      getFetch(`/api/web/sites/${siteId}/data-sources`, {
        allowedTypes,
        limit,
        order,
        page,
        resourceModels,
        search: escapeSpecialChars(search),
        siteId,
        skip: pagination ? null : skip,
        sort,
        statuses: typeof statuses === 'string' ? [statuses] : statuses,
      }),
    { enabled: enabled ? (fetchOnSearch ? search !== '' : true) : false }
  )

  return {
    ...rest,
    count: data?.count ?? 0,
    dataSources: data?.items ?? [],
  }
}
