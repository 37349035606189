import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import EntityPicker from '@modules/entities/components/entities/EntityPicker'
import { SitePageSelect } from '@modules/web/components/shared/SitePageSelect'
import usePagesByResource from '@modules/web/services/hooks/usePagesByResource'
import Button from '@ui/buttons/Button'
import Message from '@ui/data-display/Message'
import Checkbox from '@ui/data-entry/Checkbox'
import Field from '@ui/data-entry/Field'
import Input from '@ui/data-entry/Input'
import Select from '@ui/data-entry/Select'
import Tags from '@ui/data-entry/Tags'
import Box from '@ui/layout/Box'

export default function WordPressImportTaskFields({ task = {} }) {
  const { t } = useTranslation(['articles/tasks', 'web/public'])

  const { meta = {} } = task || {}
  const { context = {} } = meta || {}
  const { categories = [] } = context || {}

  const { items: sites } = usePagesByResource({
    resourceName: 'Article',
    resourceType: 'model',
  })

  const { setValue } = useFormContext()
  const watch = useWatch()

  const {
    fields: fieldMappings,
    append: appendFieldMapping,
    remove: removeFieldMapping,
  } = useFieldArray({
    name: 'settings.fieldMappings',
  })
  const {
    fields: organizationMappings,
    append: appendOrganizationMapping,
    remove: removeOrganizationMapping,
  } = useFieldArray({
    name: 'settings.organizationMappings',
  })

  const {
    fields: siteFields,
    append: appendSite,
    remove: removeSite,
  } = useFieldArray({
    name: 'settings.sites',
    rules: {
      required: true,
    },
  })

  const siteOptions = useMemo(() => {
    return sites
      ?.filter(site => watch.settings?.sites?.find?.(s => s.site === site.id))
      ?.flatMap(site =>
        site.pages.map(page => ({
          label: page.name,
          value: page._id,
          page,
        }))
      )
  }, [sites, watch.settings?.sites])

  return (
    <Box space="xl">
      <SitePageSelect
        name="settings[canonicalSitePage]"
        label={t('wordpress-import-canonicalSitePage')}
        options={siteOptions}
        required={true}
      />
      <Box space="sm" className="border-b pb-6">
        <Field label={t('web/public:sites')} required>
          <ul>
            {siteFields.map((field, index) => {
              return (
                <li
                  key={field.id}
                  className="border-1 flex flex-wrap justify-between gap-4 border-t bg-gray-50 px-4 py-4 first:border-none"
                >
                  <div className="flex flex-1 flex-col gap-4">
                    <Select
                      name={`settings.sites.${index}.site`}
                      label={t('web/public:site')}
                      required={sites?.length > 0}
                      onChange={() => {
                        setValue(`settings.sites.${index}.flag`, 'import')
                      }}
                    >
                      <Select.Placeholder label="Select site" />
                      {sites?.map(site => (
                        <Select.Option
                          label={site.name}
                          value={site.id}
                          key={site.id}
                        />
                      ))}
                    </Select>
                    <Select
                      name={`settings.sites.${index}.flag`}
                      label={t('wordpress-import-flag')}
                      required={sites?.length > 0}
                    >
                      <Select.Option
                        label={t('wordpress-import-importAllFlags')}
                        value="import"
                      />
                      <optgroup label={t('wordpress-import-useExistingFlag')}>
                        {sites
                          ?.find(
                            site =>
                              site.id === watch.settings?.sites?.[index]?.site
                          )
                          ?.flags?.map(flag => (
                            <Select.Option
                              label={flag.name}
                              value={flag.id}
                              key={flag.id}
                            />
                          ))}
                      </optgroup>
                    </Select>
                  </div>
                  <Button
                    className="mt-8 shrink-0"
                    onClick={() => removeSite(index)}
                    icon="trash-alt"
                    variant="danger-light"
                  />
                </li>
              )
            })}
          </ul>
        </Field>
        <Button
          className="mt-4"
          label={t('wordpress-import-addSite')}
          onClick={() =>
            appendSite({
              site: null,
              flag: 'import',
            })
          }
          icon="plus"
          variant="success"
        />
      </Box>
      <Input
        name="settings[restApiUrl]"
        label={t('wordpress-import-restApiUrl')}
        help={t('wordpress-import-restApiUrlHelp')}
      />
      {watch.settings?.restApiUrl && categories?.length > 0 && (
        <>
          <Tags
            name="settings[categories]"
            label={t('wordpress-import-categories')}
            help={t('wordpress-import-categoriesHelp')}
            suggestions={categories
              ?.filter(c => !watch.settings?.categories?.includes?.(c.id))
              .map(i => i.id)}
            getItemLabel={id => categories?.find(i => i.id === id)?.name}
            items={watch.settings?.categories || []}
            asText
          />
          <Tags
            name="settings[categoriesExclude]"
            label={t('wordpress-import-categoriesExclude')}
            help={t('wordpress-import-categoriesExcludeHelp')}
            suggestions={categories
              ?.filter(
                c => !watch.settings?.categoriesExclude?.includes?.(c.id)
              )
              .map(i => i.id)}
            getItemLabel={id => categories?.find(i => i.id === id)?.name}
            items={watch.settings?.categoriesExclude || []}
            asText
          />
        </>
      )}
      <Checkbox
        name="settings[importAll]"
        label={t('wordpress-import-importAll')}
        help={t('wordpress-import-importAllHelp')}
      />
      <Checkbox
        name="settings[updateSitesOnly]"
        label={t('wordpress-import-updateSitesOnly')}
        help={t('wordpress-import-updateSitesOnlyHelp')}
      />

      <Box space="md" className="border-t pt-6">
        <h3 className="font-semibold">
          {t('wordpress-import-organizationMappings')}
        </h3>
        <Message text={t('wordpress-import-organizationMappingsDescription')} />
        <ul>
          {organizationMappings.map((field, index) => {
            return (
              <li
                key={field.id}
                className="border-1 flex flex-wrap justify-between gap-4 border-t bg-gray-50 px-4 py-4 first:border-none"
              >
                <div className="flex flex-1 flex-col gap-4">
                  <Select
                    name={`settings.organizationMappings.${index}.source`}
                    label={t('wordpress-import-organizationMappingSource')}
                  >
                    <Select.Placeholder
                      label={t('wordpress-import-selectOrganization')}
                    />
                    {categories
                      ?.filter(
                        c =>
                          !watch.settings?.organizationMappings?.includes?.(
                            c.id
                          )
                      )
                      .map(category => (
                        <Select.Option
                          label={category.name}
                          value={category.id}
                          key={category.id}
                        />
                      ))}
                  </Select>
                  <EntityPicker
                    className="w-full"
                    name={`settings.organizationMappings.${index}.target`}
                    label={t('wordpress-import-organizationMappingTarget')}
                  />
                </div>
                <Button
                  className="mt-8 shrink-0"
                  onClick={() => removeOrganizationMapping(index)}
                  icon="trash-alt"
                  variant="danger-light"
                />
              </li>
            )
          })}
        </ul>
        <Button
          className="mt-4"
          label={t('wordpress-import-addOrganizationMapping')}
          onClick={() => appendOrganizationMapping({ target: '', source: '' })}
          icon="plus"
          variant="success"
        />
      </Box>

      <Box space="md" className="border-t pt-6">
        <h3 className="font-semibold">{t('wordpress-import-fieldMappings')}</h3>
        <Message text={t('wordpress-import-fieldMappingsDescription')} />
        <ul>
          {fieldMappings.map((field, index) => {
            return (
              <li
                key={field.id}
                className="border-1 flex flex-wrap justify-between gap-4 border-t bg-gray-50 px-4 py-4 first:border-none"
              >
                <div className="flex flex-1 flex-col gap-4">
                  <Input
                    name={`settings.fieldMappings.${index}.target`}
                    label={t('wordpress-import-fieldMappingTarget')}
                  />
                  <Input
                    className="w-full"
                    name={`settings.fieldMappings.${index}.source`}
                    label={t('wordpress-import-fieldMappingSource')}
                  />
                </div>
                <Button
                  className="mt-8 shrink-0"
                  onClick={() => removeFieldMapping(index)}
                  icon="trash-alt"
                  variant="danger-light"
                />
              </li>
            )
          })}
        </ul>
        <Button
          className="mt-4"
          label={t('wordpress-import-addFieldMapping')}
          onClick={() => appendFieldMapping({ target: '', source: '' })}
          icon="plus"
          variant="success"
        />
      </Box>
    </Box>
  )
}
WordPressImportTaskFields.propTypes = {
  task: PropTypes.object,
}
