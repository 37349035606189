import React from 'react'

import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import ColorPalette from '@ui/data-entry/ColorPalette'
import DangerModal from '@ui/feedback/DangerModal'
import Heading from '@ui/typography/Heading'
import { uid } from '@utils/strings'

export function CustomColorsField() {
  const { t } = useTranslation(['designs/adventist', 'designs/base'])

  const [dangerColor, setDangerColor] = React.useState(null)

  const { fields, remove, append } = useFieldArray({
    name: 'customColors',
  })

  const onAddColor = React.useCallback(() => {
    append({ code: `color_${uid()}`, name: '' })
  }, [append])

  return (
    <div className="flex flex-col gap-8">
      <Heading as="h4">{t('designs/base:customColors')}</Heading>
      {fields.map(({ id, name }, index) => (
        <ColorPalette
          key={id}
          name={`customColors.${index}.`}
          label={t('designs/base:customColor', { number: index + 1 })}
          onDelete={() => setDangerColor({ index, name })}
        />
      ))}

      <div className="flex items-center gap-4">
        <Button onClick={onAddColor} label={t('addColor')} icon="plus" />
      </div>

      {dangerColor ? (
        <DangerModal
          disableToggle
          onDelete={() => {
            remove(dangerColor.index)
            setDangerColor(null)
          }}
          onOpenChange={() => setDangerColor(null)}
          open
          dangerZoneDescriptionLabel={t('removeColorConfirm')}
          title={dangerColor.name}
        />
      ) : null}
    </div>
  )
}

CustomColorsField.propTypes = {}
