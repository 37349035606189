import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import Empty from '@ui/data-display/Empty'
import { SortableListController } from '@ui/data-entry/SortableList'

import { AddThemeDialog } from './AddThemeDialog'
import { ThemeItem } from './ThemeItem'

export function ThemesField() {
  const { t } = useTranslation('designs/adventist')
  const [openAddModal, setOpenAddModal] = React.useState(false)

  const { watch } = useFormContext()

  const themesWatch = watch('themes')

  return (
    <div className="flex flex-col gap-8">
      {(!themesWatch || themesWatch?.length < 1) && (
        <Empty label={t('themesEmpty')} />
      )}

      <SortableListController
        name="themes"
        renderItem={props => <ThemeItem {...props} />}
        renderAddItem={({ append }) => (
          <>
            <Button
              label={t('addTheme')}
              icon="plus"
              variant="success"
              onClick={() => setOpenAddModal(true)}
            />

            {openAddModal && (
              <AddThemeDialog append={append} onOpenChange={setOpenAddModal} />
            )}
          </>
        )}
      />
    </div>
  )
}

ThemesField.propTypes = {}
