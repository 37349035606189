import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'
import { escapeSpecialChars } from '@utils/strings'

/**
 * @typedef {import('./usePublication').Publication} Publication
 *
 * Hook options
 * @typedef {Object} UsePublicationsOptions
 * @property {String} religion The religion of the publication
 * @property {Boolean} featured Whether the publication is featured
 * @property {String} type The type of the publication
 * @property {String} search Search term
 * @property {String|String[]} statuses The statuses of the publication
 * @property {Boolean} fetchOnSearch Whether if it should fetch on search
 * @property {Number} limit Limit results to this number of items per page
 * @property {Number} page Page number to fetch
 * @property {Boolean} pagination Whether to use pagination
 * @property {String} sort Sort by field
 * @property {String} order Sort order
 * @property {Number} skip Skip this number of results
 */

/**
 * @typedef {Object} UsePublicationsType
 * @property {Publication[]} publications Publications list
 * @property {Number} count Total number of publications
 * @property {Error} error The error object
 * @property {Boolean} loading Whether the query is loading
 * @property {Boolean} fetching Whether the query is fetching
 */

/**
 * Hook to fetch publications
 * @param {UsePublicationsOptions} params - The options to fetch the publications
 * @returns {UsePublicationsType}
 */
export default function usePublications({
  religion = null,
  featured = null,
  type = null,
  search = '',
  statuses = ['active'],
  fetchOnSearch = false,
  limit = 10,
  page = 1,
  pagination = false,
  sort = '',
  order = 'asc',
  skip = 0,
}) {
  const { data, error, isLoading, isFetching } = useQuery(
    [
      'publications',
      {
        religion,
        featured,
        type,
        search,
        statuses,
        limit,
        sort,
        skip,
        pagination,
        page,
      },
    ],
    () =>
      getFetch('/api/publications', {
        religion,
        featured,
        type,
        statuses: typeof statuses === 'string' ? [statuses] : statuses,
        search: escapeSpecialChars(search),
        sort,
        order,
        limit,
        skip: pagination ? null : skip,
        page,
      }),
    { enabled: fetchOnSearch ? search !== '' : true }
  )

  return {
    publications: data?.items ?? [],
    count: data?.count ?? 0,
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
