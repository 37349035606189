import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import {
  SelectField,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'

export function ThemeSelectField({ help, label, name, onChange, site, value }) {
  const { t } = useTranslation('designs/adventist')

  const themeOptions = React.useMemo(() => {
    return (
      site?.design?.themes?.map(theme => ({
        value: theme.code,
        label: theme.name,
      })) ?? []
    )
  }, [site])

  return (
    <SelectField
      help={help}
      label={label || t('theme')}
      name={name}
      onChange={onChange}
      value={value}
    >
      {value && <SelectOption label={t('clearSelection')} value={null} />}
      <SelectPlaceholder label="-" />
      {themeOptions.map(({ value, label }) => (
        <SelectOption key={value} value={value} label={label} />
      ))}
    </SelectField>
  )
}

ThemeSelectField.propTypes = {
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  site: PropTypes.object.isRequired,
  value: PropTypes.string,
}
