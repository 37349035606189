import PropTypes from 'prop-types'
import React from 'react'

import { Controller, useFormContext } from 'react-hook-form'

import { useRules } from '../validationHooks'
import SelectField from './Field'
import SelectGroup from './Group'
import SelectOption from './Option'
import SelectPlaceholder from './Placeholder'

/**
 * Select with Field (label + help + error + required mark)
 */
export default function SelectController({
  children,
  className = '',
  disabled,
  extra,
  fullWidth = true,
  help,
  label,
  lightLabel,
  name,
  onChange,
  placeholder,
  placeholderValue = 'none',
  required,
  shouldUnregister,
  showClear,
  value,
}) {
  const { control } = useFormContext()

  const rules = useRules({ required })

  const onFieldChange = React.useCallback(
    field => event => {
      field.onChange(event)

      if (typeof onChange === 'function') {
        onChange(event.currentTarget.value)
      }
    },
    [onChange]
  )

  return (
    <Controller
      control={control}
      defaultValue={value}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <SelectField
          className={className}
          name={name}
          label={label}
          lightLabel={lightLabel}
          help={help}
          error={fieldState.error}
          extra={extra}
          required={rules?.required?.value}
          disabled={disabled}
          fullWidth={fullWidth}
          id={name}
          onChange={onFieldChange(field)}
          placeholder={placeholder}
          placeholderValue={placeholderValue}
          showClear={showClear}
          value={field.value}
        >
          {children}
        </SelectField>
      )}
    />
  )
}
SelectController.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  extra: PropTypes.node,
  fullWidth: PropTypes.bool,
  help: PropTypes.string,
  label: PropTypes.string,
  lightLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  placeholderValue: PropTypes.any,
  required: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
  showClear: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
SelectController.displayName = 'Select'

SelectController.Placeholder = SelectPlaceholder
SelectController.Placeholder.displayName = 'Select.Placeholder'

SelectController.Group = SelectGroup
SelectController.Group.displayName = 'Select.Group'

SelectController.Option = SelectOption
SelectController.Option.displayName = 'Select.Option'
