import PropTypes from 'prop-types'
import React from 'react'

import { SelectField as Select } from '@ui/data-entry/Select'
import { pick } from '@utils/objects'

import useCollections from '../services/hooks/useCollections'

export default function CollectionSelect({
  channelId,
  onChange,
  ...selectProps
}) {
  const { collections } = useCollections({ channelId })

  const handleOnChange = e => {
    const channel = collections.find(c => c.id === e.target.value)
    onChange && onChange(pick(channel, ['title', 'id', 'items']))
  }

  return (
    <Select {...selectProps} onChange={handleOnChange} showClear>
      <Select.Placeholder label="-" />
      {collections?.map(collection => (
        <Select.Option
          key={collection.id}
          value={collection.id}
          label={collection.title}
        />
      ))}
    </Select>
  )
}
CollectionSelect.propTypes = {
  channelId: PropTypes.string,
  onChange: PropTypes.func,
}
