import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { SortableItemPanel } from '@ui/data-entry/SortableList'
import DangerModal from '@ui/feedback/DangerModal'

import { ThemeFields } from './ThemeFields'

export function ThemeItem({ field, index, remove }) {
  const { t } = useTranslation('designs/adventist')

  const [dangerTheme, setDangerTheme] = React.useState(null)

  const { id, name } = field

  return (
    <SortableItemPanel
      className="flex-grow"
      name={`theme_${id}`}
      onDelete={event => {
        event.stopPropagation()
        setDangerTheme(id)
      }}
      title={name}
    >
      <ThemeFields prefix={`themes.${index}.`} />

      {dangerTheme ? (
        <DangerModal
          disableToggle
          onDelete={() => {
            remove(index)
            setDangerTheme(null)
          }}
          onOpenChange={() => setDangerTheme(null)}
          open
          dangerZoneDescriptionLabel={t('removeThemeConfirm')}
          title={name}
        />
      ) : null}
    </SortableItemPanel>
  )
}

ThemeItem.propTypes = {
  field: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
}
