import React from 'react'

/**
 * Hook to get the selected text from the browser window
 * @param {object} existingRef - existing ref to use
 * @returns `object` with `ref` and `selectedText`
 */
export default function useSelectedText(existingRef) {
  const newRef = React.useRef(null)
  const ref = existingRef || newRef
  const [selectedText, setSelectedText] = React.useState('')

  React.useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('mouseup', () => {
        setSelectedText(window.getSelection().toString())
      })
    }
  }, [ref])

  const clearSelectedText = () => setSelectedText('')

  return { ref, selectedText, clearSelectedText }
}
