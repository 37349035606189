import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Label from '@ui/data-display/Label'
import {
  AutocompleteField as Autocomplete,
  AutocompleteOption,
} from '@ui/data-entry/Autocomplete'
import useDebounce from '@utils/useDebounce'

import useEvent from '../../services/hooks/useEvent'
import useEvents from '../../services/hooks/useEvents'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export function EventPicker({
  className = '',
  help,
  label,
  name,
  onChange,
  required,
  value,
}) {
  const { t } = useTranslation('events/public')
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const { events } = useEvents({
    search: debouncedSearchTerm,
  })
  const { event } = useEvent(value)

  const onSearch = useCallback(text => {
    setSearchTerm(text)
  }, [])

  const handleChange = newValue => {
    setSearchTerm('')
    onChange?.(newValue)
  }

  return (
    <Autocomplete
      className={className}
      displayIcon={
        value ? <Icon name="tag" className="text-primary-500" /> : null
      }
      displayValue={() => event?.title || searchTerm}
      help={help}
      label={label || t('event')}
      name={name}
      onChange={handleChange}
      onSearch={onSearch}
      placeholder={t('searchPlaceholder')}
      required={required}
      value={event ? value : undefined}
    >
      {events?.map(event => (
        <AutocompleteOption
          label={<Label label={event.title} icon="tag" iconColor="primary" />}
          value={event.id}
          key={`event-${event.id}`}
        />
      ))}
    </Autocomplete>
  )
}
EventPicker.propTypes = {
  className: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
}

export default function EventPickerController({
  className = '',
  help,
  label,
  name,
  required,
  shouldUnregister,
}) {
  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <EventPicker
          className={className}
          help={help}
          label={label}
          name={name}
          required={required}
          value={field.value}
          onChange={field.onChange}
        />
      )}
    />
  )
}
EventPickerController.propTypes = {
  className: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
}
