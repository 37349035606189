import PropTypes from 'prop-types'
import React from 'react'

import BlockField from '../../ContentEditor/shared/BlockField'
import { DataSourceSelectField } from './Field'

export function BlockDataSourceSelectField({
  allowedTypes,
  className,
  help,
  label,
  name,
  placeholder,
  required,
}) {
  return (
    <BlockField name={name}>
      {({ fieldProps, onDataChange, value }) => (
        <DataSourceSelectField
          {...fieldProps}
          allowedTypes={allowedTypes}
          className={className}
          help={help}
          label={label}
          onChange={onDataChange}
          placeholder={placeholder}
          required={required}
          value={value}
        />
      )}
    </BlockField>
  )
}

BlockDataSourceSelectField.propTypes = {
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
}
