import PropTypes from 'prop-types'
import React from 'react'

import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import usePageIcon from '@modules/web/helpers/usePageIcon'
import Button from '@ui/buttons/Button'
import Tree from '@ui/data-display/Tree'
import Field from '@ui/data-entry/Field'
import Dialog, { DialogContent } from '@ui/feedback/FloatingDialog'

import usePage from '../../services/hooks/usePage'
import usePages, { useSubPages } from '../../services/hooks/usePages'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

function PageItem({
  condition,
  currentPage,
  page,
  multiple,
  selected,
  siteId,
  onSelect,
}) {
  const hasSubpages = page.subpagesCount > 0
  const isSelected = multiple
    ? selected?.includes(page.id)
    : selected === page.id
  const [expanded, setExpanded] = React.useState(false)
  const { pages, isLoading } = useSubPages(expanded ? page : null, siteId, {
    deleted: false,
  })

  const canSelect = typeof condition === 'function' ? condition(page) : true

  const isAncestor = currentPage?.ancestorsIds?.includes(page.id) || false

  const pageIcon = usePageIcon(page)

  return (
    <Tree.Item
      active={isSelected}
      defaultExpanded={isAncestor}
      expanding={isLoading}
      extra={
        <div className={`p-1 ${isSelected ? 'text-white' : 'text-gray-400'}`}>
          {canSelect ? (
            <Icon
              name={`${isSelected ? 'check-' : ''}${
                multiple ? 'square' : 'circle'
              }`}
            />
          ) : (
            <div className="block h-5" />
          )}
        </div>
      }
      disabled={!canSelect}
      label={page.dynamic ? `[ ${page.name} ]` : page.title}
      icon={pageIcon}
      hasChildren={hasSubpages}
      onExpand={hasSubpages ? expanded => setExpanded(expanded) : undefined}
      variant={
        page.deleted ? 'deleted' : !page.enabled ? 'disabled' : undefined
      }
      spacing="sm"
      onClick={canSelect ? () => onSelect(page) : undefined}
    >
      {pages?.map(subpage => (
        <PageItem
          page={subpage}
          currentPage={currentPage}
          siteId={siteId}
          condition={condition}
          key={subpage.id}
          selected={selected}
          multiple={multiple}
          onSelect={onSelect}
        />
      ))}
    </Tree.Item>
  )
}
PageItem.propTypes = {
  page: PropTypes.object.isRequired,
  currentPage: PropTypes.object,
  condition: PropTypes.func,
  multiple: PropTypes.bool,
  selected: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  siteId: PropTypes.string,
  onSelect: PropTypes.func,
}

export function PagePicker({ condition, multiple, onChange, siteId, value }) {
  const { page: currentPage } = usePage(value) // currentPage
  const { pages } = usePages(siteId, { deleted: false })
  const [selected, setSelected] = React.useState(multiple ? [] : currentPage)
  const [selectedIds, setSelectedIds] = React.useState(value)

  const onSelect = React.useCallback(
    page => {
      if (multiple) {
        const pages = selected.map(p => p.id).includes(page.id)
          ? selected.filter(p => p.id !== page.id)
          : [...selected, page]

        setSelected(pages)
        setSelectedIds(pages.map(p => p.id))

        if (typeof onChange === 'function') {
          onChange(pages)
        }
      } else {
        const isSame = page.id === selected?.id
        const selectedPage = isSame ? null : page

        setSelected(selectedPage)
        setSelectedIds(selectedPage?.id || null)

        if (typeof onChange === 'function') {
          onChange(selectedPage, isSame)
        }
      }
    },
    [multiple, selected, onChange]
  )

  return (
    <Tree
      className="w-full"
      items={pages}
      itemChildren="subpages"
      spacing="sm"
      renderItem={page => (
        <PageItem
          condition={condition}
          page={page}
          siteId={siteId}
          multiple={multiple}
          selected={selectedIds}
          onSelect={onSelect}
          currentPage={currentPage}
          key={page.id}
        />
      )}
    />
  )
}
PagePicker.propTypes = {
  condition: PropTypes.func,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  siteId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
}

export function PagePickerField({
  className,
  condition,
  disabled,
  error,
  help,
  label,
  multiple,
  name,
  onChange,
  required,
  siteId,
  value,
}) {
  const { t } = useTranslation('web/pages')
  const [modalValue, setModalValue] = React.useState(null)
  const [hasCleared, setHasCleared] = React.useState(false)
  const [dialogOpen, setDialogOpen] = React.useState()

  const onSelect = React.useCallback(() => {
    onChange(
      multiple
        ? modalValue.map(p => p.id)
        : hasCleared
          ? null
          : modalValue?.id || value
    )

    setDialogOpen(false)
  }, [modalValue, onChange, multiple, hasCleared, value])

  const onCancel = React.useCallback(() => {
    setModalValue(null)
    setDialogOpen(false)
  }, [])

  return (
    <Field
      className={className}
      disabled={disabled}
      name={name}
      label={label}
      help={help}
      error={error}
      required={required}
    >
      <div className="flex flex-row items-center justify-between space-x-4">
        <div className="flex flex-row gap-2 truncate">
          {value ? (
            multiple ? (
              value.map(pid => <PageTag id={value} siteId={siteId} key={pid} />)
            ) : (
              <PageTag id={value} siteId={siteId} />
            )
          ) : (
            <p className="text-gray-400">{t('noPageSelected')}</p>
          )}
        </div>

        <div className="flex gap-2">
          <Button
            label={t(
              value ? 'update' : multiple ? 'selectPages' : 'selectPage'
            )}
            icon="file"
            size="sm"
            disabled={disabled}
            onClick={() => setDialogOpen(true)}
          />

          {value && (
            <Button
              label={t('clear')}
              icon="times"
              size="sm"
              variant="danger-light"
              disabled={disabled}
              onClick={() => onChange(null)}
            />
          )}
        </div>
      </div>

      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent
          title={t(multiple ? 'selectPages' : 'selectPage')}
          boxClass="min-w-[320px]"
          childrenClass="flex flex-col gap-6"
          buttonsClass="justify-between"
          buttons={
            <>
              <Button label={t('cancel')} onClick={onCancel} />
              <Button
                label={t('select')}
                variant="primary"
                onClick={onSelect}
                disabled={!modalValue}
              />
            </>
          }
        >
          <PagePicker
            multiple={multiple}
            value={value}
            onChange={(newValue, isSame) => {
              setModalValue(newValue)
              setHasCleared(isSame && newValue === null)
            }}
            condition={condition}
            siteId={siteId}
          />
        </DialogContent>
      </Dialog>
    </Field>
  )
}
PagePickerField.propTypes = {
  className: PropTypes.string,
  condition: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  siteId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

export default function PagePickerController({
  className,
  condition,
  disabled,
  error,
  help,
  label,
  multiple,
  name,
  required,
  shouldUnregister,
  siteId,
  value,
}) {
  const { control, formState } = useFormContext()
  const validationError = formState.errors[name]

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={{ required }}
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <PagePickerField
          className={className}
          condition={condition}
          disabled={disabled}
          error={validationError || error}
          help={help}
          name={name}
          label={label}
          multiple={multiple}
          onChange={field.onChange}
          required={required}
          siteId={siteId}
          value={field.value}
        />
      )}
    />
  )
}

PagePickerController.propTypes = {
  className: PropTypes.string,
  condition: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
  siteId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

export function PageTag({ id, siteId }) {
  const { page } = usePage(id, true, siteId)

  if (!page) return null

  return (
    <div
      className={`truncate rounded-full bg-primary-500 px-3 py-1 font-semibold text-white ${
        page.dynamic ? 'italic' : ''
      }`}
    >
      <span className="truncate">
        {page.dynamic ? `[ ${page.name} ]` : page.title}
      </span>
    </div>
  )
}
PageTag.propTypes = {
  id: PropTypes.string,
  siteId: PropTypes.string,
}
