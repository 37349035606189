import PropTypes from 'prop-types'
import React from 'react'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export function SelectedTypeOption({ value, label, icon } = {}) {
  if (!value && !label && !icon) {
    return null
  }

  return (
    <div className="flex items-center gap-2">
      <Icon name={icon} />
      <span>{label ?? value}</span>
    </div>
  )
}

SelectedTypeOption.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
}
