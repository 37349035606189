import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Button from '@ui/buttons/Button'
import ProgressBar from '@ui/feedback/ProgressBar'
import Box from '@ui/layout/Box'

const config = {
  name: 'CoursesEnrolledCourses',
  label: 'courses/public:enrolledCourses',
  help: 'courses/public:enrolledCoursesHelp',
  type: 'plugin',
  icon: 'bars',
  component: <EnrolledCourses />,
}

function getPlaceholder(t) {
  return {
    courses: [
      {
        title: `[${t('course')} 1]`,
        progress: 65,
      },
      {
        title: `[${t('course')} 2]`,
        progress: 79,
      },
      {
        title: `[${t('course')} 3]`,
        progress: 55,
      },
    ],
  }
}

export default function EnrolledCourses() {
  const { t } = useTranslation('courses/public')
  return (
    <Content className="w-full" icon={config.icon} title={config.label}>
      <div className="space-y-3">
        <h3 className="text-lg font-semibold">{t('myCourses')}</h3>
        <div className="space-y-2">
          {getPlaceholder(t).courses.map((course, key) => {
            const { progress, title } = course
            return (
              <div
                className="flex items-center gap-2 overflow-hidden rounded border"
                key={key}
              >
                <div>
                  <div className="aspect-h-4 aspect-w-16 w-20 rounded-l bg-gray-300 text-gray-300">
                    .
                  </div>
                </div>
                <div className="w-full">
                  <div className="text-sm">{title}</div>
                  <ProgressBar percentage={progress} hidePercentage />
                </div>
                <div>
                  <Button label={t('continue')} size="xs" />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Content>
  )
}
EnrolledCourses.toolbarItem = config

function EnrolledCoursesSettings() {
  const { t } = useTranslation('courses/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  return (
    <SettingsWrap title={t(config.label)} help={t(config.help)}>
      <Box space="xl">
        <PagePicker
          label={t('courseDetailPageId')}
          help={t('courseDetailPageIdHelp')}
          value={data.courseDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.courseDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Course'
          }
        />
        <PagePicker
          label={t('lessonDetailPageId')}
          help={t('lessonDetailPageIdHelp')}
          value={data.lessonDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.lessonDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic &&
            getDynamicResourceIdentifier(page) === 'CourseLesson'
          }
        />
      </Box>
    </SettingsWrap>
  )
}

EnrolledCourses.craft = {
  displayName: config.name,
  props: {
    courseDetailPageId: undefined,
    lessonDetailPageId: undefined,
  },
  custom: {
    type: config.type,
    resources: ['coursesEnrolledCourses'],
    i18nNamespaces: ['courses'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EnrolledCoursesSettings,
  },
}
