import PropTypes from 'prop-types'
import React from 'react'

import useBreakpoint from '@modules/web/components/ContentEditor/shared/useBreakpoint'
import BibleVerse from '@ui/data-display/BibleVerse'
import Image from '@ui/data-display/Image'
import Player from '@ui/data-display/Player'
import ImageClipboardDropdown from '@ui/data-entry/ImageClipboardDropdown'
import Link from '@ui/navigation/Link'
import { intersperse } from '@utils/arrays'

import Footnote from '../Footnote'
import Heading from '../Heading'
import Text from '../Text'
import { isValidRichText } from './helpers'

const mappings = {
  'paragraph': {
    render: (node, key, children, { textSize, color }) => {
      return (
        <Text key={key} textSize={textSize} color={color}>
          {children}
        </Text>
      )
    },
  },
  'heading': {
    render: (node, key, children, { decreaseHeaders, color }) => {
      const { attrs } = node
      const level = parseInt(attrs.level) + (decreaseHeaders ? 1 : 0)
      return (
        <Heading as={`h${level}`} key={key} color={color}>
          {children}
        </Heading>
      )
    },
  },
  'image': {
    render: (node, key, children, { breakpoint }) => {
      const { alt, file, caption, copyright, align } = node.attrs
      const alignments = {
        'left': {
          sm: 'float-left w-1/2 mr-8',
          md: 'float-left w-1/3 mr-8',
          lg: 'float-left w-1/3 mr-8',
          xl: 'float-left w-1/3 mr-8',
        },
        'right': {
          sm: 'float-right w-1/2 ml-8',
          md: 'float-right w-1/3 ml-8',
          lg: 'float-right w-1/3 ml-8',
          xl: 'float-right w-1/3 ml-8',
        },
        'center-medium': {
          sm: 'mx-auto w-4/5',
          md: 'mx-auto w-2/3',
          lg: 'mx-auto w-2/3',
          xl: 'mx-auto w-2/3',
        },
      }

      return (
        <div
          className={`not-prose mb-8 mt-4 overflow-hidden rounded-sm bg-white shadow ${alignments[align]?.[breakpoint] || ''}`}
          key={key}
        >
          <Image file={file} alt={alt || 'missing alt text'} />
          <div className="flex gap-4">
            <p className="flex grow flex-col px-4 py-2 text-sm">
              {caption && <strong>{caption}</strong>}
              {copyright && <em className="text-gray-500">{copyright}</em>}
            </p>
            <div className="flex justify-end p-1">
              <ImageClipboardDropdown value={file} allowPaste={false} />
            </div>
          </div>
        </div>
      )
    },
  },
  'media': {
    render: (node, key) => {
      const { id, provider, caption } = node.attrs
      if (!id || !provider) return null

      return <Player id={id} provider={provider} caption={caption} key={key} />
    },
  },
  'table': {
    render: (node, key, children) => {
      return (
        <table key={key} className="table-fixed text-center">
          <tbody>{children}</tbody>
        </table>
      )
    },
  },
  'tableHeader': {
    render: (node, key, children) => {
      return <th key={key}>{children}</th>
    },
  },
  'tableRow': {
    render: (node, key, children) => {
      return (
        <tr key={key} className="table-row">
          {children}
        </tr>
      )
    },
  },
  'tableCell': {
    render: (node, key, children) => {
      return (
        <td key={key} className="table-cell">
          {children}
        </td>
      )
    },
  },
  'bulletList': {
    tag: 'ul',
    className: 'list-disc ml-6 not-prose',
  },
  'orderedList': {
    tag: 'ol',
    className: 'list-decimal ml-6 not-prose',
  },
  'listItem': {
    tag: 'li',
    className: 'dark:text-white pl-2',
  },
  'blockquote': {
    tag: 'blockquote',
    className: 'relative font-serif text-xl italic text-gray-600',
  },
  'code-block': {
    tag: 'pre',
    className:
      'my-4 p-4 overflow-x-auto whitespace-pre-wrap font-mono bg-gray-900 rounded text-warn-200',
  },
  'horizontalRule': {
    tag: 'hr',
    className: 'my-8 border-gray-200 dark:border-gray-700',
  },
  'link': {
    render: (mark, key, children) => {
      const { attrs } = mark
      let to = ''

      switch (attrs.type) {
        case 'email':
          to = `mailto:${attrs.href}`
          break
        case 'phone':
          to = `tel:${attrs.href}`
          break
        default:
          to = attrs.href
          break
      }

      return (
        <Link
          href={to}
          key={key}
          className="dark:text-secondary-500 text-primary-700 underline"
        >
          {children}
        </Link>
      )
    },
  },
  'bold': {
    tag: 'strong',
    className: 'bold dark:text-secondary-400',
  },
  'italic': {
    tag: 'em',
    className: 'italic',
  },
  'strike': {
    tag: 'strike',
    className: 'line-through',
  },
  'code': {
    tag: 'code',
    className: 'px-1 bg-gray-900 text-secondary-200 font-mono',
  },
  'hardBreak': {
    tag: 'br',
  },
  'span': {
    tag: 'span',
  },
  'footnote': {
    render: (node, key, children) => {
      return (
        <Footnote key={key} className="mb-4">
          {children}
        </Footnote>
      )
    },
  },
  'bibleVerse': {
    render: (node, key) => {
      const { attrs } = node
      const { passage, text, bible } = attrs
      return (
        <BibleVerse key={key} passage={passage} text={text} bible={bible} />
      )
    },
  },
}

/**
 *
 * @param {object} node
 * @param {string} key
 */
function serialize(node, key, renderProps = {}) {
  // TEXT (LEAF) NODE:
  if (node.type === 'text' && typeof node.text === 'string') {
    // Set node text as initial children
    let children = node.text

    if (children.match('\n')) {
      const lines = children.split('\n')

      children = intersperse(lines, index => (
        <br key={`text-br-${key}-${index}`} />
      ))
    }

    // Nest children in mark elements (if any)
    if (node.marks) {
      let i = 0
      for (const mark of node.marks) {
        const { tag, render, className } = mappings[mark.type] || mappings.span
        const textKey = `text-${key}-${i}`

        children =
          typeof render === 'function'
            ? render(mark, textKey, children, renderProps)
            : React.createElement(
                tag,
                {
                  className,
                  key: textKey,
                },
                children
              )
        i++
      }
    }

    return children
  }

  // BLOCK (ELEMENT) NODE:
  else {
    const { type, content, attrs } = node
    const nodeConf = mappings[type]

    if (!nodeConf) {
      console.warn(`Missing render for block "${type}"`) //  eslint-disable-line no-console
      return null
    }

    const { tag, render, className } = nodeConf
    const children = content?.map((node, i) =>
      serialize(node, `${key}-${i}`, renderProps)
    )

    return typeof render === 'function'
      ? render(node, key, children, renderProps)
      : React.createElement(tag, { key, className, ...attrs }, children)
  }
}

/**
 * Component to render a rich text document from tiptap
 * @param {object} props - The component props
 * @param {string} props.className - The component class name
 * @param {object} props.doc - The rich text document
 * @param {string} props.id - The component id
 * @param {boolean} props.decreaseHeaders - Whether to decrease the headers level
 * @param {object} props.textSize - The text size
 * @param {string} props.color - The text color
 * @returns {JSX.Element} The component
 */
export default function RichText({
  className = '',
  doc,
  id,
  decreaseHeaders,
  textSize,
  color,
}) {
  const { breakpoint } = useBreakpoint()
  const text = isValidRichText(doc)
    ? doc?.content?.map((n, i) =>
        serialize(n, `node-${i}`, {
          decreaseHeaders,
          textSize,
          color,
          breakpoint,
        })
      )
    : null

  if (!text) return null

  return (
    <div className={`prose max-w-none dark:prose-invert ${className}`} id={id}>
      {text}
    </div>
  )
}
RichText.propTypes = {
  className: PropTypes.string,
  decreaseHeaders: PropTypes.bool,
  textSize: PropTypes.object,
  color: PropTypes.string,
  doc: PropTypes.object,
  id: PropTypes.string,
}
