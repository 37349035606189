import PropTypes from 'prop-types'
import React from 'react'

import SelectController, { SelectField } from '@ui/data-entry/Select'
import { pick } from '@utils/objects'

import useProviders from '../services/hooks/useProviders'

export default function ProviderSelect({
  controlled = true,
  onChange,
  ...selectProps
}) {
  const { providers } = useProviders()

  const Select = controlled ? SelectController : SelectField

  const handleOnChange = e => {
    const provider = providers.find(p => p.id === e.target.value)
    onChange && onChange(pick(provider, ['title', 'id']))
  }

  return (
    <Select {...selectProps} onChange={handleOnChange} showClear>
      <Select.Placeholder label="-" />
      {providers?.map(provider => (
        <Select.Option
          key={provider.id}
          value={provider.id}
          label={provider.title}
        />
      ))}
    </Select>
  )
}
ProviderSelect.propTypes = {
  controlled: PropTypes.bool,
  onChange: PropTypes.func,
}
