export const paymentsUrl = '/payments'

// Payment Configuration URL
export function getConfigurePaymentsUrl(path = '') {
  return `${paymentsUrl}/configure${path ? `/${path}` : ''}`
}

// Projects URLs
export const projectsUrl = `${paymentsUrl}/projects`

export function getProjectsUrl(path = '') {
  return `${projectsUrl}${path ? `/${path}` : ''}`
}

export function getAddProjectsUrl(path = '') {
  return `${projectsUrl}/add${path ? `/${path}` : ''}`
}

export function getProjectUrl(project, path = '') {
  return `${projectsUrl}/${project?.id}${path ? `/${path}` : ''}`
}

export function getEditProjectUrl(project, path = '') {
  return `${projectsUrl}/${project?.id}/edit${path ? `/${path}` : ''}`
}
