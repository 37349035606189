import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'

import useEvent from '../../services/hooks/useEvent'
import { EventPicker } from '../event/EventPicker'

const RichText = React.lazy(() => import('@ui/data-entry/RichText/Render'))

const config = {
  name: 'EventTerms',
  label: 'events/public:eventTerms',
  type: 'plugin',
  icon: 'file-check',
  component: <EventTerms />,
}

export default function EventTerms({ id, dynamic }) {
  const { t } = useTranslation('events/public')
  const { event } = useEvent(dynamic ? null : id)

  const { organizer } = event || '[Event terms]'

  return (
    <Content icon={config.icon} title={t('eventTerms')} locked={dynamic}>
      {organizer?.participationConditions && (
        <RichText doc={organizer?.participationConditions} />
      )}
    </Content>
  )
}
EventTerms.propTypes = {
  dynamic: PropTypes.bool,
  id: PropTypes.string,
}
EventTerms.toolbarItem = config

function EventTermsSettings() {
  const { t } = useTranslation('events/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(!data.dynamic)

  const onEventIdChange = useCallback(
    value => {
      actions.setProp(props => (props.id = value))
    },
    [actions]
  )

  return (
    <SettingsWrap title={t('eventTerms')} help={t('eventTermsHelp')}>
      <div className="space-y-4">
        <Checkbox
          label={t('dynamic')}
          help={t('dynamicHelp')}
          onChange={value => {
            setShowPicker(!value)
            actions.setProp(props => (props.dynamic = value))
          }}
          value={data.dynamic}
          name="dynamic"
        />
        {showPicker && (
          <EventPicker onChange={onEventIdChange} value={data.id} />
        )}

        <PagePicker
          label={t('detailPageId')}
          help={t('detailPageIdHelp')}
          value={data.detailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.detailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Event'
          }
        />
      </div>
    </SettingsWrap>
  )
}

EventTerms.craft = {
  displayName: 'EventTerms',
  props: {
    id: undefined,
    dynamic: false,
  },
  custom: {
    type: config.type,
    resources: ['Event', 'eventTerms'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EventTermsSettings,
  },
}
