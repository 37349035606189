import PropTypes from 'prop-types'
import React from 'react'

import Field from '../Field'
import { MultipleSelect } from './MultipleSelect'

export function MultipleSelectField({
  asText = false,
  className = '',
  disabled,
  error,
  fullWidth = true,
  getLabel,
  getImage,
  getExtra,
  filterOptions,
  help,
  items = [],
  label,
  name,
  onAddItem,
  onRemoveItem,
  options,
  required,
}) {
  return (
    <Field
      name={name}
      label={label}
      error={error}
      help={help}
      required={required}
    >
      <MultipleSelect
        asText={asText}
        className={className}
        disabled={disabled}
        fullWidth={fullWidth}
        getLabel={getLabel}
        getImage={getImage}
        getExtra={getExtra}
        filterOptions={filterOptions}
        id={name}
        items={items}
        onAdd={onAddItem}
        onRemove={onRemoveItem}
        options={options}
      />
    </Field>
  )
}
MultipleSelectField.propTypes = {
  asText: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  getLabel: PropTypes.func,
  getImage: PropTypes.func,
  getExtra: PropTypes.func,
  filterOptions: PropTypes.func,
  help: PropTypes.string,
  items: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onAddItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      value: PropTypes.bool,
      message: PropTypes.string,
    }),
  ]),
  options: PropTypes.array,
}

MultipleSelectField.displayName = 'MultipleSelect'
