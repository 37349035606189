import isEmpty from 'lodash/isEmpty'

import { isSet } from '@utils/types'

/**
 * @typedef {network|entity|site|design} ReleaseStrategy
 */
/**
 * @typedef {Object} FeatureFlag
 * @property {String} key - The key of the feature flag
 * @property {String} title - The title of the feature flag
 * @property {String} description - The description of the feature flag
 * @property {ReleaseStrategy[]} releaseStrategies - The release strategies of the feature flag
 * @property {String[]} designs - The designs of the feature flag
 * @property {String[]} sites - The sites of the feature flag
 * @property {String[]} entities - The entities of the feature flag
 * @property {String[]} networks - The networks of the feature flag
 */

/**
 * @typedef {Object} StrategyOverride
 * @property {boolean} [network] - Override for network release strategy
 * @property {boolean} [entity] - Override for entity release strategy
 * @property {boolean} [site] - Override for site release strategy
 * @property {boolean} [design] - Override for design release strategy
 */

/**
 * Check if a feature is enabled for a design
 * @param {Object} options - Options object
 * @param {FeatureFlag} options.featureFlag - Feature flag object
 * @param {Object} options.site - Site object to check design based release strategies
 * @param {StrategyOverride} options.strategyOverride - Strategy override object. Useful for cases where site is not known
 * @returns {boolean} - Whether the feature is enabled for the design
 */
function isFeatureEnabledForDesign({
  featureFlag,
  site,
  strategyOverride = {},
}) {
  const { designs = [] } = featureFlag

  // Strategy can be overridden. Useful for cases where site is not known
  if (isSet(strategyOverride.design)) {
    return strategyOverride.design
  }

  // Release strategy cannot be determined without a site
  if (!site) {
    return false
  }

  return designs.includes(site?.design?.name)
}

/**
 * Check if a feature is enabled for a site
 * @param {Object} options - Options object
 * @param {FeatureFlag} options.featureFlag - Feature flag object
 * @param {Object} options.site - Site object to use site-based release strategies
 * @param {StrategyOverride} options.strategyOverride - Strategy override object. Useful for cases where site is not known
 * @returns {boolean} - Whether the feature is enabled for the site
 */
function isFeatureEnabledForSite({ featureFlag, site, strategyOverride = {} }) {
  const { sites = [] } = featureFlag

  // Strategy can be overridden. Useful for cases where site is not known
  if (isSet(strategyOverride.site)) {
    return strategyOverride.site
  }

  // Release strategy cannot be determined without a site
  if (!site) {
    return false
  }

  return sites.includes(site.id)
}

const strategies = {
  design: isFeatureEnabledForDesign,
  site: isFeatureEnabledForSite,
}

/**
 * @typedef {Function} ReleaseStrategyEnabled
 * @param {String} strategy - The release strategy to check
 * @returns {boolean} - Whether the release strategy is enabled
 */

/**
 * Check if a release strategy is enabled
 * @param {Object} options - Options object
 * @param {FeatureFlag} options.featureFlag - Feature flag object
 * @param {Object} [options.site] - Site object to use site-based or design based release strategies
 * @param {StrategyOverride} [options.strategyOverride] - Strategy override object. Useful for cases where site is not known
 * @returns {ReleaseStrategyEnabled} - Function to check if a release strategy is enabled
 */
function isReleaseStrategyEnabled({ featureFlag, site, strategyOverride }) {
  return function (strategy) {
    return strategies[strategy]({
      featureFlag,
      site,
      strategyOverride,
    })
  }
}

/**
 * Check if a feature is enabled. Pass in the site to use site-based or design based release strategies.
 * @param {FeatureFlag} featureFlag - Feature flag object
 * @param {Object} [options] - Options object
 * @param {Object} [options.site] - Site object to use site-based or design based release strategies
 * @param {StrategyOverride} [options.strategyOverride] - Strategy override object. Useful for cases where site is not known
 * @returns {boolean} - Whether the feature is enabled
 */
export function isFeatureEnabled(
  featureFlag,
  { site, strategyOverride = {} } = {}
) {
  if (!featureFlag) {
    return false
  }

  // Feature has no release strategies, so it's always enabled
  if (isEmpty(featureFlag) || !featureFlag.releaseStrategies) {
    return true
  }

  // Check if all release strategies are enabled
  return featureFlag.releaseStrategies.every(
    isReleaseStrategyEnabled({
      featureFlag,
      site,
      strategyOverride,
    })
  )
}
