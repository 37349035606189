import React from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import DragHandle from './DragHandle'

/**
 * @typedef {import('./DragHandle').DragHandleSize} DragHandleSize
 * @typedef {'top'|'center'|'bottom'} HandleVerticalAlign
 * @typedef {'left'|'right'} HandlePosition
 */

const verticalAlignClasses = {
  top: 'items-start',
  center: 'items-center',
  bottom: 'items-end',
}

/**
 * SortableItem component to render a sortable item
 *
 * @param {object} props SortableItem Props
 * @param {string} [props.className=''] Extra classes for the item (default: '')
 * @param {React.ReactNode} props.children Children to render
 * @param {boolean} [props.disabled=false] Whether the item is disabled (default: false)
 * @param {string} [props.handleClass=''] Class of the handle (default: '')
 * @param {DragHandleSize} [props.handleSize] Size of the handle
 * @param {HandleVerticalAlign} [props.handleVerticalAlign='center'] Vertical alignment of the handle (default: 'center')
 * @param {string} props.id (**required**) Unique id of the item within the list
 * @param {boolean} [props.showHandle=true] Whether to show the handle of the item (default: true)
 * @param {HandlePosition} [props.handlePosition='left'] Position of the handle (default: 'left')
 * @returns {React.ReactElement} The SortableItem component
 */
export default function SortableItem({
  className = '',
  children,
  disabled = false,
  handleClass = '',
  handleSize,
  handleVerticalAlign = 'center',
  id,
  // TODO: The showHandle prop needs to be refactored. Currently sorting is only possible with the handle and not the whole child element. This is due to a race condition with the onPointerDown event for the often used CollapsePanel together with this component.
  showHandle = true,
  handlePosition = 'left',
}) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  }

  return (
    <div
      className={`relative flex justify-between gap-2 ${
        handlePosition === 'left' ? 'flex-row' : 'flex-row-reverse'
      } ${
        isDragging ? 'z-10' : 'z-0'
      } ${verticalAlignClasses[handleVerticalAlign]} ${className}`}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      {!disabled && showHandle && (
        <DragHandle
          className={handleClass}
          disabled={disabled}
          isDragging={isDragging}
          listeners={listeners}
          size={handleSize}
          {...listeners}
        />
      )}
      <div className={`w-full ${isDragging ? 'shadow-xl' : ''}`}>
        {React.Children.map(children, child =>
          React.cloneElement(child, { isDragging })
        )}
      </div>
    </div>
  )
}
