import PropTypes from 'prop-types'
import React from 'react'

import Button from '@ui/buttons/Button'
import Badge from '@ui/data-display/Badge'
import Image from '@ui/data-display/Image'
import DragAndDrop from '@ui/helpers/DragAndDrop'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

function getContainerClass({ droppable, isDraggingOver } = {}) {
  return `flex flex-col flex-grow divide-y ${droppable ? '' : ''} ${
    isDraggingOver ? '' : ''
  }`
}
function getItemClass({ isDragging } = {}) {
  return `flex flex-col transition-all ease-in-out duration-300 rounded-lg ring-1 bg-white ${
    isDragging ? 'shadow-xl px-4 -mx-4 ring-primary-400' : 'ring-transparent'
  }`
}

const imageSizesMap = {
  xs: 'w-12',
  sm: 'w-16',
  md: 'w-24',
  lg: 'w-32',
}

export function SortableItem({
  actions,
  avatar,
  avatarSize = 'sm',
  className = '',
  extra,
  image,
  imageSize = 'md',
  onDelete,
  showAvatar,
  showHandle = true,
  showImage,
  status,
  subtitle,
  title,
}) {
  return (
    <SortableItemWrapper
      actions={actions}
      className={className}
      extra={extra}
      onDelete={onDelete}
      showHandle={showHandle}
    >
      {showImage && (
        <div className="relative">
          <div className={`rounded bg-gray-200 ${imageSizesMap[imageSize]}`}>
            {image ? (
              <Image
                className="rounded object-cover"
                alt={title}
                file={image}
              />
            ) : (
              <div className="aspect-h-9 aspect-w-16" />
            )}
          </div>
        </div>
      )}
      {showAvatar && (
        <div className="relative">
          <div
            className={`aspect-h-1 aspect-w-1 rounded-full bg-gray-200 ${imageSizesMap[avatarSize]}`}
          >
            {avatar && (
              <Image
                className="rounded-full object-cover"
                alt={title}
                file={avatar}
              />
            )}
          </div>
        </div>
      )}
      <div className="flex shrink flex-col truncate">
        <div className="flex flex-row items-center space-x-2 truncate">
          {status && (
            <Badge
              dot
              variant={
                status === 'deleted'
                  ? 'danger'
                  : status === 'disabled'
                    ? 'warn'
                    : 'success'
              }
            />
          )}
          <h3 className="truncate" title={title}>
            {title}
          </h3>
        </div>
        {subtitle && (
          <p
            className={`truncate text-xs text-gray-400 ${status ? 'ml-4' : ''}`}
            title={subtitle}
          >
            {subtitle}
          </p>
        )}
      </div>
    </SortableItemWrapper>
  )
}
SortableItem.propTypes = {
  actions: PropTypes.node,
  avatar: PropTypes.object,
  avatarSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  className: PropTypes.string,
  extra: PropTypes.node,
  image: PropTypes.object,
  imageSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  onDelete: PropTypes.func,
  showAvatar: PropTypes.bool,
  showHandle: PropTypes.bool,
  showImage: PropTypes.bool,
  status: PropTypes.oneOf(['active', 'disabled', 'deleted']),
  subtitle: PropTypes.string,
  title: PropTypes.string,
}
SortableList.Item = SortableItem
SortableList.Item.displayName = 'SortableList.Item'

export function SortableItemWrapper({
  actions,
  className = '',
  children,
  deleteDisabled,
  deleteClass = '',
  extra,
  handleClass = '',
  innerClass = '',
  onDelete,
  showHandle = true,
}) {
  return (
    <div
      className={`flex items-center justify-between gap-3 bg-white py-3 ${className}`}
    >
      {showHandle && (
        <Icon className={`text-gray-300 ${handleClass}`} name="bars" />
      )}
      <div
        className={`flex flex-grow items-start gap-3 truncate ${innerClass}`}
      >
        {children}
      </div>
      {extra}
      {actions}
      {onDelete && (
        <Button
          className={deleteClass}
          onClick={onDelete}
          icon="trash-alt"
          variant="danger-light"
          disabled={deleteDisabled}
        />
      )}
    </div>
  )
}
SortableItemWrapper.propTypes = {
  actions: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
  deleteClass: PropTypes.string,
  deleteDisabled: PropTypes.bool,
  extra: PropTypes.node,
  handleClass: PropTypes.string,
  innerClass: PropTypes.string,
  onDelete: PropTypes.func,
  showHandle: PropTypes.bool,
}
SortableList.ItemWrapper = SortableItemWrapper
SortableList.ItemWrapper.displayName = 'SortableList.ItemWrapper'

export default function SortableList({
  className = '',
  items = [],
  name,
  onDragEnd,
  renderItem,
  sortEnabled = true,
}) {
  if (!items || items.length <= 0) return null

  return (
    <DragAndDrop enable={sortEnabled} onDragEnd={onDragEnd}>
      {({ Draggable, Droppable }) => (
        <Droppable className={className} getClass={getContainerClass} id={name}>
          {items.map((item, index) => (
            <Draggable
              getClass={getItemClass}
              id={`${name}-id-${index}`}
              index={index}
              key={`${name}-key-${index}`}
            >
              {renderItem(item, index)}
            </Draggable>
          ))}
        </Droppable>
      )}
    </DragAndDrop>
  )
}

SortableList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  name: PropTypes.string.isRequired,
  onDragEnd: PropTypes.func,
  renderItem: PropTypes.func,
  sortEnabled: PropTypes.bool,
}
