import PropTypes from 'prop-types'

import { formatDate } from '@utils/datetime'

import { useDatetimeLocale } from './datetime'

/**
 * Format a date using the given format and locale.
 * @param {Object} props - The component props.
 * @param {string|number|Date} props.date - The date to format.
 * @param {string} props.format - The format to use (default: `Pp` => '9/3/2021, 4:00 PM').
 * @returns {string} The formatted date.
 */
export default function FormatDate({ date, format = 'Pp' }) {
  const locale = useDatetimeLocale()
  if (!date) return null
  return formatDate(date, format, { locale })
}
FormatDate.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  format: PropTypes.string,
}
