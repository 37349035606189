import PropTypes from 'prop-types'
import React from 'react'

import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  AutocompleteField,
  AutocompleteOption,
} from '@ui/data-entry/Autocomplete'
import useDebounce from '@utils/useDebounce'

import usePublication from '../services/hooks/usePublication'
import usePublications from '../services/hooks/usePublications'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export function PublicationPicker({
  className = '',
  label,
  help,
  name,
  onChange,
  placeholder,
  value,
}) {
  const { t } = useTranslation('publications/public')
  const [searchTerm, setSearchTerm] = React.useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const { publications } = usePublications({
    search: debouncedSearchTerm,
    fetchOnSearch: true,
  })
  const { publication } = usePublication({ id: value })

  const onSearch = React.useCallback(text => {
    setSearchTerm(text)
  }, [])

  return (
    <AutocompleteField
      className={className}
      label={label || t('publication')}
      placeholder={placeholder || t('searchPlaceholder')}
      help={help}
      name={name}
      value={value}
      displayIcon={<Icon name="book" />}
      displayValue={() => (publication ? publication.title : searchTerm)}
      onChange={onChange}
      onSearch={onSearch}
    >
      {publications?.map(publication => (
        <AutocompleteOption
          icon={<Icon name="book" />}
          key={publication.id}
          label={publication.title}
          name={name}
          value={publication.id}
        />
      ))}
    </AutocompleteField>
  )
}
PublicationPicker.propTypes = {
  className: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  currentSiteOnly: PropTypes.bool,
}

export default function PublicationPickerController({
  className,
  label,
  help,
  name,
  placeholder,
  shouldUnregister,
}) {
  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <PublicationPicker
          className={className}
          label={label}
          help={help}
          name={name}
          onChange={field.onChange}
          placeholder={placeholder}
          value={field.value}
        />
      )}
    />
  )
}
PublicationPickerController.propTypes = {
  className: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  shouldUnregister: PropTypes.bool,
}
