import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'
import { useImpersonateUser } from '@modules/users/services/ImpersonationServices'
import { fileUpload, getFetch, patchFetch, postFetch } from '@utils/fetcher'
import { get, set } from '@utils/objects'

import useCurrentUser from '../helpers/useCurrentUser'

const baseUrl = '/api/users'

export function useLogin() {
  const queryClient = useQueryClient()
  // Stop impersonating if the user is impersonating
  const { impersonationId, stopImpersonation } = useImpersonateUser()

  if (impersonationId) {
    stopImpersonation()
  }

  return useMutation(
    ({ email, password, token }) =>
      postFetch(`${baseUrl}/login`, {
        email,
        password,
        token,
      }),
    {
      onSuccess: user => {
        // Update `users` and optimistically the individual query when this mutation succeeds
        queryClient.setQueryData(['users', 'me'], user)
        queryClient.invalidateQueries('users')
      },
    }
  )
}

export function useLogout() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useMutation(() => postFetch(`${baseUrl}/logout`), {
    onMutate: () => {
      // "optimistically" log out the user by setting the user cache to null
      queryClient.setQueryData(['users', 'me'], null)
      queryClient.invalidateQueries('users')

      navigate('/auth/login')
    },
  })
}

const tokenRefreshInterval = 30 * 60 * 1000 // Every 30 minutes

/**
 * Hook to renew the session token every hour
 * @returns {import('react-query').UseQueryResult} The session token query result
 */
export function useRenewToken() {
  const { user } = useCurrentUser()

  return useQuery(
    ['sessionToken', user?.id],
    () => {
      if (!user) return
      return postFetch('/api/users/renew-token')
    },
    {
      refetchInterval: tokenRefreshInterval,
      // refetchIntervalInBackground: true,
      refetchOnWindowFocus: true,
      retry: false,
      enabled: !!user,
      staleTime: 15 * 60 * 1000, // only call this every 15 minutes
    }
  )
}

export async function forgotPassword(email) {
  try {
    const data = await postFetch(`${baseUrl}/forgot-password`, { email })

    return data
  } catch (error) {
    return {
      error: error.data,
    }
  }
}

export async function resetPassword(password, token) {
  try {
    const data = await patchFetch(`${baseUrl}/reset-password/${token}`, {
      password,
    })

    return data
  } catch (error) {
    return {
      error: error.data,
    }
  }
}

export async function get2FASecretAndUrl() {
  try {
    const data = await getFetch(`${baseUrl}/new-2fa`)

    return data
  } catch (error) {
    return {
      error: error.data,
    }
  }
}

export function useUpdatePassword() {
  return useMutation(data => patchFetch(`${baseUrl}/update-password`, data))
}

export function useUpdateCurrentUser() {
  const queryClient = useQueryClient()

  return useMutation(user => patchFetch(`${baseUrl}/update-me`, user), {
    onSuccess: user => {
      // Update `users` and optimistically the individual query when this mutation succeeds
      queryClient.setQueryData(['users', 'me'], user)
      queryClient.invalidateQueries('users')
    },
  })
}

/**
 * Hook to update current user's preference value
 * @param {string} path The path to the preference
 * @param {boolean} global Whether the preference is global or not
 * @returns {[any, (newValue: any) => any]} The current value and a function to update the value
 */
export function useUserPreference(path = '', global = false) {
  const { user } = useCurrentUser()
  const { entity } = useCurrentEntity()
  const { mutate: updateUser } = useUpdateCurrentUser()

  // If the user is not logged in, return null
  if (!user || !entity) return []

  const preferencePath = `preferences.${global ? 'global' : entity.id}.${path}`

  // Get the current value of the preference
  const value = get(user, preferencePath)

  return [
    value,
    newValue => {
      // Update the user object with the new value
      const updatedUser = set(user, preferencePath, newValue)

      // Call the mutation to update the user
      updateUser(updatedUser)

      // Return the updated user
      return updatedUser
    },
  ]
}

export function useEnable2FA() {
  const queryClient = useQueryClient()

  return useMutation(data => patchFetch(`${baseUrl}/enable-2fa`, data), {
    onSuccess: data => {
      const { user } = data
      // Update `users` and optimistically the individual query when this mutation succeeds
      queryClient.setQueryData(['users', 'me'], user)
      queryClient.invalidateQueries('users')
    },
  })
}

export function useDisable2FA() {
  const queryClient = useQueryClient()

  return useMutation(data => patchFetch(`${baseUrl}/disable-2fa`, data), {
    onSuccess: user => {
      // Update `users` and optimistically the individual query when this mutation succeeds
      queryClient.setQueryData(['users', 'me'], user)
      queryClient.invalidateQueries('users')
    },
  })
}

export function useAvatarUpload() {
  const queryClient = useQueryClient()

  const { mutate, error, isError, isLoading, reset } = useMutation(async file =>
    fileUpload(`${baseUrl}/upload-avatar`, file)
  )

  function onAvatarUpload(file, onSuccess) {
    return mutate(file, {
      onSuccess: () => {
        queryClient.invalidateQueries(['users', 'me'])
        onSuccess()
      },
    })
  }

  return { onAvatarUpload, error, isError, isLoading, reset }
}

export default {
  useLogin,
  useLogout,
  forgotPassword,
  resetPassword,
  get2FASecretAndUrl,
  useUpdatePassword,
  useUpdateCurrentUser,
  useEnable2FA,
  useDisable2FA,
  useAvatarUpload,
}
