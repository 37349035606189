import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import FooterForm from './FooterForm'
import AdventistenDeHeaderForm from './HeaderForm'

export default function useAdventistenDesignSettingsTabs() {
  const { t } = useTranslation('web/appearance')

  return useMemo(
    () => [
      {
        name: 'header',
        title: t('header'),
        component: AdventistenDeHeaderForm,
      },
      {
        name: 'footer',
        title: t('footer'),
        component: FooterForm,
      },
    ],
    [t]
  )
}
