import React from 'react'

import { config } from './config'

const EpisodeHeaderSettings = React.lazy(() => import('./Settings'))
const EpisodeHeaderBlock = React.lazy(() => import('./Block'))

export default function EpisodeHeader(props) {
  return <EpisodeHeaderBlock {...props} />
}

EpisodeHeader.toolbarItem = {
  ...config,
  component: <EpisodeHeader />,
}

EpisodeHeader.craft = {
  displayName: 'EpisodeHeader',
  props: {
    showAbstract: true,
  },
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EpisodeHeaderSettings,
  },
}
