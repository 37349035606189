import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { CategoryPickerField } from '@modules/categories/components/CategoryAutocompletePicker'
import BlockField from '@modules/web/components/ContentEditor/shared/BlockField'

export default function CategoriesFields({
  dataSource,
  fieldPrefix = '',
  help,
  label,
  name = 'categories',
  singular,
  siteId,
}) {
  const { t } = useTranslation('articles/public')

  const dataSourceOverride = React.useMemo(() => {
    return dataSource?.settings?.categories || []
  }, [dataSource?.settings?.categories])

  return (
    <BlockField name={`${fieldPrefix}${name}`}>
      {({ fieldProps, value, onDataChange }) => (
        <CategoryPickerField
          {...fieldProps}
          help={help || t('categoriesHelp', { count: singular ? 1 : 0 })}
          label={label || t('categories')}
          multiple
          onChange={onDataChange}
          protectedItems={dataSourceOverride}
          showValueInline={false}
          siteId={siteId}
          value={[...(value || []), ...dataSourceOverride]}
        />
      )}
    </BlockField>
  )
}

CategoriesFields.propTypes = {
  dataSource: PropTypes.object,
  fieldPrefix: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  singular: PropTypes.bool,
  siteId: PropTypes.string,
}
