import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

export default function useSeasons(showId) {
  const { data, error, isLoading, isFetching } = useQuery(
    ['seasons', showId],
    () => getFetch(showId ? `/api/media-library/shows/${showId}/seasons` : null)
  )

  return {
    seasons: data?.items || [],
    count: data?.count || 0,
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
