import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import useDataSources from '@modules/web/services/hooks/useDataSources'
import { ListboxField } from '@ui/data-entry/Listbox'

import { SelectedTypeOption } from '../SelectedTypeOption'
import { TypeOption } from '../TypeOption'
import { useDataSourcesContext } from '../context/hooks'

export function DataSourceSelectField({
  allowedTypes,
  className,
  help,
  label,
  name = 'dataSource',
  onChange,
  placeholder,
  required,
  value,
}) {
  const { t } = useTranslation('web/sites')
  const registeredDataSources = useDataSourcesContext()

  const { dataSources, isLoading } = useDataSources({
    allowedTypes,
    limit: 50,
  })

  const options = React.useMemo(
    () =>
      dataSources?.map(({ id, name, type }) => {
        return {
          value: id,
          label: name,
          icon: registeredDataSources[type || '']?.icon,
        }
      }) || [],
    [registeredDataSources, dataSources]
  )

  return (
    <ListboxField
      className={className}
      help={help}
      label={label ?? t('dataSource')}
      name={name}
      onChange={onChange}
      options={options}
      placeholder={<SelectedTypeOption label={placeholder} />}
      renderSelectedLabel={SelectedTypeOption}
      required={required}
      value={value}
    >
      <ListboxField.Options>
        {Boolean(value) && (
          <>
            <ListboxField.Option
              as="li"
              value={null}
              className="flex items-center gap-4"
            >
              {t('clearSelection')}
            </ListboxField.Option>
            <ListboxField.Divider />
          </>
        )}
        {allowedTypes?.length && options.length === 0 && !isLoading ? (
          <ListboxField.Option as="li" disabled>
            {t('noDataSourcesFound')}
          </ListboxField.Option>
        ) : null}
        {options.map(({ value, label, icon }) => (
          <ListboxField.Option as="li" key={value} value={value}>
            <TypeOption value={value} label={label} icon={icon} />
          </ListboxField.Option>
        ))}
      </ListboxField.Options>
    </ListboxField>
  )
}

DataSourceSelectField.propTypes = {
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
}
