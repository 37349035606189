import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import { socialMediaButtons } from '@modules/web/components/ContentEditor/blocks/ShareButton/hooks'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { useBlock } from '@modules/web/helpers/useBlock'
import useSite from '@modules/web/services/hooks/useSite'
import Image from '@ui/data-display/Image'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'
import FormatDate from '@ui/helpers/FormatDate'
import Divider from '@ui/layout/Divider'
import Heading from '@ui/typography/Heading'

import useArticle from '../../services/hooks/useArticle'
import { ArticlePicker } from '../Picker'

const RichText = React.lazy(() => import('@ui/data-entry/RichText/Render'))

const config = {
  name: 'ArticleDetail',
  label: 'articles/public:articleDetail',
  type: 'plugin',
  icon: 'file-invoice',
  component: <ArticleDetail />,
}

function getPlaceholder() {
  return {
    image: {},
    createdAt: new Date(),
    categories: '[Categories]',
    author: '[Author]',
    location: '[Location]',
    title: '[Placeholder title]',
    subtitle: '[Placeholder subtitle]',
    abstract: '[Placeholder for abstarct.]',
    body: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: "[This is a placeholder for article's body]",
            },
          ],
        },
      ],
    },
    pullQuote: '[Placeholder for pull quote]',
    files: '[Files to be downloaded]',
  }
}

export default function ArticleDetail({
  id,
  showTitle,
  showAbstract,
  showAuthor,
  showBody,
  showDate,
  showImage,
  showLocation,
  showSubtitle,
  showPullQuote,
  showCategories,
  showFiles,
  dynamic,
}) {
  const { t } = useTranslation('articles/public')
  const { article } = useArticle(dynamic ? null : id)
  const { site } = useSite()

  const {
    image,
    title,
    subtitle,
    abstract,
    pullQuote,
    categories,
    body,
    author,
    location,
    createdAt,
    sites,
    files,
  } = article || getPlaceholder()

  return (
    <Content
      icon={config.icon}
      title={t('articleDetail')}
      locked={dynamic}
      className="w-full"
    >
      <div className="flex flex-col p-4">
        <div className="flex max-w-xl flex-col gap-6">
          {showImage && image?.file && (
            <Image file={image.file} alt={title} width={600} />
          )}
          <div className="flex flex-col gap-1">
            {showTitle && <h1 className="text-3xl font-bold">{title}</h1>}
            {showSubtitle && (
              <h2 className="text-xl font-semibold text-gray-600">
                {subtitle}
              </h2>
            )}
          </div>
          {showCategories && <p>{categories}</p>}
          {(showAuthor || showLocation || showDate || showCategories) && (
            <div className="flex flex-row gap-4">
              {showAuthor && <span>{author}</span>}
              {showLocation && <span>{location}</span>}
              {showDate && (
                <span>
                  <FormatDate
                    format="P"
                    date={sites?.[site?.id]?.startsAt || createdAt}
                  />
                </span>
              )}
            </div>
          )}
          {showAbstract && (
            <p className="font-serif italic text-gray-600">{abstract}</p>
          )}
          {showBody && <RichText doc={body} />}
          {showPullQuote && <p>&ldquo;{pullQuote}&rdquo;</p>}
          {showFiles && <p>{typeof files === 'string' ? files : '[Files]'}</p>}
        </div>
      </div>
    </Content>
  )
}
ArticleDetail.propTypes = {
  dynamic: PropTypes.bool,
  id: PropTypes.string,
  showTitle: PropTypes.bool,
  showImage: PropTypes.bool,
  showDate: PropTypes.bool,
  showSubtitle: PropTypes.bool,
  showAuthor: PropTypes.bool,
  showLocation: PropTypes.bool,
  showBody: PropTypes.bool,
  showAbstract: PropTypes.bool,
  showPullQuote: PropTypes.bool,
  showCategories: PropTypes.bool,
  showFiles: PropTypes.bool,
}
ArticleDetail.toolbarItem = config

function ArticleDetailSettings() {
  const { t } = useTranslation('articles/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(!data.dynamic)
  const {
    showTitle,
    showImage,
    showDate,
    showSubtitle,
    showAuthor,
    showLocation,
    showBody,
    showPullQuote,
    showCategories,
    showFiles,
    shareOptions,
  } = useBlock(config.name)

  const onArticleIdChange = useCallback(
    value => {
      actions.setProp(props => (props.id = value))
    },
    [actions]
  )

  return (
    <SettingsWrap title={t('articleDetail')} help={t('articleDetailHelp')}>
      <div className="flex flex-col gap-2">
        <Checkbox
          label={t('dynamic')}
          help={t('dynamicHelp')}
          onChange={value => {
            setShowPicker(!value)
            actions.setProp(props => (props.dynamic = value))
          }}
          value={data.dynamic}
          name="dynamic"
        />
        <Divider />
        <Heading as="h5" text={t('options')} />
        {showPicker && (
          <ArticlePicker
            onChange={onArticleIdChange}
            value={data.id}
            currentSiteOnly
          />
        )}
        {showTitle && (
          <Checkbox
            name="showTitle"
            label={t('showTitle')}
            onChange={value => {
              actions.setProp(props => (props.showTitle = value))
            }}
            value={data.showTitle}
          />
        )}
        {showImage && (
          <Checkbox
            name="showImage"
            label={t('showImage')}
            onChange={value => {
              actions.setProp(props => (props.showImage = value))
            }}
            value={data.showImage}
          />
        )}
        {showCategories && (
          <Checkbox
            name="showCategories"
            label={t('showCategories')}
            onChange={value => {
              actions.setProp(props => (props.showCategories = value))
            }}
            value={data.showCategories}
          />
        )}
        {showDate && (
          <Checkbox
            name="showDate"
            label={t('showDate')}
            onChange={value => {
              actions.setProp(props => (props.showDate = value))
            }}
            value={data.showDate}
          />
        )}
        {showSubtitle && (
          <Checkbox
            name="showSubtitle"
            label={t('showSubtitle')}
            onChange={value => {
              actions.setProp(props => (props.showSubtitle = value))
            }}
            value={data.showSubtitle}
          />
        )}
        {showAuthor && (
          <Checkbox
            name="showAuthor"
            label={t('showAuthor')}
            onChange={value => {
              actions.setProp(props => (props.showAuthor = value))
            }}
            value={data.showAuthor}
          />
        )}
        {showLocation && (
          <Checkbox
            name="showLocation"
            label={t('showLocation')}
            onChange={value => {
              actions.setProp(props => (props.showLocation = value))
            }}
            value={data.showLocation}
          />
        )}
        <Checkbox
          name="showAbstract"
          label={t('showAbstract')}
          onChange={value => {
            actions.setProp(props => (props.showAbstract = value))
          }}
          value={data.showAbstract}
        />
        {showPullQuote && (
          <Checkbox
            name="showPullQuote"
            label={t('showPullQuote')}
            onChange={value => {
              actions.setProp(props => (props.showPullQuote = value))
            }}
            value={data.showPullQuote}
          />
        )}
        {showBody && (
          <Checkbox
            name="showBody"
            label={t('showBody')}
            onChange={value => {
              actions.setProp(props => (props.showBody = value))
            }}
            value={data.showBody}
          />
        )}
        {showFiles && (
          <>
            <Checkbox
              name="showFiles"
              label={t('showFiles')}
              onChange={value => {
                actions.setProp(props => (props.showFiles = value))
              }}
              value={data.showFiles}
            />

            <Input
              name="filesLabel"
              label={t('filesLabel')}
              help={t('filesLabelHelp')}
              placeholder={t('filesLabelPlaceholder')}
              onChange={e => {
                actions.setProp(props => (props.filesLabel = e.target.value))
              }}
              value={data.filesLabel}
            />
          </>
        )}
        {shareOptions && (
          <>
            <Divider />
            <Heading as="h5" text={t('sharingOptions')} />

            {socialMediaButtons.map(media => (
              <Checkbox
                name={media.name}
                value={data[media.name]}
                label={media.label || media.name}
                onChange={value =>
                  actions.setProp(props => (props[media.name] = value))
                }
                key={media.name}
              />
            ))}
          </>
        )}
      </div>
    </SettingsWrap>
  )
}

ArticleDetail.craft = {
  displayName: 'ArticleDetail',
  props: {
    id: undefined,
    dynamic: false,
    showTitle: true,
    showAbstract: true,
    showBody: true,
  },
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ArticleDetailSettings,
  },
}
