import PropTypes from 'prop-types'
import React from 'react'

import { ModalContext } from './hooks'

export default function ModalProvider({ children, initialOpen }) {
  const focusRef = React.useRef(null)
  const [open, setOpen] = React.useState(initialOpen)

  const onClose = React.useCallback(() => setOpen(false), [])
  const onOpen = React.useCallback(() => setOpen(true), [])

  return (
    <ModalContext.Provider value={{ open, onClose, onOpen, focusRef }}>
      {children}
    </ModalContext.Provider>
  )
}
ModalProvider.propTypes = {
  children: PropTypes.node,
  initialOpen: PropTypes.bool,
}
