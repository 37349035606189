import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { useBackgroundStyles } from '@modules/web/components/ContentEditor/shared/BackgroundColorSelect/hooks'
import { usePaddingsClasses } from '@modules/web/components/ContentEditor/shared/PaddingsSelect/hooks'
import { useShadowStyles } from '@modules/web/components/ContentEditor/shared/ShadowSelect/hooks'

import { useBorderStyles } from '../shared/useBorderStyles'
import useTextStyles from '../shared/useTextStyles'
import useButtonSiteSettings from './helpers'

export default function ButtonPreview({ className = '', label, settings }) {
  const { t } = useTranslation('designs/base')
  const { className: buttonClass, style } = useButtonStyles(settings)

  return (
    <div className={`cursor-pointer ${buttonClass} ${className}`} style={style}>
      {label || t('buttonPreviewLabel')}
    </div>
  )
}
ButtonPreview.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  settings: PropTypes.object.isRequired,
}

/**
 * Button variant preview
 * @param {string} className - The component className
 * @param {string} label - The button label
 * @param {string} variant - The button variant
 * @returns {React.Component} The component
 */
export function ButtonVariantPreview({ className, label, variant }) {
  const settings = useButtonSiteSettings(variant)

  return (
    <ButtonPreview className={className} label={label} settings={settings} />
  )
}
ButtonVariantPreview.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string.isRequired,
}

/**
 * Get button styles from variant settings
 * @param {string} variant - variant name
 * @returns {object} - button styles object (with className: string, style: object)
 * @example
 * const buttonStyles = useButtonStyles('primary')
 * // -> { className: 'text-white bg-primary-500 border-primary-500', style: {} }
 */
function useButtonStyles(settings = {}) {
  const textStyles = useTextStyles(settings?.text)
  const borderStyles = useBorderStyles(settings?.border)
  const paddingClasses = usePaddingsClasses(settings?.padding)
  const shadowStyles = useShadowStyles(settings?.shadow)
  const backgroundStyles = useBackgroundStyles(settings?.background)

  if (!settings) return {}

  const style = {
    ...textStyles.style,
    ...borderStyles.style,
    ...backgroundStyles.style,
  }

  const classNames = [
    textStyles.className,
    borderStyles.className,
    paddingClasses.className,
    shadowStyles.className,
  ]

  return {
    className: classNames.filter(Boolean).join(' '),
    style,
  }
}
