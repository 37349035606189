import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { getFetch } from '@utils/fetcher'

import { useSiteId } from './useSite'

export default function useLayout(id) {
  const siteId = useSiteId()
  const params = useParams()

  const layoutId = id ?? params.layoutId

  const { data, error, isLoading } = useQuery(
    ['layouts', layoutId],
    () => getFetch(`/api/web/sites/${siteId}/layouts/${layoutId}`),
    {
      enabled: !!(siteId && layoutId),
    }
  )

  return { layout: data, layoutId, error, loading: isLoading }
}
