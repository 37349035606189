import PropType from 'prop-types'
import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Select from '@ui/data-entry/Select'

export function DarkModeSelect({ className = '', name, required, enableAuto }) {
  const { watch } = useFormContext()
  const { t } = useTranslation('ui')

  const watchDarkMode = watch(name)

  return (
    <>
      <Select
        className={className}
        label={t('appearance')}
        help={t('appearanceHelp')}
        name={name}
        shouldUnregister
        required={required}
      >
        {watchDarkMode && (
          <Select.Option label={t('clearSelection')} value={null} />
        )}
        <Select.Placeholder label="-" />
        <Select.Option label={t('appearance-light')} value="light" />
        <Select.Option label={t('appearance-dark')} value="dark" />
        {enableAuto && (
          <Select.Option label={t('appearance-auto')} value="auto" />
        )}
      </Select>
    </>
  )
}
DarkModeSelect.propTypes = {
  className: PropType.string,
  name: PropType.string.isRequired,
  required: PropType.bool,
  enableAuto: PropType.bool,
}
