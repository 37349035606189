import PropTypes from 'prop-types'
import React from 'react'

import useSeasons from '@modules/media-library/services/hooks/useSeasons'
import SelectController, {
  SelectField,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'

export default function SeasonSelect({
  controlled = true,
  disabled,
  help,
  onChange,
  showId,
  ...selectProps
}) {
  const { seasons, loading: seasonsLoading } = useSeasons(showId)

  const Select = controlled ? SelectController : SelectField

  const handleOnChange = eventOrValue => {
    const value = eventOrValue.target ? eventOrValue.target.value : eventOrValue
    onChange?.(value)
  }

  const seasonsDisabled = !seasons?.length && !seasonsLoading

  return (
    <Select
      {...selectProps}
      help={seasonsDisabled ? null : help}
      disabled={disabled || seasonsDisabled}
      onChange={handleOnChange}
      showClear
    >
      <SelectPlaceholder label="-" />
      {seasons?.map(season => (
        <SelectOption
          key={season.id}
          value={season.id}
          label={
            season.groupName
              ? `${season.groupName}: ${season.title}`
              : season.title
          }
        />
      ))}
    </Select>
  )
}
SeasonSelect.propTypes = {
  controlled: PropTypes.bool,
  disabled: PropTypes.bool,
  help: PropTypes.string,
  onChange: PropTypes.func,
  showId: PropTypes.string,
}
