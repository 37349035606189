import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import { BlockColorSelect as ColorSelect } from '@modules/web/components/ContentEditor/shared/ColorSelect'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { useBlock } from '@modules/web/helpers/useBlock'
import Image from '@ui/data-display/Image'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import FormatDate from '@ui/helpers/FormatDate'

import useEvent from '../../services/hooks/useEvent'
import { EventPicker } from '../event/EventPicker'

const RichText = React.lazy(() => import('@ui/data-entry/RichText/Render'))

const config = {
  name: 'EventDetail',
  label: 'events/public:eventDetail',
  type: 'plugin',
  icon: 'file-invoice',
  component: <EventDetail />,
}

function getPlaceholder() {
  return {
    image: {},
    startsAt: new Date(),
    endsAt: new Date(),
    category: '[Category]',
    organizer: '[Organizer]',
    location: '[Location]',
    theme: '[Theme]',
    title: '[Placeholder title]',
    description: '[Placeholder for description]',
    body: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: "[This is a placeholder for the event's body]",
            },
          ],
        },
      ],
    },
    infoName: '[Contact person]',
    infoEmail: '[Contact email]',
    audience: '[Audience]',
    minParticipants: '[Minimum participants]',
    maxParticipants: '[Maximum participants]',
    ageMin: '[Minimum age]',
    ageMax: '[Maximum age]',
    prices: '[Placeholder for price information]',
    deadline: new Date(),
    earlyBookingDeadline: new Date(),
    accommodations: '[Placeholder for accommodation]',
    foods: '[Placeholder for meals]',
    workshops: '[Placeholder for workshops]',
  }
}

export default function EventDetail({
  id,
  dynamic,
  showImage,
  showCategory,
  showPricing,
  showContact,
  showAudience,
  showDeadline,
  showAccommodation,
  showMeals,
  showWorkshops,
  showOrganizer,
}) {
  const { t } = useTranslation('events/public')
  const { event } = useEvent(dynamic ? null : id)

  const {
    image,
    startsAt,
    endsAt,
    category,
    organizer,
    location,
    theme,
    title,
    description,
    body,
    infoName,
    infoEmail,
    audience,
    minParticipants,
    maxParticipants,
    ageMin,
    ageMax,
    prices,
    deadline,
    earlyBookingDeadline,
    accommodations,
    foods,
    workshops,
  } = event || getPlaceholder()

  return (
    <Content icon={config.icon} title={t('eventDetail')} locked={dynamic}>
      <div className="flex flex-col p-4">
        <div className="max-w-xl space-y-6">
          {showImage && image?.file && (
            <Image file={image.file} alt={title} width={600} />
          )}
          <div className="space-y-1">
            <h1 className="text-3xl font-bold">{title}</h1>
            {description && <p className="text-xl">{description}</p>}
          </div>
          {showCategory && <p>{category}</p>}

          <div className="flex gap-4">
            {startsAt && (
              <span>
                <FormatDate format="P" date={startsAt} />
              </span>
            )}
            {' – '}
            {endsAt && (
              <span>
                <FormatDate format="P" date={endsAt} />
              </span>
            )}
          </div>

          {body && <RichText doc={body} />}

          <div className="flex gap-4">
            {location && <span>{location}</span>}
            {theme && <span>{theme}</span>}
          </div>

          {showPricing && <p>{prices}</p>}

          {showContact && (
            <div className="flex gap-4">
              {infoName && <span>{infoName}</span>}
              {infoEmail && <span>{infoEmail}</span>}
            </div>
          )}

          {showAudience && (
            <div>
              <p>{audience}</p>
              <p>
                {ageMin} – {ageMax}
              </p>
              <p>
                {minParticipants} – {maxParticipants}
              </p>
            </div>
          )}

          {showDeadline && (
            <div className="flex gap-4">
              {deadline && (
                <div>
                  Registration deadline
                  <br />
                  <FormatDate format="P" date={deadline} />
                </div>
              )}
              {earlyBookingDeadline && (
                <div>
                  Early booking deadline
                  <br />
                  <FormatDate format="P" date={earlyBookingDeadline} />
                </div>
              )}
            </div>
          )}

          {showAccommodation && <p>{accommodations}</p>}

          {showMeals && <p>{foods}</p>}

          {showWorkshops && <p>{workshops}</p>}

          {showOrganizer && <p>{organizer}</p>}
        </div>
      </div>
    </Content>
  )
}
EventDetail.propTypes = {
  dynamic: PropTypes.bool,
  id: PropTypes.string,
  headerBgColor: PropTypes.string,
  bodyBgColor: PropTypes.string,
  pricesBgColor: PropTypes.string,
  workshopsBgColor: PropTypes.string,
  organizerBgColor: PropTypes.string,
  showImage: PropTypes.bool,
  showCategory: PropTypes.bool,
  showPricing: PropTypes.bool,
  showContact: PropTypes.bool,
  showAudience: PropTypes.bool,
  showDeadline: PropTypes.bool,
  showAccommodation: PropTypes.bool,
  showMeals: PropTypes.bool,
  showWorkshops: PropTypes.bool,
  showOrganizer: PropTypes.bool,
}
EventDetail.toolbarItem = config

function EventDetailSettings() {
  const { t } = useTranslation('events/public')
  const {
    headerBgColor,
    bodyBgColor,
    pricesBgColor,
    workshopsBgColor,
    organizerBgColor,
    showImage,
    registration,
    terms,
  } = useBlock(config.name)

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(!data.dynamic)

  const onEventIdChange = useCallback(
    value => {
      actions.setProp(props => (props.id = value))
    },
    [actions]
  )

  return (
    <SettingsWrap title={t('eventDetail')} help={t('eventDetailHelp')}>
      <div className="space-y-4">
        <Checkbox
          label={t('dynamic')}
          help={t('dynamicHelp')}
          onChange={value => {
            setShowPicker(!value)
            actions.setProp(props => (props.dynamic = value))
          }}
          value={data.dynamic}
          name="dynamic"
        />
        {showPicker && (
          <EventPicker onChange={onEventIdChange} value={data.id} />
        )}
        {headerBgColor && (
          <ColorSelect name="headerBgColor" label={t('headerBgColor')} />
        )}
        {bodyBgColor && (
          <ColorSelect name="bodyBgColor" label={t('bodyBgColor')} />
        )}
        {pricesBgColor && (
          <ColorSelect name="pricesBgColor" label={t('pricesBgColor')} />
        )}
        {workshopsBgColor && (
          <ColorSelect name="workshopsBgColor" label={t('workshopsBgColor')} />
        )}
        {organizerBgColor && (
          <ColorSelect name="organizerBgColor" label={t('organizerBgColor')} />
        )}
        {showImage && (
          <Checkbox
            name="showImage"
            label={t('showImage')}
            onChange={value => {
              actions.setProp(props => (props.showImage = value))
            }}
            value={data.showImage}
          />
        )}
        <Checkbox
          name="showCategory"
          label={t('showCategory')}
          onChange={value => {
            actions.setProp(props => (props.showCategory = value))
          }}
          value={data.showCategory}
        />
        <Checkbox
          name="showPricing"
          label={t('showPricing')}
          onChange={value => {
            actions.setProp(props => (props.showPricing = value))
          }}
          value={data.showPricing}
        />
        <Checkbox
          name="showContact"
          label={t('showContact')}
          onChange={value => {
            actions.setProp(props => (props.showContact = value))
          }}
          value={data.showContact}
        />
        <Checkbox
          name="showAudience"
          label={t('showAudience')}
          onChange={value => {
            actions.setProp(props => (props.showAudience = value))
          }}
          value={data.showAudience}
        />
        <Checkbox
          name="showDeadline"
          label={t('showDeadline')}
          onChange={value => {
            actions.setProp(props => (props.showDeadline = value))
          }}
          value={data.showDeadline}
        />
        <Checkbox
          name="showAccommodation"
          label={t('showAccommodation')}
          onChange={value => {
            actions.setProp(props => (props.showAccommodation = value))
          }}
          value={data.showAccommodation}
        />
        <Checkbox
          name="showMeals"
          label={t('showMeals')}
          onChange={value => {
            actions.setProp(props => (props.showMeals = value))
          }}
          value={data.showMeals}
        />
        <Checkbox
          name="showWorkshops"
          label={t('showWorkshops')}
          onChange={value => {
            actions.setProp(props => (props.showWorkshops = value))
          }}
          value={data.showWorkshops}
        />
        <Checkbox
          name="showOrganizer"
          label={t('showOrganizer')}
          onChange={value => {
            actions.setProp(props => (props.showOrganizer = value))
          }}
          value={data.showOrganizer}
        />
        {registration && (
          <PagePicker
            label={t('registrationPageId')}
            help={t('registrationPageIdHelp')}
            value={data.registrationPageId}
            onChange={pageId =>
              actions.setProp(props => (props.registrationPageId = pageId))
            }
          />
        )}
        {terms && (
          <PagePicker
            label={t('termsPageId')}
            help={t('termsPageIdHelp')}
            value={data.termsPageId}
            onChange={pageId =>
              actions.setProp(props => (props.termsPageId = pageId))
            }
          />
        )}
      </div>
    </SettingsWrap>
  )
}

EventDetail.craft = {
  displayName: 'EventDetail',
  props: {
    id: undefined,
    dynamic: false,
    showImage: true,
    showCategory: true,
    showPricing: true,
    showContact: true,
    showAudience: true,
    showDeadline: true,
    showAccommodation: true,
    showMeals: true,
    showWorkshops: true,
    showOrganizer: true,
  },
  custom: {
    type: config.type,
    resources: ['Event', 'eventRegistration'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EventDetailSettings,
  },
}
