import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'
import { SelectField as Select, SelectOption } from '@ui/data-entry/Select'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'
import Heading from '@ui/typography/Heading'

import useChannel from '../../services/hooks/useChannel'
import useEpisode from '../../services/hooks/useEpisode'
import ChannelSelect from '../ChannelSelect'
import { EpisodePicker } from '../EpisodePicker'

const config = {
  name: 'EpisodeVideos',
  label: 'media-library/public:episodeVideos',
  type: 'plugin',
  icon: 'bars',
  component: <EpisodeVideos />,
}

function getPlaceholder() {
  return {
    show: {
      videosTitle: 'Videos',
    },
    videos: [
      {
        id: '615310159e41663f120dbadf',
        title: 'First video',
        image: {},
      },
      {
        id: '622610c9a7bdda457fa35cbd',
        title: 'Second video',
        image: {},
      },
    ],
  }
}

export default function EpisodeVideos({ channelId, id, title }) {
  const { t } = useTranslation('media-library/public')
  const showContent = false

  const { resources } = usePageResources()
  const { channel } = useChannel(channelId)
  const { episode } = useEpisode(id)

  const _channel = resources?.Channel ? resources.Channel : channel
  const _show = resources?.Show
  const _episode = resources?.Episode
    ? resources.Episode
    : episode || getPlaceholder()

  const { videos } = _episode

  return (
    <Content icon={config.icon} title={t('episodeVideos')}>
      {showContent ? (
        <div className="flex flex-col p-4">
          <h3>{title}</h3>
          {videos.map(video => (
            <div key={video.id}>{video.title}</div>
          ))}
        </div>
      ) : (
        <Box space="xs">
          <Heading as="h6" className="ml-2" text={title || t('videos')} />
          <ul className="ml-8 list-disc text-sm">
            <li>
              {t('channel')}: {_channel?.title}
            </li>
            <li>
              {t('show')}: {_show?.title || t('dynamic')}
            </li>
            <li>
              {t('episode')}: {_episode?.title || t('dynamic')}
            </li>
          </ul>
        </Box>
      )}
    </Content>
  )
}
EpisodeVideos.propTypes = {
  channelId: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
}
EpisodeVideos.toolbarItem = config

function EpisodeVideosSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(data.channelId || data.id)

  const { resources } = usePageResources()

  const onEpisodeIdChange = useCallback(
    value => {
      actions.setProp(props => (props.id = value))
    },
    [actions]
  )

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t('episodeVideos')} help={t('episodeVideosHelp')}>
      <Box space="xl">
        {resources?.Channel && !resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Channel?.title}
          </Message>
        )}
        {resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Show?.title}
          </Message>
        )}
        <Input
          label={t('title')}
          onChange={onFieldUpdate('title')}
          placeholder={t('videos')}
          value={data.title}
        />
        <Select
          label={t('displayMode')}
          help={t('displayModeHelp')}
          value={data.displayMode}
          onChange={onFieldUpdate('displayMode')}
        >
          <SelectOption label={t('displayModeList')} value="list" />
          <SelectOption label={t('displayModeCards')} value="cards" />
        </Select>
        {data.displayMode?.includes('card') && (
          <>
            <Checkbox
              name="enableAnimations"
              label={t('enableAnimations')}
              help={t('enableAnimationsCardsHelp')}
              value={data.enableAnimations}
              onChange={checked =>
                actions.setProp(p => (p.enableAnimations = checked))
              }
            />
          </>
        )}
        <PagePicker
          label={t('videoDetailPageId')}
          help={t('videoDetailPageIdHelp')}
          value={data.videoDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.videoDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Video'
          }
        />
        {!resources?.Show && (
          <>
            <Divider />
            <Checkbox
              label={t('pickEpisodeManually')}
              help={t('pickEpisodeManuallyHelp')}
              onChange={value => setShowPicker(value)}
              value={showPicker}
            />
            {showPicker && (
              <Box space="lg">
                {!resources?.Channel && (
                  <ChannelSelect
                    label={t('channel')}
                    onChange={onChannelUpdate('channelId')}
                    value={data.channelId}
                    controlled={false}
                  />
                )}
                <EpisodePicker
                  channelId={data.channelId || resources?.Channel?.id}
                  onChange={onEpisodeIdChange}
                  value={data.id}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </SettingsWrap>
  )
}

EpisodeVideos.craft = {
  displayName: 'EpisodeVideos',
  props: {
    channelId: undefined,
    id: undefined,
    title: '',
    displayMode: 'list', // 'cards' or 'list'
    videoDetailPageId: undefined,
  },
  custom: {
    type: config.type,
    resources: ['episodeVideos'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EpisodeVideosSettings,
  },
}
