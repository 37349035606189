import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import useCurrentUser from '@modules/auth/helpers/useCurrentUser'
import { useDesignContext } from '@modules/web/components/DesignProvider'
import Badge from '@ui/data-display/Badge'
import Section from '@ui/data-display/Section'
import Checkbox from '@ui/data-entry/Checkbox'
import { DarkModeSelect } from '@ui/data-entry/DarkModeSelect'
import { FieldLabel } from '@ui/data-entry/Field'
import Form from '@ui/data-entry/Form'
import Submit from '@ui/data-entry/Submit'
import Box from '@ui/layout/Box'

export const PageForm = ({ page, onSubmit }) => {
  const design = useDesignContext()
  const { t } = useTranslation(design.namespace, 'ui')
  const { user } = useCurrentUser()

  return (
    <Form
      data={page}
      onSubmit={onSubmit}
      id={`design-header-${page.id}-${page.updatedAt}`}
    >
      <Section title={t('settings')}>
        <Section.Body>
          <Box space="xl">
            <DarkModeSelect
              className="w-full md:w-2/3"
              name="design.appearance"
              label={t('designAppearance')}
              help={t('designAppearanceHelp')}
            />
            {user.isAdmin && (
              <Checkbox
                name="design.showAppearanceSwitch"
                label={
                  <div className="flex items-center gap-2">
                    <FieldLabel label={t('ui:showAppearanceSwitch')} />
                    <Badge text="admin" variant="danger" />
                  </div>
                }
                help={t('ui:showAppearanceSwitchHelp')}
              />
            )}
            <Checkbox
              name="design.header.transparent"
              label={t('transparentHeader')}
              help={t('transparentHeaderHelp')}
            />
            <Checkbox
              name="design.fullWidth"
              label={t('fullWidth')}
              help={t('fullWidthHelp')}
            />
          </Box>
        </Section.Body>
        <Section.Footer>
          <Submit label={t('update')} reverse />
        </Section.Footer>
      </Section>
    </Form>
  )
}

PageForm.propTypes = {
  page: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
