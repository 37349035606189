import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import {
  SelectField,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'

import InheritSelectPlaceholder from '../InheritSelectPlaceholder'
import { useBorderStyleOptions } from './hooks'

export default function BorderStyleSelect({
  defaultValue = '',
  fieldExtra,
  help,
  inheritFrom,
  inheritValue,
  label,
  labelExtra,
  name = 'borderStyle',
  onChange,
  value,
}) {
  const { t } = useTranslation('web/content-editor')

  const options = useBorderStyleOptions()

  return (
    <SelectField
      fieldExtra={fieldExtra}
      help={help}
      label={label || t('borderStyle')}
      labelExtra={labelExtra}
      name={name}
      onChange={onChange}
      showClear={!inheritFrom}
      value={value || defaultValue}
    >
      <InheritSelectPlaceholder
        labelKey="borderStyle"
        value={value ?? ''}
        inheritValue={inheritValue}
        inheritFrom={inheritFrom}
      />
      <SelectPlaceholder label="-" value="-" />
      {options.map(key => (
        <SelectOption value={key} label={t(`borderStyle_${key}`)} key={key} />
      ))}
    </SelectField>
  )
}
BorderStyleSelect.propTypes = {
  defaultValue: PropTypes.string,
  fieldExtra: PropTypes.node,
  help: PropTypes.string,
  inheritFrom: PropTypes.string,
  inheritValue: PropTypes.string,
  label: PropTypes.string,
  labelExtra: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
}
