import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { Controller, useFormContext } from 'react-hook-form'

import { Autocomplete, AutocompleteOption } from '@ui/data-entry/Autocomplete'
import Field from '@ui/data-entry/Field'
import { useRules } from '@ui/data-entry/validationHooks'
import useDebounce from '@utils/useDebounce'

import useCountries from '../services/hooks/useCountries'
import useCountry from '../services/hooks/useCountry'

export function CountryPicker({
  className = '',
  disabled,
  onChange,
  onSearch,
  value,
}) {
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const { countries } = useCountries({ search: debouncedSearchTerm })
  const { country } = useCountry({ code: value }) // countryCode

  const onCountrySearch = useCallback(
    query => {
      setSearchTerm(query)

      if (typeof onSearch === 'function') {
        onSearch(query)
      }
    },
    [onSearch]
  )

  const handleChange = countryCode => {
    setSearchTerm('')
    onChange?.(countryCode)
  }

  return (
    <Autocomplete
      className={className}
      disabled={disabled}
      onSearch={onCountrySearch}
      displayIcon={<CountryFlag country={country} />}
      displayValue={() => country?.name['en'] || searchTerm}
      onChange={handleChange}
      value={value}
    >
      {countries?.map(country => (
        <AutocompleteOption
          label={<CountryLabel country={country} />}
          value={country.code}
          key={`country-${country.code}`}
        />
      ))}
    </Autocomplete>
  )
}
CountryPicker.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  value: PropTypes.string,
}

function CountryLabel({ country }) {
  return (
    <span className="flex flex-row gap-2 align-top">
      <span>
        <CountryFlag country={country} />
      </span>
      <span className="flex flex-col">
        <span>{country.name['en']}</span>
        <span className="text-xs text-gray-400">{country.native}</span>
      </span>
    </span>
  )
}
CountryLabel.propTypes = {
  country: PropTypes.object.isRequired,
}

function CountryFlag({ country }) {
  if (!country) return null

  return (
    <img
      src={`/images/flags/24x24/${country.code.toLowerCase()}.png`}
      alt={country.name['en']}
    />
  )
}
CountryFlag.propTypes = {
  country: PropTypes.object,
}

export function CountryPickerField({
  children,
  className = '',
  disabled,
  displayValue,
  error,
  help,
  label,
  name,
  onChange,
  onSearch,
  required,
  value,
}) {
  return (
    <Field
      className={className}
      name={name}
      label={label}
      help={help}
      error={error}
      required={required}
      disabled={disabled}
    >
      <CountryPicker
        disabled={disabled}
        displayValue={displayValue}
        onChange={onChange}
        onSearch={onSearch}
        value={value}
      >
        {children}
      </CountryPicker>
    </Field>
  )
}
CountryPickerField.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  displayValue: PropTypes.func,
  error: PropTypes.object,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default function CountryPickerController({
  className = '',
  disabled,
  help,
  label,
  name,
  onChange,
  required,
  shouldUnregister,
  value,
}) {
  const { control } = useFormContext()

  const rules = useRules({ required })

  const onFieldChange = useCallback(
    field => value => {
      field.onChange(value)

      if (typeof onChange === 'function') {
        onChange(value)
      }
    },
    [onChange]
  )

  return (
    <Controller
      control={control}
      defaultValue={value}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <CountryPickerField
          className={className}
          disabled={disabled}
          help={help}
          label={label}
          error={fieldState.error}
          name={name}
          onChange={onFieldChange(field)}
          required={required}
          value={field.value}
        />
      )}
    />
  )
}
CountryPickerController.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rules: PropTypes.object,
  shouldUnregister: PropTypes.bool,
  value: PropTypes.string,
}
