import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import {
  Autocomplete,
  AutocompleteOption,
  AutocompleteSelectedItem,
} from '@ui/data-entry/Autocomplete'
import useDebounce from '@utils/useDebounce'

import useCategories from '../../services/hooks/useCategories'
import useCategory from '../../services/hooks/useCategory'
import CategoryTag from '../CategoryTag'
import CategoryWithAncestors from '../CategoryWithAncestors'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

/**
 * This is controlled component used to select a category or multiple categories.
 * @param {object} props - The component props.
 * @param {string} props.className - The class name to apply to the component.
 * @param {boolean} props.disabled - Whether the field is disabled or not.
 * @param {boolean} props.enableCategoryPrioritisation - Whether to enable category prioritisation.
 * @param {number} props.maxItems - The maximum number of items to display in the list.
 * @param {boolean} props.multiple - Whether the field is multiple or not.
 * @param {function} props.onChange - The callback to be called when the user selects an entity.
 * @param {boolean} props.placeholder - The placeholder to display in the search field.
 * @param {string} props.protectedItems - A list of items that cannot be removed.
 * @param {boolean} props.required - Whether the field is required or not.
 * @param {boolean} props.showValueInline - Whether to show the value tag/s inline or under the field.
 * @param {string} props.value - The value of the component.
 * @returns {JSX.Element} - The component.
 */
export function CategoryPickerInput({
  className,
  disabled,
  enableCategoryPrioritisation,
  maxItems = 50,
  multiple,
  onChange,
  placeholder,
  protectedItems,
  required,
  showValueInline,
  siteId,
  value,
}) {
  const { t } = useTranslation('categories')
  const [searchTerm, setSearchTerm] = React.useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const onSearch = React.useCallback(text => {
    setSearchTerm(text)
  }, [])

  const { categories, isLoading: loadingCategories } = useCategories({
    search: debouncedSearchTerm,
    pagination: true,
    limit: maxItems,
    sort: 'title',
    siteId,
  })

  const categoryId = typeof value === 'object' ? value?.id : value

  const { category, loading: loadingSelectedSingle } = useCategory(categoryId, {
    translate: true,
    enabled: !multiple,
  })

  const categoryIds = useMemo(
    () =>
      Array.isArray(value)
        ? value.map(v => (typeof v === 'object' ? v?.id : v))
        : [],
    [value]
  )

  const { categories: selectedCategories, loading: loadingSelectedMultiple } =
    useCategories({
      ids: categoryIds,
      enabled: multiple && Boolean(value),
      keepPreviousData: true,
      sort: '__idsOrder', // NOTE: The '__idsOrder' is a special sort value that is used to sort the categories in the order of the ids array.
    })

  const loading = loadingSelectedSingle || loadingSelectedMultiple

  // Call the onChange callback when the value changes, and clear the search term
  const handleOnChange = React.useCallback(
    newValue => {
      // Check if there is a value
      const hasValue = multiple
        ? Array.isArray(newValue) && newValue.length > 0
        : Boolean(newValue)

      // Clear the search term if there is a value
      if (hasValue) {
        setSearchTerm('')
      }

      // Call the onChange callback, if it exists
      if (typeof onChange === 'function') {
        onChange(newValue)
      }
    },
    [onChange, multiple]
  )

  // Clear field value when clicking on Entity Tag's clear button
  const handleClear = React.useCallback(() => {
    // Call the onChange callback, if it exists
    if (typeof onChange === 'function') {
      onChange(protectedItems?.length ? protectedItems : null)
    }
  }, [onChange, protectedItems])

  const setMainCategory = React.useCallback(
    id => {
      const sortedCategoryIds = categoryIds.filter(
        categoryId => categoryId !== id
      )
      sortedCategoryIds.unshift(id)
      onChange?.(sortedCategoryIds)
    },
    [categoryIds, onChange]
  )

  // Display multiple entities
  const displayMultiple = React.useCallback(
    (value, onItemRemove) => {
      if (!Array.isArray(value) || !Array.isArray(selectedCategories)) return ''

      return selectedCategories.map((selectedCategory, index) => {
        return (
          <AutocompleteSelectedItem
            key={selectedCategory.id}
            icon={
              enableCategoryPrioritisation ? (
                index !== 0 ? (
                  <Icon
                    className="cursor-pointer"
                    name="star"
                    onClick={() => setMainCategory(selectedCategory.id)}
                  />
                ) : (
                  <Icon name="star" style="fas" />
                )
              ) : undefined
            }
            label={
              <CategoryWithAncestors
                category={selectedCategory}
                isCompact
                variant="primary"
              />
            }
            onRemove={
              protectedItems?.includes(selectedCategory.id)
                ? undefined
                : () => onItemRemove(selectedCategory.id)
            }
          />
        )
      })
    },
    [
      enableCategoryPrioritisation,
      protectedItems,
      selectedCategories,
      setMainCategory,
    ]
  )

  return (
    <Autocomplete
      className={className}
      disabled={disabled}
      displayIcon={category ? null : <Icon name="tag" />}
      displayMultiple={multiple ? displayMultiple : undefined}
      displayValue={
        multiple
          ? undefined
          : () =>
              category ? (
                <CategoryTag
                  category={category}
                  onDelete={
                    protectedItems?.includes(category.id) ? null : handleClear
                  }
                />
              ) : (
                searchTerm
              )
      }
      loading={loading || loadingCategories}
      loadingText={t('searching')}
      multiple={multiple}
      onChange={handleOnChange}
      onSearch={onSearch}
      placeholder={placeholder || t('searchCategoryPlaceholder')}
      required={required}
      showValueInline={showValueInline}
      value={value}
    >
      {categories?.map(c => (
        <AutocompleteOption
          icon={<Icon name="tag" />}
          key={c.id}
          label={<CategoryWithAncestors category={c} isCompact />}
          sublabel={
            c.type && (
              <span className="truncate text-sm text-gray-400">
                {t(`categoryType_${c.type || 'missing'}`)}
              </span>
            )
          }
          value={c.id}
        />
      ))}
    </Autocomplete>
  )
}
CategoryPickerInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  enableCategoryPrioritisation: PropTypes.bool,
  maxItems: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  protectedItems: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  showValueInline: PropTypes.bool,
  siteId: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
}
