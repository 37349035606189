import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { useImageUpload } from '@modules/images/services/ImageServices'
import { useColorOptions } from '@modules/web/components/ContentEditor/shared/ColorSelect/hooks'
import { useDesignContext } from '@modules/web/components/DesignProvider'
import MenuPicker from '@modules/web/components/menus/MenuPicker'
import Section from '@ui/data-display/Section'
import ColorSelect from '@ui/data-entry/ColorSelect'
import Form from '@ui/data-entry/Form'
import ImageUpload from '@ui/data-entry/ImageUpload'
import Submit from '@ui/data-entry/Submit'
import TextArea from '@ui/data-entry/TextArea'

export default function AdventistHeaderForm({ site, onSubmit }) {
  const design = useDesignContext()
  const { onImageUpload } = useImageUpload()
  const { t } = useTranslation(['designs/adventist', 'web/appearance'])

  const logoColorOptions = useColorOptions({
    includedColors: ['primary', 'dark', 'darker', 'light', 'lighter'],
  })

  const designVariant = site.design?.options?.variant

  return (
    <Form data={site} onSubmit={onSubmit} id={`${site.id}-${site.updatedAt}`}>
      <Section>
        <Section.Body>
          <ImageUpload
            name="design[header][logo]"
            label={t('web/appearance:headerLogo')}
            help={t(`${design.namespace}:headerLogoHelp`)}
            onUpload={onImageUpload}
            maxSize="10mb"
            accept={design.logoFormats}
            required
          />
          {designVariant !== 'minimal' && (
            <>
              <ColorSelect
                name="design[header][logoColorLight]"
                label={t(`${design.namespace}:headerLogoColorLight`)}
                help={t(`${design.namespace}:headerLogoColorLightHelp`)}
                showClear={true}
                clearLabel={t(`${design.namespace}:headerLogoColorDefault`)}
                placeholder={t(`${design.namespace}:headerLogoColorDefault`)}
                options={logoColorOptions}
              />
              <ColorSelect
                name="design[header][logoColorDark]"
                label={t(`${design.namespace}:headerLogoColorDark`)}
                help={t(`${design.namespace}:headerLogoColorDarkHelp`)}
                showClear={true}
                clearLabel={t(`${design.namespace}:headerLogoColorDefault`)}
                placeholder={t(`${design.namespace}:headerLogoColorDefault`)}
                options={logoColorOptions}
              />
            </>
          )}
          {designVariant !== 'news' && (
            <MenuPicker
              name="design[header][topMenu]"
              label={t(`${design.namespace}:menus_top`)}
              help={t(`${design.namespace}:menusHelp_top`)}
              required
            />
          )}
          <MenuPicker
            name="design[header][primaryMenu]"
            label={t(`${design.namespace}:menus_primary`)}
            help={t(`${design.namespace}:menusHelp_primary`)}
            required
          />
          <MenuPicker
            name="design[header][secondaryMenu]"
            label={t(`${design.namespace}:menus_secondary`)}
            help={t(`${design.namespace}:menusHelp_secondary`)}
            required
          />
          <MenuPicker
            name="design[header][drawerMenu]"
            label={t(`${design.namespace}:menus_drawer`)}
            help={t(`${design.namespace}:menusHelp_drawer`)}
          />
          <TextArea
            name="design[header][drawerDescription]"
            label={t('drawerDescription')}
            help={t('drawerDescriptionHelp')}
          />
        </Section.Body>
        <Section.Footer>
          <Submit label={t('update')} reverse />
        </Section.Footer>
      </Section>
    </Form>
  )
}
AdventistHeaderForm.propTypes = {
  site: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
