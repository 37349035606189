import PropTypes from 'prop-types'
import React from 'react'

import { Listbox as UIListbox } from '@headlessui/react'

export function ListboxOption({
  as: Tag = 'li',
  children,
  className = '',
  ...rest
}) {
  return (
    <UIListbox.Option as={React.Fragment} {...rest}>
      {({ active, selected, disabled }) => {
        const activeClass =
          active && !selected && !disabled ? 'bg-primary-50' : ''
        const selectedClass =
          selected && !disabled
            ? 'bg-primary-500 hover:bg-primary-600 text-white group isSelected'
            : ''
        const disabledClass = disabled ? 'cursor-not-allowed opacity-50' : ''

        return (
          <Tag
            className={`cursor-pointer rounded-md px-3 py-2 ${activeClass} ${selectedClass} ${disabledClass} ${className}`}
          >
            {typeof children === 'function'
              ? children({ active, selected, disabled })
              : children}
          </Tag>
        )
      }}
    </UIListbox.Option>
  )
}

ListboxOption.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
}
