import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import Field from '@ui/data-entry/Field'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'
import Heading from '@ui/typography/Heading'

import useChannel from '../../services/hooks/useChannel'
import useEpisode from '../../services/hooks/useEpisode'
import ChannelSelect from '../ChannelSelect'
import { EpisodePicker } from '../EpisodePicker'

const config = {
  name: 'EpisodeMeta',
  label: 'media-library/public:episodeMeta',
  type: 'plugin',
  icon: 'cog',
  component: <EpisodeMeta />,
}

function getPlaceholder() {
  return {
    creationDate: '',
    firstAirDate: '',
    hosts: [],
    guests: [],
    categories: [],
    language: 'en',
  }
}

export default function EpisodeMeta({
  channelId,
  id,
  showPublicationDate,
  showFirstAirDate,
  showHosts,
  showGuests,
  showCategories,
  showLanguage,
  showSubtitles,
}) {
  const { t } = useTranslation('media-library/public')
  const showContent = false

  const { resources } = usePageResources()
  const { channel } = useChannel(channelId)
  const { episode } = useEpisode(id)

  const _channel = resources?.Channel ? resources.Channel : channel
  const _episode = resources?.Episode
    ? resources.Episode
    : episode || getPlaceholder()

  // const { links } = _episode

  return (
    <Content icon={config.icon} title={t('episodeMeta')}>
      {showContent ? (
        <div className="flex flex-col p-4">TODO</div>
      ) : (
        <Box space="xs">
          <Heading as="h6" className="ml-2" text={t('metaData')} />
          <ul className="ml-8 list-disc text-sm">
            <li>
              {t('channel')}: {_channel?.title}
            </li>
            <li>
              {t('episode')}: {_episode?.title || t('dynamic')}
            </li>
            {showPublicationDate && <li>{t('creationDate')}</li>}
            {showFirstAirDate && <li>{t('firstAirDate')}</li>}
            {showHosts && <li>{t('hosts')}</li>}
            {showGuests && <li>{t('guests')}</li>}
            {showCategories && <li>{t('categories')}</li>}
            {showLanguage && <li>{t('language')}</li>}
            {showSubtitles && <li>{t('subtitles')}</li>}
          </ul>
        </Box>
      )}
    </Content>
  )
}
EpisodeMeta.propTypes = {
  channelId: PropTypes.string,
  id: PropTypes.string,
  showPublicationDate: PropTypes.bool,
  showFirstAirDate: PropTypes.bool,
  showHosts: PropTypes.bool,
  showGuests: PropTypes.bool,
  showCategories: PropTypes.bool,
  showLanguage: PropTypes.bool,
  showSubtitles: PropTypes.bool,
}
EpisodeMeta.toolbarItem = config

function EpisodeMetaSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(data.channelId || data.id)

  const { resources } = usePageResources()

  const onEpisodeIdChange = useCallback(
    value => {
      actions.setProp(props => (props.id = value))
    },
    [actions]
  )

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t('episodeMeta')} help={t('episodeMetaHelp')}>
      <Box space="xl">
        {resources?.Channel && !resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Channel?.title}
          </Message>
        )}
        {resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Show?.title}
          </Message>
        )}
        <Field label={t('metaData')}>
          <Box space="xs">
            <Checkbox
              label={t('publicationDate')}
              onChange={value => {
                actions.setProp(props => (props.showPublicationDate = value))
              }}
              value={data.showPublicationDate}
              name="showPublicationDate"
            />
            <Checkbox
              label={t('firstAirDate')}
              onChange={value => {
                actions.setProp(props => (props.showFirstAirDate = value))
              }}
              value={data.showFirstAirDate}
              name="showFirstAirDate"
            />
            <Checkbox
              label={t('hosts')}
              onChange={value => {
                actions.setProp(props => (props.showHosts = value))
              }}
              value={data.showHosts}
              name="showHosts"
            />
            <Checkbox
              label={t('guests')}
              onChange={value => {
                actions.setProp(props => (props.showGuests = value))
              }}
              value={data.showGuests}
              name="showGuests"
            />
            <Checkbox
              label={t('categories')}
              onChange={value => {
                actions.setProp(props => (props.showCategories = value))
              }}
              value={data.showCategories}
              name="showCategories"
            />
            <Checkbox
              label={t('language')}
              onChange={value => {
                actions.setProp(props => (props.showLanguage = value))
              }}
              value={data.showLanguage}
              name="showLanguage"
            />
            <Checkbox
              label={t('subtitles')}
              onChange={value => {
                actions.setProp(props => (props.showSubtitles = value))
              }}
              value={data.showSubtitles}
              name="showSubtitles"
            />
          </Box>
        </Field>
        {!resources?.Show && (
          <>
            <Divider />
            <Checkbox
              label={t('pickEpisodeManually')}
              help={t('pickEpisodeManuallyHelp')}
              onChange={value => setShowPicker(value)}
              value={showPicker}
            />
            {showPicker && (
              <Box space="lg">
                {!resources?.Channel && (
                  <ChannelSelect
                    label={t('channel')}
                    onChange={onChannelUpdate('channelId')}
                    value={data.channelId}
                    controlled={false}
                  />
                )}
                <EpisodePicker
                  channelId={data.channelId || resources?.Channel?.id}
                  onChange={onEpisodeIdChange}
                  value={data.id}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </SettingsWrap>
  )
}

EpisodeMeta.craft = {
  displayName: 'EpisodeMeta',
  props: {
    channelId: undefined,
    id: undefined,
    showPublicationDate: true,
    showFirstAirDate: true,
    showHosts: true,
    showGuests: true,
    showCategories: true,
    showLanguage: true,
    showSubtitles: true,
  },
  custom: {
    type: config.type,
    resources: ['episodeMetaData'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EpisodeMetaSettings,
  },
}
