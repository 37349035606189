import PropTypes from 'prop-types'
import React from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import CloseButton from '@ui/buttons/CloseButton'
import Heading from '@ui/typography/Heading'

import ModalActions from './components/Actions'
import { useModal } from './hooks'

const modalSizes = {
  sm: 'max-w-xs',
  md: 'max-w-lg',
  lg: 'max-w-2xl',
  xl: 'max-w-6xl',
}

/**
 * A modal dialog that can be used to display content or request user input.
 * @param {object} props Component props
 * @param {function} [props.actions] Render function to provide a custom set of actions
 * @param {boolean} [props.acceptDisabled] When `true`, disables the "Accept" button
 * @param {string} [props.acceptLabel] Label for the default "Accept" button
 * @param {string} [props.acceptVariant] Variant for the default "Accept" button
 * @param {string} [props.cancelLabel] Label for the default "Cancel" button
 * @param {boolean} [props.canClose] Defines if modal can close
 * @param {React.ReactNode|function} props.children Modal's main contents
 * @param {string} [props.childrenWrapAs] Sets how children's wrapper will render
 * @param {string} [props.className] Modal box's extra classes (not for the overlay)
 * @param {function} [props.onAccept] Function to be called when the default "Accept" button is pressed
 * @param {function} [props.onCancel] Function to be called when the default "Cancel" button is pressed
 * @param {boolean} [props.showAccept] Indicates if the "Accept" button must be displayed
 * @param {boolean} [props.showCancel] Indicates if the "Cancel" button must be displayed (same effect as settting `onCancel` prop)
 * @param {string} [props.size] Contols Modal box's max witdh
 * @param {string} [props.title] Modal's title
 * @returns {React.ReactElement}
 */
export default function Modal({
  acceptDisabled,
  acceptLabel,
  acceptVariant = 'primary',
  actions,
  cancelLabel,
  canClose = true,
  children,
  childrenWrapAs = 'div',
  className = '',
  onAccept,
  onCancel,
  showAccept = true,
  showCancel,
  size,
  title,
}) {
  const { t } = useTranslation()
  const { open, onClose, focusRef } = useModal()

  const sizeClasses = modalSizes[size] ?? ''

  const onModalAccept = React.useCallback(() => {
    if (canClose) onClose()
    if (typeof onAccept === 'function') onAccept()
  }, [canClose, onAccept, onClose])

  const onModalClose = React.useCallback(() => {
    if (canClose) onClose()
    if (typeof onCancel === 'function') onCancel()
  }, [canClose, onCancel, onClose])

  return (
    <Transition appear as={React.Fragment} show={open ?? false}>
      <Dialog onClose={onModalClose} initialFocus={focusRef}>
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30" />
          </Transition.Child>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="z-20 flex min-h-screen min-w-full items-center justify-center">
              <Dialog.Panel
                className={`z-30 flex min-h-screen min-w-full transform flex-col justify-between rounded bg-white shadow-lg md:min-h-full md:min-w-[320px] ${sizeClasses} ${className}`}
              >
                {title && (
                  <Dialog.Title
                    as="div"
                    className="flex flex-row items-center justify-between border-b px-6 py-5"
                  >
                    <Heading as="h4">{title}</Heading>
                    <CloseButton onClick={onModalClose} />
                  </Dialog.Title>
                )}
                <Dialog.Description
                  className="overflow-y-auto px-6 py-5"
                  as={childrenWrapAs}
                >
                  {typeof children === 'function'
                    ? children({ open, onClose: onModalClose })
                    : children}
                </Dialog.Description>
                {(typeof actions === 'function' ||
                  showAccept ||
                  showCancel) && (
                  <ModalActions>
                    {typeof actions === 'function' ? (
                      actions({ onClose: onModalClose, focusRef })
                    ) : (
                      <>
                        {(showCancel || onCancel) && (
                          <Button
                            label={cancelLabel || t('cancel')}
                            onClick={onModalClose}
                          />
                        )}

                        {showAccept && (
                          <Button
                            label={acceptLabel || t('ok')}
                            onClick={onModalAccept}
                            variant={acceptVariant}
                            disabled={acceptDisabled}
                            innerRef={focusRef}
                          />
                        )}
                      </>
                    )}
                  </ModalActions>
                )}
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
Modal.propTypes = {
  actions: PropTypes.func,
  acceptDisabled: PropTypes.bool,
  acceptLabel: PropTypes.string,
  acceptVariant: PropTypes.oneOf(['primary', 'warn', 'danger', 'default']),
  cancelLabel: PropTypes.string,
  canClose: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  childrenWrapAs: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  showAccept: PropTypes.bool,
  showCancel: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  title: PropTypes.string,
}
