import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Field from '@ui/data-entry/Field'

import { CategoryPickerInput } from './Input'

/**
 * This is the field component for the CategoryPicker component.
 * It wraps the CategoryPickerInput component in a Field (with label, help, etc.)
 *
 * @param {object} props - The component props.
 * @param {string} props.className - The class name to apply to the component.
 * @param {boolean} props.disabled - Whether the field is disabled or not.
 * @param {boolean} props.enableCategoryPrioritisation - Whether to enable category prioritisation.
 * @param {string} props.help - The help text to display below the component.
 * @param {string} props.label - The label to display above the component.
 * @param {number} props.maxItems - The maximum number of items to display in the list.
 * @param {string} props.multiple - Whether the field is multiple or not.
 * @param {string} props.name - The name of the component.
 * @param {function} props.onChange - The callback to be called when the user selects an entity.
 * @param {boolean} props.placeholder - The placeholder to display in the search field.
 * @param {string} props.protectedItems - A list of items that cannot be removed.
 * @param {boolean} props.required - Whether the field is required or not.
 * @param {boolean} props.showValueInline - Whether to show the value tag/s inline or under the field.
 * @param {string} props.value - The value of the component.
 * @returns {React.Component} - The component.
 */
export function CategoryPickerField({
  className = '',
  disabled,
  enableCategoryPrioritisation,
  help,
  label,
  maxItems,
  multiple,
  name,
  onChange,
  placeholder,
  protectedItems,
  required,
  showValueInline,
  siteId,
  value,
}) {
  const { t } = useTranslation('categories')
  return (
    <Field
      className={className}
      disabled={disabled}
      help={help}
      label={label || t('category')}
      name={name}
      placeholder={t('searchCategoryPlaceholder')}
      required={required}
    >
      <CategoryPickerInput
        disabled={disabled}
        enableCategoryPrioritisation={enableCategoryPrioritisation}
        maxItems={maxItems}
        multiple={multiple}
        onChange={onChange}
        placeholder={placeholder}
        protectedItems={protectedItems}
        required={required}
        showValueInline={showValueInline}
        siteId={siteId}
        value={value}
      />
    </Field>
  )
}
CategoryPickerField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  enableCategoryPrioritisation: PropTypes.bool,
  help: PropTypes.string,
  label: PropTypes.string,
  maxItems: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  protectedItems: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.bool,
  showValueInline: PropTypes.bool,
  siteId: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
}
