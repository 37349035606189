import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import Form from '@ui/data-entry/Form'
import Dialog, { DialogContent } from '@ui/feedback/FloatingDialog'
import { uid } from '@utils/strings'

import { ThemeFields } from './ThemeFields'

const formId = 'add-theme-form'

export function AddThemeDialog({ onOpenChange, append }) {
  const { t } = useTranslation('designs/adventist')

  const onSubmit = React.useCallback(
    formData => {
      append({ ...formData, code: uid() })
      onOpenChange()
    },
    [append, onOpenChange]
  )

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <DialogContent
        title={t('addTheme')}
        boxClass="m-4 sm:min-w-[320px] max-w-[480px]"
        childrenClass="flex flex-col gap-8 overflow-y-auto"
        buttons={
          <Button
            label={t('addTheme')}
            type="submit"
            form={formId}
            variant="primary"
          />
        }
      >
        <Form formId={formId} onSubmit={onSubmit}>
          <ThemeFields />
        </Form>
      </DialogContent>
    </Dialog>
  )
}

AddThemeDialog.propTypes = {
  append: PropTypes.func.isRequired,
  onOpenChange: PropTypes.func.isRequired,
}
