import React from 'react'

import config from './config'

const CartDonationSettings = React.lazy(() => import('./Settings'))
const CartDonationBlock = React.lazy(() => import('./Block'))

export default function CartDonation(props) {
  return <CartDonationBlock {...props} />
}

CartDonation.toolbarItem = {
  ...config,
  component: <CartDonation />,
}

CartDonation.craft = {
  displayName: 'CartDonation',
  props: {
    paymentMethod: '',
    successMessage: '',
  },
  custom: {
    type: config.type,
    i18nNamespaces: ['payments'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CartDonationSettings,
  },
}
