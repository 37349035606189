import PropTypes from 'prop-types'
import React from 'react'

import Box from '@ui/layout/Box'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const colors = {
  primary: 'text-primary-500',
  success: 'text-success-600',
  info: 'text-info-600',
  warn: 'text-warn-500',
  danger: 'text-danger-500',
}

export default function Label({
  children,
  className = '',
  color,
  icon,
  iconColor,
  label,
  labelColor,
  space = 'md',
}) {
  return (
    <Box
      inline
      horizontal
      align="center"
      space={space}
      className={`${colors[color] ? colors[color] : ''} ${className}`}
    >
      {icon && (
        <Box
          horizontal
          align="center"
          justify="center"
          space={space}
          className={`w-4 ${colors[iconColor] ? colors[iconColor] : ''}`}
        >
          <Icon name={icon} />
        </Box>
      )}
      <span className={colors[labelColor] ? colors[labelColor] : ''}>
        {children || label}
      </span>
    </Box>
  )
}

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'success', 'info', 'warn', 'danger']),
  icon: PropTypes.string,
  iconColor: PropTypes.oneOf(['primary', 'success', 'info', 'warn', 'danger']),
  label: PropTypes.string,
  labelColor: PropTypes.oneOf(['primary', 'success', 'info', 'warn', 'danger']),
  space: PropTypes.oneOf(['xs', 'sm', 'md']),
}
