import React from 'react'

import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Message from '@ui/data-display/Message'
import Box from '@ui/layout/Box'
import Heading from '@ui/typography/Heading'

const config = {
  name: 'VideoCategories',
  label: 'media-library/public:videoCategories',
  type: 'plugin',
  icon: 'tags',
  component: <VideoCategories />,
}

function getPlaceholder() {
  return {
    videoCategories: [{ title: 'Category One' }, { title: 'Category Two' }],
  }
}

export default function VideoCategories() {
  const { t } = useTranslation('media-library/public')
  const showContent = true

  const { resources } = usePageResources()

  const _channel = resources?.Channel
  const _video = resources?.Video || getPlaceholder()

  const { videoCategories } = _video

  return (
    <Content icon={config.icon} title={t('videoCategories')}>
      {showContent ? (
        <div className="flex gap-2">
          {videoCategories.map((category, key) => (
            <span className="rounded bg-gray-200 px-2 py-1" key={key}>
              {category.title}
            </span>
          ))}
        </div>
      ) : (
        <Box space="xs">
          <Heading as="h6" className="ml-2" text={t('videoCategories')} />
          <ul className="ml-8 list-disc text-sm">
            <li>
              {t('channel')}: {_channel?.title}
            </li>
            <li>
              {t('video')}: {_video?.title || t('dynamic')}
            </li>
          </ul>
        </Box>
      )}
    </Content>
  )
}
VideoCategories.toolbarItem = config

function VideoCategoriesSettings() {
  const { t } = useTranslation('media-library/public')
  const { resources } = usePageResources()

  return (
    <SettingsWrap title={t('videoCategories')} help={t('videoCategoriesHelp')}>
      <Box space="xl">
        {resources?.Channel && !resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources.Channel.title}
          </Message>
        )}
        {resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources.Show.title}
          </Message>
        )}
      </Box>
    </SettingsWrap>
  )
}

VideoCategories.craft = {
  displayName: 'VideoCategories',
  props: {},
  custom: {
    type: config.type,
    resources: ['video'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: VideoCategoriesSettings,
  },
}
