import PropTypes from 'prop-types'
import React from 'react'

import { ThemeSelectController } from '../appearance/Themes'

export function DataSourceOptions({ site }) {
  return <ThemeSelectController name="settings.design.theme" site={site} />
}

DataSourceOptions.propTypes = {
  site: PropTypes.object.isRequired,
}
