import React from 'react'

import Button from '@ui/buttons/Button'
import { CollapsePanel } from '@ui/data-display/Collapse'

/**
 * **Sortable item panel** component.
 *
 * @param {object} props The component props
 * @param {React.ReactNode} props.children The children of the panel
 * @param {string} [props.className=''] The additional class name for the panel
 * @param {string} [props.deleteClass=''] The class name for the delete button
 * @param {boolean} [props.deleteDisabled] Whether the delete button is disabled
 * @param {boolean} [props.disabled] Whether the panel is disabled
 * @param {string} [props.editClass] The class name for the edit button
 * @param {boolean} [props.editDisabled] Whether the edit button is disabled
 * @param {React.ReactNode} [props.extra] The extra content for the panel
 * @param {string} [props.innerClass=''] The inner class name for the panel
 * @param {boolean} props.isDragging Whether the panel is being dragged
 * @param {Function} [props.onDelete] The delete button click handler
 * @param {Function} [props.onEdit] The edit button click handler
 * @param {Function} [props.onToggle] The toggle button click handler
 * @param {React.ReactNode} props.title The title of the panel
 * @returns {React.ReactElement} The sortable item panel component
 */
export default function SortableItemPanel({
  children,
  className = '',
  deleteClass = '',
  deleteDisabled,
  disabled,
  editClass,
  editDisabled,
  extra,
  innerClass = '',
  isDragging,
  onDelete,
  onEdit,
  onToggle,
  title,
}) {
  return (
    <CollapsePanel
      className={`flex-grow ${
        isDragging
          ? 'z-20 overflow-hidden shadow-xl ring-2 ring-primary-500'
          : 'z-0 shadow-none ring-0'
      } ${className}`}
      innerClass={innerClass}
      extra={
        <div className="flex flex-row items-center gap-2">
          {extra}
          {onEdit && (
            <Button
              className={editClass}
              onClick={onEdit}
              icon="pen-to-square"
              variant="primary"
              disabled={editDisabled}
            />
          )}
          {onDelete && (
            <Button
              className={deleteClass}
              onClick={onDelete}
              icon="trash-alt"
              variant="danger-light"
              disabled={deleteDisabled}
            />
          )}
        </div>
      }
      onToggle={onToggle}
      title={title}
      disabled={disabled}
    >
      <div className="flex flex-col gap-4">{children}</div>
    </CollapsePanel>
  )
}
