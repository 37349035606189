import { format } from 'date-fns'
import omit from 'lodash/omit'
import { useMutation, useQueryClient } from 'react-query'

import { supportedLanguages } from '@config/i18n'
import { deleteFetch, fileUpload, patchFetch, postFetch } from '@utils/fetcher'
import { slugify } from '@utils/strings'

const baseUrl = '/api/web/sites'

function getSiteApiUrl(site, path = '') {
  if (!site || typeof site !== 'object' || !site?.id) return undefined
  return `${baseUrl}/${site.id}${path ? `/${path}` : ''}`
}

export function useCreateOrUpdateSite(site) {
  const queryClient = useQueryClient()

  return useMutation(
    siteData => {
      siteData.supportedLanguages = supportedLanguages // Needed for user creation.
      const siteWithData = { ...site, ...siteData }
      return siteWithData?.id
        ? patchFetch(getSiteApiUrl(siteWithData), siteWithData)
        : postFetch(baseUrl, siteData)
    },
    {
      onMutate: data => {
        // optimistically update the query value
        queryClient.setQueryData(['sites', site?.id], { ...site, ...data })
      },
      onSuccess: data => {
        // update query value with server response
        queryClient.setQueryData(['sites', site?.id], data)
      },
      onSettled: () => {
        queryClient.invalidateQueries('sites')
        queryClient.invalidateQueries('groups') // We also invalidate the groups query, because a group might have been created
      },
    }
  )
}

export function useLogoUpload() {
  const { mutate, error, isLoading } = useMutation(async file =>
    fileUpload(`${baseUrl}/upload-logo`, file)
  )

  function onLogoUpload(file, onChange) {
    return mutate(file, {
      onSuccess: data => onChange(data),
    })
  }
  return { onLogoUpload, error, isLoading }
}

export function useFaviconUpload() {
  const { mutate, error, isLoading } = useMutation(async file =>
    fileUpload(`${baseUrl}/upload-favicon`, file)
  )

  function onFaviconUpload(file, onChange) {
    return mutate(file, {
      onSuccess: data => onChange(data),
    })
  }
  return { onFaviconUpload, error, isLoading }
}

export function useCloneSite() {
  const queryClient = useQueryClient()
  return useMutation(
    data => {
      const formData = { ...omit(data, ['file']), file: data?.file?.[0] }
      // Returns { site, group }
      return postFetch(`${baseUrl}/clone`, formData, { fileUpload: true })
    },
    {
      onSuccess: data => {
        queryClient.setQueryData(['sites', data?.site?.id], data?.site)
        queryClient.setQueryData(['groups', data?.group?.id], data?.group)
        queryClient.invalidateQueries('sites')
        queryClient.invalidateQueries('groups')
      },
    }
  )
}

export function useExportSite(site) {
  return useMutation(() => postFetch(getSiteApiUrl(site, 'export')), {
    onSuccess: jsonData => {
      // Create a temporarily link element to trigger the download programmatically
      const link = document.createElement('a')

      // Set the link element's attributes:
      // - Set the url by create a blob url from the json data
      link.href = window.URL.createObjectURL(
        new Blob([JSON.stringify(jsonData, null, 2)])
      )

      // - Set the donwload attribute to the file name (site name + date + .json)
      const siteName = slugify(site.name, { maxLength: 120 })
      const date = format(new Date(), 'yyyy-MM-dd')
      link.setAttribute('download', `${siteName}-${date}.json`)

      // Append the link element to the body
      document.body.appendChild(link)

      // Click the link element to download the file
      link.click()

      // Clean up the link element after the download
      link.remove()
    },
  })
}

export function useAddSiteTranslation() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ site, locale }) =>
      postFetch(getSiteApiUrl(site, `add-translation/${locale}`)),
    {
      onSuccess: data => {
        // Update `sites` and optimistically the individual query when this mutation succeeds
        queryClient.setQueryData(['sites', data.id], data)
        queryClient.invalidateQueries('sites')
      },
    }
  )
}

export function useDeleteSite() {
  const queryClient = useQueryClient()

  return useMutation(site => deleteFetch(getSiteApiUrl(site)), {
    onSuccess: data => {
      queryClient.setQueryData(['sites', data.id], data)
      queryClient.invalidateQueries('sites')
    },
  })
}

export function useRestoreSite() {
  const queryClient = useQueryClient()

  return useMutation(site => patchFetch(getSiteApiUrl(site, 'restore')), {
    onSuccess: data => {
      queryClient.setQueryData(['sites', data.id], data)
      queryClient.invalidateQueries('sites')
    },
  })
}

export default {
  useCreateOrUpdateSite,
  useDeleteSite,
  useRestoreSite,
}
