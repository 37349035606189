import { useMemo } from 'react'

import merge from 'lodash/merge'
import { useTranslation } from 'react-i18next'

import { getThemeColors } from '@app/designs/adventist/utils'
import { useDesignContext } from '@modules/web/components/DesignProvider'
import { useCurrentLayout } from '@modules/web/services/hooks/useCurrentLayout'
import useSite from '@modules/web/services/hooks/useSite'
import { removeEmpty } from '@utils/objects'

// The default colors for the system, which are always available, and must provide a valid color as value
export const defaultColors = {
  system: {
    name: 'System',
    black: 'black',
    white: 'white',
    transparent: 'transparent',
    current: 'currentColor',
  },
}

/**
 * A helper hook that merges the design's `colors` object with the site's `design.colors` object
 * @returns {object} - The merged colors object
 */
export function useDesignColors() {
  const { t } = useTranslation('web/content-editor')
  const design = useDesignContext()
  const { site } = useSite()

  // Get the current layout (if available) to check if there's a color theme
  const layout = useCurrentLayout()

  const colors = useMemo(() => {
    const layoutColorTheme = layout?.design?.colorTheme
    const designColors = site?.design?.colors || {}

    // Merge the design colors with the site colors
    const mergedColors = merge(design.colors, designColors)

    // If theres a theme color, or there's a color theme in the layout, get the custom theme colors
    if (mergedColors.theme || layoutColorTheme) {
      const layoutTheme = getThemeColors({
        colorTheme: layoutColorTheme,
        designVariant: site?.design?.options?.variant,
        customThemes: site?.design?.themes,
        designColors: site?.design?.colors,
      })

      // Remove any nested objects from the theme object
      Object.entries(mergedColors.theme).forEach(([key, value]) => {
        if (typeof value === 'object') {
          delete mergedColors.theme[key]
        }
      })

      mergedColors.theme = {
        ...mergedColors.theme,
        ...(layoutTheme ? layoutTheme : {}),
        name: `${t('theme')} (${layoutTheme?.name || mergedColors.theme?.name || layoutColorTheme || site?.design?.options?.colorTheme})`,
      }
    }

    return removeEmpty(mergedColors)
  }, [
    design.colors,
    layout?.design?.colorTheme,
    site?.design?.colors,
    site?.design?.options?.colorTheme,
    site?.design?.options?.variant,
    site?.design?.themes,
    t,
  ])

  return { ...colors, ...defaultColors }
}

/**
 * @typedef {object} ColorOption The color option object
 * @property {string} label The label of the color
 * @property {string} value The value of the color
 * @property {string} color The color value
 */

/**
 * A helper hook that converts the design's `colors` object into a list
 * of objects
 *
 * @param {object} options An options object
 * @param {string[]} options.excludedColors A list of color keys to exclude from the design colors
 * @param {string[]} options.includedColors A list of color keys to include from the design colors. Will only include these. Overrides excludedColors
 * @returns {ColorOption[]} The color options
 */
export function useColorOptions(options = {}) {
  const { excludedColors = [], includedColors } = options
  const colors = useDesignColors()

  /**
   * The color options list
   * @type {ColorOption[]}
   */
  const colorOptions = useMemo(
    () =>
      Object.entries(colors).reduce((groups, [groupKey, value]) => {
        if (typeof value !== 'object' || Object.entries(value).length === 0) {
          return groups
        }

        // Initialize the group object
        const group = {
          label: value.name || groupKey,
          value: groupKey,
          colors: [],
        }

        // Add the default color to the top of the list if it exists
        if (value['DEFAULT']) {
          group.colors.push({
            label: groupKey,
            value: groupKey,
            color: value['DEFAULT'],
          })
        }

        // Add the rest of the colors
        group.colors.push(
          ...Object.entries(value).reduce(
            (groupColors, [colorKey, subValue]) => {
              // Skip the name key if present, as it's not a color value
              if (colorKey === 'name') {
                return groupColors
              }

              // If includedColors is present, skip the color if it's **not** in the list
              if (
                includedColors &&
                !includedColors.includes(`${groupKey}-${colorKey}`)
              ) {
                return groupColors
              }

              // If excludedColors is present, skip the color if it's in the list
              if (excludedColors.includes(`${groupKey}-${colorKey}`)) {
                return groupColors
              }

              // Skip the default color as it's already been added
              if (colorKey === 'DEFAULT') {
                return groupColors
              }

              const colorValue =
                groupKey === 'system' ? colorKey : `${groupKey}-${colorKey}`

              const labelValue = groupKey.startsWith('color_')
                ? `${value.name} - ${colorKey}`
                : colorValue

              return [
                ...groupColors,
                {
                  label: labelValue,
                  value: colorValue,
                  color: subValue,
                },
              ]
            },
            []
          )
        )

        return [...groups, group]
      }, []),
    [colors, excludedColors, includedColors]
  )

  return colorOptions
}
