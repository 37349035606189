import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const typeStyles = {
  info: 'bg-info-50 border-info-200 text-info-600',
  success: 'bg-success-50 border-success-200 text-success-600',
  warn: 'bg-warn-50 border-warn-200 text-warn-600',
  danger: 'bg-danger-50 border-danger-200 text-danger-600',
  ai: 'bg-ai-50 border-ai-200 text-ai-600',
}

const typeIcons = {
  info: 'info-circle',
  success: 'check-circle',
  warn: 'exclamation-circle',
  danger: 'times-circle',
  ai: 'robot',
}

const sizeStyles = {
  xs: {
    wrap: 'p-2 gap-2 rounded-sm shadow-sm',
    inner: 'gap-1',
    icon: 'p-1 text-md',
    texts: 'gap-0',
    title: 'text-sm',
    description: 'text-xs',
  },
  sm: {
    wrap: 'p-2 gap-2 rounded-md shadow',
    inner: 'gap-1',
    icon: 'p-1 text-base',
    texts: 'gap-0',
    title: 'text-base',
    description: 'text-sm',
  },
  md: {
    wrap: 'p-3 gap-2 rounded-lg shadow',
    inner: 'gap-3',
    icon: 'p-1.5 text-xl',
    texts: 'gap-0',
    title: 'text-lg',
    description: 'text-base',
  },
  lg: {
    wrap: 'py-4 px-8 gap-4 rounded-xl shadow-md',
    inner: 'gap-4',
    icon: 'text-2xl',
    texts: 'gap-0.5',
    title: 'text-xl',
    description: 'text-md',
  },
}

/**
 * Message component. Displays a message with an icon, title, and text.
 *
 * @param {object} props
 * @param {ReactNode} props.children - The addition content of the message apart from the text.
 * @param {string} props.className - Additional classes to apply to the message.
 * @param {string} props.contentClass - Additional classes to apply to the content.
 * @param {string} props.icon - The icon to display.
 * @param {string} props.iconClass - Additional classes to apply to the icon.
 * @param {boolean} props.iconHidden - Whether to hide the icon.
 * @param {('xs'|'sm'|'md'|'lg')} props.size - The size of the message.
 * @param {ReactNode} props.text - The text of the message.
 * @param {string} props.textClass - Additional classes to apply to the text.
 * @param {string} props.title - The title of the message.
 * @param {('info'|'success'|'warn'|'danger'|'ai')} props.type - The type of the message.
 */
export default function Message({
  children,
  className = '',
  contentClass = '',
  icon,
  iconClass = '',
  iconHidden = false,
  size = 'md',
  text,
  textClass = '',
  title,
  type = 'info',
}) {
  const typeClass = typeStyles[type] || typeStyles.info
  const sizeClasses = sizeStyles[size] || sizeStyles.md
  const iconName = icon || typeIcons[type]

  return (
    <div
      className={`flex flex-row items-center border ${className} ${typeClass} ${sizeClasses.wrap}`}
    >
      {!iconHidden && iconName && (
        <div className={`${sizeClasses.icon} ${iconClass}`}>
          <Icon name={iconName} />
        </div>
      )}
      <div className={`flex flex-col grow ${sizeClasses.inner}`}>
        {(title || text) && (
          <div className={`flex flex-col ${sizeClasses.texts}`}>
            {title && (
              <h6 className={`font-bold tracking-wide ${sizeClasses.title}`}>
                {title}
              </h6>
            )}
            {text && (
              <div className={`${sizeClasses.description} ${textClass}`}>
                {text}
              </div>
            )}
          </div>
        )}
        <div className={`flex flex-col grow empty:hidden ${contentClass}`}>
          {children}
        </div>
      </div>
    </div>
  )
}
Message.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  contentClass: PropTypes.string,
  icon: PropTypes.string,
  iconClass: PropTypes.string,
  iconHidden: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  text: PropTypes.node,
  textClass: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(['info', 'success', 'warn', 'danger', 'ai']),
}

export function ErrorMessage({
  className = '',
  error,
  errorNamespace = 'common',
  useErrorMessageField = false,
}) {
  const { t } = useTranslation(errorNamespace)

  const message = {
    title: t('error'),
    text: t('UNEXPECTED'),
  }

  const { data, status } = error

  switch (status) {
    case 422: {
      const field = Object.keys(data.error?.validationErrors ?? [])[0]
      const { type, expected } = data.error?.validationErrors?.[field] ?? {}

      message.title = t('VALIDATION_ERROR')
      message.text = useErrorMessageField
        ? data.error?.message
        : t(type, { field, expected })
      break
    }

    case 400:
      message.text = useErrorMessageField
        ? data.error?.message
        : t(
            `${errorNamespace}:${
              data?.code || data?.error?.code || data.error?.message
            }`
          )
      break

    default:
      message.text = data?.message || data?.error?.message
      break
  }

  return (
    <Message
      className={`w-full ${className}`}
      title={message.title}
      text={message.text}
      type="danger"
    />
  )
}
ErrorMessage.propTypes = {
  className: PropTypes.string,
  error: PropTypes.object.isRequired,
  errorNamespace: PropTypes.string,
  useErrorMessageField: PropTypes.bool,
}
