import React, { forwardRef } from 'react'

import Tooltip from '@ui/feedback/Tooltip'
import Link from '@ui/navigation/Link'

import { useButtonClasses } from './hooks'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

/**
 * @typedef {import('./hooks').ButtonVariant} ButtonVariant
 * @typedef {import('./hooks').ButtonSize} ButtonSize
 * @typedef {import('@ui/icons/Icon').IconStyle} IconStyle
 * @typedef {'top'|'right'|'bottom'|'left'} ButtonTooltipPlacement
 * @typedef {'xs'|'sm'|'md'|'lg'|'xl'} ButtonTooltipSize
 * @typedef {'button'|'submit'|'reset'} ButtonType
 */

/**
 * Button component
 * @typedef {object} ButtonProps The Button component props
 * @property {string} [as='button'] Render as a different element (default: 'button')
 * @property {boolean} [asLink] Whether to render the button as a link (an <a> tag, instead of a <button>)
 * @property {React.ReactNode} [children] Button content (the `label` will override this)
 * @property {string} [className] Additional classes
 * @property {boolean} [disabled] Disable button
 * @property {string} [href] Link href
 * @property {string} [icon] Icon name
 * @property {string} [iconClassName] Icon classes
 * @property {IconStyle} [iconStyle] Icon style (default: far)
 * @property {object} [innerRef] Inner ref
 * @property {React.ReactNode} [label] Button label
 * @property {boolean} [loading] Show loading spinner
 * @property {string} [name] Button name
 * @property {Function} [onClick] Click handler function
 * @property {Function} [onMouseDown] Mouse down handler
 * @property {boolean} [pressed] Button pressed
 * @property {ButtonSize} [size='md'] Button size
 * @property {string} [secondaryIcon] Secondary icon name
 * @property {string} [target] Link target (only for `asLink`)
 * @property {string} [tooltip] Button tooltip
 * @property {ButtonTooltipSize} [tooltipSize] Tooltip size
 * @property {ButtonTooltipPlacement} [tooltipPlacement] Tooltip placement
 * @property {ButtonType} [type='button'] Button type
 * @property {any} [value] Button value
 * @property {ButtonVariant} [variant] Button variant
 */

/**
 * Button is a component that renders the content of a Dialog.
 * @type {React.ForwardRefRenderFunction<HTMLButtonElement, ButtonProps>} React component
 */
const Button = forwardRef(function Button(
  {
    as = 'button',
    asLink,
    children,
    className = '',
    disabled,
    href,
    icon,
    iconClassName,
    iconStyle,
    innerRef,
    label,
    linkClass = '',
    loading,
    name,
    onClick,
    onMouseDown,
    pressed,
    secondaryIcon,
    size = 'md',
    target,
    tooltip,
    tooltipClass = '',
    tooltipPlacement,
    tooltipSize,
    type = 'button',
    value,
    variant,
    ...rest
  },
  ref
) {
  const buttonClasses = useButtonClasses({
    asLink,
    disabled,
    pressed,
    size,
    variant,
  })

  return (
    <Link.Conditional
      href={href}
      basic={false}
      target={target}
      className={linkClass}
    >
      {React.createElement(
        asLink ? 'span' : as,
        {
          className,
          disabled,
          onClick,
          onMouseDown,
          name,
          type,
          ref: ref ?? innerRef,
          value,
          ...rest,
        },
        <Tooltip
          content={tooltip}
          size={tooltipSize || size || 'sm'}
          placement={tooltipPlacement}
          className={tooltipClass}
        >
          <div
            className={`inline-flex select-none flex-row items-center justify-center whitespace-nowrap transition-all duration-300 ease-in-out ${buttonClasses}`}
          >
            {typeof icon === 'string' || Array.isArray(icon) ? (
              <Icon
                name={loading ? 'spinner' : icon}
                className={iconClassName}
                spin={loading}
                style={iconStyle}
              />
            ) : (
              icon
            )}
            {(label || children) && (
              <span className="font-semibold">{label ?? children}</span>
            )}
            {secondaryIcon && (
              <span className="scale-75 transform opacity-70">
                <Icon name={secondaryIcon} className={iconClassName} />
              </span>
            )}
          </div>
        </Tooltip>
      )}
    </Link.Conditional>
  )
})

/**
 * Button component
 * @param {ButtonProps} props - The component props
 * @returns {React.ReactElement} The component
 */
export default Button
