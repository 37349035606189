import PropTypes from 'prop-types'
import React from 'react'

import Button from '@ui/buttons/Button'

export default function CollectionTitle({ layout, title }) {
  return (
    <div className="flex items-center justify-between">
      <h3 className="text-lg font-semibold">{title}</h3>
      {layout === 'slider' && (
        <div className="space-x-1">
          <Button icon="chevron-left" size="xs" />
          <Button icon="chevron-right" size="xs" />
        </div>
      )}
    </div>
  )
}

CollectionTitle.propTypes = {
  layout: PropTypes.oneOf(['grid', 'slider']),
  title: PropTypes.string,
}
