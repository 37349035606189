import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import useSite from '@modules/web/services/hooks/useSite'
import {
  SelectField,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'

export function DesignColorSelectField({ name, label, help, value, onChange }) {
  const { t } = useTranslation('web/content-editor')
  const { site } = useSite()

  const { colors = {} } = site?.design || {}

  const colorOptions = React.useMemo(
    () =>
      Object.entries(colors).map(([key, color]) => ({
        value: key,
        label: color.name || key,
      })),
    [colors]
  )

  if (!site) {
    return null
  }

  return (
    <SelectField
      label={label}
      help={help}
      name={name}
      onChange={onChange}
      value={value}
    >
      {value && <SelectOption label={t('clearSelection')} value={null} />}
      <SelectPlaceholder label="-" />
      {colorOptions.map(({ value, label }) => (
        <SelectOption label={label} value={value} key={value} />
      ))}
    </SelectField>
  )
}

DesignColorSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  help: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}
