import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import Tooltip from '@ui/feedback/Tooltip'
import { getImageUrl } from '@utils/images'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

/**
 * @typedef {import('@utils/images').ImageFile} ImageFile
 *
 * @typedef {object} ImageFormatProps
 * @property {ImageFile} file The file object to get the image format from (used to calculate aspect ratio based on the image dimensions)
 * @property {number} width Requested image width (px)
 * @property {number} height Requested height (px)
 * @property {number} [quality=80] Requested quality (0-100, default: 80)
 * @property {boolean} [hasAspectRatio=false] Indicates if the image has been requested with an specific aspectRatio (e.g. 16:9)
 */

/**
 * Get image format paramaters with given format
 * @param {ImageFormatProps} props component props
 * @returns {string} image
 * @example
 * const file = { name: 'image.jpg', containerId: '123456' }
 * const width = 800
 * const height = 600
 * const quality = 80
 * const hasAspectRatio = false
 * const imageFormat = useImageFormat({ file, width, height, quality, hasAspectRatio })
 * // Returns 'w:800,h:600,q:80'
 */
function useImageFormat({
  file,
  width,
  height,
  quality = 80,
  hasAspectRatio = false,
}) {
  // If no file is provided, stop here and just return an empty string
  if (!file) return ''

  // Get current Device Pixel Ratio (DPR)
  const dpr = window?.devicePixelRatio || 1

  // Calculate aspect ratio to rescale the image size accordingly (so the object-fit: cover works as expected, without stretching the image)
  const aspectRatio = hasAspectRatio && file ? file.width / file.height : 1

  // Ensure quality is between 0 and 100
  if (quality > 100) quality = 100
  if (quality < 0) quality = 0

  // Inform the developer if the quality is too low
  if (quality < 50) {
    // eslint-disable-next-line no-console
    console.warn(
      `Image quality is too low! Received ${quality}, but we are setting it to at least 50 to avoid extreme pixelation of the image.`
    )

    quality = 50
  }

  // Concatenate all params into a string to be used as the image format
  const params = []
  if (width) params.push(`w:${width * aspectRatio * dpr}`) // Calculate width for current DPR
  if (height) params.push(`h:${height * aspectRatio * dpr}`) // Calculate height for current DPR
  if (quality) params.push(`q:${quality}`)

  return params.join(',')
}

/**
 * @typedef {'1:1' | '3:4' | '4:3' | '9:16' | '16:9'} ImageAspectRatio Image aspect ratio
 */

const aspectRatios = {
  '1:1': 'aspect-1',
  '3:4': 'aspect-w-3 aspect-h-4',
  '4:3': 'aspect-w-2 aspect-h-1',
  '9:16': 'aspect-w-9 aspect-h-16',
  '16:9': 'aspect-w-16 aspect-h-9',
}

/**
 * Get the aspect ratio class based on the given aspect ratio
 * @param {ImageAspectRatio} aspectRatio image aspect ratio (e.g. 16:9, 4:3, 1:1)
 * @returns {string} aspect ratio class
 * @example
 * const aspectRatio = '16:9'
 * const aspectRatioClass = useImageAspectRatio(aspectRatio)
 * // Returns 'aspect-w-16 aspect-h-9'
 */
function useImageAspectRatio(aspectRatio) {
  return aspectRatio ? `${aspectRatios[aspectRatio] || ''} object-cover` : ''
}

/**
 * @typedef {object} ImageProps Image component props
 * @property {string} alt Image's **alt**ernative text (required)
 * @property {ImageAspectRatio} aspectRatio image aspect ratio (e.g. 16:9, 4:3, 1:1)
 * @property {string} [className=''] image class name (optional)
 * @property {ImageFile} file image file object (required if `src` is not provided)
 * @property {number} height image height (px)
 * @property {number} [quality=80] image quality (0-100)
 * @property {boolean} [showPlaceholder=false] show a placeholder image if the image is not available
 * @property {boolean} [showAspectRatioMismatch=false] show a warning if the image aspect ratio is different from the requested aspect ratio
 * @property {string} src image src (required if `file` is not provided)
 * @property {number} width image width (px)
 * @returns {React.Component}

/**
 * Generic image component. It can fetch images stored in the CMS (with the `file` param) or from external sources (with the `src` param).
 * @param {ImageProps} props component props
 * @returns {React.Component}
 */
export default function Image({
  alt,
  aspectRatio,
  className = '',
  file,
  height,
  quality = 80,
  showPlaceholder = false,
  showAspectRatioMismatch = false,
  src,
  width,
}) {
  const { t } = useTranslation()

  // Get the aspect ratio class based on the given aspect ratio
  const aspectRatioClass = useImageAspectRatio(aspectRatio)

  // Get the image format string based on the given parameters
  const imageFormat = useImageFormat({
    file,
    width: parseInt(width, 10),
    height: parseInt(height, 10),
    quality: parseInt(quality, 10),
    hasAspectRatio: !!aspectRatio,
  })

  const imgSrc = file ? getImageUrl(file, imageFormat) : src

  // Detect if the image aspect ratio is different from the requested aspectRatio prop
  const isAspectRatioDifferent = useMemo(() => {
    return showAspectRatioMismatch && aspectRatio && file?.width && file?.height
      ? Math.abs(
          file.width / file.height -
            aspectRatio.split(':')[0] / aspectRatio.split(':')[1]
        ) > 0.01 // 1% tolerance
      : false
  }, [aspectRatio, file, showAspectRatioMismatch])

  // If no image source is provided and the showPlaceholder prop is enabled, show a placeholder image
  if (!imgSrc && showPlaceholder) {
    return (
      <Tooltip content={t('imageMissing')} size="sm">
        <ImagePlaceholder
          className={className}
          aspectRatio={aspectRatio}
          noImageIcon
        />
      </Tooltip>
    )
  }

  return (
    <div
      className={`relative flex grow bg-gray-300 ${aspectRatioClass} ${className}`}
    >
      <img
        src={imgSrc}
        alt={alt}
        width={width}
        height={height}
        className={`object-cover ${className}`}
      />

      {showAspectRatioMismatch && isAspectRatioDifferent && (
        <div className="flex h-5 w-5 -translate-x-2.5 -translate-y-2.5 items-center justify-center rounded-full bg-white shadow-md">
          <Tooltip content={t('imageAspectRatioMismatch')} size="sm">
            <Icon
              name="exclamation-circle"
              className="text-danger-500"
              size="sm"
            />
          </Tooltip>
        </div>
      )}
    </div>
  )
}
Image.propTypes = {
  alt: PropTypes.string.isRequired,
  aspectRatio: PropTypes.oneOf(Object.keys(aspectRatios)),
  className: PropTypes.string,
  file: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  quality: PropTypes.number,
  showPlaceholder: PropTypes.bool,
  showAspectRatioMismatch: PropTypes.bool,
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

/**
 * @typedef {object} ImagePlaceholderProps ImagePlaceholder component props
 * @property {string} [className=''] Extra class name
 * @property {ImageAspectRatio} [aspectRatio] Aspect ratio to apply to the placeholder
 * @property {boolean} [noImageIcon=false] Whether to show a no-image icon instead of a generic placeholder
 */

/**
 * Placeholder image component
 * @param {ImagePlaceholderProps} props component props
 * @returns {React.Component}
 */
export function ImagePlaceholder({ className = '', aspectRatio, noImageIcon }) {
  const aspectRatioClass = useImageAspectRatio(aspectRatio)

  return (
    <div
      className={`group/placeholder-image relative flex grow select-none items-center justify-center gap-1 overflow-hidden bg-gray-300 object-contain drop-shadow-sm ${aspectRatioClass} ${className}`}
    >
      {noImageIcon ? (
        <svg viewBox="0 0 640 512" className="scale-50">
          <path
            d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7l-55.5-43.5c.5-3.1 .7-6.3 .7-9.6l0-320c0-35.3-28.7-64-64-64L128 32c-14.4 0-27.8 4.8-38.5 12.9L38.8 5.1zM134.4 80L512 80c8.8 0 16 7.2 16 16l0 292.5-53.4-41.9L387 233.3c-4.5-5.9-11.6-9.3-19-9.3s-14.4 3.4-19 9.3l-7.2 9.3L256 175.3c-.4-26.2-21.7-47.3-48-47.3c-3.7 0-7.4 .4-10.8 1.2L134.4 80zm353 400L282.9 318.9 266 340.7l-30.5-42.7C231 291.7 223.8 288 216 288s-15 3.7-19.5 10.1l-80 112-4.5 6.3 0-.3 0-231.8L64 146.4 64 416c0 35.3 28.7 64 64 64l359.4 0z"
            className="fill-gray-50"
          />
        </svg>
      ) : (
        <svg viewBox="0 0 470 358" className="scale-50">
          <g fill="none">
            <rect width="470" height="358" rx="40" className="fill-white" />
            <path
              d="M20,296 L178,167 L264,234 L372,107 L450,167 L450,305.341168 C450,322 436.568542,335.341168 420,335.341168 L50,335.341168 C33.4314575,335.341168 20,322 20,305.341168 L20,296 L20,296 Z"
              className="fill-gray-300"
            />
            <circle cx="92" cy="112" r="40" className="fill-gray-300" />
          </g>
        </svg>
      )}
    </div>
  )
}
ImagePlaceholder.propTypes = {
  aspectRatio: PropTypes.oneOf(Object.keys(aspectRatios)),
  className: PropTypes.string,
  noImageIcon: PropTypes.bool,
}
