import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import { useCurrentLayout } from '@modules/web/services/hooks/useCurrentLayout'
import CollapsablePanel from '@ui/data-display/CollapsablePanel'
import { CheckboxField } from '@ui/data-entry/Checkbox'
import { DateTimeField as DateTime } from '@ui/data-entry/DateTime'
import { InputField as Input } from '@ui/data-entry/Input'
import Divider from '@ui/layout/Divider'

import { useDesignContext } from '../../DesignProvider'
import FlexOptions from './FlexOptions'
import GridItemOptions from './GridItemOptions'

export default function SharedSettings() {
  const design = useDesignContext()
  const layout = useCurrentLayout()
  const { t } = useTranslation('web/content-editor')

  const { actions, data, nodeName } = useNode(node => {
    return {
      data: node.data.props,
      nodeName: node.data.name,
    }
  })

  const offsetBySiteHeader =
    layout?.design?.header?.collapse &&
    design?.blockSettings?.offsetBySiteHeader &&
    nodeName !== 'SiteHeader'

  return (
    <>
      {design?.blocks?.Grid?.showGridOptions && <GridItemOptions />}
      {design?.blocks?.Box?.showFlexOptions && <FlexOptions />}
      <CollapsablePanel title={t('advancedSettings')}>
        {offsetBySiteHeader && (
          <>
            <CheckboxField
              name="offsetBySiteHeader"
              label={t('offsetBySiteHeader')}
              help={t('offsetBySiteHeaderHelp')}
              value={data.offsetBySiteHeader}
              onChange={checked =>
                actions.setProp(p => (p.offsetBySiteHeader = checked))
              }
            />
            <Divider />
          </>
        )}
        <Input
          label={t('name')}
          help={t('nameHelp')}
          value={data.name}
          onChange={e =>
            actions.setProp(props => (props.name = e.target.value))
          }
        />
        <Input
          label={t('id')}
          help={t('idHelp')}
          value={data.id}
          onChange={e => actions.setProp(props => (props.id = e.target.value))}
        />
        <Input
          label={t('className')}
          help={t('classNameHelp')}
          value={data.className}
          onChange={e =>
            actions.setProp(props => (props.className = e.target.value))
          }
        />
        <DateTime
          type="datetime"
          label={t('publishStartsAt')}
          help={t('publishStartsAtHelp')}
          value={data.publishStartsAt}
          onChange={date =>
            actions.setProp(props => (props.publishStartsAt = date))
          }
          maxValue={data.publishEndsAt}
          fullWidth={true}
        />
        <DateTime
          type="datetime"
          label={t('publishEndsAt')}
          help={t('publishEndsAtHelp')}
          value={data.publishEndsAt}
          onChange={date =>
            actions.setProp(props => (props.publishEndsAt = date))
          }
          minValue={data.publishStartsAt}
          fullWidth={true}
        />
      </CollapsablePanel>
    </>
  )
}
