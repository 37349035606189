import PropTypes from 'prop-types'
import React from 'react'

import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { SearchDropdown } from '@ui/data-entry/SearchDropdown'
import { isEmpty } from '@utils/arrays'
import useDebounce from '@utils/useDebounce'

import getFullName from '../helpers/getFullName'
import usePerson from '../services/hooks/usePerson'
import usePersons from '../services/hooks/usePersons'

export function PersonPicker({
  channelId,
  className = '',
  hideLabel,
  ignoreIds,
  name,
  onChange,
  roles = [],
  value,
}) {
  const { t } = useTranslation('media-library/public')
  const [searchTerm, setSearchTerm] = React.useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const filters = {
    'ignore-ids': !isEmpty(ignoreIds) ? ignoreIds : null,
    'roles': roles,
  }

  const { resources } = usePageResources()

  const _channelId = channelId || resources?.Channel?.id

  const { persons } = usePersons({
    channelId: _channelId,
    limit: 100,
    search: debouncedSearchTerm,
    fetchOnSearch: false,
    filters,
  })
  const { person } = usePerson(value)

  const onSearch = React.useCallback(text => {
    setSearchTerm(text)
  }, [])

  return (
    <div className={`space-y-4 ${className}`}>
      <SearchDropdown
        label={hideLabel ? '' : t('person')}
        placeholder={t('typeToSearch')}
        name={name}
        value={value}
        selectedLabel={getFullName(person)}
        onChange={onChange}
        onSearch={onSearch}
      >
        {persons?.map(person => (
          <SearchDropdown.Option
            key={person.id}
            label={getFullName(person)}
            name={name}
            value={person.id}
          />
        ))}
      </SearchDropdown>
    </div>
  )
}
PersonPicker.propTypes = {
  channelId: PropTypes.string,
  className: PropTypes.string,
  hideLabel: PropTypes.bool,
  ignoreIds: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
  roles: PropTypes.array,
  value: PropTypes.string,
}

export default function PersonPickerController({
  channelId,
  className,
  hideLabel,
  ignoreIds,
  name,
  onChange,
  roles,
  shouldUnregister,
}) {
  const onFieldChange = React.useCallback(
    field => value => {
      field.onChange(value)

      if (typeof onChange === 'function') {
        onChange(value)
      }
    },
    [onChange]
  )

  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <PersonPicker
          channelId={channelId}
          className={className}
          hideLabel={hideLabel}
          ignoreIds={ignoreIds}
          name={name}
          value={field.value}
          onChange={onFieldChange(field)}
          roles={roles}
        />
      )}
    />
  )
}
PersonPickerController.propTypes = {
  channelId: PropTypes.string,
  className: PropTypes.string,
  hideLabel: PropTypes.bool,
  ignoreIds: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
  roles: PropTypes.array,
  shouldUnregister: PropTypes.bool,
}
