import PropTypes from 'prop-types'
import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import Box from '@ui/layout/Box'
import Heading from '@ui/typography/Heading'

const config = {
  name: 'VideoParent',
  label: 'media-library/public:videoParent',
  type: 'plugin',
  icon: 'cog',
  component: <VideoParent />,
}

function getPlaceholder() {
  return {
    episode: {
      title: '[Episode title]',
      image: {},
    },
    show: {
      title: '[Show title]',
      images: {
        default: {},
      },
    },
  }
}

export default function VideoParent({ showImage }) {
  const { t } = useTranslation('media-library/public')
  const showContent = false

  const { resources } = usePageResources()

  const _channel = resources?.Channel
  const _video = resources?.Video || getPlaceholder()

  return (
    <Content icon={config.icon} title={t('videoParent')}>
      {showContent ? (
        <div className="flex flex-col p-4">
          {showImage && <div>IMAGE</div>}
          <div>TODO</div>
        </div>
      ) : (
        <Box space="xs">
          <Heading as="h6" className="ml-2" text={t('videoParent')} />
          <ul className="ml-8 list-disc text-sm">
            <li>
              {t('channel')}: {_channel?.title || t('n/a')}
            </li>
            <li>
              {t('video')}: {_video?.title || t('dynamic')}
            </li>
          </ul>
        </Box>
      )}
    </Content>
  )
}
VideoParent.propTypes = {
  showImage: PropTypes.bool,
}
VideoParent.toolbarItem = config

function VideoParentSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { resources } = usePageResources()

  return (
    <SettingsWrap title={t('videoParent')} help={t('videoParentHelp')}>
      <Box space="xl">
        {resources?.Channel && !resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Channel?.title}
          </Message>
        )}
        {resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Show?.title}
          </Message>
        )}
        <Checkbox
          label={t('showImage')}
          onChange={value => {
            actions.setProp(props => (props.showImage = value))
          }}
          value={data.showImage}
          name="showImage"
        />
      </Box>
    </SettingsWrap>
  )
}

VideoParent.craft = {
  displayName: 'VideoParent',
  props: {
    showImage: true,
  },
  custom: {
    type: config.type,
    resources: ['videoParent'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: VideoParentSettings,
  },
}
