import PropTypes from 'prop-types'
import React from 'react'

const styles = {
  p: '',
  blockquote: 'pl-4 py-2 border-l-2 font-serif text-xl italic',
}

export default function Text({ as = 'p', children, text, className = '' }) {
  return React.createElement(
    as,
    { className: `${styles[as]} ${className}` },
    children || text
  )
}

Text.propTypes = {
  as: PropTypes.oneOf(['p', 'blockquote']),
  children: PropTypes.node,
  className: PropTypes.string,
  text: PropTypes.string,
}
