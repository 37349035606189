import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'
import Heading from '@ui/typography/Heading'

import useChannel from '../../services/hooks/useChannel'
import ChannelSelect from '../ChannelSelect'

const config = {
  name: 'LivestreamPlayer',
  label: 'media-library/public:livestreamPlayer',
  type: 'plugin',
  icon: 'router',
  component: <LivestreamPlayer />,
}

function getPlaceholder() {
  return {
    hls: '',
    jetstreamId: '',
  }
}

export default function LivestreamPlayer({ channelId }) {
  const { t } = useTranslation('media-library/public')
  const showContent = false

  const { resources } = usePageResources()
  const { channel } = useChannel(channelId)

  const _channel = resources?.Channel ? resources.Channel : channel
  const { hls, jetstreamId } = _channel ? _channel : getPlaceholder()

  return (
    <Content icon={config.icon} title={t('livestreamPlayer')}>
      {showContent ? (
        <div className="max-w-xl p-2">
          <div className="aspect-h-9 aspect-w-16 w-full border bg-gray-100" />
        </div>
      ) : (
        <Box space="xs">
          <Heading as="h6" className="ml-2" text={t('livestream')} />

          <ul className="ml-8 list-disc space-y-3 text-sm">
            <li>
              <strong>{t('channel')}:</strong>
              <div>{_channel?.title}</div>
            </li>
            {jetstreamId && (
              <li>
                <strong>{t('jetstream')}:</strong>
                <div className="font-mono text-sm">{jetstreamId}</div>
              </li>
            )}
            {hls && (
              <li>
                <strong>{t('hls')}:</strong>
                <div className="truncate">
                  <span className="font-mono text-sm">{hls}</span>
                </div>
              </li>
            )}
          </ul>
        </Box>
      )}
    </Content>
  )
}
LivestreamPlayer.propTypes = {
  channelId: PropTypes.string,
}
LivestreamPlayer.toolbarItem = config

function LivestreamPlayerSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(data.channelId)

  const { resources } = usePageResources()

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap
      title={t('livestreamPlayer')}
      help={t('livestreamPlayerHelp')}
    >
      <Box space="xl">
        <Checkbox
          label={t('showCurrentBroadcast')}
          help={t('showCurrentBroadcastHelp')}
          onChange={value =>
            actions.setProp(props => (props.showCurrentBroadcast = value))
          }
          value={data.showCurrentBroadcast}
        />
        {resources?.Channel && (
          <>
            <Message className="text-sm" type="warn">
              {resources?.Channel?.title}
            </Message>
            <Divider />
            <Checkbox
              label={t('pickChannelManually')}
              help={t('pickChannelManuallyHelp')}
              onChange={value => setShowPicker(value)}
              value={showPicker}
            />
          </>
        )}
        {(!resources?.Channel || showPicker) && (
          <ChannelSelect
            label={t('channel')}
            onChange={onChannelUpdate('channelId')}
            value={data.channelId || resources?.Channel?.id}
            controlled={false}
          />
        )}
      </Box>
    </SettingsWrap>
  )
}

LivestreamPlayer.craft = {
  displayName: 'LivestreamPlayer',
  props: {
    channelId: undefined,
    showCurrentBroadcast: true,
  },
  custom: {
    type: config.type,
    resources: ['channel'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: LivestreamPlayerSettings,
  },
}
