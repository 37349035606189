import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { EntityPickerField } from '@modules/entities/components/entities/EntityPicker'
import BlockField from '@modules/web/components/ContentEditor/shared/BlockField'

export function OrganizationFilter({ dataSource, fieldPrefix }) {
  const { t } = useTranslation(['articles/public', 'articles'])

  const organizationDataSourceOverride = React.useMemo(() => {
    return dataSource?.settings?.organizations || []
  }, [dataSource?.settings?.organizations])

  return (
    <BlockField name={`${fieldPrefix}organizations`}>
      {({ fieldProps, value, onDataChange }) => (
        <EntityPickerField
          {...fieldProps}
          dataSource={dataSource}
          help={t('filterOrganizationsHelp')}
          label={t('articles:organizations')}
          multiple
          name="organizations"
          onChange={onDataChange}
          placeholder={t('articles:organizationPlaceholder')}
          protectedItems={organizationDataSourceOverride}
          publicMode
          showValueInline={false}
          value={[...(value || []), ...organizationDataSourceOverride]}
        />
      )}
    </BlockField>
  )
}

OrganizationFilter.propTypes = {
  dataSource: PropTypes.object,
  fieldPrefix: PropTypes.string,
}
