import PropTypes from 'prop-types'
import React from 'react'

import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Label from '@ui/data-display/Label'
import {
  AutocompleteField as Autocomplete,
  AutocompleteOption,
  AutocompleteSelectedItem,
} from '@ui/data-entry/Autocomplete'
import useDebounce from '@utils/useDebounce'

import useRegions from '../../services/hooks/useRegions'
import useRegionsById from '../../services/hooks/useRegionsById'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export function RegionPicker({
  className = '',
  help,
  label,
  multiple,
  name,
  onChange,
  value,
  required,
}) {
  const { t } = useTranslation('events/public')
  const [searchTerm, setSearchTerm] = React.useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const { regions: foundRegions } = useRegions({
    search: debouncedSearchTerm,
    fetchOnSearch: true,
  })

  const { regions: selectedRegions, isLoading } = useRegionsById({
    ids: value ? (multiple ? value : [value]) : undefined,
    queryOptions: {
      keepPreviousData: true,
    },
  })

  const displayValue = React.useCallback(
    id => selectedRegions?.find(r => r.id === id)?.title ?? searchTerm,
    [searchTerm, selectedRegions]
  )

  const displayMultiple = (value, onItemRemove) => {
    if (!Array.isArray(value) || !Array.isArray(selectedRegions)) return ''

    return selectedRegions.map(region => (
      <AutocompleteSelectedItem
        key={region.id}
        label={region.title}
        onRemove={() => onItemRemove(region.id)}
      />
    ))
  }

  const onRegionSearch = React.useCallback(text => setSearchTerm(text), [])

  const handleChange = React.useCallback(
    newValue => {
      setSearchTerm('')
      onChange?.(newValue)
    },
    [onChange]
  )

  return (
    <Autocomplete
      className={className}
      displayIcon={
        value ? (
          <Icon name="map-marked-alt" className="text-primary-500" />
        ) : null
      }
      displayValue={displayValue}
      displayMultiple={multiple ? displayMultiple : undefined}
      help={help}
      label={label || t('region')}
      multiple={multiple}
      name={name}
      onChange={handleChange}
      onSearch={onRegionSearch}
      placeholder={isLoading ? t('loading') : t('searchPlaceholder')}
      required={required}
      value={isLoading ? undefined : value}
    >
      {foundRegions?.map(region => (
        <AutocompleteOption
          label={
            <Label
              label={region.title}
              icon="map-marked-alt"
              iconColor="primary"
            />
          }
          value={region.id}
          key={`region-${region.id}`}
        />
      ))}
    </Autocomplete>
  )
}
RegionPicker.propTypes = {
  className: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
}

export default function RegionPickerController({
  className,
  help,
  label,
  multiple,
  name,
  required,
  shouldUnregister,
}) {
  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <RegionPicker
          className={className}
          help={help}
          label={label}
          onChange={field.onChange}
          multiple={multiple}
          name={name}
          value={field.value}
          required={required}
        />
      )}
    />
  )
}
RegionPickerController.propTypes = {
  className: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
}
