import PropTypes from 'prop-types'
import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import useFeatureFlagEnabled from '@modules/feature-flags/hooks/useFeatureFlagEnabled'
import BlockField from '@modules/web/components/ContentEditor/shared/BlockField'
import useSite from '@modules/web/services/hooks/useSite'
import useSiteOrAncestorSite from '@modules/web/services/hooks/useSiteOrAncestorSite'
import { SelectField, SelectOption } from '@ui/data-entry/Select'
import { TagsField as Tags } from '@ui/data-entry/Tags'

export default function FlagFields({
  dataSource,
  fieldPrefix = '',
  singular,
  siteId,
}) {
  const { t } = useTranslation('articles/public')

  const { site: currentSite } = useSite()
  const isArticlesBySiteEnabled = useFeatureFlagEnabled('articles-by-site', {
    currentSite,
  })
  const { site: siteOrAncestorSite } = useSiteOrAncestorSite(siteId, {
    enabled: isArticlesBySiteEnabled,
  })

  const site = siteOrAncestorSite || currentSite

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const onAddFlagException = React.useCallback(
    flagId =>
      actions.setProp(
        data =>
          (data[`${fieldPrefix}flagExceptions`] = [
            ...(data[`${fieldPrefix}flagExceptions`] || []),
            flagId,
          ])
      ),
    [actions, fieldPrefix]
  )

  const onRemoveFlagException = React.useCallback(
    flagId => {
      actions.setProp(
        data =>
          (data[`${fieldPrefix}flagExceptions`] = (
            data[`${fieldPrefix}flagExceptions`] || []
          ).filter(id => id !== flagId))
      )
    },
    [actions, fieldPrefix]
  )

  const { flagDataSourceOverride, flagExceptionsDataSourceOverride } =
    React.useMemo(() => {
      return {
        flagDataSourceOverride: dataSource?.settings?.flag
          ? site?.flags?.find(flag => flag.id === dataSource?.settings?.flag)
          : null,
        flagExceptionsDataSourceOverride:
          dataSource?.settings?.flagExceptions || [],
      }
    }, [
      dataSource?.settings?.flag,
      dataSource?.settings?.flagExceptions,
      site?.flags,
    ])

  if (!Array.isArray(site?.flags)) return null

  return (
    <>
      <BlockField name={`${fieldPrefix}flag`}>
        {({ fieldProps, value, onDataChange }) => (
          <SelectField
            {...fieldProps}
            disabled={Boolean(flagDataSourceOverride)}
            help={t('flagHelp', { count: singular ? 1 : 0 })}
            label={t('flag')}
            onChange={e => onDataChange(e.target.value)}
            placeholder={flagDataSourceOverride?.name}
            value={flagDataSourceOverride ? null : value}
          >
            <SelectOption value="" label={t('flagShowAny')} />

            {site.flags.map(flag => (
              <SelectOption
                value={flag.id}
                label={flag.name}
                key={`flag-${site.id}-${flag.id}`}
              />
            ))}
          </SelectField>
        )}
      </BlockField>

      <BlockField name={`${fieldPrefix}flagExceptions`}>
        {({ fieldProps, value }) => (
          <Tags
            {...fieldProps}
            label={t('flagExceptions')}
            help={t('flagExceptionsHelp')}
            suggestions={site.flags
              .filter(
                flag =>
                  flag.id !== data[`${fieldPrefix}flag`] &&
                  flag.id !== flagDataSourceOverride
              )
              .map(i => i.id)}
            getItemLabel={id => site.flags.find(i => i.id === id)?.name}
            items={[
              ...(value ? value : []),
              ...flagExceptionsDataSourceOverride,
            ]}
            protectedItems={flagExceptionsDataSourceOverride}
            onAddItem={id => onAddFlagException(id)}
            onRemoveItem={id => onRemoveFlagException(id)}
            asText
          />
        )}
      </BlockField>
    </>
  )
}

FlagFields.propTypes = {
  dataSource: PropTypes.object,
  fieldPrefix: PropTypes.string,
  singular: PropTypes.bool,
  siteId: PropTypes.string,
}
