import PropTypes from 'prop-types'

import { timeSince } from '@utils/datetime'

import { useDatetimeLocale } from './datetime'

/**
 * Format a date using the given format and locale.
 * @param {Object} props - The component props.
 * @param {string|number|Date} props.date - The date to format.
 * @param {string} props.format - The format to use.
 * @param {number} props.maxDays - The maximum number of days to show the date. Defaults to `3`.
 * @returns {string} The formatted date.
 */
export default function TimeSince({ date, format = 'Pp', maxDays }) {
  const locale = useDatetimeLocale()
  return timeSince(date, format, { locale, maxDays })
}
TimeSince.propTypes = {
  date: PropTypes.string,
  format: PropTypes.string,
  maxDays: PropTypes.number,
}
