import PropTypes from 'prop-types'
import React from 'react'

import Field from '@ui/data-entry/Field'

import SelectGroup from './Group'
import SelectOption from './Option'
import SelectPlaceholder from './Placeholder'
import Select from './Select'

export default function SelectField({
  children,
  className,
  disabled,
  error,
  extra,
  fieldExtra,
  fullWidth,
  help,
  inputRef,
  label,
  labelExtra,
  lightLabel,
  name,
  onChange,
  placeholder,
  placeholderValue,
  required,
  showClear,
  value,
  id,
}) {
  return (
    <Field
      className={className}
      disabled={disabled}
      error={error}
      fieldExtra={fieldExtra}
      help={help}
      label={label}
      labelExtra={labelExtra}
      lightLabel={lightLabel}
      name={name}
      required={required}
    >
      <Select
        disabled={disabled}
        extra={extra}
        fullWidth={fullWidth}
        id={id || name}
        inputRef={inputRef}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        placeholderValue={placeholderValue}
        showClear={showClear}
        value={value}
      >
        {children}
      </Select>
    </Field>
  )
}
SelectField.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.object,
  extra: PropTypes.node,
  fieldExtra: PropTypes.node,
  fullWidth: PropTypes.bool,
  help: PropTypes.string,
  inputRef: PropTypes.any,
  label: PropTypes.string,
  labelExtra: PropTypes.node,
  lightLabel: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  placeholderValue: PropTypes.any,
  required: PropTypes.bool,
  showClear: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

SelectField.Option = SelectOption
SelectField.Option.displayName = 'Select.Option'

SelectField.Placeholder = SelectPlaceholder
SelectField.Placeholder.displayName = 'Select.Placeholder'

SelectField.Group = SelectGroup
SelectField.Group.displayName = 'Select.Group'
