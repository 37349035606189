import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { useDesign } from '@app/designs/hooks/useDesign'
import ColorPreview from '@ui/data-display/ColorPreview'
import Section from '@ui/data-display/Section'
import Form from '@ui/data-entry/Form'
import Submit from '@ui/data-entry/Submit'

import { ThemesField } from './components/ThemesField'

export function Themes({ site, onSubmit: onSubmitSite }) {
  const { t } = useTranslation('designs/adventist')
  const design = useDesign({ design: 'adventist', site })

  const onSubmit = React.useCallback(
    ({ themes }) => {
      onSubmitSite({
        ...site,
        design: {
          ...site.design,
          themes,
        },
      })
    },
    [onSubmitSite, site]
  )

  const formData = React.useMemo(() => {
    return {
      themes: site.design?.themes || [],
    }
  }, [site])

  return (
    <>
      <Section title={t('customThemes')}>
        <Form
          data={formData}
          onSubmit={onSubmit}
          id={`${site.id}-${site.updatedAt}`}
        >
          <Section.Body>
            <ThemesField />
          </Section.Body>
          <Section.Footer className="justify-between">
            <Submit label={t('update')} reverse />
          </Section.Footer>
        </Form>
      </Section>

      <Section title={t('defaultThemes')}>
        <Section.Body>
          <div className="grid grid-cols-4 gap-12">
            {design.appearance.themes.map(({ name, label, colors }) => {
              return (
                <div key={name} className="flex flex-col gap-4">
                  <h3 className="font-bold">{label}</h3>

                  <div className="flex flex-col gap-2">
                    {Object.entries(colors).map(([key, color]) => (
                      <ColorPreview
                        color={color}
                        number={key}
                        key={`color-${key}`}
                        size="lg"
                      />
                    ))}
                  </div>
                </div>
              )
            })}
          </div>
        </Section.Body>
      </Section>
    </>
  )
}

Themes.propTypes = {
  site: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
