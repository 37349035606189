import PropTypes from 'prop-types'
import React from 'react'

import { useFormContext } from 'react-hook-form'

import { useRules } from '@ui/data-entry/validationHooks'

import { DataSourceSelectField } from './Field'

export function DataSourceSelectController({
  allowedTypes,
  className,
  help,
  label,
  name = 'dataSource',
  placeholder,
  required,
}) {
  const { control } = useFormContext()

  const rules = useRules({ required })

  const onFieldChange = React.useCallback(
    field => newValue => {
      field.onChange(newValue)
    },
    []
  )

  return (
    <DataSourceSelectController
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => (
        <DataSourceSelectField
          allowedTypes={allowedTypes}
          className={className}
          error={fieldState.error}
          help={help}
          label={label}
          name={name}
          onChange={onFieldChange(field)}
          placeholder={placeholder}
          required={rules?.required?.value}
          value={field.value}
        />
      )}
    />
  )
}

DataSourceSelectController.propTypes = {
  allowedTypes: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
}
