import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import Dialog, { DialogContent } from '@ui/feedback/FloatingDialog'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export default function DangerModal({
  title,
  isLoading,
  isDisabled,
  onToggle,
  onDelete,
  onOpenChange,
  open,
  contentType,
  dangerZoneDescriptionLabel,
  dangerZoneEnableLabel,
  dangerZoneDisableLabel,
  disableToggle,
}) {
  const { t } = useTranslation('articles')

  const [deleteOpen, setDeleteOpen] = React.useState(false)

  const handleToggle = useCallback(() => {
    if (typeof onToggle === 'function') onToggle()
  }, [onToggle])

  const handleDelete = useCallback(() => {
    setDeleteOpen(false)
    if (typeof onDelete === 'function') onDelete()
  }, [onDelete])

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        kicker={contentType}
        title={title || t('dangerZone')}
        boxClass={`m-4 sm:min-w-[320px] max-w-[480px] ${
          deleteOpen ? 'translate-y-full' : ''
        }`}
        childrenClass="flex flex-col gap-8 overflow-y-auto"
        buttonsClass={`${disableToggle ? 'justify-end' : 'justify-between'} grow`}
        buttons={
          <>
            {!disableToggle && (
              <Button
                asLink
                label={t(isDisabled ? 'enable' : 'disable')}
                icon={isDisabled ? 'undo' : 'ban'}
                variant="warn"
                onClick={handleToggle}
                disabled={isLoading}
              />
            )}
            <Button
              label={t('delete')}
              icon="trash-alt"
              variant="danger"
              onClick={() => setDeleteOpen(true)}
              disabled={isLoading}
            />
          </>
        }
      >
        <div className="flex items-start gap-6">
          <Icon
            name="exclamation-triangle"
            className="text-warn-400"
            size="2x"
          />
          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-bold">{t('dangerZone')}</h3>
            <p className="leading-5 text-gray-500 ">
              {dangerZoneDescriptionLabel ??
                t('dangerZoneDescription', { contentType })}
            </p>
            <ul className="list-disc pl-6 flex flex-col gap-1">
              {!disableToggle && (
                <li className="text-sm text-warn-600">
                  {isDisabled
                    ? dangerZoneEnableLabel ?? t('dangerZoneEnable')
                    : dangerZoneDisableLabel ?? t('dangerZoneDisable')}
                </li>
              )}
              <li className="text-sm text-danger-700">
                {t('dangerZoneDelete')}
              </li>
            </ul>
          </div>
        </div>

        <Dialog open={deleteOpen} onOpenChange={() => setDeleteOpen(false)}>
          <DialogContent
            title={t('deleteConfirm', { contentType })}
            // overlayClass="bg-danger-950/60"
            boxClass="m-4 sm:min-w-[320px] max-w-[580px]"
            childrenClass="flex flex-col gap-8 overflow-y-auto"
            buttonsClass="justify-between grow"
            buttons={
              <>
                <Button
                  asLink
                  label={t('cancel')}
                  icon="times"
                  variant="flat"
                  onClick={() => setDeleteOpen(false)}
                  disabled={isLoading}
                />
                <Button
                  label={t('delete')}
                  icon="trash-alt"
                  variant="danger"
                  onClick={handleDelete}
                  disabled={isLoading}
                />
              </>
            }
          >
            <div className="flex flex-col items-start gap-2">
              <p>{t('deleteConfirmTextStart')}:</p>
              <p className="font-semibold text-lg leading-6">{title}</p>
              <p className="text-danger-700 animate-pulse">
                {t('deleteConfirmTextEnd')}
              </p>
            </div>
          </DialogContent>
        </Dialog>
      </DialogContent>
    </Dialog>
  )
}
DangerModal.propTypes = {
  title: PropTypes.node,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onDelete: PropTypes.func,
  onToggle: PropTypes.func,
  onOpenChange: PropTypes.func,
  contentType: PropTypes.string,
  open: PropTypes.bool,
  dangerZoneDescriptionLabel: PropTypes.string,
  dangerZoneEnableLabel: PropTypes.string,
  dangerZoneDisableLabel: PropTypes.string,
  disableToggle: PropTypes.bool,
}
