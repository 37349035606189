import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import CheckboxController from '@ui/data-entry/Checkbox'
import Divider from '@ui/layout/Divider'

import { ColorThemeSelect } from '../appearance/DesignOptions/components/ColorThemeSelect'

export function LayoutOptions({ site }) {
  const { t } = useTranslation('designs/adventist')

  return (
    <>
      <Divider />
      <ColorThemeSelect
        help={t('layoutColorThemeHelp')}
        label={t('layoutColorTheme')}
        name="design.colorTheme"
        site={site}
      />
      <CheckboxController
        name="design.header.collapse"
        label={t('collapseHeader')}
        help={t('collapseHeaderHelp')}
      />
    </>
  )
}

LayoutOptions.propTypes = {
  site: PropTypes.object.isRequired,
}
