import PropTypes from 'prop-types'
import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import Field from '@ui/data-entry/Field'
import Box from '@ui/layout/Box'
import Heading from '@ui/typography/Heading'

const RichText = React.lazy(() => import('@ui/data-entry/RichText/Render'))

const config = {
  name: 'VideoCategoryDetail',
  label: 'media-library/public:videoCategoryDetail',
  type: 'plugin',
  icon: 'video',
  component: <VideoCategoryDetail />,
}

function getPlaceholder() {
  return {
    title: '[Video category title]',
    abstract: '[Placeholder for abstract.]',
    body: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: '[This is a placeholder for the video category body]',
            },
          ],
        },
      ],
    },
  }
}

export default function VideoCategoryDetail({
  showAbstract,
  showBody,
  showTitle,
}) {
  const { t } = useTranslation('media-library/public')
  const showContent = true

  const { resources } = usePageResources()

  const _channel = resources?.Channel
  const _videoCategory = resources?.VideoCategory || getPlaceholder()

  const { abstract, body, title } = _videoCategory

  return (
    <Content icon={config.icon} title={t('videoCategoryDetail')}>
      {showContent ? (
        <div className="flex flex-col p-4">
          <div className="max-w-xl space-y-6">
            {showTitle && <h1 className="text-3xl font-bold">{title}</h1>}
            {showAbstract && abstract && (
              <p className="font-serif italic text-gray-600">{abstract}</p>
            )}
            {showBody && body && <RichText doc={body} />}
          </div>
        </div>
      ) : (
        <Box space="xs">
          <Heading as="h6" className="ml-2" text={t('videoCategoryDetail')} />
          <ul className="ml-8 list-disc text-sm">
            <li>
              {t('channel')}: {_channel?.title}
            </li>
            <li>
              {t('videoCategory')}: {_videoCategory?.title || t('dynamic')}
            </li>
            {showTitle && <li>{t('title')}</li>}
            {showAbstract && <li>{t('abstract')}</li>}
            {showBody && <li>{t('body')}</li>}
          </ul>
        </Box>
      )}
    </Content>
  )
}
VideoCategoryDetail.propTypes = {
  showAbstract: PropTypes.bool,
  showBody: PropTypes.bool,
  showTitle: PropTypes.bool,
}
VideoCategoryDetail.toolbarItem = config

function VideoCategoryDetailSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { resources } = usePageResources()

  return (
    <SettingsWrap
      title={t('videoCategoryDetail')}
      help={t('videoCategoryDetailHelp')}
    >
      <Box space="xl">
        {resources?.Channel && !resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources.Channel.title}
          </Message>
        )}
        {resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources.Show.title}
          </Message>
        )}
        <Field label={t('fields')}>
          <Box space="xs">
            <Checkbox
              label={t('title')}
              onChange={value => {
                actions.setProp(props => (props.showTitle = value))
              }}
              value={data.showTitle}
              name="showTitle"
            />
            <Checkbox
              label={t('abstract')}
              onChange={value => {
                actions.setProp(props => (props.showAbstract = value))
              }}
              value={data.showAbstract}
              name="showAbstract"
            />
            <Checkbox
              label={t('body')}
              onChange={value => {
                actions.setProp(props => (props.showBody = value))
              }}
              value={data.showBody}
              name="showBody"
            />
          </Box>
        </Field>
      </Box>
    </SettingsWrap>
  )
}

VideoCategoryDetail.craft = {
  displayName: 'VideoCategoryDetail',
  props: {
    showAbstract: false,
    showBody: true,
    showTitle: true,
  },
  custom: {
    type: config.type,
    resources: ['videoCategory'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: VideoCategoryDetailSettings,
  },
}
