import PropTypes from 'prop-types'
import React from 'react'

import {
  FloatingPortal,
  autoUpdate,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react'

import Loading from '@ui/feedback/Loading'
import Clickable from '@ui/helpers/Clickable'

import { SelectOption } from './components/SelectOption'
import { Tag } from './components/Tag'

export function MultipleSelect({
  asText = false,
  className = '',
  disabled,
  fullWidth = true,
  getLabel,
  getImage,
  getExtra,
  filterOptions,
  id,
  items = [],
  name,
  onAdd,
  onRemove,
  options = [],
  emptyMessage = 'Empty',
}) {
  const inputRef = React.useRef()

  const [text, setText] = React.useState('')
  const [existingItem, setExistingItem] = React.useState(null)
  const [selectedItem, setSelectedItem] = React.useState(null)
  const [_options, setOptions] = React.useState(options)
  const [open, setOpen] = React.useState(false)

  const { floatingStyles, refs, context } = useFloating({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
    placement: 'bottom-start',
    middleware: [
      shift(),
      offset(4),
      size({
        apply({ availableWidth, elements, rects }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${Math.min(availableWidth, rects.reference.width)}px`,
          })
        },
      }),
    ],
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ])

  const { isMounted, styles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: 'translateY(8px)',
    },
    open: {
      opacity: 1,
      transform: 'translateY(0)',
    },
  })

  React.useEffect(() => {
    if (text) {
      filterOptions && setOptions(filterOptions(text))
    } else {
      setOptions(options)
    }
  }, [filterOptions, options, text])

  const onSelectClick = () => {
    inputRef.current.focus()
    setOpen(prevOpen => !prevOpen)
  }

  const onOptionClick = React.useCallback(
    item => {
      const itemExists = items.find(i =>
        asText ? i === item : i.value === item.value
      )
      if (itemExists) {
        onRemove && onRemove(item)
      } else {
        onAdd && onAdd(item)
      }

      setText('')
    },
    [asText, items, onAdd, onRemove]
  )

  const onRemoveItem = React.useCallback(
    (item, e) => {
      e.stopPropagation()
      inputRef.current.focus()
      onRemove && onRemove(item)
    },
    [onRemove]
  )

  const onTextChange = React.useCallback(
    e => {
      const value = e.target.value
      if (!open) setOpen(true)
      setText(value)
    },
    [open, setOpen]
  )

  const onKeyDown = React.useCallback(
    e => {
      if (e.key === 'Enter') {
        e.preventDefault()

        const firstOption = _options[0]
        const itemExists = items.find(i =>
          asText ? i === firstOption : i.value === firstOption.value
        )
        if (!itemExists && firstOption) {
          onAdd && onAdd(firstOption)
        }
        setOpen(true)
        setText('')
      }
    },
    [_options, asText, items, onAdd, setOpen]
  )

  const onKeyUp = React.useCallback(
    e => {
      const { value } = e.target
      if (value) return

      switch (e.code) {
        case 'Escape': {
          if (selectedItem) {
            e.preventDefault()
            setSelectedItem(null)
          }
          setOpen(false)
          break
        }
        case 'Backspace': {
          e.preventDefault()
          const lastIndex = items.length - 1
          setSelectedItem(items[lastIndex])

          if (selectedItem) {
            onRemove(selectedItem, lastIndex)
            setSelectedItem(null)
          }
          break
        }
        default:
          break
      }
    },
    [selectedItem, items, onRemove, setOpen]
  )

  React.useEffect(() => {
    const timeout = setTimeout(() => setExistingItem(null), 1200)
    return () => clearTimeout(timeout)
  }, [existingItem])

  const disabledClass = disabled ? 'opacity-50' : ''
  const fullWidthClass = fullWidth ? 'w-full' : ''

  return (
    <div
      className={`relative flex flex-col ${fullWidthClass} ${className} ${disabledClass}`}
    >
      <div
        className="form-input rounded border-gray-300 focus-within:border-primary-500 focus-within:ring focus-within:ring-primary-200"
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        <Clickable
          className="-mx-1 flex flex-row flex-wrap focus:outline-none"
          onClick={onSelectClick}
        >
          {items.map((item, i) => {
            const itemValue = asText ? item : item.value
            const selectedValue = asText ? selectedItem : selectedItem?.value
            const existingValue = asText ? existingItem : existingItem?.value

            return (
              <Tag
                key={`tag-${i}`}
                label={getLabel ? getLabel(item) : asText ? item : item.label}
                image={getImage ? getImage(item) : null}
                onClick={e => onRemoveItem(item, e)}
                selected={selectedValue === itemValue}
                shaking={existingValue === itemValue}
                showRemove
                type={asText ? undefined : item.type}
              />
            )
          })}
          <input
            className="m-1 cursor-pointer text-base focus:outline-none"
            style={{ width: `${text.length || 2}ch` }}
            type="text"
            id={id}
            name={name}
            value={text}
            disabled={disabled}
            onChange={onTextChange}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            ref={inputRef}
          />
        </Clickable>
      </div>
      {isMounted && (
        <FloatingPortal>
          <React.Suspense fallback={<Loading />}>
            <div
              className="absolute z-max"
              {...getFloatingProps()}
              ref={refs.setFloating}
              style={floatingStyles}
            >
              <div
                className="max-h-64 overflow-y-auto rounded-b-md border bg-white shadow-xl focus:outline-none"
                style={styles}
              >
                {_options.length === 0 ? (
                  <div className="flex cursor-not-allowed items-center px-3 py-1 text-gray-400">
                    {emptyMessage}
                  </div>
                ) : (
                  _options.map((option, key) => {
                    return (
                      <SelectOption
                        image={getImage ? getImage(option) : null}
                        key={key}
                        label={
                          getLabel
                            ? getLabel(option)
                            : asText
                              ? option
                              : option.label
                        }
                        extra={getExtra ? getExtra(option) : null}
                        onClick={() => onOptionClick(option)}
                        selected={items.includes(option)}
                      />
                    )
                  })
                )}
              </div>
            </div>
          </React.Suspense>
        </FloatingPortal>
      )}
    </div>
  )
}
MultipleSelect.propTypes = {
  asText: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  getLabel: PropTypes.func,
  getImage: PropTypes.func,
  getExtra: PropTypes.func,
  filterOptions: PropTypes.func,
  id: PropTypes.string,
  items: PropTypes.array,
  name: PropTypes.string,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  options: PropTypes.array,
  emptyMessage: PropTypes.string,
}
