import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

export function useCurrentUser() {
  const isLoggedIn = document.cookie.includes(
    import.meta.env.VITE_SESSION_COOKIE
  )

  const { data, error, isLoading } = useQuery(
    ['users', 'me', isLoggedIn],
    () => {
      if (!isLoggedIn) return
      return getFetch('/api/users/me')
    },
    {
      enabled: isLoggedIn,
      staleTime: 15 * 60 * 1000, // 15 minutes
    }
  )

  return { loading: isLoading, user: error ? null : data, error }
}

export default useCurrentUser
