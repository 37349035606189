import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import ColorsForm from './ColorsForm'
import HopeFooterForm from './FooterForm'
import HopeHeaderForm from './HeaderForm'

export default function useHopeDesignSettingsTabs() {
  const { t } = useTranslation(['designs/hope', 'web/appearance'])

  return useMemo(
    () => [
      {
        name: 'colors',
        title: t('colors'),
        component: ColorsForm,
      },
      {
        name: 'header',
        title: t('web/appearance:header'),
        component: HopeHeaderForm,
      },
      {
        name: 'footer',
        title: t('web/appearance:footer'),
        component: HopeFooterForm,
      },
    ],
    [t]
  )
}
