import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { getFetch } from '@utils/fetcher'
import { validateMongoId } from '@utils/strings'

import { useSiteId } from './useSite'

const defaultOptions = {
  enabled: true,
}

export default function useDataSource(id, options = defaultOptions) {
  const siteId = useSiteId()
  const params = useParams()

  const { enabled } = Object.assign({}, defaultOptions, options)

  const dataSourceId = id ?? params?.dataSourceId

  const { data, ...rest } = useQuery(
    ['site', siteId, 'data-sources', dataSourceId],
    () => getFetch(`/api/web/sites/${siteId}/data-sources/${dataSourceId}`),
    { enabled: enabled && validateMongoId(dataSourceId) }
  )

  return { ...rest, dataSource: data }
}
