import PropTypes from 'prop-types'
import React, { Suspense } from 'react'

import Button from '@ui/buttons/Button'

import useBreakpoint from './useBreakpoint'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export function InlineResponsiveControls({
  breakpoint,
  dataValue,
  icons,
  open,
  setBreakpoint,
}) {
  const { breakpointKeys } = useBreakpoint()

  if (!open) return null

  const responsiveDataValue =
    typeof dataValue === 'object' ? dataValue : { xs: dataValue }

  return (
    <Suspense fallback={<div />}>
      <div className="-mb-1 flex justify-around border-t bg-gray-50 px-1 py-3">
        {breakpointKeys.map(bp => (
          <div key={`breakpoint_${bp}`} className="flex flex-col">
            <Button
              icon={icons[bp]}
              size="xs"
              variant={breakpoint === bp ? 'primary' : 'basic'}
              className="w-[calc(100%/6)] shrink-0 grow-0"
              onClick={() => setBreakpoint(bp)}
            />
            <Icon
              name="period"
              className={
                responsiveDataValue?.[bp] ? 'text-primary-500' : 'text-gray-300'
              }
            />
          </div>
        ))}
      </div>
    </Suspense>
  )
}

InlineResponsiveControls.propTypes = {
  breakpoint: PropTypes.string,
  dataValue: PropTypes.object,
  icons: PropTypes.object,
  open: PropTypes.bool,
  setBreakpoint: PropTypes.func.isRequired,
}

export default InlineResponsiveControls
