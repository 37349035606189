import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { getFetch } from '@utils/fetcher'

import usePublicationType from './usePublicationType'

/**
 * @typedef {import('@utils/images').ImageFile} ImageFile
 * @typedef {import('@utils/documents').DocumentFile} DocumentFile
 * @typedef {import('./usePublicationType').PublicationType} PublicationType
 *
 * Publication
 * @typedef {object} Publication A publication object (e.g. a book, journal, etc.)
 * @property {string} _id The ID of the publication
 * @property {string} id An alias for _id
 * @property {string} title The title of the publication (e.g. 'My Publication')
 * @property {string} slug Short identifier for the publication (e.g. 'my-publication')
 * @property {string} type The type of the publication, aka PublicationType's name (e.g. 'journal', 'book'). It will depend on entity's defined publication types.
 * @property {string} religion (optional) The religion of the publication (e.g. 'islam', 'christianity'). It will depend on entity's defined religions (if any).
 * @property {string} language The language of the publication (e.g. 'en', 'fr')
 * @property {string} source If the publication record is a translation, this will be the source publication's ID.
 * @property {boolean} featured Whether the publication is featured
 * @property {object} cover Data related to the cover image
 * @property {ImageFile} cover.file The cover image file object
 * @property {object} body A longer description of the publication in rich text format (TipTap)
 * @property {object} fullContent The full content of the publication rich text format (TipTap)
 * @property {string} publisher The publisher of the publication
 * @property {string} author The author of the publication
 * @property {string} isbn The ISBN of the publication
 * @property {number} pages Amount of pages in the publication
 * @property {object} downloadCount The download count info of the publication
 * @property {number} downloadCount.total The total download count (only for the source publication)
 * @property {number} downloadCount.current The current translation download count (if applicable)
 * @property {DocumentFile} designFile The design file of the publication
 * @property {DocumentFile} epub The epub file of the publication
 * @property {DocumentFile} pdfPrinting The pdf printing file of the publication
 * @property {DocumentFile} pdfDigital The pdf digital file of the publication
 * @property {DocumentFile} mp3 The mp3 file of the publication
 * @property {string} mp3Url The mp3 url of the publication
 * @property {DocumentFile} legalFile The legal file of the publication
 * @property {DocumentFile} promotionalMaterials The promotional materials file of the publication
 * @property {DocumentFile} studyGuide The study guide file of the publication
 * @property {DocumentFile} readingPlan The reading plan file of the publication
 * @property {Array} categories The categories of the publication
 * @property {Array} generalCategories The general categories of the publication
 * @property {string} entity The entity ID of the publication
 * @property {boolean} shared Whether the publication is shared
 * @property {boolean} assetsRequired Whether the publication requires assets
 * @property {PublicationVolume[]} volumes An array of volumes of the publication (if any)
 */

/**
 * Publication Volume
 * @typedef {object} PublicationVolume A volume of a publication, containing issues
 * @property {string} _id The ID of the volume in mongodb format (e.g. '1234567890abcdef12345678')
 * @property {string} title The title of the volume (e.g. 'Volume 1')
 * @property {string} slug A short identifier for the volume (e.g. 'volume-1')
 */

/**
 * Hook options
 * @typedef {object} UsePublicationOptions
 * @property {string} id The ID of the publication to fetch
 * @property {boolean} enabled Whether the query is enabled
 * @property {string} translationsSort The sort order of the translations
 * @property {boolean} includeType Whether to fetch also the publication type
 */

/**
 * Hook response
 * @typedef {object} UsePublicationType
 * @property {Publication} publication The publication object
 * @property {string} publicationId The ID of the publication
 * @property {PublicationType} publicationType The publication type object
 * @property {string} publicationTypeName The name of the publication type
 * @property {Error} error The error object
 * @property {boolean} loading Whether the query is loading
 */

/**
 * @type {UsePublicationOptions}
 */
const defaultOptions = {
  id: null,
  enabled: true,
  translationsSort: '',
  includeType: false,
}

/**
 * Hook to fetch a publication by ID
 * @param {UsePublicationOptions} options - The options to fetch the publication
 * @returns {UsePublicationType} An object containing the publication and its status
 */
export default function usePublication(options = defaultOptions) {
  const { id, enabled, translationsSort } = Object.assign(
    {},
    defaultOptions,
    options
  )

  const params = useParams()

  const publicationId = id || params?.publicationId

  // Fetch the publication
  const { data, error, isLoading } = useQuery(
    ['publications', publicationId, translationsSort, enabled],
    () =>
      getFetch(`/api/publications/${publicationId}`, {
        translationsSort,
      }),
    { enabled: enabled && !!publicationId }
  )

  const publicationTypeName = data?.type

  // Fetch the publication type if requested
  const { publicationType, loading: typeLoading } = usePublicationType(
    {
      name: publicationTypeName,
    },
    { enabled: options.includeType && !!publicationTypeName } // Only call this if the publication type is requested and available
  )

  return {
    publication: data,
    publicationId,
    publicationType,
    publicationTypeName,
    error,
    loading: isLoading || typeLoading,
  }
}
