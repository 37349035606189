import PropTypes from 'prop-types'
import React, { Suspense, useEffect, useRef, useState } from 'react'

import {
  FloatingFocusManager,
  FloatingList,
  FloatingNode,
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useHover,
  useInteractions,
  useListItem,
  useListNavigation,
  useMergeRefs,
  useRole,
  useTypeahead,
} from '@floating-ui/react'

import { useButtonClasses } from '@ui/buttons/Button'
import Tooltip from '@ui/feedback/Tooltip'

import DropdownContext, { useDropdownContext } from './Context'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const staticSides = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
}

const arrowBorders = {
  top: 'border-r border-b',
  right: 'border-l border-b',
  bottom: 'border-l border-t',
  left: 'border-r border-t',
}

const DropdownMenu = React.forwardRef(
  (
    {
      variant = 'basic',
      children,
      label,
      icon,
      size = 'md',
      disabled,
      loading,
      pressed,
      className = '',
      floatingClass = '',
      horizontalPlacement = 'start',
      showArrow = false,
      tooltip,
      tooltipPlacement = 'bottom',
      ...props
    },
    forwardedRef
  ) => {
    const arrowRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const [hasFocusInside, setHasFocusInside] = useState(false)
    const [activeIndex, setActiveIndex] = useState(null)

    const elementsRef = useRef([])
    const labelsRef = useRef([])
    const parent = useDropdownContext()

    const tree = useFloatingTree()
    const nodeId = useFloatingNodeId()
    const parentId = useFloatingParentNodeId()
    const item = useListItem()

    const isNested = parentId != null

    const { floatingStyles, refs, context, middlewareData, placement } =
      useFloating({
        nodeId,
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: isNested
          ? `right-${horizontalPlacement}`
          : `bottom-${horizontalPlacement}`,
        middleware: [
          shift(),
          offset({
            mainAxis: isNested ? 0 : 6, // vertical offset
            crossAxis: isNested ? 0 : 6, // horizontal offset
            alignmentAxis: -8,
          }),
          flip(),
          arrow({ element: arrowRef }), // Sets arrow position
        ],
        whileElementsMounted: autoUpdate,
      })

    const placementSide = placement?.split('-')?.[0] ?? 'top'
    const staticSide = staticSides[placementSide]
    const arrowBorderClass = arrowBorders[placementSide]

    const { arrow: { x: arrowX, y: arrowY } = {} } = middlewareData

    const hover = useHover(context, {
      enabled: isNested,
      delay: { open: 75 },
      handleClose: safePolygon({ blockPointerEvents: true }),
    })

    const click = useClick(context, {
      event: 'mousedown',
      toggle: !isNested,
      ignoreMouse: isNested,
    })

    const role = useRole(context, { role: 'menu' })
    const dismiss = useDismiss(context, { bubbles: true })
    const listNavigation = useListNavigation(context, {
      listRef: elementsRef,
      activeIndex,
      nested: isNested,
      onNavigate: setActiveIndex,
    })

    const typeahead = useTypeahead(context, {
      listRef: labelsRef,
      onMatch: isOpen ? setActiveIndex : undefined,
      activeIndex,
    })

    const { getReferenceProps, getFloatingProps, getItemProps } =
      useInteractions([hover, click, role, dismiss, listNavigation, typeahead])

    // Event emitter allows you to communicate across tree components.
    // This effect closes all menus when an item gets clicked anywhere
    // in the tree.
    useEffect(() => {
      if (!tree) return

      function handleTreeClick() {
        setIsOpen(false)
      }

      function onSubMenuOpen(event) {
        if (event.nodeId !== nodeId && event.parentId === parentId) {
          setIsOpen(false)
        }
      }

      tree.events.on('click', handleTreeClick)
      tree.events.on('menuopen', onSubMenuOpen)

      return () => {
        tree.events.off('click', handleTreeClick)
        tree.events.off('menuopen', onSubMenuOpen)
      }
    }, [tree, nodeId, parentId])

    useEffect(() => {
      if (isOpen && tree) {
        tree.events.emit('menuopen', { parentId, nodeId })
      }
    }, [tree, isOpen, nodeId, parentId])

    const buttonClass = useButtonClasses({
      variant,
      size,
      disabled,
      pressed,
    })

    return (
      <Suspense fallback={null}>
        <FloatingNode id={nodeId}>
          <div className="relative">
            <Tooltip content={tooltip} placement={tooltipPlacement} size={size}>
              <button
                ref={useMergeRefs([refs.setReference, item.ref, forwardedRef])}
                tabIndex={
                  !isNested
                    ? undefined
                    : parent.activeIndex === item.index
                      ? 0
                      : -1
                }
                role={isNested ? 'menuitem' : undefined}
                data-open={isOpen ? '' : undefined}
                data-nested={isNested ? '' : undefined}
                data-focus-inside={hasFocusInside ? '' : undefined}
                type="button"
                className={`group ${
                  isNested
                    ? `flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-primary-400 hover:text-primary-800 focus:bg-primary-100 focus:text-primary-800 focus:outline-none ${
                        hasFocusInside ? 'bg-gray-100 text-gray-900' : ''
                      }`
                    : `focus-visible:outline-none group-[.row-active]:text-primary-50 ${buttonClass} ${className}`
                }`}
                {...getReferenceProps(
                  parent.getItemProps({
                    ...props,
                    onFocus(event) {
                      props.onFocus?.(event)
                      setHasFocusInside(false)
                      parent.setHasFocusInside(true)
                    },
                  })
                )}
              >
                {loading ? (
                  <Icon name="spinner" spin />
                ) : (
                  icon && <Icon name={icon} />
                )}
                <span className="font-medium empty:hidden">{label}</span>
                {showArrow && (
                  <span
                    aria-hidden
                    className="text-xs text-gray-400 group-hover:text-gray-600"
                  >
                    {isNested ? (
                      <Icon name="chevron-right" />
                    ) : (
                      <Icon
                        name="chevron-down"
                        className={`transition-all duration-150 ease-in-out ${isOpen ? 'rotate-180' : ''}`}
                      />
                    )}
                  </span>
                )}
              </button>
            </Tooltip>
          </div>

          <DropdownContext.Provider
            value={{
              activeIndex,
              setActiveIndex,
              getItemProps,
              setHasFocusInside,
              isOpen,
            }}
          >
            <FloatingList elementsRef={elementsRef} labelsRef={labelsRef}>
              {isOpen && (
                <FloatingPortal>
                  <FloatingFocusManager
                    context={context}
                    modal={false}
                    initialFocus={isNested ? -1 : 0}
                    returnFocus={!isNested}
                  >
                    <div
                      ref={refs.setFloating}
                      className={`isolate rounded-md border border-gray-200 bg-white py-1 drop-shadow-md focus:outline-none ${floatingClass}`}
                      style={floatingStyles}
                      {...getFloatingProps()}
                    >
                      {isOpen && !isNested && (
                        <div
                          className={`absolute z-0 h-4 w-4 rotate-45 border-gray-200 bg-white ${arrowBorderClass}`}
                          ref={arrowRef}
                          style={{
                            top: arrowY ?? '',
                            left: arrowX ?? '',
                            [staticSide]: '-8.5px', // half of arrow's width (16px) + border width (1px)
                          }}
                        />
                      )}
                      <div className="relative z-10 flex flex-col">
                        {children}
                      </div>
                    </div>
                  </FloatingFocusManager>
                </FloatingPortal>
              )}
            </FloatingList>
          </DropdownContext.Provider>
        </FloatingNode>
      </Suspense>
    )
  }
)
DropdownMenu.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  icon: PropTypes.string,
  children: PropTypes.node,
  floatingClass: PropTypes.string,
  onFocus: PropTypes.func,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  horizontalPlacement: PropTypes.oneOf(['start', 'center', 'end']),
  loading: PropTypes.bool,
  pressed: PropTypes.bool,
  showArrow: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  variant: PropTypes.string,
}

export default DropdownMenu
