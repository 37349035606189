import PropTypes from 'prop-types'
import React from 'react'

import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useImageUpload } from '@modules/images/services/ImageServices'
import Button from '@ui/buttons/Button'
import Image from '@ui/data-display/Image'
import MetaItem from '@ui/data-display/MetaItem'
import Dialog, { DialogContent } from '@ui/feedback/FloatingDialog'
import Popover from '@ui/feedback/Popover'
import Box from '@ui/layout/Box'
import { DownloadLink } from '@ui/navigation/Link'
import bytes from '@utils/bytes'
import { getOriginalImageUrl } from '@utils/images'
import { isFunction } from '@utils/types'

import Field from './Field'
import { FileDragger } from './FileDragger'
import ImageClipboardDropdown from './ImageClipboardDropdown'
import { useRules } from './validationHooks'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export function ImagePreview({ file }) {
  const { t } = useTranslation('ui/image-upload')

  if (!file) return null

  const downloadUrl = getOriginalImageUrl(file)

  return (
    <div className="relative flex shrink flex-row self-start">
      <div className="relative isolate rounded border-4 border-white bg-gray-50 shadow drop-shadow bg-checkered">
        <Image
          className="relative max-w-64"
          alt={file.originalFilename}
          file={file}
          width={256}
        />
      </div>
      <div className="absolute -end-3 -top-3">
        <Popover offset={{ mainAxis: 4, crossAxis: -10 }}>
          <Popover.Trigger asChild>
            <div title={t('info')}>
              <Icon
                className="rounded-full border-2 border-white bg-white p-0.5 text-primary-500 opacity-50 shadow transition-opacity duration-300 ease-in-out hover:opacity-100 hover:shadow-lg"
                name="info-circle"
              />
            </div>
          </Popover.Trigger>
          <Popover.Content>
            <div className="divide-y">
              <MetaItem label={t('format')} value={file.mime} />
              <MetaItem
                label={t('dimensions')}
                value={
                  <span>
                    {file.width} &times; {file.height}
                  </span>
                }
              />
              <MetaItem label={t('size')} value={bytes(file.size)} />
              <div className="flex items-center justify-center px-2 pt-3">
                <DownloadLink
                  href={downloadUrl}
                  label={t('downloadImage')}
                  className="text-sm"
                />
              </div>
            </div>
          </Popover.Content>
        </Popover>
      </div>
    </div>
  )
}
ImagePreview.propTypes = {
  file: PropTypes.shape({
    originalFilename: PropTypes.string,
    mime: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    size: PropTypes.number,
  }),
}

export function ImageUpload({
  accept = ['jpg', 'png', 'gif', 'svg', 'webp'],
  className = '',
  disabled,
  id,
  inputRef,
  maxSize = '1mb',
  multiple,
  name,
  onChange,
  onUpload,
  openModal,
  isSourced,
  value,
}) {
  const { t } = useTranslation('ui/image-upload')
  const hasImage = !!value?.mime
  const hasValue = hasImage || !!value?.resource
  const [dialogOpen, setDialogOpen] = React.useState(openModal)
  const [dialogRemoveOpen, setDialogRemoveOpen] = React.useState(false)
  const { onImageUpload: defaultOnImageUplaod } = useImageUpload()

  const maxFileSize = maxSize ? bytes(maxSize) : undefined

  const onImageUpload = React.useCallback(
    (data, onChange) => {
      // Close the dialog
      setDialogOpen(false)

      // Call the onUpload function if it exists
      if (typeof onUpload === 'function') {
        onUpload(data, onChange)
      } else {
        // Otherwise, call the default onImageUpload function
        defaultOnImageUplaod(data, onChange)
      }
    },
    [onUpload, defaultOnImageUplaod]
  )

  return (
    <React.Suspense fallback={<div />}>
      <div className={`flex flex-col space-y-4 ${className}`}>
        {hasImage && <ImagePreview file={value} />}
        <div>
          <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <Box space="lg" align="center" horizontal>
              <Button
                label={t(
                  hasValue ? 'replace' : multiple ? 'selectFiles' : 'selectFile'
                )}
                icon="images"
                size="sm"
                disabled={disabled || dialogOpen}
                onClick={() => setDialogOpen(true)}
              />

              <ImageClipboardDropdown
                value={value}
                onChange={onChange}
                disabled={!hasImage}
              />

              {isSourced && (
                <Icon name="plug-circle-bolt" className="text-gray-400" />
              )}
              {hasValue && (
                <Dialog
                  open={dialogRemoveOpen}
                  onOpenChange={setDialogRemoveOpen}
                >
                  <Button
                    tooltip={t('remove')}
                    variant="danger-light"
                    icon="trash-alt"
                    onClick={() => setDialogRemoveOpen(true)}
                    size="sm"
                  />

                  <DialogContent
                    title={t('removeImage')}
                    buttons={
                      <>
                        <Button
                          label={t('cancel')}
                          onClick={() => setDialogRemoveOpen(false)}
                        />
                        <Button
                          icon="trash-alt"
                          label={t('removeImageConfirm')}
                          variant="danger"
                          onClick={() => {
                            onChange(null)
                            setDialogRemoveOpen(false)
                          }}
                        />
                      </>
                    }
                    buttonsClass="justify-between"
                  >
                    {t('removeImageMessage')}
                  </DialogContent>
                </Dialog>
              )}
            </Box>
            <DialogContent title={t(multiple ? 'selectFiles' : 'selectFile')}>
              <FileDragger
                accept={accept}
                acceptLabel={t('accept')}
                dropHint={t(multiple ? 'dropHintMultiple' : 'dropHint')}
                disabled={disabled}
                id={id}
                inputRef={inputRef}
                maxSize={maxFileSize}
                multiple={multiple}
                name={name}
                onChange={onChange}
                onUpload={onImageUpload}
                selectFilesLabel={t(multiple ? 'selectFiles' : 'selectFile')}
              />
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </React.Suspense>
  )
}
ImageUpload.propTypes = {
  accept: PropTypes.array,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  inputRef: PropTypes.any,
  isSourced: PropTypes.bool,
  maxSize: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onUpload: PropTypes.func,
  openModal: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export function ImageUploadField({
  accept,
  className,
  disabled,
  error,
  help,
  inputRef,
  isSourced,
  label,
  labelExtra,
  maxSize,
  missing,
  multiple,
  name,
  onChange,
  onUpload,
  openModal,
  popover,
  required,
  value,
}) {
  return (
    <Field
      className={className}
      name={name}
      label={label}
      labelExtra={labelExtra}
      error={error}
      help={help}
      missing={missing}
      popover={popover}
      required={required}
    >
      <ImageUpload
        accept={accept}
        disabled={disabled}
        id={name}
        inputRef={inputRef}
        isSourced={isSourced}
        maxSize={maxSize}
        multiple={multiple}
        name={name}
        onChange={onChange}
        onUpload={onUpload}
        openModal={openModal}
        value={value}
      />
    </Field>
  )
}
ImageUploadField.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.object,
  help: PropTypes.string,
  inputRef: PropTypes.any,
  isSourced: PropTypes.bool,
  label: PropTypes.string,
  labelExtra: PropTypes.node,
  maxSize: PropTypes.string,
  missing: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onUpload: PropTypes.func,
  openModal: PropTypes.bool,
  popover: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default function ImageUploadController({
  accept,
  className,
  disabled,
  help,
  isSourced,
  label,
  labelExtra,
  maxSize,
  missing,
  multiple,
  name,
  onChange,
  onUpload,
  openModal,
  required,
  shouldUnregister,
  value,
}) {
  const { control } = useFormContext()

  const rules = useRules({ required })

  const onFieldChange = React.useCallback(
    field => newValue => {
      field.onChange(newValue)

      if (isFunction(onChange)) {
        onChange(newValue)
      }
    },
    [onChange]
  )

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <ImageUploadField
          accept={accept}
          className={className}
          disabled={disabled}
          error={fieldState.error}
          help={help}
          id={name}
          inputRef={field.ref}
          isSourced={isSourced}
          label={label}
          labelExtra={labelExtra}
          maxSize={maxSize}
          missing={missing}
          multiple={multiple}
          name={name}
          onBlur={field.onBlur}
          onChange={onFieldChange(field)}
          onUpload={onUpload}
          openModal={openModal}
          required={rules?.required?.value}
          value={field.value}
        />
      )}
    />
  )
}

ImageUploadController.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  help: PropTypes.string,
  isSourced: PropTypes.bool,
  label: PropTypes.string,
  labelExtra: PropTypes.node,
  maxSize: PropTypes.string,
  missing: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onUpload: PropTypes.func,
  openModal: PropTypes.bool,
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({ value: PropTypes.bool, message: PropTypes.string }),
  ]),
  shouldUnregister: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}
ImageUploadController.displayName = 'ImageUpload'
