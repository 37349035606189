import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

import { currentEntityApiUrl } from '../EntityServices'

/**
 * Get the current entity
 * @param {object} options The options
 * @param {boolean} [options.includeAncestors=false] Include ancestors
 * @param {boolean} [options.includeNetwork=false] Include network
 * @param {boolean} [options.enabled=true] Enabled
 * @returns {import('react-query').UseQueryResult} The current entity
 */
export default function useCurrentEntity({
  includeAncestors = false,
  includeNetwork = false,
  enabled = true,
} = {}) {
  const { data, error, isLoading } = useQuery(
    ['entities', 'current', includeAncestors, includeNetwork],
    () => getFetch(currentEntityApiUrl, { includeAncestors, includeNetwork }),
    {
      enabled,
      staleTime: 60 * 60 * 1000, // 1 hour
    }
  )

  return { entity: data, error, loading: isLoading }
}
