import PropTypes from 'prop-types'
import React from 'react'

import Button from '@ui/buttons/Button'

import { useModal } from '../hooks'

/**
 * A trigger to open a modal.
 * @param {object} props
 * @param {object} [props.render] Element to provide a custom trigger (like a Clickable, Link, etc.). If not set, it will render as fallback a `Button`.
 * @param {string} [props.label] Label for the fallback `Button`
 * @param {string} [props...btnProps] You can also provide for the fallback all props that `Button` accepts, like `icon`, `variant`, etc.
 * @returns {React.ReactElement}
 */
export default function ModalTrigger({
  render,
  label = 'Open modal',
  ...btnProps
}) {
  const { onOpen } = useModal()

  if (typeof render === 'object')
    return React.cloneElement(render, { onClick: onOpen })

  return (
    <Button
      onClick={() => {
        onOpen()
      }}
      label={label}
      {...btnProps}
    />
  )
}
ModalTrigger.propTypes = {
  'render': PropTypes.object,
  'label': PropTypes.string,
  '...btnProps': PropTypes.string,
}
