import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { useBlock } from '@modules/web/helpers/useBlock'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'
import Heading from '@ui/typography/Heading'

import useChannel from '../../services/hooks/useChannel'
import useEpisode from '../../services/hooks/useEpisode'
import ChannelSelect from '../ChannelSelect'
import { EpisodePicker } from '../EpisodePicker'

const config = {
  name: 'EpisodePlayer',
  label: 'media-library/public:episodePlayer',
  type: 'plugin',
  icon: 'play-circle',
  component: <EpisodePlayer />,
}

function getPlaceholder() {
  return {
    mediaLinks: {
      jetstream: { link: '' },
      youtube: { link: '' },
      vimeo: { link: '' },
    },
  }
}

export default function EpisodePlayer({ channelId, id }) {
  const { t } = useTranslation('media-library/public')
  const showContent = true

  const { resources } = usePageResources()
  const { channel } = useChannel(channelId)
  const { episode } = useEpisode(id)

  const _channel = resources?.Channel ? resources.Channel : channel
  const _episode = resources?.Episode
    ? resources.Episode
    : episode || getPlaceholder()

  return (
    <Content className="w-full" icon={config.icon} title={t('episodePlayer')}>
      {showContent ? (
        <div className="p-2">
          <div className="aspect-h-9 aspect-w-16 w-full border bg-gray-100" />
        </div>
      ) : (
        <Box space="xs">
          <Heading as="h6" className="ml-2" text={t('episodePlayer')} />
          <ul className="ml-8 list-disc text-sm">
            <li>
              {t('channel')}: {_channel?.title}
            </li>
            <li>
              {t('episode')}: {_episode?.title || t('dynamic')}
            </li>
          </ul>
        </Box>
      )}
    </Content>
  )
}
EpisodePlayer.propTypes = {
  channelId: PropTypes.string,
  id: PropTypes.string,
}
EpisodePlayer.toolbarItem = config

function EpisodePlayerSettings() {
  const { t } = useTranslation(['media-library/public', 'web/content-editor'])

  const { fullSize } = useBlock(config.name)

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(data.channelId || data.id)

  const { resources } = usePageResources()

  const onEpisodeIdChange = useCallback(
    value => {
      actions.setProp(props => (props.id = value))
    },
    [actions]
  )

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t('episodePlayer')} help={t('episodePlayerHelp')}>
      <Box space="xl">
        {resources?.Channel && !resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Channel?.title}
          </Message>
        )}
        {resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Show?.title}
          </Message>
        )}
        {!resources?.Show && (
          <>
            <Divider />
            <Checkbox
              label={t('pickEpisodeManually')}
              help={t('pickEpisodeManuallyHelp')}
              onChange={value => setShowPicker(value)}
              value={showPicker}
            />
            {showPicker && (
              <Box space="lg">
                {!resources?.Channel && (
                  <ChannelSelect
                    label={t('channel')}
                    onChange={onChannelUpdate('channelId')}
                    value={data.channelId}
                    controlled={false}
                  />
                )}
                <EpisodePicker
                  channelId={data.channelId || resources?.Channel?.id}
                  onChange={onEpisodeIdChange}
                  value={data.id}
                />
              </Box>
            )}
          </>
        )}
        {fullSize && (
          <>
            <Divider />
            <Checkbox
              name="fullSize"
              label={t('web/content-editor:fullSize')}
              help={t('web/content-editor:fullSizeHelp')}
              onChange={checked =>
                actions.setProp(value => (value.fullSize = checked))
              }
              value={data.fullSize}
            />
          </>
        )}
      </Box>
    </SettingsWrap>
  )
}

EpisodePlayer.craft = {
  displayName: 'EpisodePlayer',
  props: {
    channelId: undefined,
    id: undefined,
  },
  custom: {
    type: config.type,
    resources: ['episodePlayer'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EpisodePlayerSettings,
  },
}
