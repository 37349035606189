import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { TypeSelect } from './TypeSelect'

export function EntityTypePicker({
  className = '',
  name,
  label,
  help,
  onChange,
  value,
}) {
  const { t } = useTranslation('entities/public')

  return (
    <div className={`space-y-4 ${className}`}>
      <TypeSelect
        label={label || t('entityType')}
        help={help || t('entityTypeHelp')}
        name={name}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}
EntityTypePicker.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default function EntityTypePickerController({
  className = '',
  name,
  label,
  help,
  onChange,
  shouldUnregister,
}) {
  const onFieldChange = useCallback(
    field => event => {
      field.onChange(event)

      if (typeof onChange === 'function') {
        onChange(event.currentTarget.value)
      }
    },
    [onChange]
  )

  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <EntityTypePicker
          className={className}
          label={label}
          help={help}
          name={name}
          value={field.value}
          onChange={onFieldChange(field)}
        />
      )}
    />
  )
}
EntityTypePickerController.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.string,
  onChange: PropTypes.func,
  shouldUnregister: PropTypes.bool,
}
