import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

/**
 * @typedef {object} PublicationsConfig The publications module configuration object
 * @property {boolean} hasPublicationTypes Whether the module has publication types
 * @property {boolean} hasPublicationCategories Whether the module has publication categories
 * @property {boolean} hasPublicationReligions Whether the module has publication religions
 * @property {boolean} hasPublishingHouses Whether the module has publishing houses
 * @property {PublicationType[]} publicationTypes The enabled publication types list
 * @property {object} fields The enabled fields
 */

/**
 * Hook response
 * @typedef {object} UsePublicationsConfig
 * @property {PublicationsConfig} publicationsConfig The configuration object
 * @property {Error} error The error object
 * @property {boolean} loading Whether the query is loading
 */

const defaultOptions = {
  enabled: true,
}

/**
 * Fetches an publications module's configuration
 * @param {object} options The hook options
 * @param {boolean} options.enabled Whether the query is enabled
 * @returns {UsePublicationsConfig} An object containing the publications module configuration and its status
 */
export default function usePublicationsConfig(options = defaultOptions) {
  const { enabled } = Object.assign({}, defaultOptions, options)

  /**
   * @type {import('react-query').UseQueryResult<PublicationsConfig,Error>} The query result
   */
  const { data, error, isLoading } = useQuery(
    ['publications', 'configure', enabled],
    () => getFetch('/api/publications/configure'),
    {
      enabled,
      staleTime: 60 * 60 * 1000, // 1 hour
    }
  )

  return {
    publicationsConfig: data ?? {},
    error,
    loading: isLoading,
  }
}
