import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { getFetch } from '@utils/fetcher'

/**
 * Publication Type object
 * @typedef {Object} PublicationType - The publication type object
 * @property {String} id - The ID of the publication type
 * @property {String} title - The title of the publication type (e.g. 'Journal')
 * @property {String} name - The short name of the publication type (e.g. 'journal')
 * @property {Boolean} hasIssues - Whether the publication type has issues
 * @property {Boolean} hasVolumes - Whether the publication type has volumes (depends on `hasIssues` being true)
 * @property {Boolean} enabled - Whether the publication type is enabled
 */

/**
 * Hook options
 * @typedef {Object} UsePublicationTypeOptions
 * @property {String} id The ID of the publication type to fetch
 * @property {String} name The name of the publication type to fetch
 * @property {Boolean} enabled Whether the query is enabled
 */

/**
 * Hook response
 * @typedef {Object} UsePublicationType
 * @property {PublicationType} publicationType - The publication type object
 * @property {String} publicationTypeId - The ID of the publication type
 * @property {Error} error - The error object
 * @property {Boolean} loading - Whether the query is loading
 */

const defaultOptions = {
  id: null,
  name: null,
  enabled: true,
}

/**
 * Hook to fetch a publication type by ID or name
 * @param {UsePublicationTypeOptions} options
 * @param {String} options.id The ID of the publication type to fetch
 * @param {String} options.name The name of the publication type to fetch
 * @param {Boolean} options.enabled Whether the query is enabled
 * @returns {UsePublicationType} An object containing the publication type and its status
 */
export default function usePublicationType(options = defaultOptions) {
  const { id, name, enabled } = Object.assign({}, defaultOptions, options)
  const params = useParams()

  const publicationTypeId = name || id || params?.publicationTypeId

  const { data, error, isLoading } = useQuery(
    ['publications-types', publicationTypeId, enabled],
    () => getFetch(`/api/publications-types/${publicationTypeId}`),
    { enabled: enabled && !!publicationTypeId }
  )

  return { publicationType: data, publicationTypeId, error, loading: isLoading }
}
