import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Divider from '@ui/layout/Divider'

import { ColorThemeSelect } from '../appearance/DesignOptions/components/ColorThemeSelect'

export function FlagOptions({ fieldPrefix, site }) {
  const { t } = useTranslation('designs/adventist')

  return (
    <>
      <Divider />
      <ColorThemeSelect
        help={t('flagThemeHelp')}
        label={t('flagTheme')}
        name={`${fieldPrefix}theme`}
        site={site}
      />
    </>
  )
}

FlagOptions.propTypes = {
  fieldPrefix: PropTypes.string,
  site: PropTypes.object.isRequired,
}
