import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useDesign } from '@app/designs/hooks/useDesign'
import { useUserPreference } from '@modules/auth/services/AuthService'
import useFeatureFlagEnabled from '@modules/feature-flags/hooks/useFeatureFlagEnabled'
import { useGlobalParams } from '@utils/urls'

import { getLayoutsUrl } from '../helpers/layoutUrls'
import {
  getAnalyticsSiteUrl,
  getAppearanceSiteUrl,
  getAuthenticationSiteUrl,
  getDataSourcesSiteUrl,
  getEditSiteUrl,
  getMenusUrl,
  getPagesUrl,
  getRedirectsSiteUrl,
  getSearchSiteUrl,
  getSitesUrl,
} from '../helpers/siteUrls'
import useSite from '../services/hooks/useSite'
import useSites from '../services/hooks/useSites'
import useWebPermissions from '../services/hooks/useWebPermissions'

const MenuGroup = React.lazy(() => import('@ui/navigation/Menu/MenuGroup'))
const MenuItem = React.lazy(() => import('@ui/navigation/Menu/MenuItem'))
const MenuDivider = React.lazy(() => import('@ui/navigation/Menu/MenuDivider'))

function SitesMenu({ permissions }) {
  const { siteId, action } = useGlobalParams(getSitesUrl('/:siteId/:action/*'))
  const [favorites] = useUserPreference(`web.sites.favorites`)
  const favoriteSitesIds =
    Object.entries(favorites || {}).reduce((acc, [id, enabled]) => {
      if (enabled) acc.push(id)
      return acc
    }, []) || []

  const { sites } = useSites({ limit: 20, ids: favoriteSitesIds })

  const isCurrentSiteListed = sites?.map(site => site._id).includes(siteId)

  const { site: currentSite } = useSite(siteId, {
    enabled: !isCurrentSiteListed,
  })

  if (!sites && !currentSite) return null

  return (
    <>
      {!isCurrentSiteListed && currentSite ? (
        <>
          <SiteItem
            site={currentSite}
            permissions={permissions}
            action={action}
            active
          />
          <MenuDivider />
        </>
      ) : null}
      {sites
        ? sites.map(site => (
            <SiteItem
              key={site.id}
              site={site}
              permissions={permissions}
              action={action}
              active={siteId === site.id}
            />
          ))
        : null}
    </>
  )
}
SitesMenu.propTypes = {
  permissions: PropTypes.object,
}

function SiteItem({ site, permissions, action, active }) {
  const { t } = useTranslation(['web/public', 'common'])

  const {
    canUpdateSites,
    canReadAnalytics,
    canReadPages,
    canReadLayouts,
    canReadRedirects,
    canReadMenus,
    canReadAuth,
    canReadSearch,
    canReadDataSources,
  } = permissions

  const designName = site.design?.name || 'base'

  const design = useDesign({ design: designName, site })
  const isUserLoginEnabled = useFeatureFlagEnabled('user-login', { site })
  const isSiteSearchEnabled = useFeatureFlagEnabled('site-search', { site })
  const isDataSourcesEnabled = useFeatureFlagEnabled('data-sources', { site })

  return (
    <MenuItem
      active={
        active &&
        action === (canReadPages({ siteId: site.id }) ? 'pages' : 'details')
      }
      expanded={active}
      label={site.name}
      icon="globe"
      key={site.id}
      href={canReadPages({ siteId: site.id }) ? getPagesUrl(site) : undefined}
    >
      {canUpdateSites({ siteId: site.id }) && (
        <MenuItem
          active={active && action === 'appearance'}
          label={t('appearance')}
          href={getAppearanceSiteUrl(site)}
          icon="pencil-paintbrush"
        />
      )}

      {canReadAnalytics({ siteId: site.id }) && site.analytics?.enabled && (
        <MenuItem
          active={active && action === 'analytics'}
          label={t('analytics')}
          href={getAnalyticsSiteUrl(site)}
          icon="chart-mixed"
        />
      )}

      {canReadRedirects({ siteId: site.id }) && (
        <MenuItem
          active={active && action === 'redirects'}
          label={t('redirects')}
          href={getRedirectsSiteUrl(site)}
          icon="directions"
        />
      )}

      {canReadMenus({ siteId: site.id }) && (
        <MenuItem
          active={active && action === 'menus'}
          label={t('menus')}
          href={getMenusUrl(site)}
          icon="bars"
        />
      )}

      {design.layouts && canReadLayouts({ siteId: site.id }) && (
        <MenuItem
          active={active && action === 'layouts'}
          label={t('layouts')}
          href={getLayoutsUrl(site)}
          icon="table-layout"
        />
      )}
      {isUserLoginEnabled && canReadAuth({ siteId: site.id }) && (
        <MenuItem
          active={active && action === 'auth'}
          label={t('authentication')}
          href={getAuthenticationSiteUrl(site)}
          icon="lock"
        />
      )}
      {isSiteSearchEnabled &&
        canReadSearch({ siteId: site.id }) &&
        site.search?.provider === 'awe' && (
          <MenuItem
            active={active && action === 'search'}
            label={t('common:search')}
            href={getSearchSiteUrl(site)}
            icon="magnifying-glass"
          />
        )}
      {isDataSourcesEnabled && canReadDataSources({ siteId: site.id }) && (
        <MenuItem
          active={active && action === 'data-sources'}
          label={t('dataSources')}
          href={getDataSourcesSiteUrl(site)}
          icon="plug"
        />
      )}
      {canUpdateSites({ siteId: site.id }) && (
        <MenuItem
          active={active && action === 'edit'}
          label={t('settings')}
          href={getEditSiteUrl(site)}
          icon="cog"
        />
      )}
    </MenuItem>
  )
}
SiteItem.propTypes = {
  site: PropTypes.object,
  permissions: PropTypes.object,
  action: PropTypes.string,
  active: PropTypes.bool,
}

export default function WebMenuGroup() {
  const { t } = useTranslation('web/public')
  const { pathname } = useLocation()
  const { siteId } = useGlobalParams(getSitesUrl('/:siteId/:action/*'))
  const webPermissions = useWebPermissions()

  const {
    canReadSites,
    canReadPresets,
    canReadAutomatedSites,
    canReadReports,
  } = webPermissions

  if (!canReadSites && !canReadPresets) return null

  const inWeb =
    pathname.startsWith('/web') && !location.pathname.startsWith('/web-auth')
  const inSites = pathname.startsWith(getSitesUrl())
  const inAutomatedSites = pathname.startsWith('/web/automated-sites')
  const inPagePresets = pathname.startsWith('/web/page-presets')
  const inReports = pathname.startsWith('/web/reports')

  return (
    <MenuGroup label={t('webTitle')} icon="globe" name="web" active={inWeb}>
      {canReadSites && (
        <>
          <SitesMenu permissions={webPermissions} />
          <MenuDivider />
          <MenuItem
            active={inSites && !siteId}
            label={t('manageSites')}
            icon="globe"
            href={getSitesUrl()} // this is currently /web/sites
          />
        </>
      )}

      {canReadAutomatedSites && (
        <MenuItem
          active={inAutomatedSites}
          label={t('manageAutomatedSites')}
          icon="robot"
          href="/web/automated-sites"
        />
      )}

      {canReadPresets && (
        <MenuItem
          active={inPagePresets}
          label={t('managePagePresets')}
          icon="stamp"
          href="/web/page-presets"
        />
      )}

      {canReadReports && (
        <MenuItem
          active={inReports}
          label={t('reports')}
          icon="chart-bar"
          href="/web/reports"
        />
      )}
    </MenuGroup>
  )
}
