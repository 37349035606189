import React from 'react'

import { config } from './config'

const ArticleHeaderSettings = React.lazy(() => import('./Settings'))
const ArticleHeaderBlock = React.lazy(() => import('./Block'))

export default function ArticleHeader(props) {
  return <ArticleHeaderBlock {...props} />
}

ArticleHeader.toolbarItem = {
  ...config,
  component: <ArticleHeader />,
}

ArticleHeader.craft = {
  displayName: 'ArticleHeader',
  props: {
    id: undefined,
    dynamic: false,
    filtered: false,
    sortField: 'publishedAt',
    sortOrder: 'desc',
    showAuthor: true,
    showLocation: true,
    showDate: true,
    showSubtitle: true,
    showCategories: true,
  },
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ArticleHeaderSettings,
  },
}
