import PropTypes from 'prop-types'
import React from 'react'

export default function Address({ address, className = '' }) {
  if (!address) return null

  return (
    <address
      className={`flex flex-col space-y-0.5 text-sm text-gray-500 ${className}`}
    >
      <span>
        {address.street}
        <br />
      </span>
      {address.additionalAddress && (
        <span>
          {address.additionalAddress}
          <br />
        </span>
      )}
      {(address.city || address.zip) && (
        <span>
          {address.zip} {address.city}
        </span>
      )}
    </address>
  )
}
Address.propTypes = {
  className: PropTypes.string,
  address: PropTypes.shape({
    street: PropTypes.node,
    additionalAddress: PropTypes.node,
    zip: PropTypes.node,
    city: PropTypes.node,
  }),
}
