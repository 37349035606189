import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  ModelPermissionsWrap,
  PermissionCheckbox,
} from '@modules/users/components/GroupPermissions'

export default function PaymentsPermissions() {
  const { t } = useTranslation('payments/public')
  const { watch } = useFormContext()

  const canReadPayments = watch('payment-methods[read]')
  const canReadProjects = watch('projects[read]')

  return (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
      <ModelPermissionsWrap label={t('paymentMethods')}>
        <PermissionCheckbox model="payment-methods" permission="read" />
        <PermissionCheckbox
          model="payment-methods"
          permission="create"
          disabled={!canReadPayments}
        />
        <PermissionCheckbox
          model="payment-methods"
          permission="update"
          disabled={!canReadPayments}
        />

        <PermissionCheckbox
          model="payment-methods"
          permission="delete"
          disabled={!canReadPayments}
        />
      </ModelPermissionsWrap>

      <ModelPermissionsWrap model="projects" label={t('projects')}>
        <PermissionCheckbox model="projects" permission="read" />
        <PermissionCheckbox
          model="projects"
          permission="create"
          disabled={!canReadProjects}
        />
        <PermissionCheckbox
          model="projects"
          permission="update"
          disabled={!canReadProjects}
        />
        <PermissionCheckbox
          model="projects"
          permission="delete"
          disabled={!canReadProjects}
        />
      </ModelPermissionsWrap>
    </div>
  )
}
