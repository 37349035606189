import React from 'react'

import PaymentsMenuGroup from './components/PaymentsMenuGroup'
import PaymentsPermissions from './components/PaymentsPermissions'
import blocks from './components/blocks/index'

const PaymentsRouter = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    name: 'payments',
    menu: PaymentsMenuGroup,
    routes: {
      private: {
        '/payments/*': { Component: PaymentsRouter },
      },
    },
    permissions: {
      payments: {
        name: 'payments',
        label: 'payments/public:paymentsAndDonations',
        icon: 'donate',
        PermissionsComponent: PaymentsPermissions,
        models: [
          {
            name: 'payment-methods',
            label: 'payments/public:payments',
          },
          {
            name: 'projects',
            label: 'payments/public:projects',
          },
        ],
      },
    },

    blocksResolver: blocks.resolvers,
    blocksToolbars: blocks.toolbars,
    translatableBlocks: blocks.translatableBlocks,
  }
}
