import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import useSite from '@modules/web/services/hooks/useSite'
import { SearchDropdown } from '@ui/data-entry/SearchDropdown'
import useDebounce from '@utils/useDebounce'

import useArticle from '../services/hooks/useArticle'
import useArticles from '../services/hooks/useArticles'

export function ArticlePicker({
  className = '',
  name,
  onChange,
  value,
  currentSiteOnly,
}) {
  const { t } = useTranslation('articles/public')
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const { site } = useSite()
  const { articles } = useArticles({
    search: debouncedSearchTerm,
    fetchOnSearch: true,
    siteId: currentSiteOnly ? site?.id : undefined,
  })
  const { article } = useArticle(value)

  const onSearch = useCallback(text => {
    setSearchTerm(text)
  }, [])

  return (
    <div className={`flex flex-col gap-4 ${className}`}>
      <SearchDropdown
        label={t('article')}
        placeholder={t('searchPlaceholder')}
        name={name}
        value={value}
        selectedLabel={article?.title}
        onChange={onChange}
        onSearch={onSearch}
      >
        {articles?.map(article => (
          <SearchDropdown.Option
            icon="file-invoice"
            key={article.id}
            label={article.title}
            name={name}
            value={article.id}
          />
        ))}
      </SearchDropdown>
    </div>
  )
}
ArticlePicker.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  currentSiteOnly: PropTypes.bool,
}

export default function ArticlePickerController({
  className = '',
  name,
  shouldUnregister,
}) {
  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <ArticlePicker
          className={className}
          name={name}
          value={field.value}
          onChange={field.onChange}
        />
      )}
    />
  )
}
ArticlePickerController.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  shouldUnregister: PropTypes.bool,
}
