import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import { useNode } from '@craftjs/core'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'

import { OrganizationFilter } from '@modules/articles/components/blocks/shared/OrganizationFilter'
import useCategoriesForTags from '@modules/categories/helpers/useCategoriesForTags'
import useFeatureFlagEnabled from '@modules/feature-flags/hooks/useFeatureFlagEnabled'
import BlockField from '@modules/web/components/ContentEditor/shared/BlockField'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { BlockDataSourceSelectField } from '@modules/web/components/data-sources/DataSourceSelect'
import useDataSource from '@modules/web/services/hooks/useDataSource'
import usePage from '@modules/web/services/hooks/usePage'
import useSite from '@modules/web/services/hooks/useSite'
import { CheckboxField } from '@ui/data-entry/Checkbox'
import Divider from '@ui/layout/Divider'

import { PublishedInTheLastSelectField } from '../../PublishedInTheLastSelect'
import CategoriesFields from './CategoriesFields'
import FlagsFields from './FlagsFields'
import PaginationFields from './PaginationFields'
import SortFields from './SortFields'

export function ArticlesFilterFields({
  disableCategoryField,
  disableFlagFields,
  disablePaginationFields,
  disablePublishedInTheLast,
  disableSortFields,
  fieldPrefix = '',
  filterCurrentArticle,
  showDataSources,
  showFilters,
  showOrganizationFilter,
  singular,
  siteId,
}) {
  const { t } = useTranslation(['articles/public', 'articles', 'web/sites'])
  const { page } = usePage()
  const { site } = useSite()
  const { resources } = usePageResources()

  const { dynamic, dynamicResource } = page ?? {}

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const isDynamicCategoryResourceEnabled = useFeatureFlagEnabled(
    'dynamic-category-resource',
    {
      site,
    }
  )

  const isDataSourcesEnabled = useFeatureFlagEnabled('data-sources', {
    site,
  })

  const useDynamicCategory =
    isDynamicCategoryResourceEnabled &&
    dynamicResource?.type === 'model' &&
    dynamicResource?.name === 'Category'

  const { categories } = useCategoriesForTags({
    ids:
      useDynamicCategory && Boolean(resources?.Category)
        ? [resources?.Category?._id]
        : [],
    enabled: useDynamicCategory && Boolean(resources?.Category),
  })

  const { dataSource } = useDataSource(data.dataSource)

  // Ensures that the selected flag is removed from flagExceptions
  useEffect(() => {
    if (
      data[`${fieldPrefix}flagExceptions`]?.includes(data[`${fieldPrefix}flag`])
    ) {
      actions.setProp(
        data =>
          (data[`${fieldPrefix}flagExceptions`] = data[
            `${fieldPrefix}flagExceptions`
          ].filter(id => id !== data[`${fieldPrefix}flag`]))
      )
    }
  }, [actions, data, fieldPrefix])

  const showFilterByDynamicResource =
    dynamic && (dynamicResource?.type === 'param' || useDynamicCategory)

  return (
    <>
      {showFilterByDynamicResource && (
        <BlockField name={`${fieldPrefix}filterByDynamicResource`}>
          {({ fieldProps, value, onDataChange }) => (
            <CheckboxField
              {...fieldProps}
              value={value}
              label={t('filterByDynamicResource', {
                resource: `${t(`articleParam-${dynamicResource.name}`)}${useDynamicCategory && categories?.[0] ? `: ${categories?.[0]?.title}` : ''}`,
              })}
              help={t('filterByDynamicResourceHelp')}
              onChange={onDataChange}
            />
          )}
        </BlockField>
      )}
      {showFilters && (
        <BlockField name={`${fieldPrefix}showFilters`}>
          {({ fieldProps, value, onDataChange }) => (
            <CheckboxField
              {...fieldProps}
              label={t('showFilters')}
              help={t('showFiltersHelp')}
              onChange={onDataChange}
              value={value}
            />
          )}
        </BlockField>
      )}
      {filterCurrentArticle && (
        <BlockField name={`${fieldPrefix}filterCurrentArticle`}>
          {({ fieldProps, value, onDataChange }) => (
            <CheckboxField
              {...fieldProps}
              label={t('filterCurrentArticle')}
              help={t('filterCurrentArticleHelp')}
              onChange={onDataChange}
              value={value}
            />
          )}
        </BlockField>
      )}

      {!disableSortFields && (
        <>
          <SortFields fieldPrefix={fieldPrefix} singular={singular} />
          <Divider />
        </>
      )}

      {isDataSourcesEnabled && showDataSources && (
        <BlockDataSourceSelectField
          allowedTypes={['articles']}
          name="dataSource"
          placeholder={t('web/sites:selectDataSource')}
          help={t('articlesDataSourceHelp')}
        />
      )}

      {!(
        useDynamicCategory && get(data, `${fieldPrefix}filterByDynamicResource`)
      ) &&
        !disableCategoryField && (
          <CategoriesFields
            dataSource={dataSource}
            fieldPrefix={fieldPrefix}
            singular={singular}
            siteId={siteId}
          />
        )}

      {!disableFlagFields && dynamicResource?.name !== 'flag' && (
        <FlagsFields
          dataSource={dataSource}
          fieldPrefix={fieldPrefix}
          singular={singular}
          siteId={siteId}
        />
      )}

      {showOrganizationFilter && (
        <OrganizationFilter dataSource={dataSource} fieldPrefix={fieldPrefix} />
      )}

      {!disablePaginationFields && (
        <>
          <Divider />
          <PaginationFields fieldPrefix={fieldPrefix} />
        </>
      )}

      {!disablePublishedInTheLast && (
        <BlockField name={`${fieldPrefix}publishedInTheLast`}>
          {({ fieldProps, value, onDataChange }) => (
            <PublishedInTheLastSelectField
              {...fieldProps}
              onChange={e => onDataChange(e.target.value)}
              value={value}
            />
          )}
        </BlockField>
      )}
    </>
  )
}

ArticlesFilterFields.propTypes = {
  disableCategoryField: PropTypes.bool,
  disableFlagFields: PropTypes.bool,
  disablePaginationFields: PropTypes.bool,
  disablePublishedInTheLast: PropTypes.bool,
  disableSortFields: PropTypes.bool,
  fieldPrefix: PropTypes.string,
  filterCurrentArticle: PropTypes.bool,
  showDataSources: PropTypes.bool,
  showFilters: PropTypes.bool,
  showOrganizationFilter: PropTypes.bool,
  singular: PropTypes.bool,
  siteId: PropTypes.string,
}
