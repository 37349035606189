import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import { InputField as Input } from '@ui/data-entry/Input'

export default function LimitFields({ showSkip = true }) {
  const { t } = useTranslation('articles/public')

  const { actions, data } = useNode(node => ({
    data: node.data.props,
  }))

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  return (
    <>
      <Input
        label={t('limit')}
        help={t('limitHelp')}
        onChange={onFieldUpdate('limit', value => {
          return value ? parseInt(isNaN(value) || value < 0 ? 0 : value) : 0
        })}
        value={data.limit}
        type="number"
      />

      {showSkip && (
        <Input
          label={t('skip')}
          help={t('skipHelp')}
          onChange={onFieldUpdate('skip', value => {
            return value ? parseInt(isNaN(value) || value < 0 ? 0 : value) : 0
          })}
          value={data.skip}
          type="number"
        />
      )}
    </>
  )
}
LimitFields.propTypes = {
  showSkip: PropTypes.bool,
}
