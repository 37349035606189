import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'
import isLanguageRTL from '@modules/languages/helpers/isLanguageRTL'
import ToggleButton from '@ui/buttons/ToggleButton'
import { FieldLabel } from '@ui/data-entry/Field'
import Tooltip from '@ui/feedback/Tooltip'
import { slugify } from '@utils/strings'
import { isFunction } from '@utils/types'

import AutomaticTranslationButton from './AutomaticTranslationButton'
import { useTranslationTarget } from './Context'
import { TranslationSourceTextContext } from './Context/contexts'
import CopySourceButton from './CopySourceButton'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export default function TranslationField({
  automaticTranslation = true,
  children,
  label,
  missing,
  name,
  onCustomChange,
  required,
  showCopyButton = true,
  slugField,
  sourcePreview,
  sourceText,
  translationClassName,
  type,
}) {
  const { t } = useTranslation('publications')
  const { setValue } = useFormContext()

  // Get the language code from the target language
  const targetLanguage = useTranslationTarget()
  const lang = targetLanguage?.replace('_', '-').split('-')[0]

  // And determine if language is RTL
  const isRTL = isLanguageRTL(lang)

  const preview =
    sourcePreview || (typeof sourceText !== 'object' ? sourceText : undefined)

  const onChange = React.useCallback(
    (shouldValidate = false) =>
      (name, text) => {
        if (onCustomChange) {
          onCustomChange(name, text)
        } else {
          setValue(name, text, { shouldValidate, shouldDirty: true })
          setValue(slugField, slugify(text))
        }
      },
    [onCustomChange, setValue, slugField]
  )

  if (!preview) return null

  // Add language and RTL props to children automatically
  const childrenWithProps = React.Children.map(
    children,
    child => child && React.cloneElement(child, { lang, isRTL })
  )

  return (
    <TranslationSourceTextContext.Provider value={sourceText}>
      <div className="group/field flex flex-col transition-colors duration-300 ease-in-out focus-within:bg-primary-100 md:flex-row">
        <div className="flex flex-col gap-2 bg-gray-50 p-6 transition-colors duration-300 ease-in-out group-focus-within/field:!bg-primary-50 lg:w-1/2">
          {label && <FieldLabel label={label} name={name} />}
          {sourcePreview || sourceText ? (
            <div className="flex justify-between gap-2">
              {preview}
              {showCopyButton && (
                <CopySourceButton
                  name={name}
                  text={sourceText}
                  onCustomChange={slugField ? onChange(false) : onCustomChange}
                />
              )}
            </div>
          ) : (
            <p className="text-gray-400">{t('notSet')}</p>
          )}
        </div>
        <div
          className={`relative flex border-s-4 p-4 transition-colors duration-300 ease-in-out lg:w-1/2 ${
            required
              ? missing
                ? 'border-danger-400 bg-danger-50/80'
                : 'group-focus-within/field:border-primary-300'
              : ''
          }`}
        >
          <div className={`flex grow flex-col ${translationClassName}`}>
            {automaticTranslation ? (
              <AutomaticTranslationWrapper
                name={name}
                sourceText={sourceText}
                onCustomChange={slugField ? onChange(true) : onCustomChange}
                type={type}
              >
                {childrenWithProps}
              </AutomaticTranslationWrapper>
            ) : (
              childrenWithProps
            )}
          </div>
        </div>
      </div>
    </TranslationSourceTextContext.Provider>
  )
}
TranslationField.propTypes = {
  aiImproveText: PropTypes.bool,
  automaticTranslation: PropTypes.bool,
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  missing: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onCustomChange: PropTypes.func,
  required: PropTypes.bool,
  showCopyButton: PropTypes.bool,
  slugField: PropTypes.string,
  sourcePreview: PropTypes.node,
  sourceText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  translationClassName: PropTypes.string,
  type: PropTypes.oneOf(['text', 'json']),
}

const fieldStyles = {
  default:
    'bg-gray-200 group-focus-within:bg-primary-200 group-focus-within:text-primary-900',
  structure: 'bg-gray-100',
  hidden:
    'bg-warn-100 group-focus-within:bg-warn-200 group-focus-within:text-warn-900',
}

export function TranslationFieldGroup({
  children,
  hidden,
  label,
  name,
  required,
  showVisibilityToggle,
  structureOnly,
}) {
  const { t } = useTranslation('translations')

  const { watch } = useFormContext()

  const translationHiddenWatch = watch(`${name}[translationHidden]`)

  const isHidden = translationHiddenWatch || (translationHiddenWatch && hidden)

  const variantClass =
    fieldStyles[isHidden ? 'hidden' : structureOnly ? 'structure' : 'default']

  return (
    <div className="group mt-2 flex flex-col border-t-2 first:mt-0 first:border-t-0">
      <div
        className={`flex items-center justify-between gap-4 px-4 py-2 transition-colors duration-300 ease-in-out ${variantClass}`}
      >
        <div className="flex items-center justify-start gap-2">
          {isHidden && (
            <Tooltip
              content={<p>{t('contentHidden')}</p>}
              placement="bottom-start"
            >
              <Icon name="eye-slash" className="text-warn-500" />
            </Tooltip>
          )}
          <span className={`font-semibold`}>{label}</span>
          {required && (
            <span className="text-danger-600" required={'required'}>
              <Icon name="asterisk" />
            </span>
          )}
        </div>
        {showVisibilityToggle && (
          <span className="self-end">
            <Controller
              name={`${name}[translationHidden]`}
              defaultValue={Boolean(hidden)}
              render={({ field }) => (
                <div className="flex items-center gap-2">
                  <Tooltip
                    content={
                      <p>
                        {t('toggleVisibility')}
                        <span className="block text-sm font-normal">
                          {t('current')}:{' '}
                          {t(field.value ? 'hidden' : 'visible')}
                        </span>
                      </p>
                    }
                  >
                    <ToggleButton
                      active={!field.value}
                      size="sm"
                      onChange={() => field.onChange(!field.value)}
                    />
                  </Tooltip>
                </div>
              )}
            />
          </span>
        )}
      </div>
      {children?.length ? <div>{children}</div> : null}
    </div>
  )
}
TranslationFieldGroup.propTypes = {
  children: PropTypes.node,
  hidden: PropTypes.bool,
  label: PropTypes.node.isRequired,
  name: PropTypes.string,
  required: PropTypes.bool,
  showVisibilityToggle: PropTypes.bool,
  structureOnly: PropTypes.bool,
}

export function AutomaticTranslationWrapper({
  children,
  name,
  onCustomChange,
  sourceText,
  type,
}) {
  const { setValue } = useFormContext()
  const { entity } = useCurrentEntity()
  const { automaticTranslations } = entity?.config || {}

  const onChange = useCallback(
    value => {
      if (isFunction(onCustomChange)) {
        onCustomChange(name, value)
      } else {
        setValue(name, value, { shouldValidate: true, shouldDirty: true })
      }
    },
    [name, onCustomChange, setValue]
  )

  const enableAutomaticTranslation =
    automaticTranslations?.enabled && sourceText

  return (
    <div className="relative">
      {React.Children.map(children, child =>
        React.cloneElement(child, {
          labelExtra: enableAutomaticTranslation ? (
            <AutomaticTranslationButton
              text={sourceText}
              onChange={onChange}
              type={type}
            />
          ) : (
            <div className="h-6"></div>
          ),
        })
      )}
    </div>
  )
}
AutomaticTranslationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  disableAutomatic: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onCustomChange: PropTypes.func,
  sourceText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  type: PropTypes.oneOf(['text', 'json']),
}
