import PropTypes from 'prop-types'
import React from 'react'

import HtmlHeader from '@ui/helpers/HtmlHeader'

/**
 * A header with design elements built in.
 */
export default function Header({
  className = '',
  subtitle,
  kicker,
  title,
  headTitle,
}) {
  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      {headTitle && (
        <HtmlHeader title={typeof headTitle === 'string' ? headTitle : title} />
      )}
      {kicker && (
        <h3 className="text-xs font-bold uppercase leading-3 tracking-wide text-primary-700 lg:text-base">
          {kicker}
        </h3>
      )}
      <h1 className="break-normal text-2xl font-extrabold lg:text-4xl">
        {title}
      </h1>
      {subtitle && (
        <h2 className="text-xs font-semibold leading-5 text-gray-500 lg:text-sm">
          {subtitle}
        </h2>
      )}
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  kicker: PropTypes.node,
  subtitle: PropTypes.node,
  title: PropTypes.node.isRequired,
  headTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}
