import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import Dropdown from '@ui/buttons/Dropdown'

import usePage from '../../services/hooks/usePage'
import { getDynamicResourceIdentifier } from '../../utils/dynamicResource'
import { useDynamicResource } from './context'

const fieldTypeIcons = {
  param: 'code-simple',
  string: 'font',
  richtext: 'font',
  object: 'brackets-curly',
  image: 'image',
  file: 'file',
  array: 'brackets',
}

export default function DynamicInput({
  field,
  fieldType,
  hasLabel,
  onFieldSelect,
  value,
  wrapClass,
  className = '',
  ...props
}) {
  const { t } = useTranslation()
  const { page } = usePage()
  const resource = useDynamicResource()
  const { fields, type, label } = resource || {}

  const availableFields = useMemo(() => {
    if (!type || type === 'xml' || (type === 'model' && !fields)) {
      return null
    }

    if (type === 'param') {
      return [
        {
          key: getDynamicResourceIdentifier(page),
          type,
          label,
        },
      ]
    }

    return Object.keys(fields)
      .filter(field => fields[field].type === fieldType)
      .map(key => ({ key, ...fields[key] }))
  }, [type, fields, page, label, fieldType])

  const _onFieldSelect = useCallback(
    field => {
      const fieldKey = `{${resource.name}.${field.key}}`

      let fieldValue

      switch (field.type) {
        case 'param': {
          fieldValue = `${value}{${field.key}}`
          break
        }
        case 'string': {
          fieldValue = `${value}${fieldKey}`
          break
        }
        case 'array': {
          fieldValue = [...value, fieldKey]
          break
        }
        case 'image': {
          fieldValue = {
            resource: resource.name,
            field: field.key,
          }
          break
        }
        default: {
          break
        }
      }

      // If a new value has been set
      if (fieldValue) {
        // update the field
        onFieldSelect(fieldValue)
      }
    },
    [onFieldSelect, value, resource]
  )

  return (
    <div className={`flex flex-row items-stretch space-x-2 ${wrapClass}`}>
      <div className="flex-1">
        {React.createElement(field, { value, className, ...props })}
      </div>
      {fieldType === 'image' && value?.resource && (
        <div
          className={`${hasLabel ? 'mt-8' : undefined}`}
        >{`{${value.resource}.${value.field}}`}</div>
      )}
      {availableFields?.length > 0 && (
        <div className={hasLabel ? 'mt-8' : undefined}>
          <Dropdown
            alignment="bottom-end"
            button={<Button icon="brackets-curly" size="xs" />}
          >
            {availableFields.map(field => (
              <Dropdown.Item
                label={t(field.label)}
                icon={fieldTypeIcons[field.type]}
                onClick={() => _onFieldSelect(field)}
                key={field.key}
              />
            ))}
          </Dropdown>
        </div>
      )}
    </div>
  )
}
DynamicInput.propTypes = {
  className: PropTypes.string,
  field: PropTypes.func.isRequired,
  fieldType: PropTypes.oneOf([
    'array',
    'boolean',
    'image',
    'file',
    'number',
    'richtext',
    'string',
    'object',
  ]).isRequired,
  onFieldSelect: PropTypes.func,
  hasLabel: PropTypes.bool,
  value: PropTypes.any,
  wrapClass: PropTypes.string,
}
