import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import serviceTypes from '@modules/entities/config/serviceTypes'
import useServices from '@modules/entities/services/hooks/useServices'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import useSite from '@modules/web/services/hooks/useSite'
import Message from '@ui/data-display/Message'
import { MultipleSelectField } from '@ui/data-entry/MultipleSelect'
import { SelectField } from '@ui/data-entry/Select'

import useEntity from '../../../services/hooks/useEntity'
import { EntityPickerField } from '../../entities/EntityPicker'
import Amenities from './shared/Amenities'

const config = {
  name: 'ChurchServices',
  label: 'entities/public:ChurchServices',
  type: 'plugin',
  icon: 'church',
  component: <ChurchServices />,
}

function getPlaceholder() {
  return {
    image: {},
    name: '[Church name]',
    location: {
      coordinates: [0, 0],
    },
    description: '[Church description.]',
  }
}

export default function ChurchServices({ churchId, showAmenities, dynamic }) {
  const { t } = useTranslation('entities/public')
  const { entity } = useEntity({ churchId: dynamic ? null : churchId })

  const { name, amenities } = entity || getPlaceholder()

  return (
    <Content icon={config.icon} title={t('ChurchServices')} locked={dynamic}>
      <div className="flex flex-col gap-6 px-4 pb-4">
        <h1 className="text-3xl font-bold">{name}</h1>
        {showAmenities && <Amenities amenities={amenities} />}
      </div>
    </Content>
  )
}
ChurchServices.propTypes = {
  dynamic: PropTypes.bool,
  churchId: PropTypes.string,

  showAmenities: PropTypes.bool,
}
ChurchServices.toolbarItem = config

function ChurchServicesSettings() {
  const { t } = useTranslation(['entities/public', 'entities'])

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { site } = useSite()
  const {
    count: servicesCount,
    services,
    loading: servicesLoading,
  } = useServices({
    entityId: data.entitySource === 'custom' ? data.entityId : site?.entity?.id,
  })
  const { entity } = useEntity({ id: data.entityId })

  const serviceOptions = useMemo(
    () =>
      services?.reduce?.((acc, service) => {
        if (service.deleted || !service.enabled) {
          return acc
        }
        return [...acc, service.id]
      }, []),
    [services]
  )

  const onAddItem = useCallback(
    item =>
      actions.setProp(
        props => (props.services = [...(data.services ?? []), item])
      ),
    [actions, data.services]
  )

  const onRemoveItem = useCallback(
    item =>
      actions.setProp(
        props =>
          (props.services = (data.services ?? []).filter(i => i !== item))
      ),
    [actions, data.services]
  )

  const onEntityIdChange = useCallback(
    value => {
      actions.setProp(props => (props.entityId = value))
    },
    [actions]
  )

  const staticEntity =
    data.entitySource === 'custom'
      ? entity
      : !data.entitySource || data.entitySource === 'site'
        ? site?.entity
        : null

  const entitySource = data.entitySource || 'site'

  return (
    <SettingsWrap title={t('ChurchServices')} help={t('ChurchServicesHelp')}>
      <SelectField
        name="entitySource"
        label={t('entityType')}
        help={t('entitySourceHelp')}
        value={entitySource}
        onChange={event => {
          actions.setProp(props => (props.entitySource = event.target.value))
        }}
      >
        <SelectField.Option value="site" label={t('site')} />
        <SelectField.Option value="dynamic" label={t('dynamic')} />
        <SelectField.Option value="custom" label={t('custom')} />
      </SelectField>

      {data.entitySource === 'custom' && (
        <EntityPickerField
          label={t('church')}
          onChange={onEntityIdChange}
          value={data.entityId}
          types={['church']}
        />
      )}

      {['site', 'custom'].includes(entitySource) && (
        <>
          {staticEntity ? (
            <Message title={t('siteEntity')} type="info">
              {staticEntity?.name}
            </Message>
          ) : data.entitySource === 'custom' ? (
            <Message title={t('noEntity')} type="warn">
              {t('noEntityDescription')}
            </Message>
          ) : (
            <Message title={t('noSiteEntity')} type="warn">
              {t('noSiteEntityDescription')}
            </Message>
          )}
        </>
      )}

      {['site', 'custom'].includes(entitySource) && (
        <SelectField
          name="entityServiceType"
          label={t('entityServiceType')}
          help={t('entityServiceTypeHelp')}
          value={data.entityServiceType || 'type'}
          onChange={event => {
            actions.setProp(
              props => (props.entityServiceType = event.target.value)
            )
          }}
        >
          <SelectField.Option value="type" label={t('servicesByType')} />
          <SelectField.Option value="custom" label={t('customServices')} />
        </SelectField>
      )}

      {staticEntity &&
        !servicesLoading &&
        data.entityServiceType === 'custom' && (
          <div className="space-y-2">
            {servicesCount > 0 ? (
              <MultipleSelectField
                asText
                label={t('services')}
                help={t('churchServicesSelectHelp')}
                name="services"
                items={data?.services || []}
                options={serviceOptions}
                onAddItem={onAddItem}
                onRemoveItem={onRemoveItem}
                getLabel={id => {
                  return services?.find?.(s => s.id === id)?.title
                }}
              />
            ) : (
              <Message type="warn">{t('noServices')}</Message>
            )}
          </div>
        )}

      {(entitySource === 'dynamic' || data.entityServiceType !== 'custom') && (
        <SelectField
          name="serviceType"
          label={t('serviceType')}
          help={t('serviceTypeHelp')}
          value={data.serviceType || 'type'}
          onChange={event => {
            actions.setProp(props => (props.serviceType = event.target.value))
          }}
        >
          <SelectField.Option value="all" label={t('serviceTypeAll')} />
          <SelectField.Option value="empty" label={t('serviceTypeEmpty')} />
          {serviceTypes?.map((serviceType, key) => (
            <SelectField.Option
              key={key}
              value={serviceType.value}
              label={t(`entities:${serviceType.label}`)}
            />
          ))}
        </SelectField>
      )}
    </SettingsWrap>
  )
}

ChurchServices.craft = {
  displayName: 'ChurchServices',
  props: {
    services: [],
  },
  custom: {
    type: config.type,
    resources: ['churchServices'],
    i18nNamespaces: ['church-finder'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ChurchServicesSettings,
  },
}
