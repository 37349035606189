import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'

import { useTranslation } from 'react-i18next'

import Tooltip from '@ui/feedback/Tooltip'

import { FieldRequired } from './FieldRequired'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

/**
 * Tooltip max width classes
 */
const tooltipWidthClasses = {
  xs: 'max-w-xs',
  sm: 'max-w-sm',
  md: 'max-w-md',
  lg: 'max-w-lg',
  xl: 'max-w-xl',
}

/**
 * Field label component
 * @param {Object} props - Component props
 * @param {Boolean} props.disabled - Whether the field is disabled
 * @param {Node} props.extra - Extra content
 * @param {String} props.label - Label text
 * @param {Boolean} props.lightLabel - Whether the label should be light
 * @param {Boolean} props.missing - Whether the field is missing
 * @param {String} props.name - Field name
 * @param {Boolean} props.required - Whether the field is required
 * @param {Node} props.tooltip - Tooltip content
 * @param {String} props.tooltipMaxWidth - Tooltip max width (xs, sm, md, lg, xl)
 * @param {String} props.tooltipPlacement - Tooltip placement
 * @returns {ReactElement}
 */
export const FieldLabel = forwardRef(
  (
    {
      disabled,
      extra,
      label,
      lightLabel,
      missing,
      name,
      required,
      tooltip,
      tooltipMaxWidth,
      tooltipPlacement,
    },
    ref
  ) => {
    const { t } = useTranslation('translations')
    const disabledClass = disabled ? 'opacity-50' : 'opacity-100'
    const tooltipSizeClass = tooltipWidthClasses[tooltipMaxWidth] ?? ''

    return (
      <div className="flex justify-between" ref={ref}>
        <div
          className={`flex flex-grow flex-row items-center gap-2 ${disabledClass}`}
        >
          <label
            className={`cursor-pointer leading-tight ${
              lightLabel ? '' : 'font-semibold'
            }`}
            htmlFor={name}
          >
            {label}
          </label>
          {tooltip && (
            <Tooltip
              content={tooltip}
              placement={tooltipPlacement}
              contentClass={tooltipSizeClass}
              className="-my-1"
            >
              <Icon
                name="question-circle"
                className="cursor-help text-info-500 opacity-50 hover:opacity-100"
              />
            </Tooltip>
          )}
          {required && <FieldRequired />}
          {missing && (
            <div className="flex items-start justify-center">
              <Tooltip
                content={t('missingFieldHelp')}
                placement={tooltipPlacement}
              >
                <Icon
                  name="exclamation-triangle"
                  className="text-sm text-danger-600"
                />
              </Tooltip>
            </div>
          )}
        </div>
        {extra}
      </div>
    )
  }
)
FieldLabel.propTypes = {
  disabled: PropTypes.bool,
  extra: PropTypes.node,
  label: PropTypes.node,
  lightLabel: PropTypes.bool,
  missing: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool, // TODO: Refactor this to be able to use this for other than missing fields in translations (@mikeb-hm)
  tooltip: PropTypes.node,
  tooltipMaxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  tooltipPlacement: PropTypes.oneOf(['top', 'left', 'right']),
}
