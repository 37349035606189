import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'

import useChannel from '../../services/hooks/useChannel'
import useEpisode from '../../services/hooks/useEpisode'
import useShow from '../../services/hooks/useShow'
import ChannelSelect from '../ChannelSelect'
import { EpisodePicker } from '../EpisodePicker'
import AudioPlayerVariant from '../shared/AudioPlayerVariant'

const config = {
  name: 'EpisodeAudioPlayer',
  label: 'media-library/public:episodeAudioPlayer',
  type: 'plugin',
  icon: 'headphones',
  component: <EpisodeAudioPlayer />,
}

export default function EpisodeAudioPlayer({ channelId, showId, id }) {
  const { t } = useTranslation('media-library/public')

  const { resources } = usePageResources()
  const { channel } = useChannel(channelId)
  const { show } = useShow(showId)
  const { episode } = useEpisode(id)

  const _channel = resources?.Channel ? resources.Channel : channel
  const _show = resources?.Show ? resources.Show : show
  const _epidose = resources?.Episode ? resources.Episode : episode

  return (
    <Content icon={config.icon} title={t('episodeAudioPlayer')}>
      <Box space="xs">
        <ul className="ml-8 list-disc text-sm">
          <li>
            {t('channel')}: {_channel?.title}
          </li>
          <li>
            {t('show')}: {_show?.title || t('dynamic')}
          </li>
          <li>
            {t('episode')}: {_epidose?.title || t('dynamic')}
          </li>
        </ul>
        <div className="aspect-h-9 aspect-w-16 w-full border bg-gray-100">
          <div className="flex h-full w-full items-center justify-center text-gray-500">
            Audio player
          </div>
        </div>
      </Box>
    </Content>
  )
}
EpisodeAudioPlayer.propTypes = {
  channelId: PropTypes.string,
  showId: PropTypes.string,
  id: PropTypes.string,
  showImage: PropTypes.bool,
  showTitle: PropTypes.bool,
  showKicker: PropTypes.bool,
  showSubtitle: PropTypes.bool,
  showAbstract: PropTypes.bool,
  showDescription: PropTypes.bool,
}
EpisodeAudioPlayer.toolbarItem = config

function EpisodeAudioPlayerSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(data.channelId || data.id)

  const { resources } = usePageResources()

  const onEpisodeIdChange = useCallback(
    value => {
      actions.setProp(props => (props.id = value))
    },
    [actions]
  )

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap
      title={t('episodeAudioPlayer')}
      help={t('episodeAudioPlayerHelp')}
    >
      <Box space="xl">
        {resources?.Channel && !resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Channel?.title}
          </Message>
        )}
        {resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Show?.title}
          </Message>
        )}
        {resources?.Episode && (
          <Message className="text-sm" type="warn">
            {resources?.Episode?.title}
          </Message>
        )}
        <AudioPlayerVariant />
        <Divider />

        <Checkbox
          label={t('showImage')}
          onChange={value => {
            actions.setProp(props => (props.showImage = value))
          }}
          value={data.showImage}
          name="showImage"
        />
        <Checkbox
          label={t('showTitle')}
          onChange={value => {
            actions.setProp(props => (props.showTitle = value))
          }}
          value={data.showTitle}
          name="showTitle"
        />
        <Checkbox
          label={t('showSubtitle')}
          onChange={value => {
            actions.setProp(props => (props.showSubtitle = value))
          }}
          value={data.showSubtitle}
          name="showSubtitle"
        />
        <Checkbox
          label={t('showKicker')}
          onChange={value => {
            actions.setProp(props => (props.showKicker = value))
          }}
          value={data.showKicker}
          name="showKicker"
        />
        <Checkbox
          label={t('showAbstract')}
          onChange={value => {
            actions.setProp(props => (props.showAbstract = value))
          }}
          value={data.showAbstract}
          name="showAbstract"
        />
        <Checkbox
          label={t('showDescription')}
          onChange={value => {
            actions.setProp(props => (props.showDescription = value))
          }}
          value={data.showDescription}
          name="showDescription"
        />

        {!resources?.Show && (
          <>
            <Divider />
            <Checkbox
              label={t('pickEpisodeManually')}
              help={t('pickEpisodeManuallyHelp')}
              onChange={value => setShowPicker(value)}
              value={showPicker}
            />
            {showPicker && (
              <Box space="lg">
                {!resources?.Channel && (
                  <ChannelSelect
                    label={t('channel')}
                    onChange={onChannelUpdate('channelId')}
                    value={data.channelId}
                    controlled={false}
                  />
                )}
                <EpisodePicker
                  channelId={data.channelId || resources?.Channel?.id}
                  onChange={onEpisodeIdChange}
                  value={data.id}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </SettingsWrap>
  )
}

EpisodeAudioPlayer.craft = {
  displayName: 'EpisodeAudioPlayer',
  props: {
    channelId: undefined,
    id: undefined,
    showImage: true,
    showKicker: true,
    showTitle: true,
    showSubtitle: true,
    showAbstract: true,
    showDescription: true,
  },
  custom: {
    type: config.type,
    resources: ['episodeAudioPlayer', 'episode'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EpisodeAudioPlayerSettings,
  },
}
