import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import { EventCategoryPicker } from '../../category/CategoryPicker'
import { OrganizerPicker } from '../../organizer/OrganizerPicker'
import { RegionPicker } from '../../region/RegionPicker'

export function EntityFields() {
  const { t } = useTranslation('events/public')

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  return (
    <>
      <OrganizerPicker
        multiple
        name="organizers"
        label={t('organizers')}
        help={t('organizersHelp')}
        value={data.organizers ?? []}
        onChange={value => actions.setProp(props => (props.organizers = value))}
      />
      <RegionPicker
        multiple
        name="regions"
        label={t('regions')}
        help={t('regionsHelp')}
        value={data.regions ?? []}
        onChange={value => actions.setProp(props => (props.regions = value))}
      />
      <EventCategoryPicker
        multiple
        name="categories"
        label={t('categories')}
        help={t('categoriesHelp')}
        value={data.categories ?? []}
        onChange={value => actions.setProp(props => (props.categories = value))}
      />
    </>
  )
}
