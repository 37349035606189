import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import SitePickerController from '@modules/web/components/sites/SitePicker'

export function IndexSiteTaskFields() {
  const { t } = useTranslation(['search'])

  return (
    <SitePickerController
      name="settings.site"
      label={t('site')}
      help={t('indexSiteTaskSiteHelp')}
      required
    />
  )
}

IndexSiteTaskFields.propTypes = {
  task: PropTypes.object.isRequired,
}
