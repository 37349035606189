import PropTypes from 'prop-types'
import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'

export default function LinkingFields({
  showListPagePicker = true,
  showRegistrationPagePicker,
  detailPageHelp,
}) {
  const { t } = useTranslation('events/public')

  const { actions, data } = useNode(node => ({
    data: node.data.props,
  }))

  return (
    <>
      <PagePicker
        label={t('detailPageId')}
        help={detailPageHelp ?? t('detailPageIdHelp')}
        value={data.detailPageId}
        onChange={pageId =>
          actions.setProp(props => (props.detailPageId = pageId))
        }
        condition={page =>
          page.dynamic && getDynamicResourceIdentifier(page) === 'Event'
        }
      />

      {showListPagePicker && (
        <PagePicker
          label={t('listPageId')}
          help={t('listPageIdHelp')}
          value={data.listPageId}
          onChange={pageId =>
            actions.setProp(props => (props.listPageId = pageId))
          }
        />
      )}

      {showRegistrationPagePicker && (
        <PagePicker
          label={t('registrationPageId')}
          help={t('registrationPageIdHelp')}
          value={data.registrationPageId}
          onChange={pageId =>
            actions.setProp(props => (props.registrationPageId = pageId))
          }
        />
      )}
    </>
  )
}
LinkingFields.propTypes = {
  showListPagePicker: PropTypes.bool,
  showRegistrationPagePicker: PropTypes.bool,
  detailPageHelp: PropTypes.string,
}
