import PropTypes from 'prop-types'
import React from 'react'

import { Controller, useFormContext } from 'react-hook-form'

import Clickable from '@ui/helpers/Clickable'
import { isFunction } from '@utils/types'

import Field from './Field'
import { useRules } from './validationHooks'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const typeStyles = {
  default: 'text-gray-700 bg-gray-200 borde-gray-300',
  white: 'text-gray-700 bg-white borde-gray-300',
  black: 'text-gray-100 bg-black borde-gray-700',
  success: 'text-success-700 bg-success-200 borde-success-300',
  info: 'text-info-700 bg-info-200 borde-info-300',
  warn: 'text-warn-700 bg-warn-200 borde-warn-300',
  danger: 'text-danger-700 bg-danger-200 borde-danger-300',
  primary: 'text-primary-700 bg-primary-200 borde-primary-300',
}

function Tag({
  label,
  shaking,
  selected,
  onClick,
  showRemove,
  type = 'default',
}) {
  if (typeof label !== 'string') return null
  const typeClasses = typeStyles[type] || typeStyles.default

  return (
    <React.Suspense fallback={null}>
      <div
        className={`rounded px-[1px] pt-px ring-0 ${
          selected
            ? 'animate-wiggle ring-2 ring-danger-500'
            : shaking
              ? 'animate-wiggle ring-2 ring-warn-500'
              : 'animate-none border-transparent'
        }`}
      >
        <Clickable
          className={`flex flex-row items-center space-x-2 rounded border px-2 py-1 ${typeClasses}`}
          onClick={onClick}
        >
          <div className="text-sm font-semibold">{label}</div>
          {showRemove && <Icon name="times" />}
        </Clickable>
      </div>
    </React.Suspense>
  )
}
Tag.propTypes = {
  label: PropTypes.node,
  shaking: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  showRemove: PropTypes.bool,
  type: PropTypes.oneOf([
    'default',
    'success',
    'info',
    'warn',
    'danger',
    'primary',
    'black',
    'white',
  ]),
}

export function Tags({
  asText,
  className = '',
  disabled,
  fullWidth = true,
  getItemLabel,
  id,
  items = [],
  name,
  onAdd,
  onRemove,
  protectedItems = [],
  suggestions,
}) {
  const disabledClass = disabled ? 'opacity-50' : ''
  const fullWidthClass = fullWidth ? 'w-full' : ''
  const inputRef = React.useRef()
  const [text, setText] = React.useState('')
  const [existingItem, setExistingItem] = React.useState(null)
  const [selectedItem, setSelectedItem] = React.useState(null)

  const onAddItem = React.useCallback(
    item => {
      if (typeof onAdd === 'function') {
        const prevItem = items.find(i =>
          asText ? i === item : i.value === item.value
        )

        if (prevItem) {
          setExistingItem(prevItem)
          return
        }

        onAdd(item)
      }
    },
    [asText, items, onAdd]
  )

  const onRemoveItem = React.useCallback(
    (item, i) => {
      if (typeof onRemove === 'function') {
        onRemove(item, i)
      }
    },
    [onRemove]
  )

  const onTextChange = React.useCallback(e => {
    const value = e.target.value
    setText(value)
  }, [])

  const onKeyPress = React.useCallback(
    e => {
      if (e.key === 'Enter') {
        e.preventDefault()
        const _text = text.trim()
        if (_text) onAddItem(asText ? _text : { label: _text, value: _text })
        setText('')
      }
    },
    [asText, onAddItem, text]
  )

  const onKeyUp = React.useCallback(
    e => {
      const { value } = e.target
      if (value) return

      switch (e.code) {
        case 'Escape': {
          if (selectedItem) {
            e.preventDefault()
            setSelectedItem(null)
          }
          break
        }
        case 'Backspace': {
          e.preventDefault()
          const lastIndex = items.length - 1

          const _selectedItem = items[lastIndex]
          const itemValue = asText ? _selectedItem : _selectedItem.value

          if (protectedItems.includes(itemValue)) break

          setSelectedItem(_selectedItem)

          if (selectedItem) {
            onRemove(selectedItem, lastIndex)
            setSelectedItem(null)
          }
          break
        }
        default:
          break
      }
    },
    [selectedItem, items, asText, protectedItems, onRemove]
  )

  React.useEffect(() => {
    const timeout = setTimeout(() => setExistingItem(null), 1200)
    return () => clearTimeout(timeout)
  }, [existingItem])

  return (
    <div
      className={`relative flex flex-col ${fullWidthClass} ${className} ${disabledClass}`}
    >
      <div className="form-input rounded border-gray-300 focus-within:border-primary-500 focus-within:ring focus-within:ring-primary-200">
        <Clickable
          className="-m-1 flex flex-row flex-wrap focus:outline-none"
          onClick={() => inputRef.current.focus()}
        >
          {items.map((item, i) => {
            const itemValue = asText ? item : item.value
            const selectedValue = asText ? selectedItem : selectedItem?.value
            const existingValue = asText ? existingItem : existingItem?.value
            const protectedItem = protectedItems.includes(itemValue)

            return (
              <Tag
                key={`tag-${i}`}
                label={
                  getItemLabel ? getItemLabel(item) : asText ? item : item.label
                }
                onClick={() => !protectedItem && onRemoveItem(item, i)}
                selected={selectedValue === itemValue}
                shaking={existingValue === itemValue}
                showRemove={!protectedItem}
                type={asText ? undefined : item.type}
              />
            )
          })}
          <input
            className="m-1 text-base focus:outline-none"
            style={{ width: `${text.length || 2}ch` }}
            type="text"
            id={id}
            name={name}
            value={text}
            disabled={disabled}
            onChange={onTextChange}
            onKeyPress={onKeyPress}
            onKeyUp={onKeyUp}
            ref={inputRef}
          />
        </Clickable>
      </div>
      {Array.isArray(suggestions) && (
        <div className="-mx-1 flex flex-row flex-wrap p-2 focus:outline-none">
          {suggestions
            .filter(
              s =>
                !items
                  .map(i => (asText ? i : i.value))
                  .includes(asText ? s : s.value)
            )
            .map((suggestion, i) => (
              <Tag
                label={
                  getItemLabel
                    ? getItemLabel(suggestion)
                    : asText
                      ? suggestion
                      : suggestion.label
                }
                onClick={() => onAddItem(suggestion)}
                key={`tag-suggestion-${i}`}
                type={asText ? undefined : suggestion.type}
              />
            ))}
        </div>
      )}
    </div>
  )
}
Tags.propTypes = {
  asText: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  getItemLabel: PropTypes.func,
  id: PropTypes.string,
  items: PropTypes.array,
  name: PropTypes.string,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  protectedItems: PropTypes.array,
  suggestions: PropTypes.array,
}

export function TagsField({
  asText,
  className,
  disabled,
  error,
  fullWidth,
  getItemLabel,
  help,
  items,
  label,
  name,
  onAddItem,
  onRemoveItem,
  protectedItems,
  required,
  suggestions,
}) {
  return (
    <Field
      className={className}
      name={name}
      label={label}
      error={error}
      help={help}
      required={required}
    >
      <Tags
        asText={asText}
        disabled={disabled}
        fullWidth={fullWidth}
        getItemLabel={getItemLabel}
        id={name}
        items={items}
        onAdd={onAddItem}
        onRemove={onRemoveItem}
        protectedItems={protectedItems}
        suggestions={suggestions}
      />
    </Field>
  )
}
TagsField.propTypes = {
  asText: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.object,
  fullWidth: PropTypes.bool,
  getItemLabel: PropTypes.func,
  help: PropTypes.string,
  items: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  onAddItem: PropTypes.func,
  onChange: PropTypes.func,
  onRemoveItem: PropTypes.func,
  protectedItems: PropTypes.array,
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({ value: PropTypes.bool, message: PropTypes.string }),
  ]),
  suggestions: PropTypes.array,
}

export default function TagsController({
  asText,
  className = '',
  disabled,
  fullWidth = true,
  getItemLabel,
  help,
  items = [],
  label,
  name,
  onAddItem,
  onChange,
  onRemoveItem,
  protectedItems = [],
  required,
  shouldUnregister,
  suggestions,
}) {
  const { control } = useFormContext()

  const rules = useRules({ required })

  const onFieldAdd = React.useCallback(
    field => item => {
      field.onChange([...field.value, item])

      if (isFunction(onChange)) {
        onChange(item, field.value.length + 1, field.value)
      }

      if (isFunction(onAddItem)) {
        onAddItem(item, field.value)
      }
    },
    [onChange, onAddItem]
  )

  const onFieldRemove = React.useCallback(
    field => (item, index) => {
      field.onChange(field.value.filter((_, i) => i !== index))

      if (isFunction(onChange)) {
        onChange(item, index, field.value)
      }

      if (isFunction(onRemoveItem)) {
        onRemoveItem(item, index, field.value)
      }
    },
    [onChange, onRemoveItem]
  )

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={items}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <TagsField
          className={className}
          name={name}
          label={label}
          error={fieldState.error}
          help={help}
          required={rules?.required?.value}
          asText={asText}
          disabled={disabled}
          getItemLabel={getItemLabel}
          id={name}
          fullWidth={fullWidth}
          items={field.value}
          onAddItem={onFieldAdd(field)}
          onRemoveItem={onFieldRemove(field)}
          protectedItems={protectedItems}
          suggestions={suggestions}
        />
      )}
    />
  )
}
TagsController.propTypes = {
  asText: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  getItemLabel: PropTypes.func,
  help: PropTypes.string,
  items: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onAddItem: PropTypes.func,
  onChange: PropTypes.func,
  onRemoveItem: PropTypes.func,
  protectedItems: PropTypes.array,
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      value: PropTypes.bool,
      message: PropTypes.string,
    }),
  ]),
  shouldUnregister: PropTypes.bool,
  suggestions: PropTypes.array,
}
TagsField.displayName = 'Tags'
