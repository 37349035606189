import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { useImageUpload } from '@modules/images/services/ImageServices'
import { useDesignContext } from '@modules/web/components/DesignProvider'
import MenuPicker from '@modules/web/components/menus/MenuPicker'
import Section from '@ui/data-display/Section'
import Form from '@ui/data-entry/Form'
import ImageUpload from '@ui/data-entry/ImageUpload'
import Input from '@ui/data-entry/Input'
import Submit from '@ui/data-entry/Submit'
import TextArea from '@ui/data-entry/TextArea'

export default function AdventistFooterForm({ site, onSubmit }) {
  const design = useDesignContext()
  const { onImageUpload } = useImageUpload()
  const { t } = useTranslation([design.namespace, 'web/appearance'])

  return (
    <Form data={site} onSubmit={onSubmit} id={`${site.id}-${site.updatedAt}`}>
      <Section>
        <Section.Body>
          <ImageUpload
            name="design[footer][logo]"
            label={t(`${design.namespace}:footerLogo`)}
            help={t(`${design.namespace}:footerLogoHelp`)}
            onUpload={onImageUpload}
            maxSize="10mb"
            accept={design.logoFormats}
          />
          <Input
            name="design[footer][description]"
            label={t('web/appearance:footerDescription')}
          />
          <TextArea
            name="design[footer][copyright]"
            label={t('web/appearance:footerCopyright')}
          />
          <MenuPicker
            name="design[footer][footerMenu]"
            label={t(`${design.namespace}:menus_footer`)}
            help={t(`${design.namespace}:menusHelp_footer`)}
          />
          <MenuPicker
            name="design[footer][footerSocialMenu]"
            label={t(`${design.namespace}:menus_footerSocial`)}
            help={t(`${design.namespace}:menusHelp_footerSocial`)}
          />
        </Section.Body>
        <Section.Footer>
          <Submit label={t('update')} reverse />
        </Section.Footer>
      </Section>
    </Form>
  )
}
AdventistFooterForm.propTypes = {
  site: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
