import PropTypes from 'prop-types'
import React from 'react'

import SelectOption from './Option'

/**
 * Select's Placeholder
 */
export default function SelectPlaceholder({ label, value = 'none' }) {
  return <SelectOption label={label} disabled value={value} />
}
SelectPlaceholder.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
}
