import React from 'react'

import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

import Empty from '@ui/data-display/Empty'

import { SortableItem } from '.'
import Field from '../Field'
import { useRules } from '../validationHooks'
import { SortableListContext } from './SortableListContext'

/**
 * Default function to find the index of an item in the list
 * @param {Array} items The list of items
 * @param {string} id The id of the item to find
 * @returns {number} The index of the item in the list
 */
function defaultFindIndex(items = [], id) {
  return items?.findIndex(item => item?.id === id) || -1
}

/**
 * Default function to get the id of an item
 * @param {object} item The item to get the id from
 * @returns {string} The id of the item
 */
function defaultGetItemId(item) {
  return item?.id || ''
}

/**
 * @typedef {import('./SortableItem').HandleVerticalAlign} HandleVerticalAlign
 * @typedef {import('./SortableItem').HandlePosition} HandlePosition
 */

/**
 * SortableListController component
 * @param {object} props SortableListController props
 * @param {string} [props.className=''] Extra classes for the list (default: '')
 * @param {boolean} [props.disabled] Whether the list is disabled (default: false)
 * @param {string} [props.emptyDescription] Description for the empty state
 * @param {string} [props.emptyTitle] Title for the empty state
 * @param {Function} [props.findIndex=defaultFindIndex] Function to find the index of an item in the list (default: defaultFindIndex)
 * @param {Function} [props.getItemId=defaultGetItemId] Function to get the id of an item (default: defaultGetItemId)
 * @param {string} [props.handleClass=''] Class of the handle (default: '')
 * @param {HandlePosition} [props.handlePosition='left'] Position of the handle (default: left)
 * @param {HandleVerticalAlign} [props.handleVerticalAlign] Vertical alignment of the handle (default: center)
 * @param {string} [props.help] Help text for the field
 * @param {string} [props.itemClass=''] Extra classes for the item (default: '')
 * @param {string} props.name Name of the list (**required**)
 * @param {string} [props.label] Label for the field
 * @param {Function} [props.renderAddItem] Function to render an add item button
 * @param {Function} props.renderItem Function to render an item (**required**)
 * @param {boolean} [props.showEmpty=false] Whether to show the empty state (default: false)
 * @param {boolean} [props.showItemHandle] Whether to show the handle of the item (default: true)
 * @param {boolean|string} [props.required] Whether the field is required
 * @param {Function|object} [props.validate] Validation rules for the field
 * @param {boolean} [props.shouldUnregister] Whether to unregister the field when unmounted
 * @returns {React.ReactElement} The SortableListController component
 */
export default function SortableListController({
  className = '',
  disabled,
  emptyDescription,
  emptyTitle,
  findIndex = defaultFindIndex,
  getItemId = defaultGetItemId,
  handleClass,
  handlePosition = 'left',
  handleVerticalAlign,
  help,
  itemClass,
  name,
  label,
  renderAddItem,
  renderItem,
  showEmpty = false,
  showItemHandle,
  required,
  validate,
  shouldUnregister,
}) {
  const { control } = useFormContext()

  const rules = useRules({
    required,
    validate,
  })

  const { fields, move, append, prepend, remove } = useFieldArray({
    name,
    shouldUnregister,
    rules,
  })

  const handleDragEnd = React.useCallback(
    event => {
      if (typeof findIndex !== 'function') return
      const { active, over } = event

      if (!active || !over) return

      if (active?.id !== over?.id) {
        const oldIndex = findIndex(fields, active.id)
        const newIndex = findIndex(fields, over.id)

        move(oldIndex, newIndex)
      }
    },
    [fields, findIndex, move]
  )

  if (typeof renderItem !== 'function') {
    console.warn('SortableList: missing renderItem prop') // eslint-disable-line no-console
    return null
  }

  if (typeof getItemId !== 'function') {
    console.warn('SortableList: missing getItemId prop') // eslint-disable-line no-console
    return null
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ fieldState }) => {
        return (
          <div className={`flex flex-col gap-4 ${className}`}>
            <Field label={label} help={help} error={fieldState.error?.['root']}>
              <SortableListContext
                disabled={disabled}
                handleDragEnd={handleDragEnd}
                items={fields}
                name={name}
              >
                <div className={`isolate flex flex-col gap-2 ${className}`}>
                  {!fields.length > 0 && showEmpty && (
                    <Empty label={emptyTitle} description={emptyDescription} />
                  )}

                  {fields.map((field, index) => (
                    <SortableItem
                      className={itemClass}
                      disabled={disabled}
                      handleClass={handleClass}
                      handlePosition={handlePosition}
                      handleVerticalAlign={handleVerticalAlign}
                      id={getItemId(field)}
                      key={field.id}
                      showHandle={showItemHandle}
                    >
                      {renderItem({
                        field,
                        index,
                        fields,
                        disabled,
                        remove,
                        error: fieldState.error?.[index],
                      })}
                    </SortableItem>
                  ))}
                </div>
              </SortableListContext>
            </Field>
            {renderAddItem && !disabled ? (
              <div>{renderAddItem({ append, prepend, fields })}</div>
            ) : null}
          </div>
        )
      }}
    />
  )
}
