import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

import { FormProvider, useForm } from 'react-hook-form'

export default function Form({
  children,
  className = '',
  data = {},
  focusedField,
  formId,
  id,
  method,
  onSubmit,
  resetOnSubmit,
  updateOnSubmit = true,
  validationMode = 'onChange',
}) {
  const prevId = useRef()
  const methods = useForm({ defaultValues: data, mode: validationMode })

  useEffect(() => {
    if (focusedField) {
      methods.setFocus(focusedField)
    }
  }, [methods, focusedField])

  useEffect(() => {
    if (id && id !== prevId.current) {
      methods.reset(data)
      prevId.current = id
    }
  }, [id, data, methods])

  const handleOnSubmit = (formData, event) => {
    onSubmit && onSubmit(formData, event)

    if (resetOnSubmit) {
      methods.reset({ ...data })
    } else if (updateOnSubmit) {
      methods.reset({ ...formData })
    }
  }

  return (
    <FormProvider {...methods} validationMode={validationMode}>
      <form
        id={formId}
        className={className}
        method={method}
        onSubmit={e => {
          methods.handleSubmit(handleOnSubmit)(e)
          e.stopPropagation()
        }}
      >
        {children}
      </form>
    </FormProvider>
  )
}
Form.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  data: PropTypes.object,
  focusedField: PropTypes.string,
  formId: PropTypes.string,
  id: PropTypes.string,
  method: PropTypes.string,
  onSubmit: PropTypes.func,
  resetOnSubmit: PropTypes.bool,
  updateOnSubmit: PropTypes.bool,
  validationMode: PropTypes.oneOf([
    'onChange',
    'onBlur',
    'onSubmit',
    'onTouched',
  ]),
}
