import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'

import { useNode } from '@craftjs/core'
import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import { BlockColorSelect as ColorSelect } from '@modules/web/components/ContentEditor/shared/ColorSelect'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { useBlock } from '@modules/web/helpers/useBlock'
import Image from '@ui/data-display/Image'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'

import Address from './shared/Address'
import Amenities from './shared/Amenities'
import ContactInfo from './shared/ContactInfo'

const Map = React.lazy(() => import('@ui/data-display/Map'))

const config = {
  name: 'ChurchDetail',
  label: 'entities/public:ChurchDetail',
  type: 'plugin',
  icon: 'church',
  component: <ChurchDetail />,
}

function getPlaceholder() {
  return {
    image: {},
    name: '[Church name]',
    location: {
      coordinates: [0, 0],
    },
    description: '[Church description.]',
  }
}

export default function ChurchDetail({
  showAddress,
  showAmenities,
  showContactInfo,
  showDescription,
  showImage,
  showLogo,
  showMap,
  dynamic,
}) {
  const { t } = useTranslation('entities/public')

  const {
    name,
    image,
    description,
    address,
    email,
    logo,
    phone,
    siteURL,
    amenities,
    location,
  } = getPlaceholder()

  const [viewport, setViewport] = useState()

  useEffect(() => {
    if (!location?.coordinates || !showMap) {
      return
    }

    const newViewport = {
      longitude: location?.coordinates[0],
      latitude: location?.coordinates[1],
      zoom: 16,
    }

    if (!isEqual(viewport, newViewport)) {
      setViewport(newViewport)
    }
  }, [location, showMap, viewport])

  const locationMarker = useMemo(
    () => [{ coordinates: location.coordinates }],
    [location]
  )

  return (
    <Content icon={config.icon} title={t('ChurchDetail')} locked={dynamic}>
      <div className="flex flex-col">
        <div className="flex flex-col gap-6">
          <div className="flex flex-row">
            {showImage && image && (
              <Image
                file={image}
                alt={name}
                width={600}
                className={showMap ? 'w-2/3' : 'w-full'}
              />
            )}
            {showMap && (
              <Map
                className={showImage ? 'w-1/3' : 'h-48'}
                markers={locationMarker}
                viewport={viewport}
                markerIcon="church"
                onMove={setViewport}
                showControls
              />
            )}
          </div>
          <div className="flex flex-col gap-6 px-4 pb-4">
            <div className="flex flex-row justify-between gap-6">
              <div className="flex flex-col gap-2">
                <h1 className="text-3xl font-bold">{name}</h1>
                {showDescription && (
                  <p className="font-serif italic text-gray-600">
                    {description}
                  </p>
                )}
              </div>
              {showLogo && logo && <Image file={logo} alt={name} width={100} />}
            </div>
            {showAddress && <Address address={address} />}
            {showContactInfo && (
              <ContactInfo email={email} phone={phone} siteURL={siteURL} />
            )}
            {showAmenities && <Amenities amenities={amenities} />}
          </div>
        </div>
      </div>
    </Content>
  )
}
ChurchDetail.propTypes = {
  dynamic: PropTypes.bool,
  id: PropTypes.string,
  showAddress: PropTypes.bool,
  showAmenities: PropTypes.bool,
  showContactInfo: PropTypes.bool,
  showDescription: PropTypes.bool,
  showImage: PropTypes.bool,
  showLogo: PropTypes.bool,
  showMap: PropTypes.bool,
}
ChurchDetail.toolbarItem = config

function ChurchDetailSettings() {
  const { t } = useTranslation('entities/public')

  const { showMap, showAddress, showAmenities, titleColor } = useBlock(
    config.name
  )

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  // We've had to disable the manual church picking due to it not having worked before the conferences started using them in November 2023.
  // const onEntityIdChange = useCallback(
  //   value => {
  //     actions.setProp(props => (props.id = value))
  //   },
  //   [actions]
  // )

  return (
    <SettingsWrap title={t('ChurchDetail')} help={t('ChurchDetailHelp')}>
      <div className="space-y-2">
        {/* <Checkbox
          label={t('dynamic')}
          help={t('dynamicHelp')}
          onChange={value => {
            actions.setProp(props => (props.dynamic = value))
          }}
          value={true} // TODO: change this back to data.dynamic when we have a way to set the church manually
          disabled={true} // TODO: and change this back as well.
          // value={data.dynamic}
          name="dynamic"
        /> */}
        {/* 
        {!data.dynamic && (
          <EntityPickerField
            label={t('church')}
            onChange={onEntityIdChange}
            value={data.id}
            types={['church']}
          />
        )} */}
        <Checkbox
          name="showImage"
          label={t('showImage')}
          onChange={value => {
            actions.setProp(props => (props.showImage = value))
          }}
          value={data.showImage}
        />
        <Checkbox
          name="showLogo"
          label={t('showLogo')}
          onChange={value => {
            actions.setProp(props => (props.showLogo = value))
          }}
          value={data.showLogo}
        />
        {showMap && (
          <Checkbox
            name="showMap"
            label={t('showMap')}
            onChange={value => {
              actions.setProp(props => (props.showMap = value))
            }}
            value={data.showMap}
          />
        )}
        <Checkbox
          name="showDescription"
          label={t('showDescription')}
          onChange={value => {
            actions.setProp(props => (props.showDescription = value))
          }}
          value={data.showDescription}
        />
        {showAmenities && (
          <Checkbox
            name="showAmenities"
            label={t('showAmenities')}
            onChange={value => {
              actions.setProp(props => (props.showAmenities = value))
            }}
            value={data.showAmenities}
          />
        )}
        <Checkbox
          name="showContactInfo"
          label={t('showContactInfo')}
          onChange={value => {
            actions.setProp(props => (props.showContactInfo = value))
          }}
          value={data.showContactInfo}
        />
        {showAddress && (
          <Checkbox
            name="showAddress"
            label={t('showAddress')}
            onChange={value => {
              actions.setProp(props => (props.showAddress = value))
            }}
            value={data.showAddress}
          />
        )}
        <Checkbox
          name="showServices"
          label={t('showServices')}
          onChange={value => {
            actions.setProp(props => (props.showServices = value))
          }}
          value={data.showServices}
        />
        {titleColor && (
          <ColorSelect name="titleColor" label={t('titleColor')} />
        )}
      </div>
    </SettingsWrap>
  )
}

ChurchDetail.craft = {
  displayName: 'ChurchDetail',
  props: {
    id: undefined,
    dynamic: true,
    showDescription: true,
    showImage: true,
    showLogo: true,
    showMap: false,
    showAmenities: false,
    showAddress: false,
    showContactInfo: true,
    showServices: true,
  },
  custom: {
    type: config.type,
    resources: ['Entity', 'sermons', 'entityServices'],
    i18nNamespaces: ['church-finder'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ChurchDetailSettings,
  },
}
