import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'
import Box from '@ui/layout/Box'
import Heading from '@ui/typography/Heading'

import useChannel from '../../services/hooks/useChannel'
import ChannelSelect from '../ChannelSelect'

const config = {
  name: 'ScheduleLive',
  label: 'media-library/public:ScheduleLive',
  type: 'plugin',
  icon: 'calendar',
  component: <ScheduleLive />,
}

export default function ScheduleLive({ channelId }) {
  const { t } = useTranslation('media-library/public')

  const { resources } = usePageResources()
  const { channel } = useChannel(channelId)

  const _channel = resources?.Channel ? resources.Channel : channel

  return (
    <Content icon={config.icon} title={t(config.name)}>
      <Box space="xs">
        <Heading as="h6" className="ml-2" text={t('channel')} />
        <ul className="ml-8 list-disc text-sm">
          <li>
            {t('channel')}: {_channel ? _channel.title : t('none')}
          </li>
        </ul>
      </Box>
    </Content>
  )
}
ScheduleLive.propTypes = {
  channelId: PropTypes.string,
}
ScheduleLive.toolbarItem = config

function ScheduleLiveSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { resources } = usePageResources()

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t('ScheduleLive')} help={t('ScheduleLiveHelp')}>
      <Box space="xl">
        <Input
          label={t('title')}
          onChange={({ target }) =>
            actions.setProp(props => (props.title = target.value))
          }
          value={data.title}
        />
        <ChannelSelect
          label={t('channel')}
          onChange={onChannelUpdate('channelId')}
          value={data.channelId || resources?.Channel?.id}
          controlled={false}
        />

        <Checkbox
          label={t('showCurrent')}
          help={t('showCurrentHelp')}
          onChange={value =>
            actions.setProp(props => (props.showCurrent = value))
          }
          value={data.showCurrent}
        />
        <Input
          label={t('maxItems')}
          help={t('maxItemsHelp')}
          onChange={({ target }) =>
            actions.setProp(props => (props.maxItems = target.value))
          }
          value={data.maxItems}
          type="number"
        />
        <PagePicker
          label={t('schedulePageId')}
          help={t('schedulePageIdHelp')}
          value={data.schedulePageId}
          onChange={pageId =>
            actions.setProp(props => (props.schedulePageId = pageId))
          }
        />
      </Box>
    </SettingsWrap>
  )
}

ScheduleLive.craft = {
  displayName: 'ScheduleLive',
  props: {
    channelId: undefined,
    schedulePageId: undefined,
    maxItems: 3,
    showCurrent: true,
  },
  custom: {
    type: config.type,
    resources: ['channel', 'broadcastsLive'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ScheduleLiveSettings,
  },
}
