import PropTypes from 'prop-types'
import React from 'react'

import { useFormContext } from 'react-hook-form'

import DynamicInput from './Input'

export default function DynamicField({
  name,
  field,
  fieldType,
  hasLabel = true,
  wrapClass,
  ...props
}) {
  const formContext = useFormContext()
  const { setValue, watch } = formContext || {}

  const currentValue = watch(name)

  const onFieldSelect = React.useCallback(
    fieldValue => {
      setValue(name, fieldValue, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      })
    },
    [name, setValue]
  )

  return (
    <DynamicInput
      field={field}
      fieldType={fieldType}
      hasLabel={hasLabel}
      name={name}
      onFieldSelect={onFieldSelect}
      value={currentValue}
      wrapClass={wrapClass}
      {...props}
    />
  )
}
DynamicField.propTypes = {
  field: PropTypes.func.isRequired,
  fieldType: PropTypes.string.isRequired,
  hasLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  wrapClass: PropTypes.string,
}
