import PropTypes from 'prop-types'
import React from 'react'

const typesStyles = {
  danger: 'bg-danger-600 text-danger-100',
  info: 'bg-info-500 text-info-100',
  success: 'bg-success-600 text-success-100',
  warn: 'bg-warn-500 text-warn-50',
}

const alignmentsStyles = {
  'top-end': 'top-0 end-0 -translate-y-1/2 translate-x-1/2',
  'bottom-end': 'bottom-0 end-0 translate-y-1/2 translate-x-1/2',
}

/**
 * Badge component to display a number or text in a small circle.
 * @param {object} props - The component props
 * @param {string} props.align - Badge alignment (top-end, bottom-end, default: top-end)
 * @param {React.ReactNode} props.children - The children elements
 * @param {number} props.count - The badge count number (default: 0)
 * @param {boolean} props.dot - Whether to display a dot instead of a number (default: false)
 * @param {number} props.max - The maximum number to display before a plus sign is shown (default: 100)
 * @param {boolean} props.showZero - Whether to show the badge when the count is zero (default: false)
 * @param {string} props.text - The badge text to display
 * @param {string} props.variant - The badge color variant (danger, info, success, warn, default: danger)
 * @returns {React.Component} The badge component
 */
export default function Badge({
  align = 'top-end',
  children,
  count = 0,
  dot,
  max = 100,
  showZero,
  text,
  variant = 'danger',
}) {
  const value = text ? text : max > 0 && count > max ? `${max}+` : count

  const typeClass = typesStyles[variant] ?? typesStyles.danger
  const innerClass = dot ? 'h-2 w-2 rounded-full' : 'px-2 text-xs rounded-xl'

  const positionClass = children
    ? `absolute transform ${alignmentsStyles[align]}`
    : ''

  return (
    <span className="relative inline-block">
      {children}
      {(value || showZero || dot) && (
        <span className={`block ${innerClass} ${typeClass} ${positionClass}`}>
          {!dot && <span className="font-semibold">{value}</span>}
        </span>
      )}
    </span>
  )
}
Badge.propTypes = {
  align: PropTypes.oneOf(['top-end', 'bottom-end']),
  children: PropTypes.node,
  count: PropTypes.number,
  dot: PropTypes.bool,
  max: PropTypes.number,
  showZero: PropTypes.bool,
  text: PropTypes.string,
  variant: PropTypes.oneOf(['danger', 'info', 'success', 'warn']),
}
