import CartDonation from './CartDonation'
import StandaloneDonation from './StandaloneDonation'
import translatableBlocks from './translatableBlocks'

export default {
  resolvers: {
    StandaloneDonation,
    CartDonation,
  },
  toolbars: [
    {
      name: 'payments',
      label: 'payments/public:payments',
      items: [StandaloneDonation, CartDonation],
    },
  ],
  translatableBlocks,
}
