import PropTypes from 'prop-types'
import React from 'react'

import Heading from '@ui/typography/Heading'

export default function TranslationSection({
  children,
  className = '',
  title,
}) {
  return (
    <div className={`flex flex-col gap-4 ${className}`}>
      {title && <Heading as="h5" text={title} />}
      <div className="divide-y overflow-hidden rounded-lg border focus-within:divide-primary-200/80">
        {children}
      </div>
    </div>
  )
}
TranslationSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}
