import PropTypes from 'prop-types'
import React from 'react'

import useBreakpoint from '@modules/web/components/ContentEditor/shared/useBreakpoint'

import CardItem from './CardItem'
import CollectionTitle from './CollectionTitle'

const itemClasses = {
  xs: 'grid-cols-2 gap-4',
  sm: 'grid-cols-2 gap-5',
  md: 'grid-cols-3 gap-5',
  lg: 'grid-cols-4 gap-5',
  xl: 'grid-cols-4 gap-5',
}

export default function CollectionItem({ collection, layout }) {
  const { breakpoint } = useBreakpoint()

  if (!collection) return null
  const { contentType, hideTitle, id, episodes, shows, title } = collection

  // Render show
  if (contentType === 'shows') {
    return (
      <div className={layout === 'slider' ? 'overflow-hidden' : ''} key={id}>
        <div className="space-y-2">
          <CollectionTitle layout={layout} title={!hideTitle ? title : ''} />
          <div
            className={
              layout === 'grid'
                ? `grid ${itemClasses[breakpoint]}`
                : 'flex gap-2'
            }
          >
            {shows.map((show, key) => (
              <CardItem
                key={key}
                title={show.title}
                image={show.images?.default}
                className={layout === 'grid' ? 'w-full' : 'min-w-36 max-w-36'}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }

  // Render episodes
  if (contentType === 'episodes') {
    return (
      <div className={layout === 'slider' ? 'overflow-hidden' : ''} key={id}>
        <div className="space-y-2">
          <CollectionTitle layout={layout} title={!hideTitle ? title : ''} />
          <div
            className={
              layout === 'grid'
                ? `grid ${itemClasses[breakpoint]}`
                : 'flex gap-2'
            }
          >
            {episodes.map((episode, key) => (
              <CardItem
                key={key}
                title={episode.title}
                image={episode.image}
                subtitle={episode.show?.title}
                className={layout === 'grid' ? 'w-full' : 'min-w-36 max-w-36'}
              />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

CollectionItem.propTypes = {
  collection: PropTypes.object,
  layout: PropTypes.oneOf(['grid', 'slider']),
}
