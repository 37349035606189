import PropTypes from 'prop-types'
import React from 'react'

import ColorPicker from 'react-color/lib/Chrome'
import { Checkboard } from 'react-color/lib/components/common'
import { getContrastingColor, toState } from 'react-color/lib/helpers/color'
import { Controller, useFormContext } from 'react-hook-form'

import Clickable from '@ui/helpers/Clickable'
import useClickOutside from '@ui/helpers/useClickOutside'

import Field from './Field'
import { Input } from './Input'
import { useRules } from './validationHooks'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const modes = ['hex', 'rgb', 'hsl']

function round(value = 0.5) {
  return Math.round(value * 100) / 100
}

function getColorStyles(value, alpha) {
  return {
    color: value ? getContrastingColor(value) : 'black',
    backgroundColor: value
      ? getDisplayValue(value, 'rgb', alpha)
      : 'transparent',
  }
}

function getDisplayValue(value, mode, alpha) {
  if (!value || !value[mode]) return null

  const color = value[mode]

  switch (mode) {
    case 'hex':
      // for HEXAGESIMAL case
      return color

    default: {
      // for RGB, HSL or HSV cases
      const output = Object.keys(color)
        .filter(key => !(!alpha && key === 'a'))
        .map(key => round(color[key]))
        .join(', ')

      return `${mode}${alpha ? 'a' : ''}(${output})`
    }
  }
}

const inputStyles = {
  hex: 'w-24',
  rgb: 'w-52',
  hsl: 'w-48',
}

export function Color({
  alpha = false,
  mode = 'hex',
  icon = 'font',
  onChange,
  value = '',
  placeholder,
  name = 'colorHex',
}) {
  const { open, setOpen, nodeRef } = useClickOutside()

  const onColorChange = color => {
    onChange(getDisplayValue(color, mode, alpha))
  }

  const onToggle = () => setOpen(!open)

  return (
    <div className="relative flex flex-row space-x-2">
      <div>
        <Clickable
          className="relative h-10 w-10 overflow-hidden rounded border border-gray-300"
          onClick={onToggle}
        >
          <Checkboard />
          <div
            className={`absolute inset-0 flex items-center justify-center rounded`}
            style={value ? getColorStyles(toState(value), alpha) : null}
          >
            <Icon name={icon} />
          </div>
        </Clickable>
      </div>

      <Input
        name={name}
        className={inputStyles[mode] ?? inputStyles.hex}
        onClick={onToggle}
        onChange={({ target }) => onChange(target.value)}
        value={value}
        fullWidth={false}
        placeholder={placeholder}
      />

      {open && (
        <div className="absolute -left-1 top-12 z-max" ref={nodeRef}>
          <ColorPicker
            color={value}
            onChange={onColorChange}
            disableAlpha={!alpha}
          />
        </div>
      )}
    </div>
  )
}
Color.propTypes = {
  alpha: PropTypes.bool,
  icon: PropTypes.string,
  mode: PropTypes.oneOf(modes),
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
}

/**
 * Color field component
 */
export default function ColorField({
  alpha,
  className,
  label,
  mode,
  icon,
  value,
  name,
  help,
  placeholder,
  required,
  shouldUnregister,
}) {
  const { control } = useFormContext()

  const rules = useRules({ required })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <Field
          className={className}
          name={name}
          label={label}
          help={help}
          error={fieldState.error}
          required={rules?.required?.value}
        >
          <Color
            alpha={alpha}
            icon={icon}
            id={name}
            mode={mode}
            name={name}
            placeholder={placeholder}
            onChange={field.onChange}
            value={field.value}
          />
        </Field>
      )}
    />
  )
}

ColorField.propTypes = {
  alpha: PropTypes.bool,
  className: PropTypes.string,
  help: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  mode: PropTypes.oneOf(modes),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rules: PropTypes.object,
  shouldUnregister: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
ColorField.displayName = 'Color'
