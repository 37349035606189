import PropTypes from 'prop-types'
import React from 'react'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const positionStyles = {
  'top-start': 'top-0 start-4',
  'top': 'top-0 start-1/2 transform -translate-x-1/2',
  'top-end': 'top-0 end-4',
  'bottom-start': 'bottom-0 start-4',
  'bottom': 'bottom-0 start-1/2 transform -translate-x-1/2',
  'bottom-end': 'bottom-0 end-4',
}

const offsetStyles = {
  top: 'translate-y-4',
  bottom: '-translate-y-4',
}

const variantStyles = {
  'default': 'bg-gray-100 text-gray-600',
  'success': 'bg-success-500 text-success-100',
  'success-light': 'bg-success-100 text-success-500',
  'danger': 'bg-danger-500 text-danger-100',
  'danger-light': 'bg-danger-100 text-danger-500',
  'warning': 'bg-warn-500 text-warn-100',
  'warning-light': 'bg-warn-100 text-warn-500',
  'info': 'bg-info-500 text-info-100',
  'info-light': 'bg-info-100 text-info-500',
}

/**
 * Renders a toast message that appears for a few seconds and then disappears.
 *
 * @param {object} params - The component parameters.
 * @param {string} params.message - The message to display in the toast.
 * @param {string} params.position - The position of the toast (top-start, top, top-end, bottom-start, bottom, bottom-end).
 * @param {string} params.variant - The variant style of the toast (default, success, danger, warning, info).
 * @param {boolean} params.visible - Whether the toast is visible or not.
 * @returns {ReactElement} The component's elements.
 */
export default function Toast({
  message,
  icon,
  onHide,
  position = 'top',
  variant = 'default',
  visible = false,
}) {
  const positionClass = positionStyles[position] ?? positionStyles.bottom
  const verticalPosition = position?.split('-')[0]
  const variantClass = variantStyles[variant] ?? variantStyles.default
  const visibleClass = visible ? 'opacity-100' : 'opacity-0'
  const offsetClass = visible
    ? offsetStyles[verticalPosition] ?? offsetStyles.bottom
    : ''

  return (
    <div
      className={`fixed z-max flex items-center gap-2 rounded-full px-4 py-2 shadow drop-shadow-lg transition-all duration-300 ease-in-out ${visibleClass} ${positionClass} ${offsetClass} ${variantClass}`}
    >
      {icon && <Icon name={icon} className="h-4 w-4 opacity-75" />}
      <span>{message}</span>
      {onHide && (
        <button onClick={onHide}>
          <Icon name="times" className="h-4 w-4 opacity-75" />
        </button>
      )}
    </div>
  )
}
Toast.propTypes = {
  message: PropTypes.string,
  icon: PropTypes.string,
  onHide: PropTypes.func,
  position: PropTypes.oneOf(Object.keys(positionStyles)),
  variant: PropTypes.oneOf(Object.keys(variantStyles)),
  visible: PropTypes.bool,
}
