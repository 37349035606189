import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import BlockTranslation from '@modules/web/components/ContentEditor/shared/BlockTranslation'
import ButtonVariantSelect from '@modules/web/components/ContentEditor/shared/ButtonVariantSelect'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { useBlockTextSizeClass } from '@modules/web/components/ContentEditor/shared/TextSizeSelect'
import {
  useDesignColor,
  useTextFieldWithModifiers,
} from '@modules/web/components/ContentEditor/shared/hooks'
import { useBlock } from '@modules/web/helpers/useBlock'
import CollapsablePanel from '@ui/data-display/CollapsablePanel'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'
import Text from '@ui/typography/Text'

import { PublicationPicker } from '../PublicationPicker'

const config = {
  name: 'PublicationDownloads',
  label: 'publications/public:PublicationDownloads',
  type: 'plugin',
  icon: 'books',
  component: <PublicationDownloads />,
}

export default function PublicationDownloads({
  title = 'Most Downloaded',
  titleTextSize,
  textColor,
  languageLabel,
  downloadLabel,
}) {
  const { title: titleModifiers = {} } = useBlock(config.name)
  const textSizeClass = useBlockTextSizeClass(titleTextSize)

  const color = useDesignColor(textColor, 'black')

  const { t } = useTranslation('publications/public')
  const [titleValue, titleStyles, titleClassNames] = useTextFieldWithModifiers(
    typeof title === 'string' ? null : title,
    titleModifiers
  )

  return (
    <Content icon={config.icon} title={t('PublicationDownloads')}>
      {title && (
        <Text
          className={`${textSizeClass || 'text-3xl'} ${titleClassNames}`}
          style={titleStyles ?? { color }}
        >
          {titleValue}
        </Text>
      )}
      {languageLabel && <Text>{languageLabel}</Text>}
      {downloadLabel && <Text>{downloadLabel}</Text>}
    </Content>
  )
}
PublicationDownloads.propTypes = {
  title: PropTypes.object,
  languageLabel: PropTypes.string,
  downloadLabel: PropTypes.string,
  titleTextSize: PropTypes.string,
  textColor: PropTypes.string,
}
PublicationDownloads.toolbarItem = config

function PublicationDownloadsSettings() {
  const { t } = useTranslation('publications/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const [showPicker, setShowPicker] = useState(!data.dynamic)

  const onPublicationIdChange = useCallback(
    value => {
      actions.setProp(props => (props.id = value))
    },
    [actions]
  )

  return (
    <SettingsWrap
      title={t('PublicationDownloads')}
      help={t('PublicationDownloadsHelp')}
    >
      <div className="space-y-4">
        <CollapsablePanel defaultOpen title={t('general')}>
          <Checkbox
            label={t('dynamic')}
            help={t('dynamicHelp')}
            onChange={value => {
              setShowPicker(!value)
              actions.setProp(props => (props.dynamic = value))
            }}
            value={data.dynamic}
            name="dynamic"
          />
          {showPicker && (
            <PublicationPicker
              onChange={onPublicationIdChange}
              help={t('publicationHelp')}
              value={data.id}
            />
          )}
        </CollapsablePanel>
        <CollapsablePanel defaultOpen title={t('display')}>
          <Input
            name="languageLabel"
            label={t('languageLabel')}
            help={t('languageDisplayLabelHelp')}
            placeholder={t('languageDisplayLabelPlaceholder')}
            onChange={e => {
              actions.setProp(props => (props.languageLabel = e.target.value))
            }}
            value={data.languageLabel}
          />
          <Input
            name="downloadLabel"
            label={t('downloadLabel')}
            help={t('downloadLabelHelp')}
            onChange={e => {
              actions.setProp(props => (props.downloadLabel = e.target.value))
            }}
            value={data.downloadLabel}
          />
          <Checkbox
            label={t('showCount')}
            help={t('showCountHelp')}
            onChange={value => {
              actions.setProp(props => (props.showCount = value))
            }}
            value={data.showCount}
            name="showCount"
          />
          <ButtonVariantSelect
            name="buttonVariant"
            label={t('downloadButtonVariant')}
          />
        </CollapsablePanel>
        <CollapsablePanel defaultOpen title={t('extensions')}>
          <Input
            name="promotionalMaterialsLabel"
            label={t('promotionalMaterialsLabel')}
            help={t('promotionalMaterialsLabelHelp')}
            onChange={e => {
              actions.setProp(
                props => (props.promotionalMaterialsLabel = e.target.value)
              )
            }}
            value={data.promotionalMaterialsLabel}
          />
          <Input
            name="studyGuideLabel"
            label={t('studyGuideLabel')}
            help={t('studyGuideLabelHelp')}
            onChange={e => {
              actions.setProp(props => (props.studyGuideLabel = e.target.value))
            }}
            value={data.studyGuideLabel}
          />
          <Input
            name="readingPlanLabel"
            label={t('readingPlanLabel')}
            help={t('readingPlanLabelHelp')}
            onChange={e => {
              actions.setProp(
                props => (props.readingPlanLabel = e.target.value)
              )
            }}
            value={data.readingPlanLabel}
          />
          <Input
            name="audioLabel"
            label={t('audioLabel')}
            help={t('audioLabelHelp')}
            onChange={e => {
              actions.setProp(props => (props.audioLabel = e.target.value))
            }}
            value={data.audioLabel}
          />
        </CollapsablePanel>
      </div>
    </SettingsWrap>
  )
}
PublicationDownloads.craft = {
  displayName: 'PublicationDownloads',
  props: {
    title: 'Most Downloaded',
    languageLabel: 'Language',
    downloadLabel: 'Downloads',
    showCount: true,
  },
  custom: {
    type: config.type,
    resources: ['Publication', 'publicationDownloads'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: PublicationDownloadsSettings,
  },
}

/**
 * Component to render the fields of a Latest Publications block translation
 * @param {object} props - The component props
 * @param {string} props.id - The id of the block
 * @param {object} props.source - The source of the block
 * @returns {JSX.Element} The component
 */
export function PublicationDownloadsTranslation({ id, source }) {
  const { t } = useTranslation(['publications/public'])
  const fields = useMemo(
    () => ({
      languageLabel: { label: t('languageLabel') },
      downloadLabel: { label: t('downloadLabel') },
    }),
    [t]
  )
  return (
    <BlockTranslation
      id={id}
      fields={fields}
      source={source}
      i18Namespace="publications/public"
    />
  )
}
PublicationDownloadsTranslation.propTypes = {
  id: PropTypes.string,
  source: PropTypes.object,
}
