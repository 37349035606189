import PropTypes from 'prop-types'
import React from 'react'

import { useFormContext } from 'react-hook-form'

import Button from '@ui/buttons/Button'
import Box from '@ui/layout/Box'

export function SubmitButton({
  label = 'Submit',
  icon,
  loading,
  variant = 'primary',
  disabled,
  ...props
}) {
  return (
    <Button
      variant={variant}
      label={label}
      icon={icon}
      loading={loading}
      type="submit"
      disabled={disabled}
      {...props}
    />
  )
}
SubmitButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  label: PropTypes.string,
  variant: PropTypes.string,
  onSubmit: PropTypes.func,
}

export function Reset({ label = 'Reset', icon, onReset, disabled }) {
  return (
    <Button
      label={label}
      icon={icon}
      type="reset"
      disabled={disabled}
      onClick={onReset}
    />
  )
}
Reset.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  onReset: PropTypes.func,
  disabled: PropTypes.bool,
}

export function SubmitReset({
  className = '',
  disabled,
  label,
  icon,
  loading,
  onReset,
  resetDisabled,
  resetLabel,
  resetIcon,
  reverse,
  variant,
  ...props
}) {
  return (
    <Box horizontal space="md" className={className} reverse={reverse}>
      <SubmitButton
        variant={variant}
        label={label}
        icon={icon}
        loading={loading}
        type="submit"
        disabled={disabled}
        {...props}
      />
      {(resetLabel || resetIcon) && (
        <Reset
          icon={resetIcon}
          label={resetLabel}
          onReset={onReset}
          disabled={resetDisabled}
        />
      )}
    </Box>
  )
}
SubmitReset.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  onReset: PropTypes.func,
  resetDisabled: PropTypes.bool,
  resetLabel: PropTypes.string,
  resetIcon: PropTypes.string,
  reverse: PropTypes.bool,
  variant: PropTypes.oneOf([
    'primary',
    'warn',
    'danger',
    'success',
    'success-light',
  ]),
}

export default function Submit({
  className,
  label,
  icon,
  disabled,
  loading,
  onReset,
  resetLabel,
  resetIcon,
  reverse,
  submitting,
  variant,
  enableOnInvalid = false,
  ...props
}) {
  const { formState, validationMode, reset } = useFormContext()
  const { isDirty, isValid } = formState

  return (
    <SubmitReset
      className={className}
      reverse={reverse}
      variant={variant}
      label={label}
      icon={icon}
      loading={loading}
      type="submit"
      disabled={
        disabled ||
        (enableOnInvalid
          ? loading || submitting || !isDirty
          : loading ||
            submitting ||
            (validationMode === 'onSubmit' ? false : !isDirty || !isValid))
      }
      resetIcon={resetIcon}
      resetLabel={resetLabel}
      resetDisabled={loading || submitting || !isDirty}
      onReset={() => {
        reset()
        if (typeof onReset === 'function') {
          onReset()
        }
      }}
      {...props}
    />
  )
}
Submit.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  enableOnInvalid: PropTypes.bool,
  label: PropTypes.string,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  onReset: PropTypes.func,
  resetLabel: PropTypes.string,
  resetIcon: PropTypes.string,
  reverse: PropTypes.bool,
  submitting: PropTypes.bool,
  variant: PropTypes.oneOf([
    'primary',
    'warn',
    'danger',
    'success',
    'success-light',
  ]),
}
