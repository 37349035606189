import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import BlockTranslation from '@modules/web/components/ContentEditor/shared/BlockTranslation'

export default function StandaloneDonationTranslation({ id, source }) {
  const { t } = useTranslation(['payments/public'])
  const fields = React.useMemo(
    () => ({
      successMessage: { label: t('successMessage') },
    }),
    [t]
  )

  return <BlockTranslation id={id} source={source} fields={fields} />
}
StandaloneDonationTranslation.propTypes = {
  id: PropTypes.string.isRequired,
  source: PropTypes.object.isRequired,
}
