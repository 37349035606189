import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Button from '@ui/buttons/Button'
import Image from '@ui/data-display/Image'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import Field from '@ui/data-entry/Field'
import { InputField as Input } from '@ui/data-entry/Input'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'
import Heading from '@ui/typography/Heading'

import useChannel from '../../services/hooks/useChannel'
import useShow from '../../services/hooks/useShow'
import ChannelSelect from '../ChannelSelect'
import { ShowPicker } from '../ShowPicker'

const RichText = React.lazy(() => import('@ui/data-entry/RichText/Render'))

const config = {
  name: 'ShowDetail',
  label: 'media-library/public:showDetail',
  type: 'plugin',
  icon: 'file-invoice',
  component: <ShowDetail />,
}

function getPlaceholder() {
  return {
    image: {},
    title: '[Placeholder title]',
    abstract: '[Placeholder for abstract.]',
    body: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: "[This is a placeholder for show's body]",
            },
          ],
        },
      ],
    },
  }
}

export default function ShowDetail({
  channelId,
  dynamic,
  id,
  showAbstract,
  showBody,
  showImage,
  showTitle,
  showTrailer,
}) {
  const { t } = useTranslation('media-library/public')
  const showContent = true

  const { resources } = usePageResources()
  const { channel } = useChannel(channelId)
  const { show } = useShow(dynamic ? null : id)

  const _show = resources?.Show ? resources.Show : show || getPlaceholder()

  const { abstract, images, body, title } = _show

  return (
    <Content icon={config.icon} title={t('showDetail')} locked={dynamic}>
      {showContent ? (
        <div className="flex flex-col p-4">
          <div className="max-w-xl space-y-6">
            {showImage && images?.default && (
              <Image file={images.default} alt={title} width={600} />
            )}
            {showTitle && <h1 className="text-3xl font-bold">{title}</h1>}
            {showAbstract && abstract && (
              <p className="font-serif italic text-gray-600">{abstract}</p>
            )}
            {showTrailer && (
              <div>
                <Button label={t('trailer')} size="sm" />
              </div>
            )}
            {showBody && body && <RichText doc={body} />}
          </div>
        </div>
      ) : (
        <Box space="xs">
          <Heading
            as="h6"
            className="ml-2"
            text={
              channelId
                ? channel?.title
                : resources?.Channel && !resources?.Show
                  ? resources.Channel.title
                  : resources?.Show
                    ? resources.Show.title
                    : null
            }
          />
          <ul className="ml-8 list-disc text-sm">
            {showImage && <li>{t('image')}</li>}
            {showTitle && <li>{t('title')}</li>}
            {showAbstract && <li>{t('abstract')}</li>}
            {showTrailer && <li>{t('trailer')}</li>}
            {showBody && <li>{t('body')}</li>}
          </ul>
        </Box>
      )}
    </Content>
  )
}
ShowDetail.propTypes = {
  channelId: PropTypes.string,
  dynamic: PropTypes.bool,
  id: PropTypes.string,
  showAbstract: PropTypes.bool,
  showBody: PropTypes.bool,
  showImage: PropTypes.bool,
  showTitle: PropTypes.bool,
  showTrailer: PropTypes.bool,
}
ShowDetail.toolbarItem = config

function ShowDetailSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(data.channelId || data.id)

  const { resources } = usePageResources()

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  const onShowIdChange = useCallback(
    value => {
      actions.setProp(props => (props.id = value))
    },
    [actions]
  )

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t('showDetail')} help={t('showDetailHelp')}>
      <Box space="xl">
        {resources?.Channel && !resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources.Channel.title}
          </Message>
        )}
        {resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources.Show.title}
          </Message>
        )}
        <Input
          label={t('title')}
          onChange={onFieldUpdate('title')}
          value={data.title}
        />
        <Field label={t('fields')}>
          <Box space="xs">
            <Checkbox
              label={t('image')}
              onChange={value => {
                actions.setProp(props => (props.showImage = value))
              }}
              value={data.showImage}
              name="showImage"
            />
            <Checkbox
              label={t('title')}
              onChange={value => {
                actions.setProp(props => (props.showTitle = value))
              }}
              value={data.showTitle}
              name="showTitle"
            />
            <Checkbox
              label={t('abstract')}
              onChange={value => {
                actions.setProp(props => (props.showAbstract = value))
              }}
              value={data.showAbstract}
              name="showAbstract"
            />
            <Checkbox
              label={t('trailer')}
              onChange={value => {
                actions.setProp(props => (props.showTrailer = value))
              }}
              value={data.showTrailer}
              name="showTrailer"
            />
            <Checkbox
              label={t('body')}
              onChange={value => {
                actions.setProp(props => (props.showBody = value))
              }}
              value={data.showBody}
              name="showBody"
            />
          </Box>
        </Field>
        {!resources?.Show && (
          <>
            <Divider />
            <Checkbox
              label={t('pickShowManually')}
              help={t('pickShowManuallyHelp')}
              onChange={value => setShowPicker(value)}
              value={showPicker}
            />
            {showPicker && (
              <Box space="lg">
                {!resources?.Channel && (
                  <ChannelSelect
                    label={t('channel')}
                    onChange={onChannelUpdate('channelId')}
                    value={data.channelId}
                    controlled={false}
                  />
                )}
                <ShowPicker
                  channelId={data.channelId || resources?.Channel?.id}
                  onChange={onShowIdChange}
                  value={data.id}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </SettingsWrap>
  )
}

ShowDetail.craft = {
  displayName: 'ShowDetail',
  props: {
    channelId: undefined,
    id: undefined,
    title: '',
    showAbstract: false,
    showBody: true,
    showImage: true,
    showTitle: true,
    showTrailer: false,
  },
  custom: {
    type: config.type,
    resources: ['showDetail'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ShowDetailSettings,
  },
}
