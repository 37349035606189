import React from 'react'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

/**
 * @typedef {'xs'|'sm'|'md'|'lg'} DragHandleSize
 */
const dragSizeClasses = {
  xs: { wrap: 'w-3', icon: 'text-sm' },
  sm: { wrap: 'w-4', icon: 'text-base' },
  md: { wrap: 'w-5', icon: 'text-lg' },
  lg: { wrap: 'w-6', icon: 'text-xl' },
}

/**
 * DragHandle component for SortableList
 * @param {object} props Component props
 * @param {boolean} props.disabled  Dragging has been disabled
 * @param {boolean} props.isDragging Indicates if the item is being dragged
 * @param {string} props.className Additional classes for the handle (optional)
 * @param {object} props.listeners Event listeners to be provided by the parent (optional)
 * @param {DragHandleSize} props.size Size of the handle (`xs`, `sm`, `md`, or `lg`, default: `sm`)
 * @returns {ReactElement} The DragHandle component
 */
export default function DragHandle({
  disabled,
  isDragging,
  className = '',
  listeners = {},
  size = 'sm',
}) {
  const disabledClass = disabled ? 'cursor-not-allowed opacity-50' : ''
  const cursorClass = isDragging ? 'cursor-grabbing' : 'cursor-grab'
  const sizeClass = dragSizeClasses[size] || dragSizeClasses.sm

  return (
    <div
      className={`flex shrink-0 text-gray-300 ${disabledClass} ${cursorClass} ${sizeClass.wrap} ${className}`}
      {...(listeners || {})}
    >
      <Icon name="bars" className={sizeClass.icon} />
    </div>
  )
}
