import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { Controller, useFormContext } from 'react-hook-form'

import { useRules } from '@ui/data-entry/validationHooks'

import AutocompleteField from './Field'

/**
 * Autocomplete with Field (label + help + error + required mark)
 */
export default function AutocompleteController({
  children,
  className = '',
  disabled,
  displayIcon,
  displayMultiple,
  displayValue,
  help,
  label,
  loading,
  loadingText,
  multiple,
  name,
  nullable,
  onChange,
  onSearch,
  placeholder,
  required,
  searchText,
  shouldUnregister,
  showValueInline,
  value,
}) {
  const { control } = useFormContext()

  const rules = useRules({ required })

  const onFieldChange = useCallback(
    field => event => {
      field.onChange(event)

      if (typeof onChange === 'function') {
        onChange(event.currentTarget.value)
      }
    },
    [onChange]
  )

  return (
    <Controller
      control={control}
      defaultValue={value}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <AutocompleteField
          className={className}
          name={name}
          label={label}
          help={help}
          error={fieldState.error}
          required={rules?.required?.value}
          disabled={disabled}
          multiple={multiple}
          nullable={nullable}
          loading={loading}
          loadingText={loadingText}
          onChange={onFieldChange(field)}
          onSearch={onSearch}
          placeholder={placeholder}
          displayIcon={displayIcon}
          displayMultiple={displayMultiple}
          displayValue={displayValue}
          value={field.value}
          searchText={searchText}
          showValueInline={showValueInline}
        >
          {children}
        </AutocompleteField>
      )}
    />
  )
}
AutocompleteController.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  displayIcon: PropTypes.node,
  displayMultiple: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  displayValue: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  nullable: PropTypes.bool,
  rules: PropTypes.object,
  loading: PropTypes.bool,
  loadingText: PropTypes.node,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.node,
  required: PropTypes.bool,
  searchText: PropTypes.string,
  shouldUnregister: PropTypes.bool,
  showValueInline: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
}
