import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

import { Controller, useFormContext } from 'react-hook-form'

import Field from './Field'
import { useRules } from './validationHooks'

export function Checkbox({
  className = '',
  disabled,
  onChange,
  indeterminate,
  ...props
}) {
  const inputRef = useRef(null)
  const disabledClass = disabled ? 'bg-gray-200' : ''

  useEffect(() => {
    if (inputRef) {
      const checkboxElem = inputRef.current
      checkboxElem.indeterminate = indeterminate
    }
  }, [indeterminate, inputRef])

  return (
    <input
      className={`form-checkbox rounded border-gray-300 text-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-300 ${disabledClass} ${className}`}
      disabled={disabled}
      onChange={
        typeof onChange === 'function'
          ? e => onChange(e.target.checked)
          : undefined
      }
      ref={inputRef}
      {...props}
      type="checkbox"
    />
  )
}
Checkbox.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
  inputRef: PropTypes.any,
  onChange: PropTypes.func,
}

export function CheckboxField({
  className,
  disabled,
  error,
  help,
  indeterminate,
  label,
  lightLabel,
  name,
  onChange,
  readOnly,
  required,
  tooltip,
  tooltipMaxWidth,
  value,
}) {
  return (
    <Field
      className={className}
      name={name}
      label={label}
      disabled={disabled}
      help={help}
      indentHelp
      error={error}
      required={required}
      labelPosition="right"
      lightLabel={lightLabel}
      tooltip={tooltip}
      tooltipMaxWidth={tooltipMaxWidth}
    >
      <Checkbox
        disabled={disabled}
        name={name}
        value={value}
        checked={!!value}
        id={name}
        indeterminate={indeterminate}
        onChange={onChange}
        readOnly={readOnly}
      />
    </Field>
  )
}
CheckboxField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  help: PropTypes.string,
  indeterminate: PropTypes.bool,
  label: PropTypes.node,
  lightLabel: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  rules: PropTypes.object,
  tooltip: PropTypes.node,
  tooltipMaxWidth: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
}

/**
 * Checkbox field component
 */
export default function CheckboxController({
  className,
  disabled,
  label,
  value,
  name,
  lightLabel,
  help,
  indeterminate,
  onChange,
  readOnly,
  required,
  shouldUnregister,
  tooltip,
  tooltipMaxWidth,
}) {
  const { control } = useFormContext()

  const rules = useRules({ required })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <CheckboxField
          className={className}
          disabled={disabled}
          name={name}
          value={field.value}
          label={label}
          lightLabel={lightLabel}
          error={fieldState.error}
          help={help}
          id={name}
          indeterminate={indeterminate}
          onChange={() => {
            field.onChange(!field.value)
            onChange && onChange(!field.value)
          }}
          required={required}
          readOnly={readOnly}
          tooltip={tooltip}
          tooltipMaxWidth={tooltipMaxWidth}
        />
      )}
    />
  )
}

CheckboxController.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  help: PropTypes.string,
  indeterminate: PropTypes.bool,
  label: PropTypes.node,
  lightLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  rules: PropTypes.object,
  shouldUnregister: PropTypes.bool,
  tooltip: PropTypes.node,
  tooltipMaxWidth: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
}
CheckboxController.displayName = 'Checkbox'
