import PropTypes from 'prop-types'
import React from 'react'

import { FieldRequired } from './FieldRequired'

/**
 * Field help component
 * @param {Object} props - Component props
 * @param {String} props.className - Custom class name
 * @param {String} props.text - Help text
 * @param {Boolean} props.required - Whether the field is required
 * @returns
 */
export function FieldHelp({ text, className = '', required }) {
  return (
    <div className={`flex items-center gap-2 text-sm leading-4 ${className}`}>
      <span className="text-gray-500">{text}</span>
      {required && <FieldRequired />}
    </div>
  )
}
FieldHelp.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  required: PropTypes.bool,
}
