import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Message from '@ui/data-display/Message'
import { InputField as Input } from '@ui/data-entry/Input'
import { SelectField as Select, SelectOption } from '@ui/data-entry/Select'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'
import Heading from '@ui/typography/Heading'

import useChannel from '../../services/hooks/useChannel'
// import useEpisodes from '../../services/hooks/useEpisodes'
import useShow from '../../services/hooks/useShow'

// import ChannelSelect from '../ChannelSelect'

const config = {
  name: 'EpisodesTeaser',
  label: 'media-library/public:episodesTeaser',
  type: 'plugin',
  icon: 'bars',
  component: <EpisodesTeaser />,
}

export default function EpisodesTeaser({
  channelId,
  limit,
  showId,
  sortField = 'createdAt',
  sortOrder = 'desc',
  title,
}) {
  const { t } = useTranslation('media-library/public')
  const sort = `${sortOrder === 'desc' ? '-' : ''}${sortField}`
  const showContent = false

  const { resources } = usePageResources()
  const { channel } = useChannel(channelId)
  const { show } = useShow(showId)
  // const { count, loading, episodes } = useEpisodes({
  //   showId,
  //   sort,
  //   limit,
  // })

  const _channel = channelId
    ? channel
    : resources?.Channel
      ? resources.Channel
      : null

  const _show = showId ? show : resources?.Show ? resources.Show : null

  return (
    <Content icon={config.icon} title={t('episodesTeaser')}>
      {showContent ? (
        <div>TODO</div>
      ) : (
        <Box space="xs">
          {title && <Heading as="h6" className="ml-2" text={title} />}
          <ul className="ml-8 list-disc text-sm">
            <li>
              {t('channel')}: {_channel?.title}
            </li>
            <li>
              {t('show')}: {_show?.title || t('dynamic')}
            </li>
            <li>
              {t('sort')}: {sort}
            </li>
            <li>
              {t('limit')}: {limit}
            </li>
          </ul>
        </Box>
      )}
    </Content>
  )
}
EpisodesTeaser.propTypes = {
  channelId: PropTypes.string,
  limit: PropTypes.number,
  showId: PropTypes.string,
  sortField: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  title: PropTypes.string,
}
EpisodesTeaser.toolbarItem = config

function EpisodesTeaserSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { resources } = usePageResources()

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  // const onChannelUpdate = useCallback(
  //   (fieldName, parser = x => x) => channel => {
  //     actions.setProp(props => (props[fieldName] = parser(channel.id)))
  //   },
  //   [actions]
  // )

  return (
    <SettingsWrap title={t('episodesTeaser')} help={t('episodesTeaserHelp')}>
      <Box space="xl">
        {resources?.Channel && !resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources.Channel.title}
          </Message>
        )}
        {resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources.Show.title}
          </Message>
        )}
        <Input
          label={t('title')}
          onChange={onFieldUpdate('title')}
          value={data.title}
        />
        {/* <ChannelSelect
          label={t('channel')}
          onChange={onChannelUpdate('channelId')}
          value={data.channelId}
          controlled={false}
        /> */}
        {/* TODO: ShowPicker */}
        <Select
          label={t('sortField')}
          help={t('sortFieldHelp')}
          value={data.sortField}
          onChange={onFieldUpdate('sortField')}
        >
          <SelectOption value="createdAt" label={t('sortByCreationDate')} />
          <SelectOption value="updatedAt" label={t('sortByUpdateDate')} />
        </Select>
        <Select
          label={t('sortOrder')}
          help={t('sortOrderHelp')}
          value={data.sortOrder}
          onChange={onFieldUpdate('sortOrder')}
        >
          <SelectOption value="asc" label={t('ascending')} />
          <SelectOption value="desc" label={t('descending')} />
        </Select>
        <Input
          label={t('limit')}
          help={t('limitHelp')}
          onChange={onFieldUpdate('limit', value =>
            value ? parseInt(isNaN(value) || value < 0 ? 0 : value) : 0
          )}
          value={data.limit}
          type="number"
        />
        <Divider />
        <PagePicker
          label={t('episodeDetailPageId')}
          help={t('episodeDetailPageIdHelp')}
          value={data.episodeDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.episodeDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Episode'
          }
        />
        <PagePicker
          label={t('episodesListPageId')}
          help={t('episodesListPageIdHelp')}
          value={data.episodesListPageId}
          onChange={pageId =>
            actions.setProp(props => (props.episodesListPageId = pageId))
          }
          condition={page => getDynamicResourceIdentifier(page) === 'Episode'}
        />
      </Box>
    </SettingsWrap>
  )
}

EpisodesTeaser.craft = {
  displayName: 'EpisodesTeaser',
  props: {
    title: '',
    channelId: undefined,
    showId: undefined,
    sortField: 'createdAt',
    sortOrder: 'desc',
    limit: 8,
    episodeDetailPageId: undefined,
    episodesListPageId: undefined,
  },
  custom: {
    type: config.type,
    resources: ['teaserEpisodes', 'Channel', 'Show'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EpisodesTeaserSettings,
  },
}
