import React from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import useIsUserAuthorized from '@modules/auth/helpers/useIsUserAuthorized'

import {
  getCategoriesUrl,
  getConfigurePublicationsUrl,
  getPublicationsUrl,
  getPublishingHousesUrl,
  getReligionsUrl,
} from '../helpers/urls'
import usePublicationsConfig from '../services/hooks/usePublicationsConfig'

const MenuGroup = React.lazy(() => import('@ui/navigation/Menu/MenuGroup'))
const MenuItem = React.lazy(() => import('@ui/navigation/Menu/MenuItem'))
const MenuDivider = React.lazy(() => import('@ui/navigation/Menu/MenuDivider'))

export default function PublicationsMenuGroup() {
  const { t } = useTranslation('publications/public')
  const location = useLocation()

  // Get permissions
  const canReadPublications = useIsUserAuthorized({
    module: 'publications',
    permission: 'read',
  })
  const canUpdateCategories = useIsUserAuthorized({
    module: 'publication-categories',
    permission: 'update',
  })
  const canUpdateReligions = useIsUserAuthorized({
    module: 'publication-religions',
    permission: 'update',
  })
  const canUpdatePublicationTypes = useIsUserAuthorized({
    module: 'publication-types',
    permission: 'update',
  })
  const canUpdatePublishingHouses = useIsUserAuthorized({
    module: 'publishing-houses',
    permission: 'update',
  })
  const canConfigurePublications = useIsUserAuthorized({
    module: 'publications',
    permission: 'configure',
  })

  const { publicationsConfig } = usePublicationsConfig()
  const {
    hasPublicationTypes,
    hasPublicationCategories,
    hasPublicationReligions,
    hasPublishingHouses,
  } = publicationsConfig

  // If user can't read any of the submodules, don't show the menu
  if (
    !canReadPublications &&
    !canUpdateCategories &&
    !canUpdatePublicationTypes &&
    !canUpdateReligions &&
    !canUpdatePublishingHouses &&
    !canConfigurePublications
  ) {
    return null
  }

  const inPublications = location.pathname.startsWith('/publications')
  const inPublicationsList = location.pathname.startsWith('/publications/list')

  const showPublicationTypes = hasPublicationTypes && canUpdatePublicationTypes
  const showCategories = hasPublicationCategories && canUpdateCategories
  const showReligions = hasPublicationReligions && canUpdateReligions
  const showPublishingHouses = hasPublishingHouses && canUpdatePublishingHouses

  if (
    !showPublicationTypes &&
    !showCategories &&
    !showReligions &&
    !showPublishingHouses &&
    !canConfigurePublications
  ) {
    return (
      <MenuGroup
        name="publications"
        label={t('publications')}
        icon="bars"
        href={getPublicationsUrl()}
        active={inPublicationsList}
      />
    )
  }

  const inPublicationsConfigure = location.pathname.startsWith(
    '/publications/configure'
  )
  const inCategories = location.pathname.startsWith('/publications/categories')
  const inPublicationTypes = location.pathname.startsWith('/publications/types')
  const inReligions = location.pathname.startsWith('/publications/religions')
  const inPublishingHouses = location.pathname.startsWith(
    '/publications/publishing-houses'
  )

  return (
    <MenuGroup
      name="publications"
      label={t('publications')}
      icon="books"
      active={inPublications}
    >
      {canReadPublications && (
        <MenuItem
          name="publications"
          label={t('publications')}
          icon="bars"
          href={getPublicationsUrl()}
          active={inPublicationsList}
        />
      )}
      {showPublicationTypes && (
        <MenuItem
          name="publication-types"
          label={t('publicationTypes')}
          icon="shapes"
          href="/publications/types"
          active={inPublicationTypes}
        />
      )}
      {showCategories && (
        <MenuItem
          name="categories"
          label={t('categories')}
          icon="tags"
          href={getCategoriesUrl()}
          active={inCategories}
        />
      )}
      {showReligions && (
        <MenuItem
          name="religions"
          label={t('religions')}
          icon="church"
          href={getReligionsUrl()}
          active={inReligions}
        />
      )}
      {showPublishingHouses && (
        <MenuItem
          name="publishing-houses"
          label={t('PublishingHouses')}
          icon="university"
          href={getPublishingHousesUrl()}
          active={inPublishingHouses}
        />
      )}
      {canConfigurePublications && (
        <>
          <MenuDivider />
          <MenuItem
            name="publications-settings"
            label={t('publicationsSettings')}
            icon="wrench"
            href={getConfigurePublicationsUrl()}
            active={inPublicationsConfigure}
          />
        </>
      )}
    </MenuGroup>
  )
}
