import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import { useDocumentUpload } from '@modules/documents/services/DocumentServices'
import { useImageUpload } from '@modules/images/services/ImageServices'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import CollapsablePanel from '@ui/data-display/CollapsablePanel'
import UIImage from '@ui/data-display/Image'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { FileUploadField as FileUpload } from '@ui/data-entry/FileUpload'
import { ImageUploadField as ImageUpload } from '@ui/data-entry/ImageUpload'
import { InputField as Input } from '@ui/data-entry/Input'
import { TextAreaField as TextArea } from '@ui/data-entry/TextArea'
import Box from '@ui/layout/Box'

import AudioPlayerVariant from '../shared/AudioPlayerVariant'

const RichText = React.lazy(() => import('@ui/data-entry/RichText/Render'))
const RichTextField = React.lazy(() => import('@ui/data-entry/RichText/Field'))

const config = {
  name: 'AudioPlayer',
  label: 'media-library/public:audioPlayer',
  type: 'plugin',
  icon: 'play-circle',
  component: <AudioPlayer />,
}

export default function AudioPlayer({
  image,
  title,
  kicker,
  subtitle,
  abstract,
  description,
  showImage,
  showKicker,
  showTitle,
  showSubtitle,
  showAbstract,
  showDescription,
}) {
  const { t } = useTranslation('media-library/public')

  return (
    <Content icon={config.icon} title={t('audioPlayer')}>
      <div className="max-w-xl space-y-2 p-2">
        {showImage && image && <UIImage alt={title} file={image} width="640" />}
        {showKicker && Boolean(kicker) && (
          <p className="text-sm font-bold uppercase">{kicker}</p>
        )}
        {showTitle && Boolean(title) && <h3 className="font-bold">{title}</h3>}
        {showSubtitle && Boolean(subtitle) && <p>{subtitle}</p>}
        <div className="aspect-h-9 aspect-w-16 w-full border bg-gray-100">
          <div className="flex h-full w-full items-center justify-center text-gray-500">
            Audio player
          </div>
        </div>
        {showAbstract && Boolean(abstract) && <p>{abstract}</p>}
        {showDescription && Boolean(description) && (
          <RichText doc={description} />
        )}
      </div>
    </Content>
  )
}
AudioPlayer.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  kicker: PropTypes.string,
  subtitle: PropTypes.string,
  abstract: PropTypes.string,
  description: PropTypes.object,
  showImage: PropTypes.bool,
  showTitle: PropTypes.bool,
  showKicker: PropTypes.bool,
  showSubtitle: PropTypes.bool,
  showAbstract: PropTypes.bool,
  showDescription: PropTypes.bool,
}
AudioPlayer.toolbarItem = config

function AudioPlayerSettings() {
  const { t } = useTranslation('media-library/public')
  const [uploading, setUploading] = useState(false)
  const [uploadError, setUploadError] = useState(false)

  const { onAudioUpload } = useDocumentUpload()
  const { onImageUpload } = useImageUpload()

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const onUploadError = useCallback(() => {
    setUploading(false)
    setUploadError(true)
  }, [])

  const onTextChange = useCallback(
    field => e => {
      actions.setProp(props => (props[field] = e.target.value))
    },
    [actions]
  )

  return (
    <SettingsWrap title={t('audioPlayer')} help={t('audioPlayerHelp')}>
      <Box space="xl">
        <ImageUpload
          value={data.image}
          className="justify-between"
          name="image"
          label={t('image')}
          onChange={file => actions.setProp(data => (data.image = file))}
          onUpload={onImageUpload}
          maxSize="10mb"
        />

        <Checkbox
          label={t('liveStream')}
          onChange={value => {
            actions.setProp(props => (props.liveStream = value))
          }}
          value={data.liveStream}
          name="liveStream"
        />

        {data.liveStream ? (
          <Input
            name="url"
            value={data.url}
            label={t('url')}
            onChange={onTextChange('url')}
          />
        ) : (
          <FileUpload
            value={data.file}
            name="file"
            label={t('file')}
            error={uploadError ? { message: t('fileUploadError') } : null}
            uploading={uploading}
            onChange={file => {
              setUploading(false)
              actions.setProp(data => (data.file = file))
            }}
            onUpload={(file, onChange) => {
              setUploading(true)
              setUploadError(false)
              onAudioUpload(file, onChange, onUploadError)
            }}
            maxSize="100mb"
            accept={['mp3']}
            required
          />
        )}

        <AudioPlayerVariant />

        <Input
          name="title"
          value={data.title}
          label={t('title')}
          onChange={onTextChange('title')}
        />
        <Input
          name="subtitle"
          value={data.subtitle}
          label={t('subtitle')}
          onChange={onTextChange('subtitle')}
        />
        <Input
          name="kicker"
          value={data.kicker}
          label={t('kicker')}
          onChange={onTextChange('kicker')}
        />

        <TextArea
          name="abstract"
          value={data.abstract}
          label={t('abstract')}
          onChange={onTextChange('abstract')}
        />
        <RichTextField
          name="description"
          value={data.description}
          label={t('description')}
          onChange={value =>
            actions.setProp(data => (data.description = value))
          }
        />

        <CollapsablePanel title={t('playerOptions')}>
          <Checkbox
            label={t('showImage')}
            onChange={value => {
              actions.setProp(props => (props.showImage = value))
            }}
            value={data.showImage}
            name="showImage"
          />
          <Checkbox
            label={t('showTitle')}
            onChange={value => {
              actions.setProp(props => (props.showTitle = value))
            }}
            value={data.showTitle}
            name="showTitle"
          />
          <Checkbox
            label={t('showSubtitle')}
            onChange={value => {
              actions.setProp(props => (props.showSubtitle = value))
            }}
            value={data.showSubtitle}
            name="showSubtitle"
          />
          <Checkbox
            label={t('showKicker')}
            onChange={value => {
              actions.setProp(props => (props.showKicker = value))
            }}
            value={data.showKicker}
            name="showKicker"
          />
          <Checkbox
            label={t('showAbstract')}
            onChange={value => {
              actions.setProp(props => (props.showAbstract = value))
            }}
            value={data.showAbstract}
            name="showAbstract"
          />
          <Checkbox
            label={t('showDescription')}
            onChange={value => {
              actions.setProp(props => (props.showDescription = value))
            }}
            value={data.showDescription}
            name="showDescription"
          />
        </CollapsablePanel>
      </Box>
    </SettingsWrap>
  )
}

AudioPlayer.craft = {
  displayName: 'AudioPlayer',
  props: {
    showImage: true,
    showKicker: true,
    showTitle: true,
    showSubtitle: true,
    showAbstract: true,
    showDescription: true,
  },
  custom: {
    type: config.type,
    resources: ['audioPlayer'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: AudioPlayerSettings,
  },
}
