import PropTypes from 'prop-types'
import React from 'react'

import { Menu, Transition } from '@headlessui/react'

import Button from '@ui/buttons/Button'
import { Search } from '@ui/data-entry/Search'
import Link from '@ui/navigation/Link'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const alignmentsStyles = {
  'bottom-start': 'start-0 origin-bottom-left',
  'bottom-end': 'end-0 origin-bottom-right',
  'top-start': 'bottom-12 start-0 origin-top-left',
  'top-end': 'bottom-12 end-0 origin-top-right',
}

const transitionsStyles = {
  bottom: {
    closed: 'opacity-0 -translate-y-4 h-0',
    open: 'opacity-100 translate-y-0 h-auto',
  },
  top: {
    closed: 'opacity-0 translate-y-4 h-0',
    open: 'opacity-100 translate-y-0 h-auto',
  },
}
/**
 * A button with a menu as a dropdown list.
 */
export default function Dropdown({
  alignment = 'bottom-start',
  className = '',
  button,
  children,
  icon,
  itemsClass = '',
  label,
  loading = false,
  onClick,
  size = 'md',
  variant,
}) {
  const alignmentClass =
    alignmentsStyles[alignment] ?? alignmentsStyles['bottom-start']
  const fromBottom = ['bottom-start', 'bottom-end'].includes(alignment)
  const transitionClass = fromBottom
    ? transitionsStyles.bottom
    : transitionsStyles.top

  return (
    <Menu>
      {({ open }) => (
        <div className={`relative inline-block ${className}`}>
          <Menu.Button
            className="inline-flex items-center focus:outline-none"
            as="div"
            onClick={onClick}
          >
            {button || (
              <Button
                label={label}
                icon={icon}
                pressed={open}
                loading={loading}
                secondaryIcon={
                  fromBottom
                    ? open
                      ? 'chevron-up'
                      : 'chevron-down'
                    : open
                      ? 'chevron-down'
                      : 'chevron-up'
                }
                size={size}
                variant={variant}
              />
            )}
          </Menu.Button>
          <Transition
            show={open}
            as={React.Fragment}
            enter="transition duration-300 ease-in-out"
            enterFrom={transitionClass.closed}
            enterTo={transitionClass.open}
            leave="transition duration-100 ease-out"
            leaveFrom={transitionClass.open}
            leaveTo={transitionClass.closed}
          >
            <Menu.Items
              className={`absolute ${alignmentClass} z-50 -ml-px mt-1 flex max-h-96 flex-col overflow-y-auto rounded border border-gray-200 bg-gray-50 shadow-lg focus:outline-none ${itemsClass}`}
              static
            >
              <React.Suspense fallback={<div />}>{children}</React.Suspense>
            </Menu.Items>
          </Transition>
        </div>
      )}
    </Menu>
  )
}
Dropdown.propTypes = {
  alignment: PropTypes.oneOf([
    'bottom-start',
    'bottom-end',
    'top-start',
    'top-end',
  ]),
  button: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.node,
  itemsClass: PropTypes.string,
  label: PropTypes.node,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  variant: PropTypes.oneOf([
    'ai',
    'basic',
    'primary',
    'success',
    'info',
    'warn',
    'danger',
    'flat',
    'link',
    'light',
  ]),
}

const itemTypes = {
  basic: {
    active: 'bg-primary-600 text-primary-50',
    normal: 'text-gray-600 hover:bg-primary-600 hover:text-primary-50',
    selected: 'bg-primary-100 text-primary-500',
    disabled: 'text-gray-300',
  },
  warn: {
    active: 'bg-warn-500 text-warn-50',
    normal: 'text-warn-500 hover:bg-warn-500 hover:text-warn-50',
    selected: 'bg-warn-100 text-warn-500',
    disabled: 'text-warn-300',
  },
  ai: {
    active: 'bg-ai-500 text-ai-50',
    normal: 'text-ai-500 hover:bg-ai-500 hover:text-ai-50',
    selected: 'bg-ai-100 text-ai-500',
    disabled: 'text-ai-300',
  },
  danger: {
    active: 'bg-danger-500 text-danger-50',
    normal: 'text-danger-500 hover:bg-danger-500 hover:text-danger-50',
    selected: 'bg-danger-100 text-danger-500',
    disabled: 'text-danger-300',
  },
}

/**
 * The individual Dropdown list item
 */
export function DropdownItem({
  disabled = false,
  href,
  icon,
  label,
  loading = false,
  onClick,
  selected,
  type = 'basic',
}) {
  const disabledClass = disabled ? '!cursor-not-allowed' : 'cursor-pointer'
  const typeClass = itemTypes[type] ?? itemTypes.basic

  return (
    <Menu.Item disabled={disabled}>
      {({ active }) => (
        <Link
          className={`group flex flex-row items-center justify-between space-x-3 px-4 py-2 text-start transition-all duration-200 ease-out ${
            typeClass[
              disabled
                ? 'disabled'
                : selected
                  ? 'selected'
                  : active
                    ? 'active'
                    : 'normal'
            ]
          } ${disabledClass}`}
          href={href}
          onClick={onClick}
          basic={false}
        >
          {(loading || icon) && (
            <div className="flex w-4 items-center justify-center">
              {typeof icon === 'string' ? (
                <Icon name={loading ? 'spinner-third' : icon} spin={loading} />
              ) : (
                icon
              )}
            </div>
          )}
          <div className="flex-grow whitespace-nowrap font-semibold">
            {label}
          </div>
          {selected && (
            <div className="flex w-4 items-center justify-center">
              <Icon name="check" />
            </div>
          )}
        </Link>
      )}
    </Menu.Item>
  )
}
DropdownItem.propTypes = {
  disabled: PropTypes.bool,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  type: PropTypes.oneOf(['ai', 'basic', 'warn', 'danger']),
}
Dropdown.Item = DropdownItem
Dropdown.Item.displayName = 'Dropdown.Item'

/**
 * The divider item
 */
export function DropdownDivider() {
  return <div className="my-1 border-b border-gray-300" />
}

Dropdown.Divider = DropdownDivider
Dropdown.Divider.displayName = 'Dropdown.Divider'

/**
 * The search item
 */
export function DropdownSearch({ onSearch, value }) {
  return (
    <div className="border-b border-gray-300 p-2">
      <Search
        onChange={e => onSearch(e.target.value)}
        onKeyDown={e => {
          if (e.code === 'Space') {
            e.stopPropagation()
          }
        }}
        value={value}
        showButton={false}
        icon="search"
      />
    </div>
  )
}
DropdownSearch.propTypes = {
  onSearch: PropTypes.func,
  value: PropTypes.string,
}

Dropdown.Search = DropdownSearch
Dropdown.Search.displayName = 'Dropdown.Search'
