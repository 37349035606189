import React from 'react'

import Clickable from '@ui/helpers/Clickable'
import useResizeElement from '@ui/helpers/useResizeElement'

/**
 * **Split panel** component.
 * @param {object} props The component props
 * @param {React.ReactNode} props.aside The aside content
 * @param {string} [props.asideClass=''] The class name for the aside
 * @param {number|string} [props.asideSize=200] The aside size
 * @param {number|string} [props.asideMinSize=200] The minimum aside size
 * @param {boolean} [props.asideEnabled=true] Whether the aside is enabled
 * @param {string} [props.className=''] The additional class name for the panel
 * @param {React.ReactNode} props.children The children of the panel
 * @param {string} [props.contentClass=''] The class name for the content
 * @param {string} props.localStoreKey (**required**) The local storage key
 * @param {boolean} [props.reverse] Whether to reverse the panel
 * @param {boolean} [props.vertical] Whether the panel is vertical
 * @returns {React.ReactElement} The split panel component
 */
export default function SplitPanel({
  aside,
  asideClass = '',
  asideSize = 200,
  asideMinSize = 200,
  asideEnabled = true,
  className = '',
  children,
  contentClass = '',
  localStoreKey,
  reverse,
  vertical,
}) {
  const { elementRef, dragging, onMouseDown } = useResizeElement({
    defaultSize: parseInt(asideSize ?? '200', 10),
    localStoreKey,
    enabled: asideEnabled,
    reverse,
    minSize: asideMinSize,
  })

  if (!aside) {
    return children
  }

  return (
    <div
      className={`isolate flex flex-1 self-stretch ${
        vertical
          ? `flex-col ${reverse ? 'flex-col-reverse' : ''}`
          : `flex-row ${reverse ? 'flex-row-reverse' : ''}`
      } ${className}`}
    >
      <div
        className={`${
          vertical
            ? `overflow-y-scroll ${
                asideEnabled ? 'max-h-screen min-h-[8rem]' : 'max-h-0 min-h-0'
              }`
            : `overflow-x-scroll ${
                asideEnabled
                  ? `max-w-full min-w-[${
                      typeof asideMinSize === 'number'
                        ? `${asideMinSize}px`
                        : asideMinSize
                    }]`
                  : 'min-w-0 max-w-0'
              }`
        } ${asideClass}`}
        ref={elementRef}
      >
        {aside}
      </div>
      {asideEnabled && (
        <Clickable
          onMouseDown={onMouseDown}
          className={`group z-10 flex cursor-col-resize select-none ${
            vertical
              ? `-my-1 flex-col py-1 ${reverse ? 'flex-col-reverse' : ''}`
              : `-mx-2 flex-row px-2 ${reverse ? 'flex-row-reverse' : ''}`
          } ${vertical ? 'cursor-[ns-resize]' : 'cursor-[ew-resize]'}`}
        >
          <div
            className={`flex-1 border-gray-200 transition-all duration-300 ease-in-out group-hover:border-primary-400 ${
              dragging ? 'border-primary-400' : ''
            } ${vertical ? 'border-t' : 'border-l'}`}
          />
        </Clickable>
      )}
      <div className={`flex flex-1 flex-col ${contentClass}`}>{children}</div>
    </div>
  )
}
