import React from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import useIsUserAuthorized from '@modules/auth/helpers/useIsUserAuthorized'

import { getConfigurePaymentsUrl, getProjectsUrl } from '../helpers/urls'

const MenuGroup = React.lazy(() => import('@ui/navigation/Menu/MenuGroup'))
const MenuItem = React.lazy(() => import('@ui/navigation/Menu/MenuItem'))
const MenuDivider = React.lazy(() => import('@ui/navigation/Menu/MenuDivider'))

export default function PaymentsMenuGroup() {
  const { t } = useTranslation('payments/public')
  const location = useLocation()

  // Get permissions
  const canReadPayment = useIsUserAuthorized({
    module: 'payment-methods',
    permission: 'read',
  })

  // If user can't read any of the submodules, don't show the menu
  if (!canReadPayment) {
    return null
  }

  const inPayments = location.pathname.startsWith('/payments')
  const inProjects = location.pathname.startsWith('/payments/projects')

  const inPaymentsConfigure = location.pathname.startsWith(
    '/payments/configure'
  )

  return (
    <MenuGroup
      name="payments"
      label={t('paymentsAndDonations')}
      icon="donate"
      active={inPayments}
    >
      <MenuItem
        name="projects"
        label={t('projects')}
        icon="tag"
        href={getProjectsUrl()}
        active={inProjects}
      />
      {canReadPayment && (
        <>
          <MenuDivider />
          <MenuItem
            name="payment-methods"
            label={t('paymentsConfig')}
            icon="wrench"
            href={getConfigurePaymentsUrl()}
            active={inPaymentsConfigure}
          />
        </>
      )}
    </MenuGroup>
  )
}
