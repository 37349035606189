import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useNode } from '@craftjs/core'
import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'

import { CountryPickerField as CountryPicker } from '@modules/countries/components/CountryPicker'
import useEntities from '@modules/entities/services/hooks/useEntities'
import useEntityTypes from '@modules/entities/services/hooks/useEntityTypes'
import FormVariantSelect from '@modules/web/components/ContentEditor/blocks/Form/shared/FormVariantSelect'
import BlockField from '@modules/web/components/ContentEditor/shared/BlockField'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { useBlock } from '@modules/web/helpers/useBlock'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Button from '@ui/buttons/Button'
import { fitMapToBounds } from '@ui/data-display/Map/helpers'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { GeocodeSearch } from '@ui/data-entry/GeocodeSearch'
import { InputField as Input } from '@ui/data-entry/Input'
import { LocationPickerField as LocationPicker } from '@ui/data-entry/LocationPicker'
import { SelectField as Select, SelectOption } from '@ui/data-entry/Select'
import Divider from '@ui/layout/Divider'
import Pagination from '@ui/navigation/Pagination'

import { EntityPickerField } from '../../entities/EntityPicker'
import ChurchItem from './shared/ChurchItem'

const UIMap = React.lazy(() => import('@ui/data-display/Map'))

const config = {
  name: 'ChurchFinder',
  label: 'entities/public:ChurchFinder',
  type: 'plugin',
  icon: 'church',
  component: <ChurchFinder />,
}

const searchRadiuses = [10, 20, 30, 40, 50] // in Kilometers

const EARTH_EQUATORIAL_RADIUS_KM = 6378.1

function getNearFilter(center, radius = 30) {
  if (!center) return undefined

  return {
    $geoWithin: {
      $centerSphere: [center, radius / EARTH_EQUATORIAL_RADIUS_KM],
    },
  }
}

function getBoundsFromLatLng(center, radius) {
  if (!Array.isArray(center) || radius < 0) {
    return null
  }

  const [longitude, lattitude] = center

  var latChange = radius / 111.2 // based on the conversion of latitude degree to kms where 1 degree latitude ~ 111.2 km.
  var loChange = Math.abs(Math.cos(lattitude * (Math.PI / 180)))

  const west = longitude - loChange
  const south = lattitude - latChange
  const east = longitude + loChange
  const north = lattitude + latChange

  return [west, south, east, north]
}

export default function ChurchFinder({
  detailPage,
  country,
  pagination,
  perPage,
  region,
  showAmenities,
  showResults,
  showSearch,
}) {
  const { t } = useTranslation('entities/public')
  const { longitude, latitude, boundingBox } = region || {}
  const [map, setMap] = useState()
  const [searchRadius, setSearchRadius] = useState(searchRadiuses[2]) // 30
  const [geolocation, setGeolocation] = useState()
  const [geocodeSearch, setGeocodeSearch] = useState('')
  const nearLocationFilter = getNearFilter(geolocation, searchRadius)

  const { entities: churchMarkers, count } = useEntities({
    filters: {
      'location': nearLocationFilter,
      'address.country': country,
    },
    types: ['church'],
    fields: 'location',
  })

  const radiusBoundingBox = useMemo(
    () => getBoundsFromLatLng(geolocation, searchRadius),
    [searchRadius, geolocation]
  )

  const { entities: churchItems } = useEntities({
    filters: {
      location: nearLocationFilter,
    },
    types: ['church'],
    limit: perPage,
    enabled: showResults,
  })

  const [viewport, setViewport] = useState({
    longitude,
    latitude,
  })

  useEffect(() => {
    if (!(longitude && latitude)) return undefined

    setViewport({
      longitude,
      latitude,
    })
  }, [longitude, latitude])

  const onChurchLocate = useCallback(
    church => {
      if (!church?.location?.coordinates) return

      map.flyTo({
        center: church.location.coordinates,
        essential: true,
        speed: 8,
        curve: 1,
        zoom: 18,
        minZoom: 10,
      })
    },
    [map]
  )

  const onResetMap = useCallback(() => {
    fitMapToBounds(map, boundingBox)
    setGeocodeSearch('')
    setGeolocation(null)
  }, [map, boundingBox])

  const onGeolocate = useCallback(
    response => {
      if (!response || typeof response.coords !== 'object') return
      const { coords } = response
      const { longitude, latitude } = coords

      if (geolocation?.[0] !== longitude || geolocation?.[1] !== latitude) {
        setGeolocation([longitude, latitude])
      }
    },
    [geolocation]
  )

  const markers = useMemo(() => {
    const items = []

    if (geolocation) {
      items.push({
        location: {
          coordinates: geolocation,
          center: true,
        },
      })
    }

    if (churchMarkers?.length > 0) {
      items.push(...churchMarkers)
    }

    return items
  }, [churchMarkers, geolocation])

  return (
    <Content
      icon={config.icon}
      title={t('ChurchFinder')}
      innerClass="space-y-6"
    >
      <UIMap
        boundingBox={radiusBoundingBox || boundingBox}
        markers={markers.map(({ location }) => location)}
        viewport={viewport}
        markerIcon="church"
        onMove={setViewport}
        onGeolocate={onGeolocate}
        getMap={setMap}
        showGeolocate
        showControls
        height={400}
      />
      {showSearch && (
        <div className="flex justify-between space-x-4">
          <div className="flex grow flex-row justify-between space-x-2">
            <GeocodeSearch
              className="grow"
              country={country}
              onChange={value => {
                setGeolocation(value?.center)
                setGeocodeSearch(value?.place_name)
              }}
              onSearch={value => setGeocodeSearch(value)}
              value={geocodeSearch}
            />
            <Select
              value={searchRadius}
              onChange={e => setSearchRadius(e.target.value)}
            >
              {searchRadiuses.map(radius => (
                <SelectOption
                  key={`radius-${radius}`}
                  value={radius}
                  label={`${radius} km`}
                />
              ))}
            </Select>
          </div>
          <Button title={t('reset')} onClick={onResetMap} icon="undo" />
        </div>
      )}
      {showResults && (
        <div className="divide-y">
          {churchItems?.map(church => (
            <ChurchItem
              key={church.id}
              church={church}
              onLocate={onChurchLocate}
              detailPage={detailPage}
              showAmenities={showAmenities}
            />
          ))}
        </div>
      )}
      {showResults && pagination && count > perPage && (
        <Pagination page={1} pageSize={perPage} total={count} />
      )}
    </Content>
  )
}
ChurchFinder.propTypes = {
  country: PropTypes.string,
  detailPage: PropTypes.string,
  pagination: PropTypes.bool,
  perPage: PropTypes.number,
  region: PropTypes.shape({
    placeName: PropTypes.string,
    longitude: PropTypes.number,
    latitude: PropTypes.number,
    boundingBox: PropTypes.array,
  }),
  showRe: PropTypes.bool,
  showAmenities: PropTypes.bool,
  showResults: PropTypes.bool,
  showSearch: PropTypes.bool,
}
ChurchFinder.toolbarItem = config

function ChurchFinderSettings() {
  const { t } = useTranslation('entities/public')

  const { showAmenities, showAdvancedSearch, searchByEntityType } = useBlock(
    config.name
  )

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { types: entityTypes = [] } = useEntityTypes({
    enabled:
      showAdvancedSearch && searchByEntityType && data.showAdvancedSearch,
  })

  return (
    <SettingsWrap title={t('ChurchFinder')} help={t('ChurchFinderHelp')}>
      <div className="space-y-4">
        <LocationPicker
          name="region"
          label={t('defaultRegion')}
          help={t('defaultRegionHelp')}
          value={data.region}
          onChange={value => {
            actions.setProp(
              props => (props.region = isEmpty(value) ? undefined : value)
            )
          }}
          height={250}
        />

        <Checkbox
          name="showSearch"
          label={t('showSearch')}
          help={t('showSearchHelp')}
          value={data.showSearch}
          onChange={value => {
            actions.setProp(props => (props.showSearch = value))
          }}
        />

        {data.showSearch && (
          <FormVariantSelect
            name="searchFormVariant"
            label={t('searchFormVariant')}
            help={t('searchFormVariantHelp')}
          />
        )}

        <EntityPickerField
          name="entity"
          label={t('entity')}
          help={t('entityMapHelp')}
          types={[
            'division',
            'unionConference',
            'unionOfChurchesConference',
            'conference',
            'convent',
            'district',
          ]}
          value={data.entity}
          onChange={value => {
            actions.setProp(props => (props.entity = value))
          }}
        />

        <CountryPicker
          name="country"
          label={t('country')}
          help={t('countryHelp')}
          value={data.country}
          onChange={value => {
            actions.setProp(props => (props.country = value))
          }}
        />

        <Divider />

        <Checkbox
          name="showResults"
          label={t('showResults')}
          help={t('showResultsHelp')}
          value={data.showResults}
          onChange={value => {
            actions.setProp(props => (props.showResults = value))
          }}
        />

        {showAmenities && data.showResults && (
          <Checkbox
            name="showAmenities"
            label={t('showAmenities')}
            help={t('showAmenitiesHelp')}
            value={data.showAmenities}
            onChange={value => {
              actions.setProp(props => (props.showAmenities = value))
            }}
          />
        )}
        {showAdvancedSearch && (
          <Checkbox
            name="showAdvancedSearch"
            label={t('showAdvancedSearch')}
            help={t('showAdvancedSearchHelp')}
            value={data.showAdvancedSearch}
            onChange={value => {
              actions.setProp(props => (props.showAdvancedSearch = value))
            }}
          />
        )}
        {searchByEntityType &&
          showAdvancedSearch &&
          data.showAdvancedSearch && (
            <Select
              label={t('searchByEntityType')}
              help={t('searchByEntityTypeHelp')}
              onChange={e => {
                actions.setProp(
                  props => (props.searchByEntityType = e.target.value)
                )
              }}
              value={data.searchByEntityType}
            >
              <Select.Placeholder label="-" />
              {entityTypes?.map(type => (
                <Select.Option
                  label={t(`type_${type.name}`)}
                  key={type.name}
                  value={type.name}
                />
              ))}
            </Select>
          )}

        <Checkbox
          name="pagination"
          label={t('pagination')}
          help={t('paginationHelp')}
          value={data.pagination}
          onChange={value => {
            actions.setProp(props => (props.pagination = value))
          }}
        />

        {data.pagination && (
          <Input
            name="perPage"
            label={t('perPage')}
            help={t('perPageHelp')}
            value={data.perPage}
            onChange={e => {
              const perPage = parseInt(e.target.value)
              actions.setProp(
                props => (props.perPage = perPage > 1 ? perPage : 1)
              )
            }}
            type="number"
          />
        )}

        <BlockField name="resultsOrder">
          {({ fieldProps, value, onDataChange }) => (
            <Select
              {...fieldProps}
              label={t('churchFinderSort')}
              help={t('churchFinderSortHelp')}
              value={value}
              onChange={e => onDataChange(e.target.value)}
            >
              <Select.Placeholder label="-" />
              <Select.Option
                label={t('churchFinderSort_distance')}
                value="distance"
              />
              <Select.Option
                label={t('churchFinderSort_alphabetical')}
                value="alphabetical"
              />
            </Select>
          )}
        </BlockField>

        <Divider />

        <PagePicker
          name="detailPage"
          label={t('detailPage')}
          help={t('detailPageHelp')}
          value={data.detailPageId}
          onChange={value => {
            actions.setProp(props => (props.detailPageId = value))
          }}
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Entity'
          }
        />
      </div>
    </SettingsWrap>
  )
}

ChurchFinder.craft = {
  displayName: 'ChurchFinder',
  props: {
    pagination: true,
    perPage: 10,
    showResults: true,
    showSearch: true,
  },
  custom: {
    type: config.type,
    resources: ['churchFinder'],
    i18nNamespaces: ['church-finder'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ChurchFinderSettings,
  },
}
