import PropTypes from 'prop-types'
import React from 'react'

import Badge from '../data-display/Badge'

export default function Section({
  adminOnly,
  children,
  className = '',
  description,
  id,
  title,
}) {
  const scrollMarginClass = 'pt-4 -mt-4' // scroll-margin-top: 16px
  return (
    <div className={`space-y-6 ${className}`}>
      {(title || description) && (
        <div className="px-3">
          {title && (
            <div className="flex items-center">
              <h2
                className={`text-xl font-semibold ${scrollMarginClass}`}
                id={id}
              >
                {title}
              </h2>
              {adminOnly && <Badge text="admin" variant="danger" />}
            </div>
          )}
          {description && <p className="text-gray-500">{description}</p>}
        </div>
      )}
      <div className="rounded-md bg-white shadow">{children}</div>
    </div>
  )
}
Section.propTypes = {
  adminOnly: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
}

function SectionHeader({
  children,
  className = '',
  description,
  id,
  title,
  titleExtra,
}) {
  return (
    <div
      className={`space-y-2 rounded-t-md border-b bg-white px-10 py-6 ${className}`}
    >
      <div className="flex justify-between">
        {title && (
          <h3 className="text-lg font-semibold" id={id}>
            {title}
          </h3>
        )}
        {titleExtra && <div className="flex items-center">{titleExtra}</div>}
      </div>
      {description && <p className="text-gray-500">{description}</p>}
      {children}
    </div>
  )
}
SectionHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.node,
  titleExtra: PropTypes.node,
}
Section.Header = SectionHeader
Section.Header.displayName = 'Section.Header'

function SectionBody({ children, gapClassName = 'gap-6', className = '' }) {
  return (
    <div className={`flex flex-col ${gapClassName} p-10 ${className}`}>
      {children}
    </div>
  )
}
SectionBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  gapClassName: PropTypes.string,
}
Section.Body = SectionBody
Section.Body.displayName = 'Section.Body'

function SectionFooter({ children, className = '' }) {
  return (
    <div
      className={`flex justify-end rounded-b-md border-t bg-gray-50 bg-opacity-50 px-10 py-6 ${className}`}
    >
      {children}
    </div>
  )
}
SectionFooter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
Section.Footer = SectionFooter
Section.Footer.displayName = 'Section.Footer'
