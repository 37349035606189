import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import DesignColorSelectController from '@modules/web/components/shared/DesignColorSelect'
import Input from '@ui/data-entry/Input'

export function ThemeFields({ prefix = '' }) {
  const { t } = useTranslation(['designs/adventist', 'designs/base'])

  return (
    <div className="flex flex-col gap-4">
      <Input name={`${prefix}name`} label={t('name')} required />
      <DesignColorSelectController
        name={`${prefix}colorTheme`}
        label={t('colorTheme')}
      />
    </div>
  )
}

ThemeFields.propTypes = {
  prefix: PropTypes.string,
}
