import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'
import Heading from '@ui/typography/Heading'

import useChannel from '../../services/hooks/useChannel'
import useCollection from '../../services/hooks/useCollection'
import ChannelSelect from '../ChannelSelect'
import CollectionSelect from '../CollectionSelect'
import CollectionItem from '../shared/CollectionItem'

const config = {
  name: 'Collection',
  label: 'media-library/public:collectionDetail',
  type: 'plugin',
  icon: 'file-invoice',
  component: <Collection />,
}

export default function Collection({ channelId, id }) {
  const { t } = useTranslation('media-library/public')
  const showContent = false

  const { resources } = usePageResources()
  const { channel } = useChannel(channelId)
  const { collection } = useCollection(id)

  const _channel = resources?.Channel ?? channel
  const _collection = resources?.Collection ?? collection

  return (
    <Content icon={config.icon} title={t('collectionDetail')}>
      {showContent ? (
        <CollectionItem collection={_collection} />
      ) : (
        <Box space="xs">
          <Heading as="h6" className="ml-2" text={_collection?.title} />
          <ul className="ml-8 list-disc text-sm">
            <li>
              {t('channel')}: {_channel?.title}
            </li>
            <li>
              {t('collection')}: {_collection?.title || t('dynamic')}
            </li>
          </ul>
        </Box>
      )}
    </Content>
  )
}
Collection.propTypes = {
  channelId: PropTypes.string,
  id: PropTypes.string,
}
Collection.toolbarItem = config

function CollectionSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(data.channelId || data.id)

  const { resources } = usePageResources()

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  const onCollectionUpdate = useCallback(
    (fieldName, parser = x => x) =>
      collection => {
        actions.setProp(props => (props[fieldName] = parser(collection.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap
      title={t('collectionDetail')}
      help={t('collectionDetailHelp')}
    >
      <Box space="xl">
        {resources?.Channel && !resources?.Collection && (
          <Message className="text-sm" type="warn">
            {resources?.Channel?.title}
          </Message>
        )}
        {resources?.Collection && (
          <Message className="text-sm" type="warn">
            {resources?.Collection?.title}
          </Message>
        )}
        <PagePicker
          label={t('showDetailPageId')}
          help={t('showDetailPageIdHelp')}
          value={data.showDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.showDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Show'
          }
        />
        <PagePicker
          label={t('episodeDetailPageId')}
          help={t('episodeDetailPageIdHelp')}
          value={data.episodeDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.episodeDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Episode'
          }
        />
        <Input
          label={t('common:perPage')}
          help={t('common:perPageHelp')}
          onChange={onFieldUpdate('limit', value => {
            return value ? parseInt(isNaN(value) || value < 0 ? 0 : value) : 0
          })}
          value={data.limit}
          type="number"
        />
        <Checkbox
          name="enableAnimations"
          label={t('enableAnimations')}
          help={t('enableAnimationsCardsHelp')}
          value={data.enableAnimations}
          onChange={checked =>
            actions.setProp(p => (p.enableAnimations = checked))
          }
        />
        <Divider />
        <Checkbox
          label={t('pickCollectionManually')}
          help={t('pickCollectionManuallyDynamicHelp')}
          onChange={value => setShowPicker(value)}
          value={showPicker}
        />
        {showPicker && (
          <Box space="lg">
            {!resources?.Channel && (
              <ChannelSelect
                label={t('channel')}
                onChange={onChannelUpdate('channelId')}
                value={data.channelId}
                controlled={false}
              />
            )}
            <CollectionSelect
              channelId={data.channelId || resources?.Channel?.id}
              label={t('collection')}
              onChange={onCollectionUpdate('id')}
              value={data.id}
              controlled={false}
            />
          </Box>
        )}
      </Box>
    </SettingsWrap>
  )
}

Collection.craft = {
  displayName: 'Collection',
  props: {
    channelId: undefined,
    id: undefined,
    showDetailPageId: undefined,
    episodeDetailPageId: undefined,
    limit: 24,
  },
  custom: {
    type: config.type,
    resources: ['collection'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CollectionSettings,
  },
}
