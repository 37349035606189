import PropTypes from 'prop-types'
import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import Field from '@ui/data-entry/Field'
import Box from '@ui/layout/Box'
import Heading from '@ui/typography/Heading'

const RichText = React.lazy(() => import('@ui/data-entry/RichText/Render'))

const config = {
  name: 'EpisodeDetail',
  label: 'media-library/public:episodeDetail',
  type: 'plugin',
  icon: 'file-invoice',
  component: <EpisodeDetail />,
}

function getPlaceholder() {
  return {
    title: '[Placeholder title]',
    abstract: '[Placeholder for abstract.]',
    plays: 1234,
    body: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: "[This is a placeholder for episode's body]",
            },
          ],
        },
      ],
    },
    show: {
      title: '[Show title]',
    },
  }
}

export default function EpisodeDetail({
  showAbstract,
  showBody,
  showShowTitle,
  showTitle,
  showViews,
}) {
  const { t } = useTranslation('media-library/public')
  const showContent = true

  const { resources } = usePageResources()

  const { abstract, body, plays, show, title } = getPlaceholder()

  return (
    <Content className="w-full" icon={config.icon} title={t('episodeDetail')}>
      {showContent ? (
        <div className="flex flex-col p-4">
          <div className="max-w-xl space-y-6">
            {(showShowTitle || showViews) && (
              <div className="flex items-center justify-between">
                {showShowTitle && (
                  <div className="font-bold uppercase">{show.title}</div>
                )}
                {showViews && (
                  <div className="rounded bg-gray-200 px-2 py-0.5 text-sm font-bold uppercase">
                    {plays} {t('views')}
                  </div>
                )}
              </div>
            )}
            {showTitle && <h1 className="text-3xl font-bold">{title}</h1>}
            {showAbstract && abstract && (
              <p className="font-serif italic text-gray-600">{abstract}</p>
            )}
            {showBody && body && <RichText doc={body} />}
          </div>
        </div>
      ) : (
        <Box space="xs">
          <Heading
            as="h6"
            className="ml-2"
            text={
              resources?.Channel && !resources?.Show
                ? resources.Channel.title
                : resources?.Show
                  ? resources.Show.title
                  : null
            }
          />
          <ul className="ml-8 list-disc text-sm">
            {showTitle && <li>{t('title')}</li>}
            {showAbstract && <li>{t('abstract')}</li>}
            {showBody && <li>{t('body')}</li>}
          </ul>
        </Box>
      )}
    </Content>
  )
}
EpisodeDetail.propTypes = {
  showAbstract: PropTypes.bool,
  showBody: PropTypes.bool,
  showShowTitle: PropTypes.bool,
  showTitle: PropTypes.bool,
  showViews: PropTypes.bool,
}
EpisodeDetail.toolbarItem = config

function EpisodeDetailSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { resources } = usePageResources()

  return (
    <SettingsWrap title={t('episodeDetail')} help={t('episodeDetailHelp')}>
      <Box space="xl">
        {resources?.Channel && !resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources.Channel.title}
          </Message>
        )}
        {resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources.Show.title}
          </Message>
        )}
        <Field label={t('fields')}>
          <Box space="xs">
            <Checkbox
              label={t('showTitle')}
              onChange={value => {
                actions.setProp(props => (props.showShowTitle = value))
              }}
              value={data.showShowTitle}
              name="showShowTitle"
            />
            <Checkbox
              label={t('title')}
              onChange={value => {
                actions.setProp(props => (props.showTitle = value))
              }}
              value={data.showTitle}
              name="showTitle"
            />
            <Checkbox
              label={t('abstract')}
              onChange={value => {
                actions.setProp(props => (props.showAbstract = value))
              }}
              value={data.showAbstract}
              name="showAbstract"
            />
            <Checkbox
              label={t('body')}
              onChange={value => {
                actions.setProp(props => (props.showBody = value))
              }}
              value={data.showBody}
              name="showBody"
            />
            <Checkbox
              label={t('views')}
              onChange={value => {
                actions.setProp(props => (props.showViews = value))
              }}
              value={data.showViews}
              name="showViews"
            />
          </Box>
        </Field>
      </Box>
    </SettingsWrap>
  )
}

EpisodeDetail.craft = {
  displayName: 'EpisodeDetail',
  props: {
    showAbstract: true,
    showBody: true,
    showShowTitle: true,
    showTitle: true,
    showViews: true,
  },
  custom: {
    type: config.type,
    resources: ['episodeDetail'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EpisodeDetailSettings,
  },
}
