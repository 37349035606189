import PropTypes from 'prop-types'
import React from 'react'

import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import ColorPreview from '@ui/data-display/ColorPreview'
import Message from '@ui/data-display/Message'
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '@ui/feedback/FloatingDialog'
import Link from '@ui/navigation/Link'
import { isObject, omit } from '@utils/objects'
import { isFunction, isString } from '@utils/types'

import { Color } from './Color'
import Field from './Field'
import { InputField as Input } from './Input'
import { TextArea } from './TextArea'
import { useRules } from './validationHooks'

// Valid color keys for a palette based on Tailwind CSS (50-950)
const validColors = [
  '50',
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
  '950',
]

/**
 * Parse string in a doubious JSON format to color palette object compatible with Tailwind CSS.
 * The only fromat requirement is one color per line, with key and value separated by a colon.
 * It is resilient to some basic errors like:
 * - missing commas, double quotes, and curly braces
 * - use of single quotes
 * - invalid color keys
 * - invalid color values
 * @param {*} rawValue
 * @returns
 */
function parseRawColors(rawValue, validColorVariants = validColors) {
  return rawValue
    .replace(/[{}'",]/g, '') // Remove unwanted characters
    .split('\n') // Split by line
    .map(
      line => line.split(':').map(item => item.trim())
      // split by colons and remove leading and trailing spaces from both key and value
    )
    .reduce((acc, [key, value]) => {
      // Ignore entries with empty keys or values
      if (!key || !value) return acc

      // Ignore when key is not a valid color
      if (!validColorVariants.includes(key)) return acc

      // Ignore when value is not a valid color
      if (!`${value}`.match(/#?([\da-fA-F]{2})([\da-fA-F]{2})([\da-fA-F]{2})/g))
        return acc

      // Add # to key if it's missing
      if (!value.startsWith('#')) value = `#${value}`

      return { ...acc, [key]: value }
    }, {})
}

/**
 * Color palette input component
 * @param {object} props
 * @param {string} [props.className] - Additional class names
 * @param {string} [props.name] - Input name
 * @param {string} [props.title] - Dialog title
 * @param {function} [props.onChange] - On change callback
 * @param {function} [props.onDelete] - On delete callback
 * @param {string[]} [props.validColors] - Valid color keys for the palette. Default is Tailwind CSS colors (50-950)
 * @param {string|object} [props.value] - Color palette value
 * @returns {JSX.Element} Color palette input component
 */
export function ColorPaletteInput({
  className = '',
  name = 'colorPalette',
  onChange,
  onDelete,
  title,
  validColors: validColorsOverride,
  value,
}) {
  const { t } = useTranslation()
  const [colorKey, setColorKey] = React.useState()
  const [paletteName, setPaletteName] = React.useState('')
  const [rawEdit, setRawEdit] = React.useState(false)
  const [rawValue, setRawValue] = React.useState()
  const [rawHasError, setHasError] = React.useState()

  // Parse color map from value prop to ensure it's an object
  const palette = React.useMemo(
    () => (isString(value) ? JSON.parse(value) : isObject(value) ? value : {}),
    [value]
  )

  const validColorVariants = React.useMemo(
    () => validColorsOverride || validColors,
    [validColorsOverride]
  )

  // update raw value
  React.useEffect(() => {
    if (rawEdit) {
      const paletteColors = omit(palette, ['name', 'code'])
      setRawValue(JSON.stringify(paletteColors, null, 2))
    }
  }, [rawEdit, palette])

  // update palette name
  React.useEffect(() => {
    if (palette.name && !paletteName) {
      setPaletteName(palette.name)
    }
  }, [palette, paletteName])

  // Set color to palette (adds or updates)
  const setColor = React.useCallback(
    (key, value) => {
      setColorKey(key)

      if (isFunction(onChange)) {
        onChange({ ...palette, [key]: value })
      }
    },
    [palette, onChange]
  )

  // Removes a color from palette
  const removeColor = React.useCallback(
    key => {
      if (isFunction(onChange)) {
        onChange(
          Object.fromEntries(Object.entries(palette).filter(([k]) => k !== key))
        )
      }
    },
    [palette, onChange]
  )

  // Handle raw edit
  const handleRawChange = React.useCallback(e => {
    setRawValue(e.target.value)
  }, [])

  // Handle name edit
  const handleNameChange = React.useCallback(
    e => {
      const name = e.target.value
      setPaletteName(name)

      if (isFunction(onChange)) {
        onChange({ ...palette, name })
      }
    },
    [palette, onChange]
  )

  const checkRaw = React.useCallback(() => {
    if (!rawEdit) {
      // Enable raw edit
      setRawEdit(true)
      return
    }

    // Parse raw value to object
    const parsed = parseRawColors(rawValue, validColorVariants)

    // Check if parsed is an object
    if (!isObject(parsed)) {
      // if not, set error
      setHasError(true)
    }

    onChange({
      ...parsed,
      name: paletteName,
      ...(value?.code ? { code: value.code } : {}),
    }) // Update palette, keeping name and code
    setRawEdit(false) // Disable raw edit
    setHasError(false) // Reset error
  }, [
    rawEdit,
    rawValue,
    validColorVariants,
    onChange,
    paletteName,
    value?.code,
  ])

  return (
    <Dialog>
      <div>
        <div className="flex items-center justify-between gap-2">
          <DialogTrigger asChild>
            <div className="flex items-start gap-4">
              <div className="flex flex-wrap gap-4">
                {validColorVariants.map(key => (
                  <ColorPreview
                    color={palette[key]}
                    number={key}
                    hideLabel
                    key={`color-${key}`}
                    size="lg"
                  />
                ))}
              </div>

              <div className="flex gap-4 pt-1">
                <Button icon="edit" size="sm" label={t('edit')} />
                {isFunction(onDelete) && (
                  <Button
                    size="sm"
                    onClick={onDelete}
                    icon="trash-alt"
                    variant="danger-light"
                  />
                )}
              </div>
            </div>
          </DialogTrigger>
        </div>
        <DialogContent
          title={title}
          boxClass="m-4 sm:max-w-sm lg:max-w-md sm:min-w-[320px] lg:min-w-[480px]"
        >
          {({ setOpen }) => (
            <div className={`flex flex-col gap-4 ${className}`}>
              <Input
                name={name}
                value={paletteName}
                onChange={handleNameChange}
                placeholder={t('paletteName')}
              />
              {rawEdit ? (
                <div className="flex flex-col gap-4">
                  <TextArea
                    className="min-h-[28rem] font-mono leading-8"
                    value={rawValue}
                    onChange={handleRawChange}
                    autosize={false}
                    aiImproveText={false}
                  />

                  {rawHasError && (
                    <div className="text-sm text-danger-500">
                      {t('invalidColorPalette')}
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-col gap-4">
                  <div className="flex min-h-[25rem] flex-col gap-1 rounded-md border px-1 py-2">
                    {validColorVariants.map(key => {
                      const value = palette[key]
                      return (
                        <div
                          className={`hover:mouse-pointer group flex select-none items-center justify-between gap-2 rounded-md p-1 ${
                            key === colorKey
                              ? 'bg-primary-100 hover:bg-primary-200'
                              : 'hover:bg-gray-100'
                          }`}
                          key={`color-${key}`}
                        >
                          <div className="flex flex-row items-center gap-2">
                            <div className="w-8 text-end font-mono">{key}</div>
                            <span className="text-gray-300">:</span>

                            <Color
                              value={value}
                              onChange={value => setColor(key, value)}
                              placeholder="#hex"
                            />
                          </div>
                          <div
                            className={`${
                              key === colorKey ? '' : 'opacity-0'
                            } group-hover:opacity-100`}
                          >
                            {value && (
                              <Button
                                icon="trash-alt"
                                size="xs"
                                variant="danger-light"
                                onClick={() => removeColor(key)}
                              />
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}

              <Message type="info" icon="info-circle" className="text-sm">
                <p>
                  {t('paletteColorsHelp')}
                  {': '}
                  <Link
                    href="https://grayscale.design/app"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://grayscale.design
                  </Link>
                </p>
              </Message>

              <div className="flex justify-between gap-4">
                <Button
                  onClick={() => checkRaw()}
                  label={t(rawEdit ? 'check' : 'rawEdit')}
                  icon={rawEdit ? 'check' : 'brackets-curly'}
                  variant={rawEdit ? 'success' : ''}
                />
                <Button
                  onClick={() => setOpen(false)}
                  label={t('ok')}
                  variant="primary"
                  disabled={rawEdit}
                />
              </div>
            </div>
          )}
        </DialogContent>
      </div>
    </Dialog>
  )
}
ColorPaletteInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  name: PropTypes.string,
  title: PropTypes.string,
  validColors: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export function ColorPaletteField({
  className,
  name,
  label,
  help,
  onChange,
  onDelete,
  validColors,
  value,
  error,
  required,
}) {
  return (
    <Field
      className={className}
      name={name}
      label={
        <span className="flex-grow">
          {value?.name ? (
            <strong className="font-bold">
              {value.name} <span className="font-normal">({label})</span>
            </strong>
          ) : (
            label
          )}
        </span>
      }
      help={help}
      error={error}
      required={required}
    >
      <ColorPaletteInput
        title={label}
        id={name}
        name={name}
        onChange={onChange}
        onDelete={onDelete}
        validColors={validColors}
        value={value}
      />
    </Field>
  )
}
ColorPaletteField.propTypes = {
  className: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  help: PropTypes.string,
  label: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  required: PropTypes.bool,
  validColors: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

/**
 * Color field component
 */
export default function ColorPalette({
  className,
  label,
  name,
  help,
  onChange,
  onDelete,
  required,
  shouldUnregister,
  validColors,
  value,
}) {
  const { control } = useFormContext()

  const rules = useRules({ required })

  const handleChange = field => value => {
    field.onChange(value)

    if (isFunction(onChange)) {
      onChange(value)
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <ColorPaletteField
          className={className}
          name={name}
          label={label}
          help={help}
          error={fieldState.error}
          required={rules?.required?.value}
          onChange={handleChange(field)}
          onDelete={onDelete}
          validColors={validColors}
          value={field.value}
        />
      )}
    />
  )
}

ColorPalette.propTypes = {
  className: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  required: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
  validColors: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}
