import PropTypes from 'prop-types'
import React from 'react'

import { useEditor } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import Tabs from '@ui/data-display/Tabs'
import Loading from '@ui/feedback/Loading'
import Box from '@ui/layout/Box'

import SharedSettings from './SharedSettings'

export default function Settings() {
  const { SettingsComponent } = useEditor(state => {
    if (!state || typeof state !== 'object') return {}

    const [currentNodeId] = state?.events.selected || []
    const currentNode = state.nodes?.[currentNodeId]

    return {
      SettingsComponent: currentNode?.related?.settings,
    }
  })

  if (typeof SettingsComponent !== 'function') return null

  return (
    <div className="space-y-4 p-4">
      <React.Suspense fallback={<Loading />}>
        {React.createElement(SettingsComponent)}
      </React.Suspense>
    </div>
  )
}

export function SettingsWrap({
  children,
  help,
  title,
  showSharedSettings = true,
}) {
  const { t } = useTranslation('web/content-editor')
  const { node, actions } = useEditor((state, query) => {
    if (!state || typeof state !== 'object') return {}

    const [currentNodeId] = state?.events.selected || []
    const currentNode = state.nodes?.[currentNodeId]

    if (!currentNode) return {}

    return {
      node: {
        id: currentNodeId,
        name: currentNode.data.name,
        parent: currentNode.data.parent,
        isDeletable: query.node(currentNodeId).isDeletable(),
        hidden: query.node(currentNodeId).toSerializedNode().hidden,
        allowedChildren:
          currentNode.related && currentNode.related.allowedChildren,
      },
    }
  })

  return (
    <div className="space-y-4">
      <Box space="sm" horizontal justify="between">
        <div className="py-2">
          <h3 className="text-xl font-semibold">{title}</h3>
          <p className="text-xs text-gray-500">{help}</p>
        </div>
        <Button
          icon="times"
          variant="flat"
          onClick={() => actions.selectNode(null)}
        />
      </Box>

      <Tabs
        activeTab={node.allowedChildren ? 'blocks' : 'settings'}
        padding={false}
        size="sm"
        preventNavigation
        name="sidebar"
      >
        {node.allowedChildren && (
          <Tabs.Pane title={t('addBlock')} name="blocks" icon="plus-circle">
            <div>{React.createElement(node.allowedChildren)}</div>
          </Tabs.Pane>
        )}
        {(children || showSharedSettings) && (
          <Tabs.Pane
            title={t('settings')}
            name="settings"
            icon="cog"
            className="w-full space-y-4"
          >
            {children && <Box space="lg">{children}</Box>}
            {showSharedSettings && <SharedSettings />}
          </Tabs.Pane>
        )}
      </Tabs>
    </div>
  )
}
SettingsWrap.propTypes = {
  children: PropTypes.node,
  help: PropTypes.string,
  title: PropTypes.string,
  showSharedSettings: PropTypes.bool,
}
