import React from 'react'

import { useSearchParams } from 'react-router-dom'

export default function useQueryParams() {
  const [queryParams, setSearchParams] = useSearchParams()

  const setQueryParams = React.useCallback(
    (filter, value, { boolean = false, replace = false } = {}) => {
      const params = Object.fromEntries(queryParams)

      if (value || (boolean && value === false)) {
        params[filter] = value
      } else {
        delete params[filter]
        setSearchParams(params, { replace })
      }

      if (value) setSearchParams(params, { replace }) // So if there is a key but no value, we dont set the query param, which would create an infinite loop
    },
    [queryParams, setSearchParams]
  )

  const onResetQueryParams = React.useCallback(
    preserveParam =>
      setSearchParams(
        preserveParam && queryParams.get(preserveParam)
          ? { [preserveParam]: queryParams.get(preserveParam) }
          : {}
      ),
    [queryParams, setSearchParams]
  )

  return { queryParams, setQueryParams, onResetQueryParams }
}

export function toggleMultipleParam(param = '', value = '') {
  const selectedValues = param ? `${param}`.split(',') : []

  const updatedValues = selectedValues.includes(value)
    ? selectedValues.filter(st => st !== value)
    : [...selectedValues, value]

  return updatedValues.filter(Boolean).join(',')
}
