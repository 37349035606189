import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

/**
 * @typedef {object} FeatureFlag The feature flag object
 * @property {Array} designs The list of designs that can use this feature flag
 * @property {Array} entities The list of entities that can use this feature flag
 * @property {Array} networks The list of networks that can use this feature flag
 * @property {Array<'network'|'entity'|'site'|'design'>} releaseStrategies The list of release strategies that can use this feature flag
 * @property {Array} sites The list of specific sites that can use this feature flag
 *
 * @typedef {{[x: string]: FeatureFlag}} FeatureFlagsObject
 *
 * @typedef {import('react-query').UseQueryResult<FeatureFlagsObject,Error> } UseFeatureFlagKeys The feature flags keys query result
 */

/**
 * Check available feature flags keys
 * @returns {UseFeatureFlagKeys} The feature flags keys
 */
export function useFeatureFlagKeys() {
  /**
   * @type {UseFeatureFlagKeys}
   */
  return useQuery(
    ['feature-flags', 'keys'],
    () => getFetch('/api/feature-flags/keys'),
    {
      keepPreviousData: true,
      staleTime: 60 * 60 * 1000, // 1 hour
    }
  )
}
