import React from 'react'

import { useParams } from 'react-router-dom'

import useLayout from './useLayout'
import usePage from './usePage'
import useSite from './useSite'

export function useCurrentLayout() {
  const params = useParams()
  const { site } = useSite()
  const { page } = usePage() || {}

  const pageLayoutId = React.useMemo(() => {
    if (!page) {
      return null
    }

    if (page.layout) {
      return page.layout
    }

    if (page.ancestors) {
      for (const ancestor of page.ancestors.reverse()) {
        if (ancestor.layout) {
          return ancestor.layout
        }
      }
    }

    return null
  }, [page])

  // If the layout is available, we can use the color theme from layout
  const { layout } =
    useLayout(pageLayoutId || params?.layoutId || site?.defaultLayout) || {}

  return layout
}
