import PropTypes from 'prop-types'
import React from 'react'

import SelectController, { SelectField } from '@ui/data-entry/Select'
import { pick } from '@utils/objects'

import useChannels from '../services/hooks/useChannels'

export default function ChannelSelect({
  controlled = true,
  onChange,
  ...selectProps
}) {
  const { channels } = useChannels()

  const Select = controlled ? SelectController : SelectField

  const handleOnChange = e => {
    const channel = channels.find(c => c.id === e.target.value)
    onChange && onChange(pick(channel, ['title', 'id']))
  }

  return (
    <Select {...selectProps} onChange={handleOnChange} showClear>
      <Select.Placeholder label="-" />
      {channels?.map(channel => (
        <Select.Option
          key={channel.id}
          value={channel.id}
          label={channel.title}
        />
      ))}
    </Select>
  )
}
ChannelSelect.propTypes = {
  controlled: PropTypes.bool,
  onChange: PropTypes.func,
}
