import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Image from '@ui/data-display/Image'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import Field from '@ui/data-entry/Field'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'
import Heading from '@ui/typography/Heading'

import getFullName from '../../helpers/getFullName'
import useChannel from '../../services/hooks/useChannel'
import usePerson from '../../services/hooks/usePerson'
import ChannelSelect from '../ChannelSelect'
import { PersonPicker } from '../PersonPicker'

const RichText = React.lazy(() => import('@ui/data-entry/RichText/Render'))

const config = {
  name: 'PersonDetail',
  label: 'media-library/public:personDetail',
  type: 'plugin',
  icon: 'user',
  component: <PersonDetail />,
}

function getPlaceholder() {
  return {
    image: {},
    prefix: '[prefix]',
    firstName: '[first name]',
    middleName: '[middle name]',
    lastName: '[last name]',
    suffix: '[suffix]',
    body: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: "[This is a placeholder for person's body]",
            },
          ],
        },
      ],
    },
    placeholder: true,
  }
}

export default function PersonDetail({
  channelId,
  dynamic,
  id,
  showBody,
  showImage,
  showName,
}) {
  const { t } = useTranslation('media-library/public')
  const showContent = false

  const { resources } = usePageResources()
  const { channel } = useChannel(channelId)
  const { person } = usePerson(dynamic ? null : id)

  const _channel = resources?.Channel ? resources.Channel : channel
  const _person = resources?.Person
    ? resources.Person
    : person || getPlaceholder()

  const { image, body } = _person

  return (
    <Content icon={config.icon} title={t('personDetail')} locked={dynamic}>
      {showContent ? (
        <div className="flex flex-col p-4">
          <div className="max-w-xl space-y-6">
            {showImage && image && (
              <Image file={image} alt={getFullName(_person)} width={600} />
            )}
            {showName && (
              <h1 className="text-3xl font-bold">{getFullName(_person)}</h1>
            )}
            {showBody && body && <RichText doc={body} />}
          </div>
        </div>
      ) : (
        <Box space="xs">
          <Heading as="h6" className="ml-2" text={t('person')} />
          <ul className="ml-8 list-disc text-sm">
            <li>
              {t('channel')}: {_channel?.title}
            </li>
            <li>
              {t('person')}:{' '}
              {!_person.placeholder ? getFullName(_person) : t('dynamic')}
            </li>
            {showImage && <li>{t('image')}</li>}
            {showName && <li>{t('name')}</li>}
            {showBody && <li>{t('body')}</li>}
          </ul>
        </Box>
      )}
    </Content>
  )
}
PersonDetail.propTypes = {
  channelId: PropTypes.string,
  dynamic: PropTypes.bool,
  id: PropTypes.string,
  showBody: PropTypes.bool,
  showImage: PropTypes.bool,
  showName: PropTypes.bool,
}
PersonDetail.toolbarItem = config

function PersonDetailSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [personPicker, setPersonPicker] = useState(data.channelId || data.id)

  const { resources } = usePageResources()

  const onPersonIdChange = useCallback(
    value => {
      actions.setProp(props => (props.id = value))
    },
    [actions]
  )

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t('personDetail')} help={t('personDetailHelp')}>
      <Box space="xl">
        {resources?.Channel && !resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources.Channel.title}
          </Message>
        )}
        {resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources.Show.title}
          </Message>
        )}
        <Field label={t('fields')}>
          <Box space="xs">
            <Checkbox
              label={t('image')}
              onChange={value => {
                actions.setProp(props => (props.showImage = value))
              }}
              value={data.showImage}
              name="showImage"
            />
            <Checkbox
              label={t('name')}
              onChange={value => {
                actions.setProp(props => (props.showName = value))
              }}
              value={data.showName}
              name="showName"
            />
            <Checkbox
              label={t('body')}
              onChange={value => {
                actions.setProp(props => (props.showBody = value))
              }}
              value={data.showBody}
              name="showBody"
            />
          </Box>
        </Field>
        {!resources?.Show && (
          <>
            <Divider />
            <Checkbox
              label={t('pickPersonManually')}
              help={t('pickPersonManuallyHelp')}
              onChange={value => setPersonPicker(value)}
              value={personPicker}
            />
            {personPicker && (
              <Box space="lg">
                {!resources?.Channel && (
                  <ChannelSelect
                    label={t('channel')}
                    onChange={onChannelUpdate('channelId')}
                    value={data.channelId}
                    controlled={false}
                  />
                )}
                <PersonPicker
                  channelId={data.channelId || resources?.Channel?.id}
                  onChange={onPersonIdChange}
                  roles={['host']}
                  value={data.id}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </SettingsWrap>
  )
}

PersonDetail.craft = {
  displayName: 'PersonDetail',
  props: {
    channelId: undefined,
    id: undefined,
    showBody: true,
    showImage: true,
    showName: true,
  },
  custom: {
    type: config.type,
    resources: ['personDetail'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: PersonDetailSettings,
  },
}
