import React from 'react'

import config from './config'

const CollectionTeaserSettings = React.lazy(() => import('./Settings'))
const CollectionTeaserBlock = React.lazy(() => import('./Block'))

export default function CollectionTeaser(props) {
  return <CollectionTeaserBlock {...props} />
}

CollectionTeaser.toolbarItem = {
  ...config,
  component: <CollectionTeaser />,
}

CollectionTeaser.craft = {
  displayName: 'CollectionTeaser',
  props: {
    channelId: undefined,
    collectionId: undefined,
    collectionDetailPageId: undefined,
    showDetailPageId: undefined,
    episodeDetailPageId: undefined,
    pickExistingCollection: false,
    hideTitle: false,
    limit: 8,
    contentType: 'shows',
    layout: 'grid',
    itemLayout: 'cards',
    shows: [],
    episodes: [],
  },
  custom: {
    type: config.type,
    resources: ['collectionTeaser'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CollectionTeaserSettings,
  },
}
