import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { themeOptions } from '@app/designs/adventist/utils'
import Select from '@ui/data-entry/Select'

/**
 * Component for selecting the design variant.
 *
 * @typedef {object} ColorThemeSelectProps
 * @property {string} [help] - The help text
 * @property {string} [label] - The label text
 * @property {string} [name] - The name of the input. Defaults to 'colorTheme'
 * @property {object} site - The site object
 *
 * @param {ColorThemeSelectProps} props
 * @returns {JSX.Element}
 */
export function ColorThemeSelect({
  help,
  label,
  name = 'colorTheme',
  required,
  site,
}) {
  const { t } = useTranslation('designs/adventist')

  const { themes = [] } = site?.design || {}

  return (
    <Select
      label={label || t('colorTheme')}
      help={help || t('colorThemeHelp')}
      name={name}
      required={required}
      shouldUnregister
    >
      <Select.Placeholder label="-" />
      {themeOptions.map(({ name, label }) => (
        <Select.Option label={label} value={name} key={name} />
      ))}

      {themes?.length ? (
        <Select.Group label={t('customThemes')}>
          {themes.map(({ name, code }) => (
            <Select.Option
              label={name}
              value={`customTheme-${code}`}
              key={code}
            />
          ))}
        </Select.Group>
      ) : null}
    </Select>
  )
}

ColorThemeSelect.propTypes = {
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  site: PropTypes.object.isRequired,
}
