import PropTypes from 'prop-types'
import React from 'react'

import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useUILanguage } from '@config/i18n'
import {
  AutocompleteField as Autocomplete,
  AutocompleteOption,
} from '@ui/data-entry/Autocomplete'
import { useRules } from '@ui/data-entry/validationHooks'
import useDebounce from '@utils/useDebounce'

import useCategories from '../services/hooks/useCategories'
import useCategory from '../services/hooks/useCategory'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

/**
 *
 * @param {object} props - The component props.
 * @param {string} props.className - The class name to apply to the component.
 * @param {string} props.label - The label to display above the component.
 * @param {string} props.name - The name of the component.
 * @param {string} props.help - The help text to display below the component.
 * @param {function} props.onChange - The callback to be called when the user selects an category.
 * @param {function} props.onSearch - The callback to be called when the user types in the search field.
 * @param {boolean} props.required - Whether the field is required or not.
 * @param {string} props.value - The value of the component.
 * @param {object[]} props.categories - The list of categories to display in the list.
 * @returns {React.Component} - The component.
 */
export function CategoryPickerField({
  className,
  disabled,
  excludedIds,
  label,
  name,
  help,
  onChange,
  required,
  maxItems = 25,
  value,
}) {
  const { t } = useTranslation('categories')
  const [searchTerm, setSearchTerm] = React.useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const language = useUILanguage()

  const onSearch = React.useCallback(text => setSearchTerm(text), [])

  const { categories } = useCategories({
    search: debouncedSearchTerm || '',
    fields: ['title', 'type'],
    limit: maxItems || 25,
    excludedIds,
  })

  const categoryId = typeof value === 'object' ? value?.id : value

  const { category, loading } = useCategory(categoryId, {
    fromParams: false,
  })

  return (
    <Autocomplete
      label={label || t('category')}
      placeholder={t('searchPlaceholder')}
      className={className}
      disabled={disabled}
      name={name}
      help={help}
      value={loading ? null : categoryId}
      displayIcon={<Icon name="tag" />}
      displayValue={() =>
        category ? category.title[language] || category.title.en : searchTerm
      }
      onChange={onChange}
      onSearch={onSearch}
      required={required}
    >
      {categories?.map(category => (
        <AutocompleteOption
          icon={<Icon name="tag" />}
          key={category.id}
          label={category.title}
          name={name}
          value={category.id}
        />
      ))}
    </Autocomplete>
  )
}
CategoryPickerField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  excludedIds: PropTypes.array,
  label: PropTypes.string,
  maxItems: PropTypes.number,
  name: PropTypes.string,
  help: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

/**
 * This is is the CategoryPicker controller component, to use within a Form.
 * It wraps the CategoryPickerField component in a Controller (with validation, etc.).
 * @param {object} props - The component props.
 * @param {string} props.className - The class name to apply to the component.
 * @param {boolean} props.disabled - Whether the component is disabled or not.
 * @param {string} props.label - The label to display above the component.
 * @param {string} props.name - The name of the component.
 * @param {string} props.help - The help text to display below the component.
 * @param {boolean} props.required - Whether the field is required or not.
 * @param {boolean} props.shouldUnregister - Whether the field should unregister or not.
 * @param {number} props.maxItems - The maximum number of items to display in the list.
 * @returns {React.Component} - The component.
 */
export default function CategoryPicker({
  className = '',
  disabled,
  excludedIds = [],
  label,
  help,
  maxItems,
  name,
  required,
  shouldUnregister,
}) {
  const rules = useRules({ required })
  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      rules={rules}
      disabled={disabled}
      render={({ field }) => (
        <CategoryPickerField
          className={className}
          disabled={disabled}
          label={label}
          help={help}
          maxItems={maxItems}
          excludedIds={excludedIds}
          name={name}
          value={field.value}
          onChange={field.onChange}
          required={required}
        />
      )}
    />
  )
}
CategoryPicker.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  excludedIds: PropTypes.array,
  label: PropTypes.string,
  maxItems: PropTypes.number,
  name: PropTypes.string,
  help: PropTypes.string,
  required: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
}
