import PropTypes from 'prop-types'
import React from 'react'

import Clickable from '@ui/helpers/Clickable'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export default function Collapse({ children, className = '' }) {
  return <div className={`flex flex-col gap-4 ${className}`}>{children}</div>
}
Collapse.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export function CollapsePanel({
  children,
  className = '',
  innerClass = '',
  extra,
  onToggle,
  title,
  disabled,
  defaultOpen = false,
}) {
  const [open, setOpen] = React.useState(defaultOpen)

  const handleToggle = React.useCallback(() => {
    setOpen(!open)
    if (typeof onToggle === 'function') onToggle(!open)
  }, [open, onToggle])

  return (
    <div
      className={`rounded-lg border bg-white transition-all duration-300 ease-in-out ${
        open ? 'shadow-md' : 'shadow-sm'
      } ${
        disabled
          ? ''
          : 'focus-within:border-primary-200 hover:border-primary-300'
      } ${className}`}
    >
      <Clickable
        className={`flex min-h-[4rem] items-center justify-between gap-4 px-6 py-2 focus:outline-none ${
          disabled ? '' : 'cursor-pointer'
        }}`}
        onClick={disabled ? undefined : handleToggle}
      >
        <div className="flex flex-grow items-center font-semibold">{title}</div>
        {extra && (
          <div className="flex items-center justify-center gap-2">{extra}</div>
        )}
        {!disabled && (
          <div className="text-sm">
            <Icon
              name="chevron-down"
              className={`transform transition duration-150 ease-linear ${
                open ? 'rotate-180' : 'rotate-0'
              }`}
            />
          </div>
        )}
      </Clickable>
      <div
        className={`cursor-default overflow-hidden rounded-b-lg border-t transition-all duration-200 ease-in-out ${
          open ? 'max-h-[200vh]' : 'max-h-0'
        }`}
      >
        <div className={`p-6 ${innerClass}`}>{children}</div>
      </div>
    </div>
  )
}
CollapsePanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  extra: PropTypes.node,
  onToggle: PropTypes.func,
  title: PropTypes.node,
  disabled: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  innerClass: PropTypes.string,
}
Collapse.Panel = CollapsePanel
Collapse.displayName = 'Collapse.Panel'
