import PropTypes from 'prop-types'
import React from 'react'

const styles = {
  h1: 'text-4xl font-extrabold',
  h2: 'text-3xl font-extrabold',
  h3: 'text-2xl font-extrabold',
  h4: 'text-xl font-bold',
  h5: 'text-lg font-bold',
  h6: 'text-base font-bold',
}

export default function Heading({ as = 'h1', children, text, className = '' }) {
  if (!text && !children) return null
  return React.createElement(
    as,
    { className: `${styles[as]} ${className}` },
    children || text
  )
}
Heading.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  children: PropTypes.node,
  className: PropTypes.string,
  text: PropTypes.string,
}
