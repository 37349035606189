import PropTypes from 'prop-types'
import React from 'react'

import BlockField from '../BlockField'
import ColorSelectField from './Field'

export default function BlockColorSelect({
  by,
  className,
  excludedColors = [],
  help,
  includedColors,
  label,
  lightLabel,
  name = 'color',
  onChange,
  popover,
  responsive,
  value: overrideValue,
}) {
  return (
    <BlockField
      name={name}
      onChange={onChange}
      responsive={responsive}
      value={overrideValue}
    >
      {({
        dataValue,
        fieldProps,
        inheritFrom,
        inheritValue,
        onDataChange,
        value,
      }) => (
        <ColorSelectField
          {...fieldProps}
          by={by}
          className={className}
          excludedColors={excludedColors}
          help={help}
          includedColors={includedColors}
          inheritFrom={inheritFrom}
          inheritValue={inheritValue}
          label={label}
          lightLabel={lightLabel}
          onChange={onDataChange}
          popover={popover}
          showClear={Boolean(dataValue)}
          value={value}
        />
      )}
    </BlockField>
  )
}

BlockColorSelect.propTypes = {
  by: PropTypes.oneOf(['value', 'color']),
  className: PropTypes.string,
  excludedColors: PropTypes.arrayOf(PropTypes.string),
  help: PropTypes.string,
  includedColors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  lightLabel: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  popover: PropTypes.func,
  responsive: PropTypes.bool,
  value: PropTypes.string,
}
