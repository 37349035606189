import PropTypes from 'prop-types'
import React from 'react'

import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import BorderStyleSelect from './Field'

export default function BorderStyleSelectController({
  name = 'borderStyle',
  label,
  help,
  value = '',
}) {
  const { t } = useTranslation('web/content-editor')

  return (
    <Controller
      name={name}
      defaultValue={value}
      render={({ field }) => (
        <BorderStyleSelect
          name={name}
          label={label || t('borderStyle')}
          help={help}
          value={field.value}
          onChange={field.onChange}
        />
      )}
    />
  )
}
BorderStyleSelectController.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  help: PropTypes.string,
  value: PropTypes.string,
}
