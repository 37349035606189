import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import {
  ColorSelectedOption,
  ColorSelectField as UIColorSelectField,
} from '@ui/data-entry/ColorSelect'

import { useDesignColor } from '../hooks'
import { useColorOptions } from './hooks'

export default function ColorSelectField({
  by,
  className,
  excludedColors = [],
  fieldExtra,
  help,
  includedColors,
  inheritFrom,
  inheritValue,
  label,
  labelExtra,
  lightLabel,
  name = 'color',
  onChange,
  popover,
  value,
}) {
  const { t } = useTranslation('web/content-editor')
  const colorOptions = useColorOptions({ excludedColors, includedColors })

  const colorStyle = useDesignColor(value)

  return (
    <UIColorSelectField
      by={by}
      className={className}
      clearLabel={t('clearSelection')}
      help={help}
      fieldExtra={fieldExtra}
      label={label ?? t('color')}
      labelExtra={labelExtra}
      lightLabel={lightLabel}
      name={name}
      onChange={onChange}
      options={colorOptions}
      placeholder={
        inheritValue ? (
          <ColorSelectedOption
            color={colorStyle}
            label={`${inheritValue} (${t('inheritFrom')} ${t(
              `breakpointDevice_${inheritFrom}`
            )})`}
          />
        ) : undefined
      }
      popover={popover}
      showClear={Boolean(value)}
      value={value}
    />
  )
}

ColorSelectField.propTypes = {
  by: PropTypes.oneOf(['value', 'color']),
  className: PropTypes.string,
  excludedColors: PropTypes.arrayOf(PropTypes.string),
  fieldExtra: PropTypes.node,
  help: PropTypes.string,
  includedColors: PropTypes.arrayOf(PropTypes.string),
  inheritFrom: PropTypes.string,
  inheritValue: PropTypes.string,
  label: PropTypes.string,
  labelExtra: PropTypes.node,
  lightLabel: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  popover: PropTypes.func,
  value: PropTypes.string,
}
