import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'
import Heading from '@ui/typography/Heading'

import useChannel from '../../services/hooks/useChannel'
import useEpisode from '../../services/hooks/useEpisode'
import ChannelSelect from '../ChannelSelect'
import { EpisodePicker } from '../EpisodePicker'

const config = {
  name: 'EpisodeHosts',
  label: 'media-library/public:episodeHosts',
  type: 'plugin',
  icon: 'user',
  component: <EpisodeHosts />,
}

function getPlaceholder() {
  return {
    hosts: [],
  }
}

export default function EpisodeHosts({ channelId, id }) {
  const { t } = useTranslation('media-library/public')
  const showContent = false

  const { resources } = usePageResources()
  const { channel } = useChannel(channelId)
  const { episode } = useEpisode(id)

  const _channel = resources?.Channel ? resources.Channel : channel
  const _episode = episode ? episode : resources?.Episode || getPlaceholder()

  // const { hosts } = _episode

  return (
    <Content icon={config.icon} title={t('episodeHosts')}>
      {showContent ? (
        <div className="p-4">TODO</div>
      ) : (
        <Box space="xs">
          <Heading as="h6" className="ml-2" text={t('hosts')} />
          <ul className="ml-8 list-disc text-sm">
            <li>
              {t('channel')}: {_channel?.title}
            </li>
            <li>
              {t('episode')}: {_episode?.title || t('dynamic')}
            </li>
          </ul>
        </Box>
      )}
    </Content>
  )
}
EpisodeHosts.propTypes = {
  channelId: PropTypes.string,
  id: PropTypes.string,
}
EpisodeHosts.toolbarItem = config

function EpisodeHostsSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(data.channelId || data.id)

  const { resources } = usePageResources()

  const onEpisodeIdChange = useCallback(
    value => {
      actions.setProp(props => (props.id = value))
    },
    [actions]
  )

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t('episodeHosts')} help={t('episodeHostsHelp')}>
      <Box space="xl">
        {resources?.Channel && !resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Channel?.title}
          </Message>
        )}
        {resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Show?.title}
          </Message>
        )}
        <PagePicker
          label={t('hostDetailPageId')}
          help={t('hostDetailPageIdHelp')}
          value={data.hostDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.hostDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Person'
          }
        />
        <Checkbox
          label={t('useShowHosts')}
          help={t('useShowHostsHelp')}
          onChange={value => {
            actions.setProp(props => (props.useShowHosts = value))
          }}
          value={data.useShowHosts}
          name="useShowHosts"
        />
        {!resources?.Show && (
          <>
            <Divider />
            <Checkbox
              label={t('pickEpisodeManually')}
              help={t('pickEpisodeManuallyHelp')}
              onChange={value => setShowPicker(value)}
              value={showPicker}
            />
            {showPicker && (
              <Box space="lg">
                {!resources?.Channel && (
                  <ChannelSelect
                    label={t('channel')}
                    onChange={onChannelUpdate('channelId')}
                    value={data.channelId}
                    controlled={false}
                  />
                )}
                <EpisodePicker
                  channelId={data.channelId || resources?.Channel?.id}
                  onChange={onEpisodeIdChange}
                  value={data.id}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </SettingsWrap>
  )
}

EpisodeHosts.craft = {
  displayName: 'EpisodeHosts',
  props: {
    channelId: undefined,
    id: undefined,
    hostDetailPageId: undefined,
    useShowHosts: false,
  },
  custom: {
    type: config.type,
    resources: ['episodeHosts'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EpisodeHostsSettings,
  },
}
