import PropTypes from 'prop-types'
import React from 'react'

export default function ChildrenWithProps({
  children,
  condition = true,
  setProps,
  ...props
}) {
  if (!children) return null
  if (!condition) return children

  return React.Children.map(children, (child, index) => {
    if (!child || typeof child === 'string') return child

    return React.cloneElement(child, {
      ...(props || {}),
      ...(typeof setProps === 'function'
        ? setProps(child.props, index)
        : child.props),
    })
  })
}
ChildrenWithProps.propTypes = {
  children: PropTypes.node,
  condition: PropTypes.bool,
  setProps: PropTypes.func,
}
