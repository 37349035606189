import PropTypes from 'prop-types'
import React from 'react'

import { Listbox as UIListbox } from '@headlessui/react'

export function ListboxOptions({ as, children, className = '', ...rest }) {
  return (
    <UIListbox.Options
      {...rest}
      as={as}
      className={`absolute mt-1 max-h-80 w-full space-y-1 overflow-auto rounded-md bg-white p-3 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${className}`}
      static
    >
      {children}
    </UIListbox.Options>
  )
}
ListboxOptions.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  className: PropTypes.string,
}
