import PropTypes from 'prop-types'
import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import ChannelSelect from '@modules/media-library/components/ChannelSelect'
import SeasonSelect from '@modules/media-library/components/SeasonSelect'
import ShowPickerController from '@modules/media-library/components/ShowPicker'
import Message from '@ui/data-display/Message'

export default function EpisodesDataSourceSettings({ site }) {
  const { t } = useTranslation([
    'media-library/public',
    'media-library/episodes',
    'web/sites',
  ])

  const { watch } = useFormContext()

  const channelWatch = watch('settings.channel')
  const showWatch = watch('settings.show')

  return (
    <>
      <Message
        text={t('media-library/episodes:episodesDataSourceSettingsDescription')}
      />

      <ChannelSelect
        label={t('channel')}
        name="settings.channel"
        required
        site={site}
      />
      <ShowPickerController
        channelId={channelWatch}
        disabled={!channelWatch}
        label={t('show')}
        name="settings.show"
        required
      />
      <SeasonSelect
        help={t('media-library/episodes:episodesDataSourceSeasonHelp')}
        label={t('season')}
        name="settings.season"
        showId={showWatch}
      />
    </>
  )
}

EpisodesDataSourceSettings.propTypes = {
  site: PropTypes.object,
}
