import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { BlockColorSelect as ColorSelect } from '@modules/web/components/ContentEditor/shared/ColorSelect'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { useBlock } from '@modules/web/helpers/useBlock'

import { EntityFields } from './shared/EntityFields'
import EventsPreview from './shared/EventsPreview'
import FieldsToggleFields from './shared/FieldsToggleFields'
import LinkingFields from './shared/LinkingFields'
import PaginationFields from './shared/PaginationFields'

const config = {
  name: 'EventList',
  label: 'events/public:eventList',
  type: 'plugin',
  icon: 'bars',
  component: <EventList />,
}

export default function EventList({
  organizers,
  regions,
  categories,
  months,
  pagination,
  showPreview,
  showFilters,
}) {
  const { t } = useTranslation(['events/public', 'web/content-editor'])
  return (
    <Content icon={config.icon} title={t('eventList')}>
      <EventsPreview
        organizers={organizers}
        regions={regions}
        categories={categories}
        months={months}
        showPreview={showPreview}
        showFilters={showFilters}
        pagination={pagination}
      />
    </Content>
  )
}
EventList.propTypes = {
  bgColor: PropTypes.string,
  organizers: PropTypes.array,
  regions: PropTypes.array,
  categories: PropTypes.array,
  months: PropTypes.number,
  pagination: PropTypes.bool,
  showPreview: PropTypes.bool,
  showFilters: PropTypes.bool,
}
EventList.toolbarItem = config

function EventListSettings() {
  const { t } = useTranslation('events/public')

  const { bgColor, showPreview } = useBlock(config.name)

  return (
    <SettingsWrap title={t('eventList')} help={t('eventListHelp')}>
      <div className="space-y-4">
        {bgColor && (
          <ColorSelect
            name="bgColor"
            label={t('web/content-editor:bgColor')}
            help={t('eventListBgColorHelp')}
          />
        )}
        <PaginationFields />
        <EntityFields />
        <FieldsToggleFields showPreview={showPreview} />
        <LinkingFields showListPagePicker={false} showRegistrationPagePicker />
      </div>
    </SettingsWrap>
  )
}

EventList.craft = {
  displayName: 'EventList',
  props: {
    showFilters: true,
    showPreview: true,
    pagination: false,
    months: 3,
  },
  custom: {
    type: config.type,
    resources: ['events'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EventListSettings,
  },
}
