import React, { useMemo } from 'react'

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  createSnapModifier,
  restrictToHorizontalAxis,
  restrictToParentElement,
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers'
import {
  SortableContext,
  horizontalListSortingStrategy,
  rectSwappingStrategy,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

const strategyMap = {
  vertical: verticalListSortingStrategy,
  horizontal: horizontalListSortingStrategy,
  grid: rectSwappingStrategy,
}

/**
 * @typedef {'vertical'|'horizontal'|'grid'} SortableListStrategy
 */

/**
 * Context to create a sortable list
 * @param {object} props Props for the component
 * @param {ReactNode} props.children Children to render
 * @param {boolean} props.disabled Whether the list is disabled (default: false)
 * @param {Function} props.handleDragEnd Callback when the drag ends (required)
 * @param {Array} props.items List of items to render (default: [], but required in practice)
 * @param {string} props.name Name of the list (required)
 * @param {boolean} props.restrictToX Restrict the movement to the X axis
 * @param {boolean} props.restrictToY Restrict the movement to the Y axis
 * @param {boolean} props.restrictToWindow Restrict the movement to the window
 * @param {boolean} props.restrictToParent Restrict the movement to the parent element
 * @param {boolean|number} props.snapToGrid Snap the item to a grid. If a number is provided, it will be the grid size in pixels (default: 10)
 * @param {SortableListStrategy} [props.strategy='vertical'] The strategy to use for sorting (default: 'vertical')
 *
 * @returns {React.ReactElement} The SortableListContext component
 */
export function SortableListContext({
  children,
  disabled, // Whether the list is disabled (default: false)
  handleDragEnd,
  items, // List of items to render (default: [], but required in practice)
  name, // Name of the list (required)
  restrictToX = false,
  restrictToY = false,
  restrictToWindow = false,
  restrictToParent = false,
  snapToGrid = false, /// snap to grid. A bolean or grid size in px (Boolean will set a 10 px grid)
  strategy = 'vertical',
}) {
  if (strategy === 'grid' && (restrictToY || restrictToX)) {
    // eslint-disable-next-line no-console
    console.warn(
      'SortableListContext: You cannot use restrictToY or restrictToX with the grid strategy. Please remove these props.'
    )
  }

  // We use two sensors to allow for both mouse and keyboard interactions
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 2 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const modifiers = useModifiers({
    restrictToX,
    restrictToY,
    restrictToWindow,
    restrictToParent,
    snapToGrid,
  })

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={modifiers}
    >
      <SortableContext
        id={name}
        items={items}
        strategy={strategyMap[strategy] ?? strategyMap.vertical}
        disabled={disabled}
      >
        {children}
      </SortableContext>
    </DndContext>
  )
}

/**
 * Returns an array of modifiers based on the options provided
 * @param {object} options Options to configure the modifiers
 * @param {boolean} options.restrictToX Restrict the movement to the X axis
 * @param {boolean} options.restrictToY Restrict the movement to the Y axis
 * @param {boolean} options.restrictToWindow Restrict the movement to the window
 * @param {boolean} options.restrictToParent Restrict the movement to the parent element
 * @param {boolean|number} options.snapToGrid Snap the item to a grid. If a number is provided, it will be the grid size in pixels (default: 10)
 * @returns {Array} An array of modifiers
 */
function useModifiers({
  restrictToX = false,
  restrictToY = false,
  restrictToWindow = false,
  restrictToParent = false,
  snapToGrid = false,
}) {
  return useMemo(() => {
    const modifiers = []

    if (restrictToX) {
      modifiers.push(restrictToHorizontalAxis)
    }

    if (restrictToY) {
      modifiers.push(restrictToVerticalAxis)
    }

    if (snapToGrid) {
      const gridSize = snapToGrid === true ? 10 : Math.abs(snapToGrid)

      modifiers.push(createSnapModifier(gridSize))
    }

    if (restrictToWindow) {
      modifiers.push(restrictToWindowEdges)
    }

    if (restrictToParent) {
      modifiers.push(restrictToParentElement)
    }

    return modifiers
  }, [restrictToY, restrictToX, restrictToWindow, restrictToParent, snapToGrid])
}
