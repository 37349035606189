import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import useFeatureFlagEnabled from '@modules/feature-flags/hooks/useFeatureFlagEnabled'

import ColorsForm from './ColorsForm'
import AdventistFooterForm from './FooterForm'
import AdventistHeaderForm from './HeaderForm'
import Themes from './Themes'

export default function useAdventistDesignSettingsTabs(site) {
  const { t } = useTranslation([
    'designs/adventist',
    'designs/base',
    'web/appearance',
  ])

  const isAdventistMinimalThemeSupported = useFeatureFlagEnabled(
    'adventist-minimal-theme',
    { site }
  )

  return useMemo(
    () => [
      ...(isAdventistMinimalThemeSupported
        ? [
            {
              name: 'colors',
              title: t('designs/base:colors'),
              component: ColorsForm,
            },
          ]
        : []),
      ...(isAdventistMinimalThemeSupported
        ? [
            {
              name: 'themes',
              title: t('designs/adventist:themes'),
              component: Themes,
            },
          ]
        : []),
      {
        name: 'header',
        title: t('web/appearance:header'),
        component: AdventistHeaderForm,
      },
      {
        name: 'footer',
        title: t('web/appearance:footer'),
        component: AdventistFooterForm,
      },
    ],
    [isAdventistMinimalThemeSupported, t]
  )
}
