import PropTypes from 'prop-types'
import React from 'react'

import { useFloatingTree, useListItem, useMergeRefs } from '@floating-ui/react'
import { useNavigate } from 'react-router-dom'

import { useDropdownContext } from './Context'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const variantStyles = {
  basic:
    'text-gray-700 hover:bg-gray-100 hover:text-gray-800 focus:bg-gray-100 focus:text-gray-800',
  primary:
    'text-primary-500 hover:bg-primary-100 hover:text-primary-800 focus:bg-primary-100 focus:text-primary-800',
  danger:
    'text-danger-500 hover:bg-danger-600 hover:text-danger-50 focus:bg-danger-100 focus:text-danger-800',
  warn: 'text-warn-500 hover:bg-warn-600 hover:text-warn-50 focus:bg-warn-100 focus:text-warn-800',
  success:
    'text-success-500 hover:bg-success-100 hover:text-success-800 focus:bg-success-100 focus:text-success-800',
}

const selectedVariantStyles = {
  basic:
    'bg-gray-700 text-gray-50 hover:bg-gray-600 hover:text-gray-50 focus:bg-gray-600 focus:text-gray-50',
  primary:
    'bg-primary-500 text-primary-50 hover:bg-primary-500 hover:text-primary-50 focus:bg-primary-500 focus:text-primary-50',
  danger:
    'bg-danger-500 text-danger-50 hover:bg-danger-500 hover:text-danger-50 focus:bg-danger-500 focus:text-danger-50',
  warn: 'bg-warn-500 text-warn-50 hover:bg-warn-500 hover:text-warn-50 focus:bg-warn-500 focus:text-warn-50',
  success:
    'bg-success-500 text-success-50 hover:bg-success-500 hover:text-success-50 focus:bg-success-500 focus:text-success-50',
}

const DropdownItem = React.forwardRef(
  (
    {
      label,
      icon,
      disabled,
      variant,
      className = '',
      selected = false,
      ...props
    },
    forwardedRef
  ) => {
    const navigate = useNavigate()
    const dropdowContext = useDropdownContext()
    const item = useListItem({ label: disabled ? null : label })
    const tree = useFloatingTree()
    const isActive = item.index === dropdowContext.activeIndex
    const variantClass = selected
      ? selectedVariantStyles[variant] || selectedVariantStyles.primary
      : variantStyles[variant] || variantStyles.basic
    const disabledClass = disabled ? 'cursor-not-allowed opacity-50' : ''

    return (
      <button
        {...props}
        ref={useMergeRefs([item.ref, forwardedRef])}
        type="button"
        role="menuitem"
        className={`group/item flex items-center gap-2 px-4 py-2 text-sm outline-none transition-all duration-300 ease-in-out first:border-none ${variantClass} ${disabledClass} ${className}`}
        tabIndex={isActive ? 0 : -1}
        disabled={disabled}
        {...dropdowContext.getItemProps({
          onClick(event) {
            if (props.href) {
              navigate(props.href)
            }
            props.onClick?.(event)
            tree?.events.emit('click')
          },
          onFocus(event) {
            props.onFocus?.(event)
            dropdowContext.setHasFocusInside(true)
          },
        })}
      >
        {icon && (
          <span className="flex w-6 items-center justify-center transition-all group-hover/item:animate-wiggle">
            <Icon name={icon} />
          </span>
        )}
        <span className="font-medium">{label}</span>
      </button>
    )
  }
)
DropdownItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  href: PropTypes.string,
  onFocus: PropTypes.func,
  selected: PropTypes.bool,
  variant: PropTypes.oneOf(['basic', 'primary', 'danger', 'warn', 'success']),
}

export default DropdownItem
