import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'
import Heading from '@ui/typography/Heading'

const config = {
  name: 'RelatedVideos',
  label: 'media-library/public:relatedVideos',
  type: 'plugin',
  icon: 'arrows-h',
  component: <RelatedVideos />,
}

function getPlaceholder() {
  return {
    // title: '[Video title]',
    relatedVideos: [],
  }
}

export default function RelatedVideos({ title }) {
  const { t } = useTranslation('media-library/public')
  const showContent = false

  const { resources } = usePageResources()

  const _channel = resources?.Channel
  const _video = resources?.Video || getPlaceholder()

  const { relatedVideos = [] } = _video

  return (
    <Content icon={config.icon} title={t('relatedVideos')}>
      {showContent ? (
        <div className="flex flex-col p-4">
          {relatedVideos.map(episode => (
            <div key={episode.id}>{episode.title}</div>
          ))}
        </div>
      ) : (
        <Box space="xs">
          <Heading
            as="h6"
            className="ml-2"
            text={title || t('relatedVideos')}
          />
          <ul className="ml-8 list-disc text-sm">
            <li>
              {t('channel')}: {_channel?.title}
            </li>
            <li>
              {t('video')}: {_video?.title || t('dynamic')}
            </li>
          </ul>
        </Box>
      )}
    </Content>
  )
}
RelatedVideos.propTypes = {
  title: PropTypes.string,
}
RelatedVideos.toolbarItem = config

function RelatedVideosSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { resources } = usePageResources()

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t('relatedVideos')} help={t('relatedVideosHelp')}>
      <Box space="xl">
        {resources?.Channel && !resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Channel?.title}
          </Message>
        )}
        {resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Show?.title}
          </Message>
        )}
        <Input
          label={t('title')}
          onChange={onFieldUpdate('title')}
          placeholder={t('relatedVideos')}
          value={data.title}
        />
        <Input
          label={t('limit')}
          help={t('limitHelp')}
          onChange={onFieldUpdate('limit', value =>
            value ? parseInt(isNaN(value) || value < 0 ? 0 : value) : 0
          )}
          value={data.limit}
          type="number"
        />
        <Divider />
        <PagePicker
          label={t('videoDetailPageId')}
          help={t('videoDetailPageIdHelp')}
          value={data.videoDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.videoDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Video'
          }
        />
        <Checkbox
          name="showDescription"
          label={t('showDescriptionInCards')}
          help={t('showDescriptionOfEpisodesInCardsHelp')}
          value={data.showDescription}
          onChange={checked =>
            actions.setProp(p => (p.showDescription = checked))
          }
        />
        <Checkbox
          name="enableAnimations"
          label={t('enableAnimations')}
          help={t('enableAnimationsCardsHelp')}
          value={data.enableAnimations}
          onChange={checked =>
            actions.setProp(p => (p.enableAnimations = checked))
          }
        />
      </Box>
    </SettingsWrap>
  )
}

RelatedVideos.craft = {
  displayName: 'RelatedVideos',
  props: {
    title: '',
    limit: 4,
    videoDetailPageId: undefined,
    showDescription: true,
  },
  custom: {
    type: config.type,
    resources: ['relatedVideos'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: RelatedVideosSettings,
  },
}
