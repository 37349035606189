import PropTypes from 'prop-types'
import React from 'react'

import { Controller } from 'react-hook-form'

import { DesignColorSelectField } from './Field'

export function DesignColorSelectController({ name, label, help }) {
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <DesignColorSelectField
          name={name}
          label={label}
          help={help}
          onChange={field.onChange}
          value={field.value}
        />
      )}
    />
  )
}

DesignColorSelectController.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  help: PropTypes.string,
}
