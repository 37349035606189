import React from 'react'

import EpisodesDataSourceSettings from '@modules/media-library/components/data-sources/EpisodesDataSourceSettings'

import ChannelPicker from './components/ChannelPicker'
import MediaMenuGroup from './components/MediaMenuGroup'
import PersonPicker from './components/PersonPicker'
import ShowPicker from './components/ShowPicker'
import AudioPlayer from './components/blocks/AudioPlayer'
import AudioPlaylist from './components/blocks/AudioPlaylist'
import CarouselEpisodeRepeater from './components/blocks/CarouselEpisodeRepeater'
import CategoryVideos from './components/blocks/CategoryVideos'
import ChannelsMap from './components/blocks/ChannelsMap'
import Collection from './components/blocks/Collection'
import CollectionList from './components/blocks/CollectionList'
import CollectionTeaser from './components/blocks/CollectionTeaser'
import EpisodeAudioPlayer from './components/blocks/EpisodeAudioPlayer'
import EpisodeDetail from './components/blocks/EpisodeDetail'
import EpisodeDocuments from './components/blocks/EpisodeDocuments'
import EpisodeDownloads from './components/blocks/EpisodeDownloads'
import EpisodeFilters from './components/blocks/EpisodeFilters'
import EpisodeGuests from './components/blocks/EpisodeGuests'
import EpisodeHeader from './components/blocks/EpisodeHeader'
import EpisodeHosts from './components/blocks/EpisodeHosts'
import EpisodeLinks from './components/blocks/EpisodeLinks'
import EpisodeMeta from './components/blocks/EpisodeMeta'
import EpisodePlayer from './components/blocks/EpisodePlayer'
import EpisodeRepeater from './components/blocks/EpisodeRepeater'
import EpisodeVideos from './components/blocks/EpisodeVideos'
import EpisodesList from './components/blocks/EpisodesList'
import EpisodesTeaser from './components/blocks/EpisodesTeaser'
import LivestreamPlayer from './components/blocks/LivestreamPlayer'
import PersonDetail from './components/blocks/PersonDetail'
import RelatedEpisodes from './components/blocks/RelatedEpisodes'
import RelatedShows from './components/blocks/RelatedShows'
import RelatedVideos from './components/blocks/RelatedVideos'
import ResourceEpisodes from './components/blocks/ResourceEpisodes'
import ScheduleDay from './components/blocks/ScheduleDay'
import ScheduleLive from './components/blocks/ScheduleLive'
import ScheduleWeek from './components/blocks/ScheduleWeek'
import ShowAudioPlaylist from './components/blocks/ShowAudioPlaylist'
import ShowDetail from './components/blocks/ShowDetail'
import ShowDocuments from './components/blocks/ShowDocuments'
import ShowHosts from './components/blocks/ShowHosts'
import ShowImage from './components/blocks/ShowImage'
import ShowLinks from './components/blocks/ShowLinks'
import ShowList from './components/blocks/ShowList'
import ShowMeta from './components/blocks/ShowMeta'
import ShowParticipants from './components/blocks/ShowParticipants'
import ShowPodcasts from './components/blocks/ShowPodcasts'
import ShowVideoCategories from './components/blocks/ShowVideoCategories'
import ShowsFeedList from './components/blocks/ShowsFeedList'
import VideoCategories from './components/blocks/VideoCategories'
import VideoCategoryDetail from './components/blocks/VideoCategoryDetail'
import VideoDetail from './components/blocks/VideoDetail'
import VideoParent from './components/blocks/VideoParent'
import VideoPlayer from './components/blocks/VideoPlayer'
import translatableBlocks from './components/blocks/translatableBlocks'
import ShowsFeedFilters from './components/shows/ShowsFeedFilters'
import jetstreamProcessing from './tasks/jetstream-processing'
import jetstreamUpdater from './tasks/jetstream-updater'
import statisticsUpdater from './tasks/statistics-updater'

// import EpisodePicker from './components/EpisodePicker'

// import EpisodePicker from './components/EpisodePicker'
// import SeasonPicker from './components/SeasonPicker'

const MediaLibraryRouter = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    name: 'media-library',
    menu: MediaMenuGroup,
    routes: {
      private: {
        '/media-library/*': { Component: MediaLibraryRouter },
      },
    },
    permissions: {
      mediaLibrary: {
        name: 'mediaLibrary',
        label: 'media-library/public:mediaLibrary',
        icon: 'tv',
        CustomFilter: {
          Component: ChannelPicker,
          props: {
            name: 'channels.records.0',
          },
        },
        models: [
          {
            name: 'channels',
            label: 'media-library/public:channels',
            permissions: ['read', 'create', 'update', 'delete'],
          },
          {
            name: 'shows',
            label: 'media-library/public:shows',
            permissions: ['read', 'create', 'update', 'translate', 'delete'],
          },
          {
            name: 'episodes',
            label: 'media-library/public:episodes',
            permissions: ['read', 'create', 'update', 'translate', 'delete'],
          },
          {
            name: 'collections',
            label: 'media-library/public:collections',
            permissions: ['read', 'create', 'update', 'delete'],
          },
          {
            name: 'persons',
            label: 'media-library/public:persons',
            permissions: ['read', 'create', 'update', 'delete'],
          },
          {
            name: 'broadcasts',
            label: 'media-library/public:schedule',
            permissions: ['read', 'create', 'update', 'delete'],
          },
        ],
      },
    },

    dataSources: {
      episodes: {
        label: 'media-library/public:episodes',
        icon: 'list',
        settings: EpisodesDataSourceSettings,
      },
    },

    blocksResolver: {
      ChannelsMap,
      Collection,
      CollectionList,
      CollectionTeaser,
      EpisodesTeaser,
      EpisodesList,
      EpisodeFilters,
      ResourceEpisodes,
      EpisodeHeader,
      EpisodeDetail,
      EpisodePlayer,
      CarouselEpisodeRepeater,
      EpisodeRepeater,
      EpisodeAudioPlayer,
      EpisodeGuests,
      EpisodeHosts,
      EpisodeLinks,
      EpisodeDocuments,
      EpisodeDownloads,
      EpisodeMeta,
      RelatedEpisodes,
      LivestreamPlayer,
      ScheduleDay,
      ScheduleLive,
      ScheduleWeek,
      ShowDetail,
      ShowImage,
      ShowHosts,
      ShowParticipants,
      ShowLinks,
      ShowDocuments,
      ShowPodcasts,
      ShowAudioPlaylist,
      ShowsFeedList,
      ShowVideoCategories,
      ShowMeta,
      ShowList,
      RelatedShows,
      PersonDetail,
      CategoryVideos,
      EpisodeVideos,
      VideoDetail,
      VideoCategories,
      VideoCategoryDetail,
      VideoPlayer,
      VideoParent,
      RelatedVideos,
      AudioPlayer,
      AudioPlaylist,
    },
    blocksToolbars: [
      {
        name: 'channel',
        label: 'media-library/public:channels',
        items: [LivestreamPlayer, ChannelsMap],
      },
      {
        name: 'shows',
        label: 'media-library/public:shows',
        items: [
          ShowDetail,
          ShowImage,
          ShowHosts,
          ShowParticipants,
          ShowLinks,
          ShowDocuments,
          ShowPodcasts,
          ShowAudioPlaylist,
          ShowsFeedList,
          ShowMeta,
          ShowList,
          RelatedShows,
          ShowVideoCategories,
        ],
      },
      {
        name: 'episodes',
        label: 'media-library/public:episodes',
        items: [
          EpisodesTeaser,
          EpisodesList,
          EpisodeFilters,
          ResourceEpisodes,
          EpisodeHeader,
          EpisodeDetail,
          EpisodePlayer,
          CarouselEpisodeRepeater,
          EpisodeRepeater,
          EpisodeAudioPlayer,
          EpisodeGuests,
          EpisodeHosts,
          EpisodeLinks,
          EpisodeDocuments,
          EpisodeDownloads,
          EpisodeMeta,
          RelatedEpisodes,
        ],
      },
      {
        name: 'videos',
        label: 'media-library/public:videos',
        items: [
          CategoryVideos,
          EpisodeVideos,
          VideoPlayer,
          VideoDetail,
          VideoCategories,
          VideoCategoryDetail,
          VideoParent,
          RelatedVideos,
        ],
      },
      {
        name: 'collections',
        label: 'media-library/public:collections',
        items: [CollectionList, CollectionTeaser, Collection],
      },
      {
        name: 'persons',
        label: 'media-library/public:persons',
        items: [PersonDetail],
      },
      {
        name: 'schedule',
        label: 'media-library/public:schedule',
        items: [ScheduleDay, ScheduleLive, ScheduleWeek],
      },
      {
        name: 'audio',
        label: 'media-library/public:audio',
        items: [AudioPlayer, AudioPlaylist],
      },
    ],
    resources: {
      Channel: {
        type: 'model',
        name: 'Channel',
        label: 'media-library/public:channels',
        labelSingular: 'media-library/public:channel',
        ResourcePicker: ChannelPicker,
        fields: {
          title: {
            label: 'media-library/public:title',
            type: 'string',
          },
          description: {
            label: 'media-library/public:description',
            type: 'string',
          },
          tags: {
            label: 'media-library/public:tags',
            type: 'array',
          },
        },
      },
      Show: {
        type: 'model',
        name: 'Show',
        label: 'media-library/public:shows',
        labelSingular: 'media-library/public:show',
        ResourcePicker: ShowPicker,
        fields: {
          'title': {
            label: 'media-library/public:title',
            type: 'string',
          },
          'abstract': {
            label: 'media-library/public:abstract',
            type: 'string',
          },
          'body': {
            label: 'media-library/public:body',
            type: 'richtext',
          },
          'images.default': {
            label: 'media-library/public:image',
            type: 'image',
          },
          'tags': {
            label: 'media-library/public:tags',
            type: 'array',
          },
        },
      },
      Episode: {
        type: 'model',
        name: 'Episode',
        label: 'media-library/public:episodes',
        labelSingular: 'media-library/public:episode',
        fields: {
          title: {
            label: 'media-library/public:title',
            type: 'string',
          },
          abstract: {
            label: 'media-library/public:abstract',
            type: 'string',
          },
          body: {
            label: 'media-library/public:body',
            type: 'richtext',
          },
          image: {
            label: 'media-library/public:image',
            type: 'image',
          },
          tags: {
            label: 'media-library/public:tags',
            type: 'array',
          },
        },
      },
      Season: {
        type: 'model',
        name: 'Season',
        label: 'media-library/public:seasons',
        labelSingular: 'media-library/public:season',
        fields: {
          title: {
            label: 'media-library/public:title',
            type: 'string',
          },
          groupName: {
            label: 'media-library/public:groupName',
            type: 'string',
          },
        },
      },
      Collection: {
        type: 'model',
        name: 'Collection',
        label: 'media-library/public:collections',
        labelSingular: 'media-library/public:collection',
        fields: {
          title: {
            label: 'media-library/public:title',
            type: 'string',
          },
          abstract: {
            label: 'media-library/public:abstract',
            type: 'string',
          },
          body: {
            label: 'media-library/public:body',
            type: 'richtext',
          },
        },
      },
      Person: {
        type: 'model',
        name: 'Person',
        label: 'media-library/public:persons',
        labelSingular: 'media-library/public:person',
        ResourcePicker: PersonPicker,
        fields: {
          prefix: {
            label: 'media-library/public:prefix',
            type: 'string',
          },
          firstName: {
            label: 'media-library/public:firstName',
            type: 'string',
          },
          middleName: {
            label: 'media-library/public:middleName',
            type: 'string',
          },
          lastName: {
            label: 'media-library/public:lastName',
            type: 'string',
          },
          suffix: {
            label: 'media-library/public:suffix',
            type: 'string',
          },
          avatar: {
            label: 'media-library/public:image',
            type: 'image',
          },
          image: {
            label: 'media-library/public:image',
            type: 'image',
          },
          body: {
            label: 'media-library/public:body',
            type: 'richtext',
          },
        },
      },
      Video: {
        type: 'model',
        name: 'Video',
        label: 'media-library/public:videos',
        labelSingular: 'media-library/public:video',
        fields: {
          title: {
            label: 'media-library/public:title',
            type: 'string',
          },
          abstract: {
            label: 'media-library/public:abstract',
            type: 'string',
          },
          body: {
            label: 'media-library/public:body',
            type: 'richtext',
          },
        },
      },
      VideoCategory: {
        type: 'model',
        name: 'VideoCategory',
        label: 'media-library/public:videoCategories',
        labelSingular: 'media-library/public:videoCategory',
        fields: {
          title: {
            label: 'media-library/public:title',
            type: 'string',
          },
          abstract: {
            label: 'media-library/public:abstract',
            type: 'string',
          },
          body: {
            label: 'media-library/public:body',
            type: 'richtext',
          },
          image: {
            label: 'media-library/public:image',
            type: 'image',
          },
        },
      },
      showsFeed: {
        type: 'xml',
        label: 'media-library/public:showsFeed',
        name: 'showsFeed',
        /**
         * @deprecated use type: 'xml' instead
         */
        xml: true,
        ResourceFilters: ShowsFeedFilters,
      },
      episodes: {
        type: 'list',
        name: 'episodes',
        itemName: 'episode',
        label: 'media-library/public:episodes',
        labelSingular: 'media-library/public:episode',
        fields: {
          title: {
            label: 'media-library/public:title',
            type: 'string',
          },
          slug: {
            label: 'media-library/public:slug',
            type: 'string',
          },
          abstract: {
            label: 'media-library/public:abstract',
            type: 'string',
          },
          image: {
            label: 'media-library/public:image',
            type: 'image',
          },
          duration: {
            label: 'media-library/public:duration',
            type: 'number',
          },
          publishedAt: {
            label: 'articles/public:publishedAt',
            type: 'date',
          },
          url: {
            label: 'media-library/public:url',
            type: 'url',
          },
        },
        show: {
          type: 'resource',
          name: 'show',
          label: 'media-library/public:show',
          fields: {
            'title': {
              label: 'media-library/public:title',
              type: 'string',
            },
            'seasons': {
              label: 'media-library/public:seasons',
              type: 'array',
              fields: {
                title: {
                  label: 'media-library/public:title',
                  type: 'string',
                },
                groupName: {
                  label: 'media-library/public:groupName',
                  type: 'string',
                },
                slug: {
                  label: 'media-library/public:slug',
                  type: 'string',
                },
                episodeCount: {
                  label: 'media-library/public:episodeCount',
                  type: 'number',
                },
              },
            },
            'images.default': {
              label: 'media-library/public:images_default',
              type: 'image',
            },
            'images.banner': {
              label: 'media-library/public:images_banner',
              type: 'image',
            },
            'images.poster': {
              label: 'media-library/public:images_poster',
              type: 'image',
            },
            'images.podcast': {
              label: 'media-library/public:images_podcast',
              type: 'image',
            },
          },
        },
      },
    },
    translatableBlocks,

    tasks: {
      'jetstream-updater': jetstreamUpdater,
      'statistics-updater': statisticsUpdater,
      'jetstream-processing': jetstreamProcessing,
    },
  }
}
