import { ButtonVariantPreview } from './appearance/ComponentsForm/components/button/Preview'
import useDesignSettingsTabs from './appearance/useDesignSettingsTabs'

/**
 * Use this as base for custom designs and override as needed.
 */
export default function () {
  return {
    title: 'Base design',
    logoFormats: ['svg'],
    namespace: 'designs/base',
    layouts: true,
    notifications: true,
    appearance: {
      useSettingsTabs: useDesignSettingsTabs, // This is a hook that returns an array of tabs
    },
    fonts: {
      weights: [
        'thin',
        'extralight',
        'light',
        'normal',
        'medium',
        'semibold',
        'bold',
        'extrabold',
        'black',
      ],
      upload: true,
      sizes: [
        'xs',
        'sm',
        'md',
        'lg',
        'xl',
        '2xl',
        '3xl',
        '4xl',
        '5xl',
        '6xl',
        '7xl',
        '8xl',
        '9xl',
      ],
    },
    colors: {
      color1: {},
      color2: {},
      color3: {},
      color4: {},
      color5: {},
      color6: {},
      gray: {},
    },
    spacing: {
      type: 'all', // options: 'all', 'design' or 'defaults'
      options: ['zero', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'],
    },
    gap: {
      type: 'all', // options: 'all', 'design' or 'defaults'
      options: ['none', 'xs', 'sm', 'md', 'lg', 'xl'],
    },
    padding: {
      type: 'all', // options: 'all', 'design' or 'defaults'
      options: [
        'zero',
        'px',
        '2xs',
        'xs',
        'sm',
        'md',
        'lg',
        'xl',
        '2xl',
        '3xl',
      ],
    },
    shadows: ['none', 'xs', 'sm', 'default', 'md', 'lg', 'xl', '2xl', 'inner'],
    widths: {
      'auto': 'w-auto',
      'full': 'w-full',
      '1/6': 'w-1/6',
      '1/5': 'w-1/5',
      '1/4': 'w-1/4',
      '1/3': 'w-1/3',
      '1/2': 'w-1/2',
      '2/3': 'w-2/3',
      '3/4': 'w-3/4',
    },
    breakpoints: {
      xs: '350px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
    },
    columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    buttons: {
      PreviewComponent: ButtonVariantPreview,
      sizes: ['xs', 'sm', 'md', 'lg', 'xl'],
      colors: ['default', 'primary', 'secondary', 'none'],
      variants: [
        'base',
        'primary',
        'secondary',
        'tertiary',
        'flat',
        'link',
        'danger',
      ],
      iconPositions: ['left', 'right'],
    },
    links: {
      colors: {
        primary: 'primary',
        secondary: 'secondary',
        tertiary: 'tertiary',
        default: 'default',
      },
    },
    form: {
      variants: [],
    },
    menus: {
      cookieOptions: true,
    },
    icons: [
      'app-store',
      'arrow',
      'arrow-rotate-right',
      'article',
      'asterisk',
      'at',
      'audio',
      'backward-step',
      'bars',
      'book',
      'calendar',
      'check',
      'chevron-down',
      'chevron-left',
      'chevron-right',
      'chevron-up',
      'download',
      'download-arrow',
      'envelope',
      'expand-arrows',
      'external-link',
      'facebook',
      'file-invoice',
      'forward-step',
      'globe',
      'google-play',
      'instagram',
      'language',
      'link',
      'map-marker',
      'page',
      'pause-solid',
      'play',
      'play-circle',
      'play-solid',
      'podcast',
      'sda-logo-circular',
      'search',
      'share',
      'sound-cloud',
      'speech-bubble',
      'spinner',
      'spinner-third',
      'spotify',
      'stack',
      'telegram',
      'times',
      'tv',
      'twitter',
      'user',
      'video',
      'vimeo',
      'volume',
      'volume-xmark',
      'whatsapp',
      'youtube',
      'xmark',
    ],
    blocks: {
      Root: true,
      Section: {
        padding: true,
        position: true,
        stackOrder: true,
        gradient: true,
        bgImageSource: true,
        height: true,
      },
      Container: {
        direction: true,
        justify: true,
        align: true,
        position: true,
        transformTranslate: true,
        maxWidth: true,
        bgColor: true,
        gradient: true,
        bgImageSource: true,
      },
      Grid: {
        showGridOptions: true,
      },
      Box: {
        backgroundColor: true,
        clipPath: true,
        position: true,
        showFlexOptions: true,
        border: true,
        borderRadius: true,
        overflow: true,
        hidden: true,
        maxWidth: true,
      },
      Modal: true,
      Menu: true,
      Header: true,
      Hero: {
        title: {
          color: true,
          textCase: true,
          fontFamily: true,
          fontWeight: true,
          textSize: true,
        },
        titleSource: true,
        titleHyphenated: true,
        kicker: {
          color: true,
          textCase: true,
          fontFamily: true,
          fontWeight: true,
          textSize: true,
        },
        kickerSource: true,
        textPosition: true,
        textWidth: true,
        height: true,
        spacing: true,
        padding: true,
        imageSource: true,
        urlSource: true,
      },
      SimpleText: {
        noWrap: true,
        textAlign: true,
        textCase: true,
        textSizeCustom: true,
        fontFamily: true,
        source: true,
        url: true,
        urlSource: true,
        opacity: true,
      },
      LabelIcon: {
        iconPositions: ['top', 'right', 'bottom', 'left'],
      },
      RichText: {
        source: true,
      },
      Image: {
        aspectRatio: true,
        svgInline: true,
        svgColor: true,
        height: true,
        source: true,
        borderRadius: true,
        urlSource: true,
      },
      PageList: true,
      Player: {
        variant: true,
        poster: true,
        playIconText: true,
        playIconHoverColor: true,
      },
      Carousel: true,
      QuoteCarousel: {
        fontFamily: true,
        fontSerif: false,
        fontSansSerif: false,
      },
      Accordion: true,
      AccordionItem: true,
      AccordionItemContent: true,
      Card: {
        kicker: true,
      },
      ContentPlaceholder: {
        bgColor: true,
      },
      Form: {
        requiredMessage: true,
        emailErrorMessage: true,
        borderRadius: true,
        border: true,
        padding: true,
        backgroundColor: true,
        labelColor: true,
      },
      FormCaptcha: true,
      FormCheckbox: true,
      FormInput: {
        files: true,
      },
      FormSelect: true,
      FormTextArea: true,
      Button: {
        icon: true,
        buttonColor: true,
        urlSource: true,
      },
      LinkList: {
        title: true,
        variants: ['simple', 'text'],
      },
      LanguageMenu: true,
      Breadcrumbs: true,
      HtmlEmbed: true,
      ShareButton: true,
      SocialMediaLinks: true,
      Testimonies: true,
      // FeaturedContent: true,
      Map: true,
      Avatar: true,
      BibleVerse: true,
      NewsletterSubscription: {
        appearance: true,
        emailErrorMessage: true,
        emailPlaceholderLabel: true,
        requiredMessage: true,
        size: true,
        successButtonSize: true,
        successButtonVariant: true,
        successColor: true,
        successIconColor: true,
        termsColor: true,
        termsLinkColor: true,
        termsPage: true,
        title: true,
        titleColor: true,
      },
      NewsletterConfirmation: { appearance: true },

      // Articles
      ArticleList: {
        displayModes: ['cards', 'list'],
      },
      ArticleRepeater: {
        blockResource: true,
        resources: ['articles'],
      },
      ArticleDetail: true,
      ArticleArchive: true,

      // Media library
      CarouselEpisodeRepeater: {
        blockResource: true,
        resources: ['episodes', 'show'],
      },
      EpisodesList: true,
      EpisodeRepeater: {
        blockResource: true,
        resources: ['episodes', 'show'],
      },
      EpisodeDetail: true,
      EpisodePlayer: true,

      // Publications
      PublicationsList: {
        variants: {
          list: {
            displayModes: {
              posters: {},
              cards: {},
            },
          },
          menu: {
            showLabel: true,
          },
        },
      },
      PublicationDetail: {
        showTitle: true,
        variant: true,
        shareOptions: true,
        showAuthor: true,
        showBody: true,
        showCategories: true,
        showDescription: true,
        showFullContent: true,
        showDownloads: true,
        showImage: true,
        showPages: true,
        showPublisher: true,
        showReligion: true,
        showSubtitle: true,
        showType: true,
        showLanguages: true,
      },
      PublicationDownloads: {
        title: {
          color: true,
          textCase: true,
          fontFamily: true,
          fontWeight: true,
          textSize: true,
        },
      },
      PublicationsReligionsMenu: {
        variants: {
          simple: {
            showIcons: true,
          },
          dropdown: {
            showIcons: true,
          },
          large: {
            showAllLink: true,
            showIcons: true,
          },
        },
      },
      PublicationsSearch: {
        variants: {
          simple: {
            showAllLink: false,
          },
          dropdown: {
            showAllLink: true,
          },
        },
      },
      PublicationStatistics: {
        showPicker: true,
        showPublications: true,
        showLanguages: true,
        showDownloadCount: true,
      },
      PublishingHouses: true,
    },
  }
}
