import PropTypes from 'prop-types'
import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'

export default function LinkingFields({
  detailPagehelp,
  resourceIdentifier,
  showListPagePicker = true,
}) {
  const { t } = useTranslation('web/content-editor')

  const { actions, data } = useNode(node => ({
    data: node.data.props,
  }))

  return (
    <>
      <PagePicker
        label={t('detailPageId')}
        help={detailPagehelp}
        value={data.detailPageId}
        onChange={pageId =>
          actions.setProp(props => (props.detailPageId = pageId))
        }
        condition={page =>
          page.dynamic &&
          getDynamicResourceIdentifier(page) === resourceIdentifier
        }
      />

      {showListPagePicker && (
        <PagePicker
          label={t('listPageId')}
          help={t('listPageIdHelp')}
          value={data.listPageId}
          onChange={pageId =>
            actions.setProp(props => (props.listPageId = pageId))
          }
        />
      )}
    </>
  )
}
LinkingFields.propTypes = {
  detailPagehelp: PropTypes.string,
  resourceIdentifier: PropTypes.string.isRequired,
  showListPagePicker: PropTypes.bool,
}
