import PropTypes from 'prop-types'
import React from 'react'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export function TypeOption({ value, label, icon }) {
  return (
    <div className="flex items-center gap-2">
      <Icon name={icon} />
      <span>{label ?? value}</span>
    </div>
  )
}

TypeOption.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
}
